import BigNumber from 'bignumber.js';
import { isNil, isNaN } from 'lodash';

const ONE_MILLION = 1000000,
  ZERO = 0;
export class TokenAmount {
  constructor (wei, decimals = 0, isWei = true) {
    this.decimals = decimals;
    this._decimals = new BigNumber(10).exponentiatedBy(decimals);

    if (isWei) {
      this.wei = new BigNumber(wei)
    } else {
      this.wei = new BigNumber(wei).multipliedBy(this._decimals)
    }
  }

  toEther() {
    return this.wei.dividedBy(this._decimals)
  }

  toWei() {
    return this.wei
  }

  format() {
    return this.wei.dividedBy(this._decimals).toFormat(this.decimals)
  }

  fixed() {
    return this.wei.dividedBy(this._decimals).toFixed(this.decimals)
  }

  isNullOrZero() {
    return this.wei.isNaN() || this.wei.isZero()
  }
}

export const getFormattedNumber = function (num, minimumFractionDigits = 2, maximumFractionDigits = 2) {
  // `isNil` check for `null` or `undefined`
  // `num !== num` check for `NaN`
  if (isNil(num) || isNaN(num)) {
    return ZERO.toFixed(2);
  }

  if (num < ONE_MILLION) {
    return num.toLocaleString(undefined, { minimumFractionDigits, maximumFractionDigits });
  }

  const units = ['M','B','T','Q'],
    unit = Math.floor((num / 1.0e+1).toFixed(0).toString().length),
    r = unit%3,
    x =  Math.abs(Number(num))/Number('1.0e+'+(unit-r)).toFixed(2);
  
  return x.toFixed(2)+ '' + units[Math.floor(unit / 3) - 2];
}