/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
// Solana Modules
import * as anchorold from '@project-serum/anchor';
import * as anchorlatest from 'anchorlatest';
import * as serum from '@project-serum/serum';
import {PublicKey, SystemProgram, SYSVAR_CLOCK_PUBKEY, Transaction, TransactionInstruction} from '@solana/web3.js';
import * as serumAssoToken from '@project-serum/associated-token';
import * as splToken from "@solana/spl-token";
import { findIndex, assign } from 'lodash';
import * as BufferLayout from "buffer-layout";

// Store
import { getStore } from '../stores/get-store';

// Utils
import {
  getFarmPoolAuthority,
  getFarmPoolId,
  getFarmProgramId,
  getVaultAccount,
  getVaultInfoAccount,
  getVaultLpTokenAccount,
  getVaultPdaAccount,
  getFarmPoolLpTokenAccount,
  getVaultProgramId,
  getFarmLpMintAddress,
  getVaultRewardAccountA,
  getVaultRewardAccountB,
  getFarmPoolRewardATokenAccount,
  getFarmPoolRewardBTokenAccount,
  getFarmFusion,
  getVaultTulipTokenAccount,
  getLendingProgramId,
  getLendingFarmAccount,
  getLendingFarmProgramId,
  getLendingReserve,
  getLendingMarketAccount,
  getVaultSerumOpenOrdersAccount,
  getVaultSerumVaultSigner,
  getLendingFarmManagementAccount,
  getPriceFeedsForReserve,
  getLendingReserveFromKey,
  getSaberVaultProgramId,
  getSaberVaultLpTokenMint,
  getSaberVaultAccount,
  getSaberVaultPdaAccount,
  getSaberAssociatedVaultFarmerAccount,
  getSaberVaultFarmPlot,
  getSaberFarmVaultLpTokenAccount,
  getSaberVaultTempLpTokenAccount,
  getSaberVaultLandlord,
  getSaberVaultFarmProgram,
  getVaultOldInfoAccount,
  getSaberFarmSunnyPool,
  getSaberFarmSunnyVaultAccount,
  getSaberFarmSunnyFarmer,
  getSaberFarmSunnyFarmerVault,
  getSaberFarmSunnyFarmTokenAccount,
  getSaberFarmSunnyMineProgram,
  getSaberFarmSunnyFarmMint,
  getSaberFarmSunnyRewarder,
  getSaberFarmSunnyQuarry,
  getSaberFarmSunnyMiner,
  getSaberFarmSunnyMinerVault,
  getSaberFarmSunnyVaultFarmTokenAccount,
  getSaberFarmSunnyVaultLpTokenAccount,
  getSaberVaultSunnyFarmProgram,
  getSaberFarmQuarryMiner,
  getSaberFarmQuarry,
  getSaberFarmQuarryMinerVault,
  getSaberFarmQuarryRewarder,
  getOrcaVaultProgramId,
  getOrcaFarmPoolCoinTokenaccount,
  getOrcaFarmPoolPcTokenaccount,
  getOrcaLpMintAddress,
  getOrcaVaultAccount,
  getOrcaVaultLpAccount,
  getOrcaVaultLpMint,
  getOrcaVaultFarmMint,
  getOrcaVaultRewardMint,
  getOrcaVaultGlobalBaseTokenVault,
  getOrcaVaultGlobalFarm,
  getOrcaVaultGlobalRewardTokenVault,
  getOrcaVaultConvertAuthority,
  getOrcaVaultFeeAccount,
  getOrcaVaultSwapPoolTokenAccount,
  getOrcaFarmAmmId,
  getOrcaFarmAmmAuthority, getOrcaFarmFeeAccount, getOrcaManagementAccount
} from '../utils/config';
import { TOKENS } from '../utils/tokens';
import {
  commitment,
  // createOpenOrdersAccount,
  getMultipleAccounts,
  sendTransaction,
  createAssociatedTokenAccount,
  getAccount,
  sendAllTransactions
} from '../utils/web3';
import { getFarmBySymbol } from '../utils/farms';
import {
  findObligationVaultAddress,
  findUserFarmAddress,
  findUserFarmManagerAddress,
  findUserFarmObligationAddress,
  findLeveragedFarmAddress,
  findBorrowAuthorizer, findOrcaUserFarmAddress
} from '../utils/leverageFarmUtils';
import { getReserveByName } from '../utils/lendingReserves';
import {_OPEN_ORDERS_LAYOUT_V2} from "@project-serum/serum/lib/market";
import { LendingInstruction } from '../view/LendPage/LendBody';
import {
  AQUAFARM_PROGRAM_ID,
  LENDING_PROGRAM_ID,
  ORCA_SWAP_PROGRAM_ID,
  QUARRY_MINE_PROGRAM,
  TOKEN_PROGRAM_ID
} from '../utils/ids';
import {deriveVaultUserAccount} from "../utils/vault";
import {TokenAmount} from "../utils/safe-math";
import {ACCOUNT_LAYOUT, WAD} from "../utils/layouts";
import * as anchor from "@project-serum/anchor";
import {Token} from "@solana/spl-token";
import { FARM_PLATFORMS } from '../constants/farmConstants';
import {ALL_PLATFORMS} from "../utils/vaultFilterUtils";

// IDL
const idl = require('../idl/vault.json');
const saberIdl = require('../idl/saber_idl.json');
const farmIdl = require('../idl/farm.json');
const orcaIdl = require('../idl/orca_idl.json');

export const TransactionService = {
  /**
   *
   * @param {String} assetSymbol
   * @param {String|Number} value
   *
   * @returns {Promise}
   */
  async handleDepositToVault (assetSymbol, value) {
    const {decimals, saber, sunny} = getFarmBySymbol(assetSymbol) || {};
    let anchor = anchorold;

    if (saber) {
      anchor = anchorlatest
    }

    const { wallet, tokenAccounts, isMintAddressExisting } = getStore('WalletStore'),
      walletToInitialize = {
        signTransaction: wallet.signTransaction,
        signAllTransactions: wallet.signAllTransactions,
        publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
      },
      provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: false, preflightCommitment: commitment }),
      tulipPubKey = new anchor.web3.PublicKey(TOKENS.TULIP.mintAddress);

    anchor.setProvider(provider);

    // Address of the deployed program.
    const vaultProgramId = new anchor.web3.PublicKey(getVaultProgramId());
    // Generate the program client from IDL.
    const vaultProgram = new anchor.Program(idl, vaultProgramId);

    const saberVaultProgramId = new anchor.web3.PublicKey(getSaberVaultProgramId());
    const saberVaultProgram = new anchor.Program(saberIdl, saberVaultProgramId);

    const txn = new anchor.web3.Transaction();
    if (saber) {
      let authorityTokenAccount = tokenAccounts[getSaberVaultLpTokenMint(assetSymbol)]?.tokenAccountAddress;

      let [saberVaultUserAccountAddress, saberVaultUserAccountNonce] = await deriveVaultUserAccount(
          new anchor.web3.PublicKey(getSaberVaultAccount(assetSymbol)),
          provider.wallet.publicKey,
          saberVaultProgramId,
      );

      if (sunny) {
        const saberDepositAccounts = {
          authority: provider.wallet.publicKey,
          vaultUserAccount: saberVaultUserAccountAddress,
          vaultAccount: new anchor.web3.PublicKey(getSaberVaultAccount(assetSymbol)),
          vaultPdaSigner: new anchor.web3.PublicKey(getSaberVaultPdaAccount(assetSymbol)),
          sunnyPool: new anchor.web3.PublicKey(getSaberFarmSunnyPool(assetSymbol)),
          sunnyVaultAccount: new anchor.web3.PublicKey(getSaberFarmSunnyVaultAccount(assetSymbol)),
          tokenProgram: serum.TokenInstructions.TOKEN_PROGRAM_ID,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          sunnyVaultFarmTokenAccount: new anchor.web3.PublicKey(getSaberFarmSunnyVaultFarmTokenAccount(assetSymbol)),
          sunnyVaultLpTokenAccount: new anchor.web3.PublicKey(getSaberFarmSunnyVaultLpTokenAccount(assetSymbol)),
          sunnyFarmProgram: new anchor.web3.PublicKey(getSaberVaultSunnyFarmProgram(assetSymbol)),
          saberLandlord: new anchor.web3.PublicKey(getSaberVaultLandlord(assetSymbol)),
          saberFarmPlot: new anchor.web3.PublicKey(getSaberVaultFarmPlot(assetSymbol)),
          sunnyFarmer: new anchor.web3.PublicKey(getSaberFarmSunnyFarmer(assetSymbol)),
          sunnyFarmerVault: new anchor.web3.PublicKey(getSaberFarmSunnyFarmerVault(assetSymbol)),
          // sunnyFarmTokenAccount: new anchor.web3.PublicKey(getSaberFarmSunnyFarmTokenAccount(assetSymbol)),
          sunnyMineProgram: new anchor.web3.PublicKey(getSaberFarmSunnyMineProgram(assetSymbol)),
          sunnyFarmMint: new anchor.web3.PublicKey(getSaberFarmSunnyFarmMint(assetSymbol)),
          sunnyRewarder: new anchor.web3.PublicKey(getSaberFarmSunnyRewarder(assetSymbol)),
          sunnyQuarry: new anchor.web3.PublicKey(getSaberFarmSunnyQuarry(assetSymbol)),
          sunnyMiner: new anchor.web3.PublicKey(getSaberFarmSunnyMiner(assetSymbol)),
          sunnyMinerVault: new anchor.web3.PublicKey(getSaberFarmSunnyMinerVault(assetSymbol)),
          incomingTokenAccount: new anchor.web3.PublicKey(
              authorityTokenAccount,
          ),
          systemProgram: anchor.web3.SystemProgram.programId,
          saberFarmProgram: new anchor.web3.PublicKey(getSaberVaultFarmProgram(assetSymbol)),
        }

        txn.add(
            saberVaultProgram.instruction.depositSunnyVault(
                new anchor.BN(Number(value) * Math.pow(10, Number(decimals))),
                saberVaultUserAccountNonce,
                {
                  accounts: saberDepositAccounts,
                }
            )
        );
      } else {
        const saberDepositAccounts = {
          authority: provider.wallet.publicKey,
          vaultAccount:  new anchor.web3.PublicKey(getSaberVaultAccount(assetSymbol)),
          vaultUserAccount: saberVaultUserAccountAddress,
          vaultPdaSigner: new anchor.web3.PublicKey(getSaberVaultPdaAccount(assetSymbol)),
          sourceTokenAccount: new anchor.web3.PublicKey(
              authorityTokenAccount,
          ),
          vaultTempLpTokenAccount: new anchor.web3.PublicKey(getSaberVaultTempLpTokenAccount(assetSymbol)),
          miner:  new anchor.web3.PublicKey(getSaberFarmQuarryMiner(assetSymbol)),
          quarry: new anchor.web3.PublicKey(getSaberFarmQuarry(assetSymbol)),
          minerVault: new anchor.web3.PublicKey(getSaberFarmQuarryMinerVault(assetSymbol)),
          quarryMineProgram: QUARRY_MINE_PROGRAM,
          rewarder: new anchor.web3.PublicKey(getSaberFarmQuarryRewarder(assetSymbol)),

          tokenProgram: serum.TokenInstructions.TOKEN_PROGRAM_ID,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          systemProgram: anchor.web3.SystemProgram.programId,
        }

        txn.add(
            saberVaultProgram.instruction.depositQuarry(
                new anchor.BN(Number(value) * Math.pow(10, Number(decimals))),
                saberVaultUserAccountNonce,
                {
                  accounts: saberDepositAccounts,
                }
            )
        );
      }
    } else {
      let authorityTokenAccount = tokenAccounts[getFarmLpMintAddress(assetSymbol)].tokenAccountAddress;
      const [
        userBalanceAccount,
        userBalanceAccountNonce,
      ] = await anchor.web3.PublicKey.findProgramAddress(
          [
            new anchor.web3.PublicKey(getVaultOldInfoAccount(assetSymbol)).toBytes(),
            provider.wallet.publicKey.toBytes(),
          ],
          vaultProgramId
      );

      const [
        userBalanceMetadataAccount,
        userBalanceMetadataAccountNonce
      ] = await anchor.web3.PublicKey.findProgramAddress(
          [
            userBalanceAccount.toBuffer(),
            provider.wallet.publicKey.toBytes()
          ],
          vaultProgramId
      );

      const [
        tulipRewardMetadataAccount,
        tulipRewardMetadataNonce
      ] = await anchor.web3.PublicKey.findProgramAddress(
          [
            userBalanceMetadataAccount.toBytes(),
            provider.wallet.publicKey.toBytes()
          ],
          vaultProgramId,
      );

      const tulipRewardTokenAccount = await serumAssoToken.getAssociatedTokenAddress(
          wallet.publicKey,
          tulipPubKey
          );
      //     tulipRewardTokenAccountInfo = await window.$web3.getAccountInfo(tulipRewardTokenAccount);


      const depositAccounts = {
        vault: new anchor.web3.PublicKey(getVaultAccount(assetSymbol)),
        lpTokenAccount: new anchor.web3.PublicKey(
            getVaultLpTokenAccount(assetSymbol)
        ),
        authorityTokenAccount: new anchor.web3.PublicKey(
            authorityTokenAccount,
        ),
        authority: provider.wallet.publicKey,
        stakeProgramId: new anchor.web3.PublicKey(getFarmProgramId(assetSymbol)),
        vaultPdaAccount: new anchor.web3.PublicKey(
            getVaultPdaAccount(assetSymbol)
        ),
        poolId: new anchor.web3.PublicKey(getFarmPoolId(assetSymbol)),
        poolAuthority: new anchor.web3.PublicKey(getFarmPoolAuthority(assetSymbol)),
        userInfoAccount: new anchor.web3.PublicKey(
            getVaultInfoAccount(assetSymbol)
        ),
        userLpTokenAccount: new anchor.web3.PublicKey(
            getVaultLpTokenAccount(assetSymbol)
        ),
        poolLpTokenAccount: new anchor.web3.PublicKey(
            getFarmPoolLpTokenAccount(assetSymbol)
        ),
        userRewardATokenAccount: new anchor.web3.PublicKey(
            getVaultRewardAccountA(assetSymbol)
        ),
        poolRewardATokenAccount: new anchor.web3.PublicKey(
            getFarmPoolRewardATokenAccount(assetSymbol)
        ),
        userRewardBTokenAccount: new anchor.web3.PublicKey(
            getVaultRewardAccountA(assetSymbol)
        ),
        poolRewardBTokenAccount: new anchor.web3.PublicKey(
            getFarmPoolRewardBTokenAccount(assetSymbol)
        ),
        userBalanceAccount: userBalanceAccount,
        userBalanceMetadata: userBalanceMetadataAccount,
        userTulipRewardMetadata: tulipRewardMetadataAccount,
        vaultTulipTokenAccount: new anchor.web3.PublicKey(
            getVaultTulipTokenAccount(assetSymbol)
        ),
        userTulipTokenAccount: tulipRewardTokenAccount,
        clock: SYSVAR_CLOCK_PUBKEY,
        tokenProgramId: serum.TokenInstructions.TOKEN_PROGRAM_ID,
        systemProgram: new anchor.web3.PublicKey(
            "11111111111111111111111111111111"
        ),
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      };

      if (getFarmFusion(assetSymbol)) {
        depositAccounts.userRewardBTokenAccount = new anchor.web3.PublicKey(
            getVaultRewardAccountB(assetSymbol)
        );
      }


      if (!isMintAddressExisting(TOKENS.TULIP.mintAddress)) {
        // add instruction to the deposit transaction
        // to also create $TULIP (rewards) token account
        txn.add(
            await serumAssoToken.createAssociatedTokenAccount(
                // who will pay for the account creation
                wallet.publicKey,

                // who is the account getting created for
                wallet.publicKey,

                // what mint address token is being created
                tulipPubKey
            )
        );
      }

      // Add tulip harvest instruction
      const harvestAccounts = {
        authority: provider.wallet.publicKey,
        vault: new anchor.web3.PublicKey(getVaultAccount(assetSymbol)),
        vaultPdaAccount: new anchor.web3.PublicKey(
            getVaultPdaAccount(assetSymbol)
        ),
        userInfoAccount: new anchor.web3.PublicKey(
            getVaultInfoAccount(assetSymbol)
        ),
        userBalanceAccount: userBalanceAccount,
        userBalanceMetadata: userBalanceMetadataAccount,
        userTulipRewardMetadata: tulipRewardMetadataAccount,
        userTulipTokenAccount: tulipRewardTokenAccount,
        vaultTulipTokenAccount: new anchor.web3.PublicKey(
            getVaultTulipTokenAccount(assetSymbol)
        ),
        tokenProgramId: serum.TokenInstructions.TOKEN_PROGRAM_ID,
        clock: SYSVAR_CLOCK_PUBKEY,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        systemProgram: new anchor.web3.PublicKey(
            "11111111111111111111111111111111"
        ),
      };

      txn.add(
          vaultProgram.instruction.harvestTulips(
              {
                nonce: userBalanceAccountNonce,
                metaNonce: userBalanceMetadataAccountNonce,
                rewardNonce: tulipRewardMetadataNonce
              },
              {
                accounts: harvestAccounts,
              }
          )
      );

      // Add deposit instruction
      txn.add(
          vaultProgram.instruction.depositVault(
              {
                nonce: userBalanceAccountNonce,
                amount: new anchor.BN(Number(value) * Math.pow(10, Number(decimals))),
                metaNonce: userBalanceMetadataAccountNonce,
                rewardNonce: tulipRewardMetadataNonce
              },
              {
                accounts: depositAccounts,
              }
          )
      );
    }

    return await sendTransaction(window.$web3, wallet, txn, []);
  },

  /**
   *
   * @param {String} assetSymbol
   * @param {String|Number} value
   * @param {Boolean} withdrawMax
   *
   * @returns {Promise}
   */
  async handleWithdrawFromVault (assetSymbol, value, withdrawMax) {
    const {decimals, saber, sunny} = getFarmBySymbol(assetSymbol) || {};
    let anchor = anchorold;

    if (saber) {
      anchor = anchorlatest
    }

    const { wallet, tokenAccounts } = getStore('WalletStore'),
      // account for RAY-SOL
      // authorityTokenAccount = tokenAccounts[getFarmLpMintAddress(assetSymbol)].tokenAccountAddress,
      walletToInitialize = {
        signTransaction: wallet.signTransaction,
        signAllTransactions: wallet.signAllTransactions,
        publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
      };

    const provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: false, preflightCommitment: commitment});
    anchor.setProvider(provider);

    // Address of the deployed program.
    const vaultProgramId = new anchor.web3.PublicKey(getVaultProgramId());
    // Generate the program client from IDL.
    const vaultProgram = new anchor.Program(idl, vaultProgramId);

    const saberVaultProgramId = new anchor.web3.PublicKey(getSaberVaultProgramId());
    const saberVaultProgram = new anchor.Program(saberIdl, saberVaultProgramId);

    const txn = new anchor.web3.Transaction();

    let withdrawAmount;


    if (saber) {
      let authorityTokenAccount = tokenAccounts[getSaberVaultLpTokenMint(assetSymbol)]?.tokenAccountAddress;

      let [saberVaultUserAccountAddress, _] = await deriveVaultUserAccount(
          new anchor.web3.PublicKey(getSaberVaultAccount(assetSymbol)),
          provider.wallet.publicKey,
          saberVaultProgramId,
      );

      if (withdrawMax) {
        const accountsToFetch = [saberVaultUserAccountAddress],
            [
              userAccountInfo
            ] = await getMultipleAccounts(window.$web3, accountsToFetch, commitment),

            decodedUserAccountInfo = saberVaultProgram.coder.accounts.decode('VaultUserAccount', userAccountInfo.account.data);
        withdrawAmount = decodedUserAccountInfo.shares;
      } else {
        const accountsToFetch = [new anchor.web3.PublicKey(getSaberVaultAccount(assetSymbol))],
            [
              vaultAccountInfo
            ] = await getMultipleAccounts(window.$web3, accountsToFetch, commitment),

            decodedVaultAccountInfo = saberVaultProgram.coder.accounts.decode('VaultAccount', vaultAccountInfo.account.data);
        let { totalVaultBalance, totalVlpShares } = decodedVaultAccountInfo || {},
            { decimals } = getFarmBySymbol(assetSymbol) || {};

        const userInputValue = new anchor.BN(value * Math.pow(10, decimals));

        withdrawAmount = ((userInputValue.mul(totalVlpShares)).div(totalVaultBalance));
      }


      if (sunny) {
        const saberWithdrawAccounts = {
          authority: provider.wallet.publicKey,
          vaultUserAccount: saberVaultUserAccountAddress,
          vaultAccount: new anchor.web3.PublicKey(getSaberVaultAccount(assetSymbol)),
          vaultPdaSigner: new anchor.web3.PublicKey(getSaberVaultPdaAccount(assetSymbol)),
          sunnyPool: new anchor.web3.PublicKey(getSaberFarmSunnyPool(assetSymbol)),
          sunnyVaultAccount: new anchor.web3.PublicKey(getSaberFarmSunnyVaultAccount(assetSymbol)),
          tokenProgram: serum.TokenInstructions.TOKEN_PROGRAM_ID,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          sunnyVaultFarmTokenAccount: new anchor.web3.PublicKey(getSaberFarmSunnyVaultFarmTokenAccount(assetSymbol)),
          sunnyVaultLpTokenAccount: new anchor.web3.PublicKey(getSaberFarmSunnyVaultLpTokenAccount(assetSymbol)),
          sunnyFarmProgram: new anchor.web3.PublicKey(getSaberVaultSunnyFarmProgram(assetSymbol)),
          saberLandlord: new anchor.web3.PublicKey(getSaberVaultLandlord(assetSymbol)),
          saberFarmPlot: new anchor.web3.PublicKey(getSaberVaultFarmPlot(assetSymbol)),
          sunnyFarmer: new anchor.web3.PublicKey(getSaberFarmSunnyFarmer(assetSymbol)),
          sunnyFarmerVault: new anchor.web3.PublicKey(getSaberFarmSunnyFarmerVault(assetSymbol)),
          // sunnyFarmTokenAccount: new anchor.web3.PublicKey(getSaberFarmSunnyFarmTokenAccount(assetSymbol)),
          sunnyMineProgram: new anchor.web3.PublicKey(getSaberFarmSunnyMineProgram(assetSymbol)),
          sunnyFarmMint: new anchor.web3.PublicKey(getSaberFarmSunnyFarmMint(assetSymbol)),
          sunnyRewarder: new anchor.web3.PublicKey(getSaberFarmSunnyRewarder(assetSymbol)),
          sunnyQuarry: new anchor.web3.PublicKey(getSaberFarmSunnyQuarry(assetSymbol)),
          sunnyMiner: new anchor.web3.PublicKey(getSaberFarmSunnyMiner(assetSymbol)),
          sunnyMinerVault: new anchor.web3.PublicKey(getSaberFarmSunnyMinerVault(assetSymbol)),
          saberFarmProgram: new anchor.web3.PublicKey(getSaberVaultFarmProgram(assetSymbol)),
          systemProgram: anchor.web3.SystemProgram.programId,
          receivingLpTokenAccount: new anchor.web3.PublicKey(authorityTokenAccount)
          // vaultTempLpTokenAccount: new anchor.web3.PublicKey(getSaberVaultTempLpTokenAccount(assetSymbol)),
        }

        txn.add(
            saberVaultProgram.instruction.withdrawSunnyVaultTwo(
                new anchor.BN(withdrawAmount),
                {
                  accounts: saberWithdrawAccounts,
                  // remainingAccounts: [
                  //   {
                  //     isSigner: false,
                  //     isWritable: true,
                  //     pubkey: new anchor.web3.PublicKey(authorityTokenAccount),
                  //   }
                  // ]
                }
            )
        );
      } else {
        const saberWithdrawAccounts = {
          authority: provider.wallet.publicKey,
          vaultAccount: new anchor.web3.PublicKey(getSaberVaultAccount(assetSymbol)),
          vaultUserAccount: saberVaultUserAccountAddress,
          vaultPdaSigner: new anchor.web3.PublicKey(getSaberVaultPdaAccount(assetSymbol)),
          receivingTokenAccount: new anchor.web3.PublicKey(
              authorityTokenAccount),
          vaultTempLpTokenAccount: new anchor.web3.PublicKey(getSaberVaultTempLpTokenAccount(assetSymbol)),

          miner:  new anchor.web3.PublicKey(getSaberFarmQuarryMiner(assetSymbol)),
          quarry: new anchor.web3.PublicKey(getSaberFarmQuarry(assetSymbol)),
          minerVault: new anchor.web3.PublicKey(getSaberFarmQuarryMinerVault(assetSymbol)),
          quarryMineProgram: QUARRY_MINE_PROGRAM,
          rewarder: new anchor.web3.PublicKey(getSaberFarmQuarryRewarder(assetSymbol)),

          tokenProgram: serum.TokenInstructions.TOKEN_PROGRAM_ID,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
        }

        txn.add(
            saberVaultProgram.instruction.withdrawQuarry(
                new anchor.BN(withdrawAmount),
                {
                  accounts: saberWithdrawAccounts,
                }
            )
        );
      }
    } else {

      const { migrateAccount } = getStore('FarmStore').getFarm(getFarmLpMintAddress(assetSymbol)) || {};
      const [
        userBalanceAccount,
        userBalanceAccountNonce,
      ] = await anchor.web3.PublicKey.findProgramAddress(
          [
            new anchor.web3.PublicKey(getVaultOldInfoAccount(assetSymbol)).toBytes(),
            provider.wallet.publicKey.toBytes(),
          ],
          vaultProgramId
      );

      let authorityTokenAccount = tokenAccounts[getFarmLpMintAddress(assetSymbol)].tokenAccountAddress;

      const [
        userBalanceMetadataAccount,
        userBalanceMetadataAccountNonce
      ] = await anchor.web3.PublicKey.findProgramAddress(
          [
            userBalanceAccount.toBuffer(),
            provider.wallet.publicKey.toBytes()
          ],
          vaultProgramId
      );

      const [
        tulipRewardMetadataAccount,
        tulipRewardMetadataNonce
      ] = await anchor.web3.PublicKey.findProgramAddress(
          [
            userBalanceMetadataAccount.toBytes(),
            provider.wallet.publicKey.toBytes()
          ],
          vaultProgramId,
      );

      if (withdrawMax) {
        let vaultBalanceAccount = await vaultProgram.account.vaultBalanceAccount(userBalanceAccount);

        withdrawAmount = vaultBalanceAccount.amount;
      } else {
        let vault = await vaultProgram.account.vault(new anchor.web3.PublicKey(getVaultAccount(assetSymbol))),
            { totalVaultBalance, totalVlpShares } = vault || {},
            { decimals } = getFarmBySymbol(assetSymbol) || {};

        const userInputValue = new anchor.BN(value * Math.pow(10, decimals));

        withdrawAmount = ((userInputValue.mul(totalVlpShares)).div(totalVaultBalance));
      }

      const withdrawAccounts = {
        vault: new anchor.web3.PublicKey(getVaultAccount(assetSymbol)),
        lpTokenAccount: new anchor.web3.PublicKey(
            getVaultLpTokenAccount(assetSymbol)
        ),
        authorityTokenAccount: new anchor.web3.PublicKey(
            authorityTokenAccount,
        ),
        authority: provider.wallet.publicKey,
        stakeProgramId: new anchor.web3.PublicKey(getFarmProgramId(assetSymbol)),
        vaultPdaAccount: new anchor.web3.PublicKey(
            getVaultPdaAccount(assetSymbol)
        ),
        poolId: new anchor.web3.PublicKey(getFarmPoolId(assetSymbol)),
        poolAuthority: new anchor.web3.PublicKey(getFarmPoolAuthority(assetSymbol)),
        userInfoAccount: new anchor.web3.PublicKey(
            getVaultInfoAccount(assetSymbol)
        ),
        // userLpTokenAccount: new anchor.web3.PublicKey(
        //     getVaultLpTokenAccount(assetSymbol)
        // ),
        poolLpTokenAccount: new anchor.web3.PublicKey(
            getFarmPoolLpTokenAccount(assetSymbol)
        ),
        userRewardATokenAccount: new anchor.web3.PublicKey(
            getVaultRewardAccountA(assetSymbol)
        ),
        poolRewardATokenAccount: new anchor.web3.PublicKey(
            getFarmPoolRewardATokenAccount(assetSymbol)
        ),
        userRewardBTokenAccount: new anchor.web3.PublicKey(
            getVaultRewardAccountA(assetSymbol)
        ),
        poolRewardBTokenAccount: new anchor.web3.PublicKey(
            getFarmPoolRewardBTokenAccount(assetSymbol)
        ),
        userBalanceAccount: userBalanceAccount,
        userBalanceMeta: userBalanceMetadataAccount,
        userTulipRewardMetadata: tulipRewardMetadataAccount,
        clock: SYSVAR_CLOCK_PUBKEY,
        tokenProgramId: serum.TokenInstructions.TOKEN_PROGRAM_ID,
        systemProgram: new anchor.web3.PublicKey(
            "11111111111111111111111111111111"
        ),
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      };

      if (getFarmFusion(assetSymbol)) {
        withdrawAccounts.userRewardBTokenAccount = new anchor.web3.PublicKey(
            getVaultRewardAccountB(assetSymbol)
        );
      }

      // Add tulip harvest instruction
      const tulipPubKey = new anchor.web3.PublicKey(TOKENS.TULIP.mintAddress);

      const tulipRewardTokenAccount = await serumAssoToken.getAssociatedTokenAddress(
          wallet.publicKey,
          tulipPubKey
      );

      // console.log("migrate account", migrateAccount);
      let harvestAccounts;
      if (migrateAccount){
        const [
          newUserBalanceAccount,
          newUserBalanceAccountNonce,
        ] = await anchor.web3.PublicKey.findProgramAddress(
            [
              new anchor.web3.PublicKey(getVaultInfoAccount(assetSymbol)).toBytes(),
              provider.wallet.publicKey.toBytes(),
            ],
            vaultProgramId
        );


        const [
          newUserBalanceMetadataAccount,
          newUserBalanceMetadataAccountNonce
        ] = await anchor.web3.PublicKey.findProgramAddress(
            [
              newUserBalanceAccount.toBuffer(),
              provider.wallet.publicKey.toBytes()
            ],
            vaultProgramId
        );

        const [
          newTulipRewardMetadataAccount,
          newTulipRewardMetadataNonce
        ] = await anchor.web3.PublicKey.findProgramAddress(
            [
              newUserBalanceMetadataAccount.toBytes(),
              provider.wallet.publicKey.toBytes()
            ],
            vaultProgramId,
        );

        harvestAccounts = {
          authority: provider.wallet.publicKey,
          vault: new anchor.web3.PublicKey(getVaultAccount(assetSymbol)),
          vaultPdaAccount: new anchor.web3.PublicKey(
              getVaultPdaAccount(assetSymbol)
          ),
          userInfoAccount: new anchor.web3.PublicKey(
              getVaultInfoAccount(assetSymbol)
          ),
          userBalanceAccount: newUserBalanceAccount,
          userBalanceMetadata: newUserBalanceMetadataAccount,
          userTulipRewardMetadata: newTulipRewardMetadataAccount,
          oldUserBalanceAccount: userBalanceAccount,
          oldUserBalanceMetadata: userBalanceMetadataAccount,
          oldUserTulipRewardMetadata: tulipRewardMetadataAccount,
          userTulipTokenAccount: tulipRewardTokenAccount,
          vaultTulipTokenAccount: new anchor.web3.PublicKey(
              getVaultTulipTokenAccount(assetSymbol)
          ),
          tokenProgramId: serum.TokenInstructions.TOKEN_PROGRAM_ID,
          clock: SYSVAR_CLOCK_PUBKEY,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          systemProgram: new anchor.web3.PublicKey(
              "11111111111111111111111111111111"
          ),
        };

        txn.add(
            vaultProgram.instruction.harvestMigrateTulips(
                {
                  nonce: newUserBalanceAccountNonce,
                  metaNonce: newUserBalanceMetadataAccountNonce,
                  rewardNonce: newTulipRewardMetadataNonce,
                  oldNonce: userBalanceAccountNonce,
                  oldMetaNonce: userBalanceMetadataAccountNonce,
                  oldRewardNonce: tulipRewardMetadataNonce
                },
                {
                  accounts: harvestAccounts,
                }
            )
        );
      } else  {
        harvestAccounts = {
          authority: provider.wallet.publicKey,
          vault: new anchor.web3.PublicKey(getVaultAccount(assetSymbol)),
          vaultPdaAccount: new anchor.web3.PublicKey(
              getVaultPdaAccount(assetSymbol)
          ),
          userInfoAccount: new anchor.web3.PublicKey(
              getVaultInfoAccount(assetSymbol)
          ),
          userBalanceAccount: userBalanceAccount,
          userBalanceMetadata: userBalanceMetadataAccount,
          userTulipRewardMetadata: tulipRewardMetadataAccount,
          userTulipTokenAccount: tulipRewardTokenAccount,
          vaultTulipTokenAccount: new anchor.web3.PublicKey(
              getVaultTulipTokenAccount(assetSymbol)
          ),
          tokenProgramId: serum.TokenInstructions.TOKEN_PROGRAM_ID,
          clock: SYSVAR_CLOCK_PUBKEY,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          systemProgram: new anchor.web3.PublicKey(
              "11111111111111111111111111111111"
          ),
        };

        txn.add(
            vaultProgram.instruction.harvestTulips(
                {
                  nonce: userBalanceAccountNonce,
                  metaNonce: userBalanceMetadataAccountNonce,
                  rewardNonce: tulipRewardMetadataNonce
                },
                {
                  accounts: harvestAccounts,
                }
            )
        );
      }

      // Add withdraw instruction
      txn.add(
          vaultProgram.transaction.withdrawVault(
              {
                amount: withdrawAmount,
                metaNonce: userBalanceMetadataAccountNonce,
                rewardNonce: tulipRewardMetadataNonce,
                nonce: userBalanceAccountNonce
              },
              {
                accounts: withdrawAccounts,
              }
          )
      );
    }

    return await sendTransaction(window.$web3, wallet, txn, []);
  },

  /**
   *
   * @param {String} assetSymbol
   * @param {String|Number} coin
   * @param {String|Number} pc
   *
   * @returns {Promise}
   */
  async handleDepositToOrcaVault (assetSymbol, coin, pc, isSingleDeposit) {
    const {decimals, platform} = getFarmBySymbol(assetSymbol) || {};
    const anchor = anchorlatest;

    const { wallet, tokenAccounts } = getStore('WalletStore'),
        walletToInitialize = {
          signTransaction: wallet.signTransaction,
          signAllTransactions: wallet.signAllTransactions,
          publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
        },
        provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: false, preflightCommitment: commitment }),
        tulipPubKey = new anchor.web3.PublicKey(TOKENS.TULIP.mintAddress);

    anchor.setProvider(provider);


    const orcaVaultProgramId = new anchor.web3.PublicKey(getOrcaVaultProgramId());
    const orcaVaultProgram = new anchor.Program(orcaIdl, orcaVaultProgramId);

    let [orcaVaultUserAccountAddress, orcaVaultUserAccountNonce] = await deriveVaultUserAccount(
        new anchor.web3.PublicKey(getOrcaVaultAccount(assetSymbol)),
        provider.wallet.publicKey,
        orcaVaultProgramId,
    );

    const vaultPdaAccount = new anchor.web3.PublicKey(
        getVaultPdaAccount(assetSymbol)
    );


    let vaultBaseTokenAccount =  await serumAssoToken.getAssociatedTokenAddress(vaultPdaAccount,  new anchor.web3.PublicKey(getOrcaVaultLpMint(assetSymbol)));
    let vaultFarmTokenAccount = await serumAssoToken.getAssociatedTokenAddress(vaultPdaAccount,  new anchor.web3.PublicKey(getOrcaVaultFarmMint(assetSymbol)));
    let vaultRewardTokenAccount = await serumAssoToken.getAssociatedTokenAddress(vaultPdaAccount,  new anchor.web3.PublicKey(getOrcaVaultRewardMint(assetSymbol)));

    let orcaGlobalFarm = new anchor.web3.PublicKey(getOrcaVaultGlobalFarm(assetSymbol));

    let [orcaUserFarm, _] = await findOrcaUserFarmAddress(orcaGlobalFarm, vaultPdaAccount, TOKEN_PROGRAM_ID, AQUAFARM_PROGRAM_ID);

    let dexProgramId = ORCA_SWAP_PROGRAM_ID;

    const farm = getFarmBySymbol(assetSymbol),
        baseToken = farm.coins[0], // base / coin
        quoteToken = farm.coins[1]; // quote / pc

    let coinSourceTokenAccount = new anchor.web3.PublicKey(tokenAccounts[baseToken.mintAddress]?.tokenAccountAddress);
    let pcSourceTokenAccount = new anchor.web3.PublicKey(tokenAccounts[quoteToken.mintAddress]?.tokenAccountAddress);
    const txn = new anchor.web3.Transaction();

    let signers = [];
    if (baseToken.symbol === "SOL") {
      const lamportsToCreateAccount = await window.$web3.getMinimumBalanceForRentExemption(
          ACCOUNT_LAYOUT.span,
          commitment
      );

      const newAccount = new anchor.web3.Account();
      signers.push(newAccount);

      coinSourceTokenAccount = newAccount.publicKey
      txn.add(
          SystemProgram.createAccount({
            fromPubkey: wallet.publicKey,
            newAccountPubkey: coinSourceTokenAccount,
            lamports: ( coin ) * Math.pow(10, baseToken.decimals) + lamportsToCreateAccount,
            space: ACCOUNT_LAYOUT.span,
            programId: TOKEN_PROGRAM_ID,
          })
      );

      txn.add(
          Token.createInitAccountInstruction(
              TOKEN_PROGRAM_ID,
              new PublicKey(TOKENS.WSOL.mintAddress),
              coinSourceTokenAccount,
              wallet.publicKey
          )
      );
    }

    if (quoteToken.symbol === "SOL") {
      const lamportsToCreateAccount = await window.$web3.getMinimumBalanceForRentExemption(
          ACCOUNT_LAYOUT.span,
          commitment
      );

      const newAccount = new anchor.web3.Account();
      signers.push(newAccount);

      pcSourceTokenAccount = newAccount.publicKey
      txn.add(
          SystemProgram.createAccount({
            fromPubkey: wallet.publicKey,
            newAccountPubkey: pcSourceTokenAccount,
            lamports: ( pc ) * Math.pow(10, quoteToken.decimals) + lamportsToCreateAccount,
            space: ACCOUNT_LAYOUT.span,
            programId: TOKEN_PROGRAM_ID,
          })
      );

      txn.add(
          Token.createInitAccountInstruction(
              TOKEN_PROGRAM_ID,
              new PublicKey(TOKENS.WSOL.mintAddress),
              pcSourceTokenAccount,
              wallet.publicKey
          )
      );
    }

    const orcaDepositAccounts = {
      authority: provider.wallet.publicKey,
      vaultAccount:  new anchor.web3.PublicKey(getOrcaVaultAccount(assetSymbol)),
      vaultUserAccount: orcaVaultUserAccountAddress,
      tokenProgram: serum.TokenInstructions.TOKEN_PROGRAM_ID,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      vaultPda: vaultPdaAccount,
      systemProgram: new anchor.web3.PublicKey(
          "11111111111111111111111111111111"
      ),
      userFarmTokenAccount: vaultFarmTokenAccount,
      userRewardTokenAccount: vaultRewardTokenAccount,
      globalBaseTokenVault: new anchor.web3.PublicKey(getOrcaVaultGlobalBaseTokenVault(assetSymbol)),
      farmTokenMint: new anchor.web3.PublicKey(getOrcaVaultFarmMint(assetSymbol)),
      globalFarm: orcaGlobalFarm,
      userFarm: orcaUserFarm,
      globalRewardTokenVault: new anchor.web3.PublicKey(getOrcaVaultGlobalRewardTokenVault(assetSymbol)),
      convertAuthority: new anchor.web3.PublicKey(getOrcaVaultConvertAuthority(assetSymbol)),
      aquaFarmProgram: AQUAFARM_PROGRAM_ID,
      poolTokenA: new anchor.web3.PublicKey(getOrcaFarmPoolCoinTokenaccount(assetSymbol)),
      poolTokenB: new anchor.web3.PublicKey(getOrcaFarmPoolPcTokenaccount(assetSymbol)),
      swapProgram: dexProgramId,
      swapAccount: new anchor.web3.PublicKey(getOrcaFarmAmmId(assetSymbol)),
      swapAuthority: new anchor.web3.PublicKey(getOrcaFarmAmmAuthority(assetSymbol)),
      poolTokenMint: new anchor.web3.PublicKey(getOrcaVaultLpMint(assetSymbol)),
      vaultSwapTokenAccount: new anchor.web3.PublicKey(getOrcaVaultSwapPoolTokenAccount(assetSymbol)),
      swapPoolMint: new anchor.web3.PublicKey(getOrcaVaultLpMint(assetSymbol)),
    }

    // If it's a single deposit then we only need to send the single funding token account
    // console.log({single: isSingleDeposit});
    if (isSingleDeposit) {
      orcaDepositAccounts.fundingTokenAccount = coin ? coinSourceTokenAccount : pcSourceTokenAccount;

      let addLiqDepositSingleArgs = {
            amount: coin
                ? new anchor.BN(Number(coin) * Math.pow(10, Number(baseToken.decimals)))
                : new anchor.BN(Number(pc) * Math.pow(10, Number(quoteToken.decimals))),
            accountNonce: orcaVaultUserAccountNonce,
          };

      const orcaHarvestAccounts = {
        authority: provider.wallet.publicKey,
        global: new anchor.web3.PublicKey(getOrcaManagementAccount()), // lendingInfo -> farm -> managementAccount
        vaultAccount:  new anchor.web3.PublicKey(getOrcaVaultAccount(assetSymbol)),
        tokenProgram: serum.TokenInstructions.TOKEN_PROGRAM_ID,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        vaultPda: vaultPdaAccount,
        userFarmOwner: vaultPdaAccount,
        userRewardTokenAccount: vaultRewardTokenAccount,
        globalBaseTokenVault: new anchor.web3.PublicKey(getOrcaVaultGlobalBaseTokenVault(assetSymbol)),
        farmTokenMint: new anchor.web3.PublicKey(getOrcaVaultFarmMint(assetSymbol)),
        globalFarm: orcaGlobalFarm,
        userFarm: orcaUserFarm,
        globalRewardTokenVault: new anchor.web3.PublicKey(getOrcaVaultGlobalRewardTokenVault(assetSymbol)),
        convertAuthority: new anchor.web3.PublicKey(getOrcaVaultConvertAuthority(assetSymbol)),
        aquaFarmProgram: AQUAFARM_PROGRAM_ID,
      }
      // console.log("$$ add liq args ", coin, pc, addLiqDepositSingleArgs);

      // harvest txn
      txn.add(
          orcaVaultProgram.instruction.harvestRewards(
              {
                accounts: orcaHarvestAccounts,
              }
          )
      );
      txn.add(
        orcaVaultProgram.instruction.addLiqDepositVaultSingle(
            addLiqDepositSingleArgs,
          {
            accounts: orcaDepositAccounts,
          }
        )
      );
    }
    else {
      orcaDepositAccounts.fundingTokenAccountA = coinSourceTokenAccount;
      orcaDepositAccounts.fundingTokenAccountB = pcSourceTokenAccount;

      txn.add(
        orcaVaultProgram.instruction.addLiqDepositVault(
          {
            coin: new anchor.BN(Number(coin) * Math.pow(10, Number(baseToken.decimals))),
            pc: new anchor.BN(Number(pc) * Math.pow(10, Number(quoteToken.decimals))),
            accountNonce: orcaVaultUserAccountNonce
          },
          {
            accounts: orcaDepositAccounts,
          }
        )
      );
    }

    if (baseToken.symbol === "SOL") {
      txn.add(
          Token.createCloseAccountInstruction(
              TOKEN_PROGRAM_ID,
              coinSourceTokenAccount,
              wallet.publicKey,
              wallet.publicKey,
              []
          )
      );
    }

    if (quoteToken.symbol === "SOL") {
      txn.add(
          Token.createCloseAccountInstruction(
              TOKEN_PROGRAM_ID,
              pcSourceTokenAccount,
              wallet.publicKey,
              wallet.publicKey,
              []
          )
      );
    }

    return await sendTransaction(window.$web3, wallet, txn, signers);
  },

  /**
   *
   * @param {String} assetSymbol
   * @param {String|Number} value
   * @param {Boolean} withdrawMax
   *
   * @returns {Promise}
   */
  async handleWithdrawFromOrcaVault (assetSymbol, value, withdrawMax) {
    const anchor = anchorlatest;

    const { wallet, tokenAccounts } = getStore('WalletStore'),
        walletToInitialize = {
          signTransaction: wallet.signTransaction,
          signAllTransactions: wallet.signAllTransactions,
          publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
        },
        provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: false, preflightCommitment: commitment });

    anchor.setProvider(provider);


    const orcaVaultProgramId = new anchor.web3.PublicKey(getOrcaVaultProgramId());
    const orcaVaultProgram = new anchor.Program(orcaIdl, orcaVaultProgramId);

    let [orcaVaultUserAccountAddress, orcaVaultUserAccountNonce] = await deriveVaultUserAccount(
        new anchor.web3.PublicKey(getOrcaVaultAccount(assetSymbol)),
        provider.wallet.publicKey,
        orcaVaultProgramId,
    );

    const vaultPdaAccount = new anchor.web3.PublicKey(
        getVaultPdaAccount(assetSymbol)
    );


    let vaultFarmTokenAccount = await serumAssoToken.getAssociatedTokenAddress(vaultPdaAccount,  new anchor.web3.PublicKey(getOrcaVaultFarmMint(assetSymbol)));
    let vaultRewardTokenAccount = await serumAssoToken.getAssociatedTokenAddress(vaultPdaAccount,  new anchor.web3.PublicKey(getOrcaVaultRewardMint(assetSymbol)));

    let orcaGlobalFarm = new anchor.web3.PublicKey(getOrcaVaultGlobalFarm(assetSymbol));

    let [orcaUserFarm, _] = await findOrcaUserFarmAddress(orcaGlobalFarm, vaultPdaAccount, TOKEN_PROGRAM_ID, AQUAFARM_PROGRAM_ID);

    let dexProgramId = ORCA_SWAP_PROGRAM_ID;

    const farm = getFarmBySymbol(assetSymbol),
        baseToken = farm.coins[0], // base / coin
        quoteToken = farm.coins[1]; // quote / pc

    let withdrawAmount;

    if (withdrawMax) {
      const accountsToFetch = [orcaVaultUserAccountAddress],
          [
            userAccountInfo
          ] = await getMultipleAccounts(window.$web3, accountsToFetch, commitment),

          decodedUserAccountInfo = orcaVaultProgram.coder.accounts.decode('VaultUser', userAccountInfo.account.data);
      withdrawAmount = decodedUserAccountInfo.shares;
    } else {
      const accountsToFetch = [new anchor.web3.PublicKey(getOrcaVaultAccount(assetSymbol))],
          [
            vaultAccountInfo
          ] = await getMultipleAccounts(window.$web3, accountsToFetch, commitment),

          decodedVaultAccountInfo = orcaVaultProgram.coder.accounts.decode('Vault', vaultAccountInfo.account.data);
      let { totalVaultBalance, totalVlpShares } = decodedVaultAccountInfo || {},
          { decimals } = getFarmBySymbol(assetSymbol) || {};

      const userInputValue = new anchor.BN(value * Math.pow(10, decimals));

      withdrawAmount = ((userInputValue.mul(totalVlpShares)).div(totalVaultBalance));
    }


    const txn = new anchor.web3.Transaction();

    let coinReceivingTokenAccount = new anchor.web3.PublicKey(tokenAccounts[baseToken.mintAddress]?.tokenAccountAddress);
    let pcReceivingTokenAccount = new anchor.web3.PublicKey(tokenAccounts[quoteToken.mintAddress]?.tokenAccountAddress);

    let signers = [];
    if (baseToken.symbol === "SOL") {
      const lamportsToCreateAccount = await window.$web3.getMinimumBalanceForRentExemption(
          ACCOUNT_LAYOUT.span,
          commitment
      );

      const newAccount = new anchor.web3.Account();
      signers.push(newAccount);

      coinReceivingTokenAccount = newAccount.publicKey
      txn.add(
          SystemProgram.createAccount({
            fromPubkey: wallet.publicKey,
            newAccountPubkey: coinReceivingTokenAccount,
            lamports: lamportsToCreateAccount,
            space: ACCOUNT_LAYOUT.span,
            programId: TOKEN_PROGRAM_ID,
          })
      );

      txn.add(
          Token.createInitAccountInstruction(
              TOKEN_PROGRAM_ID,
              new PublicKey(TOKENS.WSOL.mintAddress),
              coinReceivingTokenAccount,
              wallet.publicKey
          )
      );
    }

    if (quoteToken.symbol === "SOL") {
      const lamportsToCreateAccount = await window.$web3.getMinimumBalanceForRentExemption(
          ACCOUNT_LAYOUT.span,
          commitment
      );

      const newAccount = new anchor.web3.Account();
      signers.push(newAccount);

      pcReceivingTokenAccount = newAccount.publicKey
      txn.add(
          SystemProgram.createAccount({
            fromPubkey: wallet.publicKey,
            newAccountPubkey: pcReceivingTokenAccount,
            lamports: lamportsToCreateAccount,
            space: ACCOUNT_LAYOUT.span,
            programId: TOKEN_PROGRAM_ID,
          })
      );

      txn.add(
          Token.createInitAccountInstruction(
              TOKEN_PROGRAM_ID,
              new PublicKey(TOKENS.WSOL.mintAddress),
              pcReceivingTokenAccount,
              wallet.publicKey
          )
      );
    }

    const orcaWithdrawAccounts = {
      authority: provider.wallet.publicKey,
      vaultAccount:  new anchor.web3.PublicKey(getOrcaVaultAccount(assetSymbol)),
      vaultUserAccount: orcaVaultUserAccountAddress,
      tokenProgram: serum.TokenInstructions.TOKEN_PROGRAM_ID,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      vaultPda: vaultPdaAccount,
      systemProgram: new anchor.web3.PublicKey(
          "11111111111111111111111111111111"
      ),
      userFarmTokenAccount: vaultFarmTokenAccount,
      userRewardTokenAccount: vaultRewardTokenAccount,
      globalBaseTokenVault: new anchor.web3.PublicKey(getOrcaVaultGlobalBaseTokenVault(assetSymbol)),
      farmTokenMint: new anchor.web3.PublicKey(getOrcaVaultFarmMint(assetSymbol)),
      globalFarm: orcaGlobalFarm,
      userFarm: orcaUserFarm,
      globalRewardTokenVault: new anchor.web3.PublicKey(getOrcaVaultGlobalRewardTokenVault(assetSymbol)),
      convertAuthority: new anchor.web3.PublicKey(getOrcaVaultConvertAuthority(assetSymbol)),
      aquaFarmProgram: AQUAFARM_PROGRAM_ID,
      poolTokenA: new anchor.web3.PublicKey(getOrcaFarmPoolCoinTokenaccount(assetSymbol)),
      poolTokenB: new anchor.web3.PublicKey(getOrcaFarmPoolPcTokenaccount(assetSymbol)),
      swapProgram: dexProgramId,
      swapAccount: new anchor.web3.PublicKey(getOrcaFarmAmmId(assetSymbol)),
      swapAuthority: new anchor.web3.PublicKey(getOrcaFarmAmmAuthority(assetSymbol)),
      poolTokenMint: new anchor.web3.PublicKey(getOrcaVaultLpMint(assetSymbol)),
      vaultSwapTokenAccount: new anchor.web3.PublicKey(getOrcaVaultSwapPoolTokenAccount(assetSymbol)),
      swapPoolMint: new anchor.web3.PublicKey(getOrcaVaultLpMint(assetSymbol)),
      swapPoolFee: new anchor.web3.PublicKey(getOrcaVaultFeeAccount(assetSymbol)),
    }

     txn.add(
        orcaVaultProgram.instruction.removeLiqWithdrawVault(
            new anchor.BN(withdrawAmount),
            {
              accounts: orcaWithdrawAccounts,
              remainingAccounts: [
                {
                  isSigner: false,
                  isWritable: true,
                  pubkey: coinReceivingTokenAccount,
                },
                {
                  isSigner: false,
                  isWritable: true,
                  pubkey: pcReceivingTokenAccount,
                }
              ]
            },
        )
    );

    if (baseToken.symbol === "SOL") {
      txn.add(
          Token.createCloseAccountInstruction(
              TOKEN_PROGRAM_ID,
              coinReceivingTokenAccount,
              wallet.publicKey,
              wallet.publicKey,
              []
          )
      );
    }

    if (quoteToken.symbol === "SOL") {
      txn.add(
          Token.createCloseAccountInstruction(
              TOKEN_PROGRAM_ID,
              pcReceivingTokenAccount,
              wallet.publicKey,
              wallet.publicKey,
              []
          )
      );
    }

    return await sendTransaction(window.$web3, wallet, txn, signers);
  },

  /**
   *
   * @param {String} assetSymbol
   *
   * @returns {Promise}
   */
  async checkUnsafeWithdraw (assetSymbol) {
    const {decimals, platform} = getFarmBySymbol(assetSymbol) || {};
    let anchor = anchorold;

    if (platform !== ALL_PLATFORMS.RAYDIUM) {
      anchor = anchorlatest
    }

    const { wallet } = getStore('WalletStore'),
      walletToInitialize = {
        signTransaction: wallet.signTransaction,
        signAllTransactions: wallet.signAllTransactions,
        publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
      },
      provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment });

    anchor.setProvider(provider);

    switch (platform) {
      case ALL_PLATFORMS.SABER: {
        const { saberVaultUserAccountAddress } = getStore('FarmStore').getFarm(getSaberVaultLpTokenMint(assetSymbol)) || {},
            vaultProgramId = new anchor.web3.PublicKey(getSaberVaultProgramId()),
            vaultProgram = new anchor.Program(saberIdl, vaultProgramId),
            vaultAccount = new anchor.web3.PublicKey(getSaberVaultAccount(assetSymbol)),
            accountsToFetch = [saberVaultUserAccountAddress, vaultAccount],
            [
              userAccountInfo,
              vaultAccountInfo
            ] = await getMultipleAccounts(window.$web3, accountsToFetch, commitment),

            decodedVaultAccountInfo = vaultProgram.coder.accounts.decode('VaultAccount', vaultAccountInfo.account.data),
            decodedUserAccountInfo = vaultProgram.coder.accounts.decode('VaultUserAccount', userAccountInfo.account.data);

        const lastDepositTime = decodedUserAccountInfo.lastDepositTime.toNumber();
        const lastCompoundTime = decodedVaultAccountInfo.lastCompoundTime.toNumber();
        const timeDifference = (lastCompoundTime - lastDepositTime) / (60*60); // in hours

        return (timeDifference < 2);
      }
      case ALL_PLATFORMS.RAYDIUM: {
        const farmMintAddress = getFarmLpMintAddress(assetSymbol);
        const mintAddress = (
            assetSymbol === 'RAY-SRM-DUAL' ? `${farmMintAddress}0` : farmMintAddress
        );
        const { userBalanceAccount } = getStore('FarmStore').getFarm(mintAddress) || {},
            vaultProgramId = new anchor.web3.PublicKey(getVaultProgramId()),
            vaultProgram = new anchor.Program(idl, vaultProgramId),
            [ userBalanceMetadataAccount ] = await anchor.web3.PublicKey.findProgramAddress(
                [
                  userBalanceAccount.toBuffer(),
                  provider.wallet.publicKey.toBytes()
                ],
                vaultProgramId
            ),
            vaultAccount = new anchor.web3.PublicKey(getVaultAccount(assetSymbol)),
            accountsToFetch = [userBalanceMetadataAccount, vaultAccount],
            [
              userBalanceMetadataAccountInfo,
              vaultAccountInfo
            ] = await getMultipleAccounts(window.$web3, accountsToFetch, commitment),
            decodedUserBalanceMetadataAccountInfo = vaultProgram.coder.accounts.decode(
                'VaultBalanceMetadata',
                userBalanceMetadataAccountInfo.account.data
            ),
            decodedVaultAccountInfo = vaultProgram.coder.accounts.decode('Vault', vaultAccountInfo.account.data),
            lastDepositTime = decodedUserBalanceMetadataAccountInfo.lastDepositTime.toNumber(),
            lastCompoundTime = decodedVaultAccountInfo.lastCompoundTime.toNumber(),
            timeDifference = (lastCompoundTime - lastDepositTime) / (60*60); // in hours

        return (timeDifference < 2);
      }

      case ALL_PLATFORMS.ORCA: {
        const { orcaVaultUserAccountAddress } = getStore('FarmStore').getFarm(getOrcaVaultLpMint(assetSymbol)) || {},
            vaultProgramId = new anchor.web3.PublicKey(getOrcaVaultProgramId()),
            vaultProgram = new anchor.Program(orcaIdl, vaultProgramId),
            vaultAccount = new anchor.web3.PublicKey(getOrcaVaultAccount(assetSymbol)),
            accountsToFetch = [orcaVaultUserAccountAddress, vaultAccount],
            [
              userAccountInfo,
              vaultAccountInfo
            ] = await getMultipleAccounts(window.$web3, accountsToFetch, commitment),

            decodedVaultAccountInfo = vaultProgram.coder.accounts.decode('Vault', vaultAccountInfo.account.data),
            decodedUserAccountInfo = vaultProgram.coder.accounts.decode('VaultUser', userAccountInfo.account.data);

        const lastDepositTime = decodedUserAccountInfo.lastDepositTime.toNumber();
        const lastCompoundTime = decodedVaultAccountInfo.lastCompoundTime.toNumber();
        const timeDifference = (lastCompoundTime - lastDepositTime) / (60*60); // in hours

        return (timeDifference < 2);
      }

    }
  },

  /**
   *
   * @param {String} assetSymbol
   *
   * @returns {Promise}
   */
  async handleFixTokenAccount (assetSymbol) {
    const {decimals, saber} = getFarmBySymbol(assetSymbol) || {};
    let anchor = anchorold;
    let mintAddress;

    if (saber) {
      anchor = anchorlatest;
      mintAddress = getSaberVaultLpTokenMint(assetSymbol);
    } else {
      mintAddress = getFarmLpMintAddress(assetSymbol);
    }

    const { wallet } = getStore('WalletStore'),
      walletToInitialize = {
        signTransaction: wallet.signTransaction,
        signAllTransactions: wallet.signAllTransactions,
        publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
      },
      provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment });

    anchor.setProvider(provider);

    const txn = new Transaction();

    txn.add(
      await serumAssoToken.createAssociatedTokenAccount(
        // who will pay for the account creation
        wallet.publicKey,

        // who is the account getting created for
        wallet.publicKey,

        // what mint address token is being created
        new anchor.web3.PublicKey(mintAddress)
      )
    );

    return sendTransaction(window.$web3, wallet, txn, []);
  },

  async handleFixTulipRewardTokenAccount () {
    let anchor = anchorold;

    const { wallet } = getStore('WalletStore'),
      walletToInitialize = {
        signTransaction: wallet.signTransaction,
        signAllTransactions: wallet.signAllTransactions,
        publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
      },
      provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment });

    anchor.setProvider(provider);

    const mintAddress = new anchor.web3.PublicKey(TOKENS.TULIP.mintAddress),
      txn = new Transaction();

    txn.add(
      await serumAssoToken.createAssociatedTokenAccount(
        // who will pay for the account creation
        wallet.publicKey,

        // who is the account getting created for
        wallet.publicKey,

        // what mint address token is being created
        new anchor.web3.PublicKey(mintAddress)
      )
    );

    return sendTransaction(window.$web3, wallet, txn, []);
  },

  async handleBulkTulipHarvest (farms) {
    let anchor = anchorold;

    const txn = new anchor.web3.Transaction();

    const { wallet, hasTulipRewardPending } = getStore('WalletStore'),
      walletToInitialize = {
        signTransaction: wallet.signTransaction,
        signAllTransactions: wallet.signAllTransactions,
        publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
      },
      provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment }),
      tulipPubKey = new anchor.web3.PublicKey(TOKENS.TULIP.mintAddress);

    anchor.setProvider(provider);

    const tulipRewardTokenAccount = await serumAssoToken.getAssociatedTokenAddress(
        wallet.publicKey,
        tulipPubKey
      );

    farms.forEach(async (assetSymbol) => {
      if (!hasTulipRewardPending(assetSymbol)) {
        return;
      }

      // Address of the deployed program.
      const vaultProgramId = new anchor.web3.PublicKey(getVaultProgramId());
      // Generate the program client from IDL.
      const vaultProgram = new anchor.Program(idl, vaultProgramId);

      const [
        userBalanceAccount,
        userBalanceAccountNonce,
      ] = await anchor.web3.PublicKey.findProgramAddress(
        [
          new anchor.web3.PublicKey(getVaultOldInfoAccount(assetSymbol)).toBytes(),
          provider.wallet.publicKey.toBytes(),
        ],
        vaultProgramId
      );

      const [
        userBalanceMetadataAccount,
        userBalanceMetadataAccountNonce
      ] = await anchor.web3.PublicKey.findProgramAddress(
          [
            userBalanceAccount.toBuffer(),
            provider.wallet.publicKey.toBytes()
          ],
          vaultProgramId
      );

      const [
        tulipRewardMetadataAccount,
        tulipRewardMetadataNonce
      ] = await anchor.web3.PublicKey.findProgramAddress(
          [
            userBalanceMetadataAccount.toBytes(),
            provider.wallet.publicKey.toBytes()
          ],
          vaultProgramId,
      );

      const depositAccounts = {
        authority: provider.wallet.publicKey,
        vault: new anchor.web3.PublicKey(getVaultAccount(assetSymbol)),
        vaultPdaAccount: new anchor.web3.PublicKey(
          getVaultPdaAccount(assetSymbol)
        ),
        userInfoAccount: new anchor.web3.PublicKey(
          getVaultInfoAccount(assetSymbol)
        ),
        userBalanceAccount: userBalanceAccount,
        userBalanceMetadata: userBalanceMetadataAccount,
        userTulipRewardMetadata: tulipRewardMetadataAccount,
        userTulipTokenAccount: tulipRewardTokenAccount,
        vaultTulipTokenAccount: new anchor.web3.PublicKey(
          getVaultTulipTokenAccount(assetSymbol)
        ),
        tokenProgramId: serum.TokenInstructions.TOKEN_PROGRAM_ID,
        clock: SYSVAR_CLOCK_PUBKEY,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        systemProgram: new anchor.web3.PublicKey(
          "11111111111111111111111111111111"
        ),
      };

      txn.add(
        vaultProgram.instruction.harvestTulips(
          {
            nonce: userBalanceAccountNonce,
            metaNonce: userBalanceMetadataAccountNonce,
            rewardNonce: tulipRewardMetadataNonce
          },
          {
            accounts: depositAccounts,
          }
        )
      );
    });

    return sendTransaction(window.$web3, wallet, txn, []);
  },

  async createOpenOrdersAccount(assetSymbol, obligationIdx) {
    let anchor = anchorold;

    const { wallet, isMintAddressExisting } = getStore('WalletStore'),
        walletToInitialize = {
          signTransaction: wallet.signTransaction,
          signAllTransactions: wallet.signAllTransactions,
          publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
        },
        provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment }),
        tulipTokenMint = new anchor.web3.PublicKey(TOKENS.TULIP.mintAddress),
        farm = getFarmBySymbol(assetSymbol),
        baseToken = farm.coins[0], // base / coin
        quoteToken = farm.coins[1]; // quote / pc


    anchor.setProvider(provider);

    let [userFarm, nonce2] = await findUserFarmAddress(
        provider.wallet.publicKey,
        new anchor.web3.PublicKey(getLendingFarmProgramId()), // lending_info.json -> programs -> farm -> id
        new anchor.BN(0),
        new anchor.BN(farm.marginIndex)
    );

    let [obligationVaultAccount, obligationVaultNonce] = await findObligationVaultAddress(
        userFarm,
        new anchor.BN(obligationIdx), // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
        new anchor.web3.PublicKey(getLendingFarmProgramId())
    );

    const obligationLPTokenAccount = await serumAssoToken.getAssociatedTokenAddress(
        obligationVaultAccount,
        new anchor.web3.PublicKey(farm.mintAddress)
    );


    const [
      obligationLPTokenAccountInfo,
    ]  =  await getMultipleAccounts(window.$web3, [obligationLPTokenAccount], commitment);

    const txn = new anchor.web3.Transaction();


    if (!obligationLPTokenAccountInfo) {
      txn.add(
          // userFarmManagerLpTokenAccount
          await serumAssoToken.createAssociatedTokenAccount(
              provider.wallet.publicKey,
              obligationVaultAccount,
              new anchor.web3.PublicKey(farm.mintAddress)
          ),
      );
    }

    if (baseToken.symbol !== "SOL" && !isMintAddressExisting(baseToken.mintAddress)) {
      txn.add(
          await serumAssoToken.createAssociatedTokenAccount(
              // who will pay for the account creation
              wallet.publicKey,

              // who is the account getting created for
              wallet.publicKey,

              // what mint address token is being created
              new anchor.web3.PublicKey(baseToken.mintAddress)
          )
      );
    }

    if (quoteToken.symbol !== "SOL" && !isMintAddressExisting(quoteToken.mintAddress)) {
      txn.add(
          await serumAssoToken.createAssociatedTokenAccount(
              // who will pay for the account creation
              wallet.publicKey,

              // who is the account getting created for
              wallet.publicKey,

              // what mint address token is being created
              new anchor.web3.PublicKey(quoteToken.mintAddress)
          )
      );
    }

    return txn
  },

  async createUserFarm (assetSymbol, obligationIdx) {
    let anchor = anchorold;

    // console.log("obligation index", obligationIdx);
    const { wallet, tokenAccounts, isMintAddressExisting, hasTulipRewardPending } = getStore('WalletStore'),
      walletToInitialize = {
        signTransaction: wallet.signTransaction,
        signAllTransactions: wallet.signAllTransactions,
        publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
      },
      provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment }),
      tulipTokenMint = new anchor.web3.PublicKey(TOKENS.TULIP.mintAddress),
      farm = getFarmBySymbol(assetSymbol),
        baseToken = farm.coins[0], // base / coin
        quoteToken = farm.coins[1]; // quote / pc | @to-do: change coins[0] and coins[1] to baseToken and quoteToken
    anchor.setProvider(provider);

    // Address of the deployed program.
    const farmProgramId = new anchor.web3.PublicKey(getLendingFarmProgramId());
    // Generate the program client from IDL.
    const farmProgram = new anchor.Program(farmIdl, farmProgramId);

    const solfarmVaultProgramId = new anchor.web3.PublicKey(
      (
        farm.platform === FARM_PLATFORMS.ORCA ?
          getOrcaVaultProgramId() :
          getVaultProgramId() // info.json -> programId
      )
    );
    const lendingProgramId = new anchor.web3.PublicKey(
      getLendingProgramId() // lendingInfo -> programs -> lending -> id
    );

    let [userFarm, nonce2] = await findUserFarmAddress(
      provider.wallet.publicKey,
      new anchor.web3.PublicKey(getLendingFarmProgramId()), // lending_info.json -> programs -> farm -> id
      new anchor.BN(0),
      new anchor.BN(farm.marginIndex)
    );

    let [obligationVaultAccount, obligationVaultNonce] = await findObligationVaultAddress(
        userFarm,
        new anchor.BN(obligationIdx), // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
        farmProgramId
      );

    let [userObligationAcct1, obligationNonce] = await findUserFarmObligationAddress(
        provider.wallet.publicKey,
        userFarm,
        farmProgramId,
        new anchor.BN(obligationIdx) // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
      );

    const [leveragedFarm] = await findLeveragedFarmAddress(
      solfarmVaultProgramId,
      new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).serum_market),
      farmProgramId,
      new anchor.BN(farm.marginIndex)
    );

    const obligationLPTokenAccount = await serumAssoToken.getAssociatedTokenAddress(
        obligationVaultAccount,
        new anchor.web3.PublicKey(farm.mintAddress)
    );

    const obligationTulipTokenAccount = await serumAssoToken.getAssociatedTokenAddress(
        obligationVaultAccount,
        tulipTokenMint
    );
    const [
        obligationLPTokenAccountInfo,
        obligationTulipTokenAccountInfo
    ]  =  await getMultipleAccounts(window.$web3, [obligationLPTokenAccount, obligationTulipTokenAccount], commitment);

    const instructions = [];

    if (!obligationLPTokenAccountInfo) {
      instructions.push(await serumAssoToken.createAssociatedTokenAccount(
            provider.wallet.publicKey,
            obligationVaultAccount,
            new anchor.web3.PublicKey(farm.mintAddress)
        ));
    }

    if (!obligationTulipTokenAccountInfo) {
      instructions.push(
          await serumAssoToken.createAssociatedTokenAccount(
              provider.wallet.publicKey,
              obligationVaultAccount,
              tulipTokenMint,
          )
      );
    }

    if (baseToken.symbol !== "SOL" && !isMintAddressExisting(baseToken.mintAddress)) {
      instructions.push(
          await serumAssoToken.createAssociatedTokenAccount(
              // who will pay for the account creation
              wallet.publicKey,

              // who is the account getting created for
              wallet.publicKey,

              // what mint address token is being created
              new anchor.web3.PublicKey(baseToken.mintAddress)
          )
      );
    }

    if (quoteToken.symbol !== "SOL" && !isMintAddressExisting(quoteToken.mintAddress)) {
      instructions.push(
          await serumAssoToken.createAssociatedTokenAccount(
              // who will pay for the account creation
              wallet.publicKey,

              // who is the account getting created for
              wallet.publicKey,

              // what mint address token is being created
              new anchor.web3.PublicKey(quoteToken.mintAddress)
          )
      );
    }

    const tulipTokenAccount = tokenAccounts[TOKENS.TULIP.mintAddress]?.tokenAccountAddress;
    const derivedTulipTokenAccount = await createAssociatedTokenAccount(
        provider,
        provider.wallet.publicKey,
        new anchor.web3.PublicKey(TOKENS.TULIP.mintAddress),
    );
    const isTulipAssociatedAddress = (tulipTokenAccount === derivedTulipTokenAccount.toBase58());
    const isTulipAuxillaryAddress = (tulipTokenAccount && !isTulipAssociatedAddress);
    const shouldCreateTulipAssociatedAddress = (
        (baseToken.mintAddress !== TOKENS.TULIP.mintAddress) ?
            !isTulipAssociatedAddress : isTulipAuxillaryAddress
    );

    if (shouldCreateTulipAssociatedAddress) {
      instructions.push(
          await serumAssoToken.createAssociatedTokenAccount(
              // who will pay for the account creation
              provider.wallet.publicKey,

              // who is the account getting created for
              provider.wallet.publicKey,

              // what mint address token is being created
              new anchor.web3.PublicKey(TOKENS.TULIP.mintAddress)
          )
      );
    }

    const createUserFarmAccounts = {
      authority: provider.wallet.publicKey,
      userFarm: userFarm,
      userFarmObligation: userObligationAcct1,
      lendingMarket: new anchor.web3.PublicKey(getLendingMarketAccount()), // `lendingMarketAccount` from `lending_info.json`
      global: new anchor.web3.PublicKey(getLendingFarmManagementAccount()), // lendingInfo -> farm -> managementAccount
      leveragedFarm: leveragedFarm, // use findLeveragedFarmAddress()
      clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      systemProgram: new anchor.web3.PublicKey(
          "11111111111111111111111111111111"
      ),
      lendingProgram: lendingProgramId,
      tokenProgram: splToken.TOKEN_PROGRAM_ID,
      obligationVaultAddress: obligationVaultAccount,
    };

    // console.log("farm accounts", createUserFarmAccounts);
    const txn = await farmProgram.transaction.createUserFarm(
      solfarmVaultProgramId,
      {
        accounts: createUserFarmAccounts,
        instructions,
      }
    );

    return txn;
  },

  async createUserFarmObligation (assetSymbol, obligationIdx) {
    let anchor = anchorold;

    // console.log("obligation index", obligationIdx);
    const { wallet, hasTulipRewardPending, isMintAddressExisting } = getStore('WalletStore'),
        walletToInitialize = {
          signTransaction: wallet.signTransaction,
          signAllTransactions: wallet.signAllTransactions,
          publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
        },
        provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment }),
        tulipTokenMint = new anchor.web3.PublicKey(TOKENS.TULIP.mintAddress),
        farm = getFarmBySymbol(assetSymbol),
        baseToken = farm.coins[0],
        quoteToken = farm.coins[1];
    anchor.setProvider(provider);

    // Address of the deployed program.
    const farmProgramId = new anchor.web3.PublicKey(getLendingFarmProgramId());
    // Generate the program client from IDL.
    const farmProgram = new anchor.Program(farmIdl, farmProgramId);

    const solfarmVaultProgramId = new anchor.web3.PublicKey(
        (
            farm.platform === FARM_PLATFORMS.ORCA ?
                getOrcaVaultProgramId() :
                getVaultProgramId() // info.json -> programId
        )
    );
    const lendingProgramId = new anchor.web3.PublicKey(
        getLendingProgramId() // lendingInfo -> programs -> lending -> id
    );

    let [userFarm, nonce2] = await findUserFarmAddress(
        provider.wallet.publicKey,
        new anchor.web3.PublicKey(getLendingFarmProgramId()), // lending_info.json -> programs -> farm -> id
        new anchor.BN(0),
        new anchor.BN(farm.marginIndex)
    );

    let [obligationVaultAccount, obligationVaultNonce] = await findObligationVaultAddress(
        userFarm,
        new anchor.BN(obligationIdx), // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
        farmProgramId
    );

    let [userObligationAcct1, obligationNonce] = await findUserFarmObligationAddress(
        provider.wallet.publicKey,
        userFarm,
        farmProgramId,
        new anchor.BN(obligationIdx) // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
    );

    const [leveragedFarm] = await findLeveragedFarmAddress(
        solfarmVaultProgramId,
        new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).serum_market),
        farmProgramId,
        new anchor.BN(farm.marginIndex)
    );

    const obligationLPTokenAccount = await serumAssoToken.getAssociatedTokenAddress(
        obligationVaultAccount,
        new anchor.web3.PublicKey(farm.mintAddress)
    );

    const obligationTulipTokenAccount = await serumAssoToken.getAssociatedTokenAddress(
        obligationVaultAccount,
        tulipTokenMint
    );
    const [
      obligationLPTokenAccountInfo,
      obligationTulipTokenAccountInfo
    ]  =  await getMultipleAccounts(window.$web3, [obligationLPTokenAccount, obligationTulipTokenAccount], commitment);

    const instructions = [];

    if (!obligationLPTokenAccountInfo) {
      instructions.push(await serumAssoToken.createAssociatedTokenAccount(
          provider.wallet.publicKey,
          obligationVaultAccount,
          new anchor.web3.PublicKey(farm.mintAddress)
      ));
    }

    if (!obligationTulipTokenAccountInfo) {
      instructions.push(
          await serumAssoToken.createAssociatedTokenAccount(
              provider.wallet.publicKey,
              obligationVaultAccount,
              tulipTokenMint,
          )
      );
    }

    if (baseToken.symbol !== "SOL" && !isMintAddressExisting(baseToken.mintAddress)) {
      instructions.push(
          await serumAssoToken.createAssociatedTokenAccount(
              // who will pay for the account creation
              wallet.publicKey,

              // who is the account getting created for
              wallet.publicKey,

              // what mint address token is being created
              new anchor.web3.PublicKey(baseToken.mintAddress)
          )
      );
    }

    if (quoteToken.symbol !== "SOL" && !isMintAddressExisting(quoteToken.mintAddress)) {
      instructions.push(
          await serumAssoToken.createAssociatedTokenAccount(
              // who will pay for the account creation
              wallet.publicKey,

              // who is the account getting created for
              wallet.publicKey,

              // what mint address token is being created
              new anchor.web3.PublicKey(quoteToken.mintAddress)
          )
      );
    }

    const createUserFarmObligationAccounts = {
      authority: provider.wallet.publicKey,
      userFarm: userFarm,
      leveragedFarm: leveragedFarm, // use findLeveragedFarmAddress()
      userFarmObligation: userObligationAcct1,
      lendingMarket: new anchor.web3.PublicKey(getLendingMarketAccount()), // `lendingMarketAccount` from `lending_info.json`
      obligationVaultAddress: obligationVaultAccount,
      clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      lendingProgram: lendingProgramId,
      tokenProgram: splToken.TOKEN_PROGRAM_ID,
      systemProgram: new anchor.web3.PublicKey(
          "11111111111111111111111111111111"
      ),
    };

    const txn = await farmProgram.transaction.createUserFarmObligation(
        {
          accounts: createUserFarmObligationAccounts,
          instructions,
        }
    );

    return txn;
  },

  async depositBorrow (assetSymbol, reserveName, baseTokenAmount, quoteTokenAmount, leverageValue, obligationIdx, createAccounts) {
    let anchor = anchorold;

    const { wallet, tokenAccounts, isMintAddressExisting } = getStore('WalletStore'),
      walletToInitialize = {
        signTransaction: wallet.signTransaction,
        signAllTransactions: wallet.signAllTransactions,
        publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
      },
      provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment }),
      tulipTokenMint = new anchor.web3.PublicKey(TOKENS.TULIP.mintAddress),
      farm = getFarmBySymbol(assetSymbol),
      reserve = getReserveByName(reserveName),
      baseToken = farm.coins[0], // base / coin
      quoteToken = farm.coins[1]; // quote / pc | @to-do: change coins[0] and coins[1] to baseToken and quoteToken

    anchor.setProvider(provider);

    console.log("$$ reserver ", reserveName);
    const farmDetails = getStore('FarmStore').getFarm(farm.mintAddress);
    const { userFarmInfo } = farmDetails || {};
    const { numberOfObligations = 0 } = userFarmInfo || {};

    // console.log(tokenAccounts);
    // Address of the deployed program.
    const vaultProgramId = new anchor.web3.PublicKey(getLendingFarmProgramId());
    // Generate the program client from IDL.
    const vaultProgram = new anchor.Program(farmIdl, vaultProgramId);

    // console.log('farm.marginIndex', farm.marginIndex);

    const [userFarm, nonce2] = await findUserFarmAddress(
      provider.wallet.publicKey,
      new anchor.web3.PublicKey(getLendingFarmProgramId()),
      new anchor.BN(0),
      new anchor.BN(farm.marginIndex)
    );

    const solfarmVaultProgramId = new anchor.web3.PublicKey(
      (
        farm.platform === FARM_PLATFORMS.ORCA ?
          getOrcaVaultProgramId() :
          getVaultProgramId()
      )
    );

    const [leveragedFarm] = await findLeveragedFarmAddress(
      solfarmVaultProgramId,
      new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).serum_market),
      new anchor.web3.PublicKey(getLendingFarmProgramId()),
      new anchor.BN(farm.marginIndex)
    );

    const [userObligationAcct1, obligationNonce] = await findUserFarmObligationAddress(
      provider.wallet.publicKey,
      userFarm,
      new anchor.web3.PublicKey(getLendingFarmProgramId()),
      new anchor.BN(obligationIdx) // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
    );

    const lendingProgramId = new anchor.web3.PublicKey(
      getLendingProgramId()
    );

    const lendingMarketAccount = new anchor.web3.PublicKey(getLendingMarketAccount());

    const [derivedLendingMarketAuthority, nonce] = await anchor.web3.PublicKey.findProgramAddress(
        [lendingMarketAccount.toBytes()],
        lendingProgramId
      );

    let [obligationVaultAccount, obligationVaultNonce] = await findObligationVaultAddress(
        userFarm,
        new anchor.BN(obligationIdx), // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
        new anchor.web3.PublicKey(getLendingFarmProgramId())
    );

    const reserveNotBorrowing = (reserveName === baseToken.symbol) ? quoteToken : baseToken;

    const reserves = [
      new anchor.web3.PublicKey(reserve.account),
      // new anchor.web3.PublicKey(getLendingReserve(reserveNotBorrowing.symbol).account)
    ];

    const { getTokenPrice } = getStore('PriceStore');

    const baseTokenPrice = Number(getTokenPrice(baseToken.symbol)),
        quoteTokenPrice = Number(getTokenPrice(quoteToken.symbol)),
        reserveToBeBorrowedPrice = Number(getTokenPrice(reserve.name));
    const liquidityToBorrow = (leverageValue - 1) * ((baseTokenAmount * baseTokenPrice) + (quoteTokenAmount * quoteTokenPrice)) / reserveToBeBorrowedPrice;

    const [borrowAuthorizer, borrowAuthorizerNonce] = await findBorrowAuthorizer(
        lendingMarketAccount,
        new anchor.web3.PublicKey(getLendingFarmProgramId())
    );

    const txn = new anchor.web3.Transaction();

    let coinSourceTokenAccount, pcSourceTokenAccount;
    let borrowMint = reserve.mintAddress;

    if (baseToken.symbol !== "SOL" && !isMintAddressExisting(baseToken.mintAddress)) {

      coinSourceTokenAccount = await createAssociatedTokenAccount(
          provider,
          provider.wallet.publicKey,
          new anchor.web3.PublicKey(baseToken.mintAddress),
      );
    } else {
      coinSourceTokenAccount = new anchor.web3.PublicKey(tokenAccounts[baseToken.mintAddress]?.tokenAccountAddress)
    }

    if (quoteToken.symbol !== "SOL"  && !isMintAddressExisting(quoteToken.mintAddress)) {

      pcSourceTokenAccount = await createAssociatedTokenAccount(
          provider,
          provider.wallet.publicKey,
          new anchor.web3.PublicKey(quoteToken.mintAddress),
      );
    } else {
      pcSourceTokenAccount = new anchor.web3.PublicKey(tokenAccounts[quoteToken.mintAddress]?.tokenAccountAddress)
    }

    let signers = [];
    if (baseToken.symbol === "SOL") {
      const lamportsToCreateAccount = await window.$web3.getMinimumBalanceForRentExemption(
          ACCOUNT_LAYOUT.span,
          commitment
      );

      const newAccount = new anchor.web3.Account();
      signers.push(newAccount);

      coinSourceTokenAccount = newAccount.publicKey
      txn.add(
          SystemProgram.createAccount({
            fromPubkey: wallet.publicKey,
            newAccountPubkey: coinSourceTokenAccount,
            lamports: ( baseTokenAmount ) * Math.pow(10, baseToken.decimals) + lamportsToCreateAccount,
            space: ACCOUNT_LAYOUT.span,
            programId: TOKEN_PROGRAM_ID,
          })
      );

      txn.add(
          Token.createInitAccountInstruction(
              TOKEN_PROGRAM_ID,
              new PublicKey(TOKENS.WSOL.mintAddress),
              coinSourceTokenAccount,
              wallet.publicKey
          )
      );

    }

    if (quoteToken.symbol === "SOL") {
      const lamportsToCreateAccount = await window.$web3.getMinimumBalanceForRentExemption(
          ACCOUNT_LAYOUT.span,
          commitment
      );

      const newAccount = new anchor.web3.Account();
      signers.push(newAccount);

      pcSourceTokenAccount = newAccount.publicKey
      txn.add(
          SystemProgram.createAccount({
            fromPubkey: wallet.publicKey,
            newAccountPubkey: pcSourceTokenAccount,
            lamports: ( quoteTokenAmount ) * Math.pow(10, quoteToken.decimals) + lamportsToCreateAccount,
            space: ACCOUNT_LAYOUT.span,
            programId: TOKEN_PROGRAM_ID,
          })
      );

      txn.add(
          Token.createInitAccountInstruction(
              TOKEN_PROGRAM_ID,
              new PublicKey(TOKENS.WSOL.mintAddress),
              pcSourceTokenAccount,
              wallet.publicKey
          )
      );

    }

    if (borrowMint === '11111111111111111111111111111111') {
      borrowMint = 'So11111111111111111111111111111111111111112';
    }

    const vaultAccount = (
      farm.platform === FARM_PLATFORMS.ORCA ?
        getLendingFarmAccount(assetSymbol).vault_account
        : getVaultAccount(assetSymbol)
    );

    txn.add(
        vaultProgram.instruction.depositBorrowZero(
            reserves,
            new anchor.BN(baseTokenAmount * Math.pow(10, baseToken.decimals)),
            new anchor.BN(quoteTokenAmount * Math.pow(10, quoteToken.decimals)),
            new anchor.BN(liquidityToBorrow * Math.pow(10, reserve.decimals)),
            new anchor.BN(obligationIdx),
            {
              accounts: {
                authority: provider.wallet.publicKey,
                userFarm: userFarm,
                leveragedFarm: leveragedFarm,
                userFarmObligation: userObligationAcct1,
                coinSourceTokenAccount,
                coinDestinationTokenAccount: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).farm_base_token_account),
                coinDepositReserveAccount: new anchor.web3.PublicKey(getLendingReserve(baseToken.symbol).account),
                pcSourceTokenAccount,
                pcDestinationTokenAccount: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).farm_quote_token_account),
                pcDepositReserveAccount: new anchor.web3.PublicKey(getLendingReserve(quoteToken.symbol).account),
                coinReserveLiquidityOracle: new anchor.web3.PublicKey(getPriceFeedsForReserve(baseToken.symbol).price_account),
                pcReserveLiquidityOracle: new anchor.web3.PublicKey(getPriceFeedsForReserve(quoteToken.symbol).price_account),
                lendingMarketAccount: lendingMarketAccount,
                derivedLendingMarketAuthority: derivedLendingMarketAuthority,
                clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
                tokenProgram: splToken.TOKEN_PROGRAM_ID,
                lendingProgram: lendingProgramId,
                sourceReserveLiquidityTokenAccount: new anchor.web3.PublicKey(getLendingReserve(reserveName).liquidity_supply_token_account),
                borrowMint: new anchor.web3.PublicKey(borrowMint),
                reserveLiquidityFeeReceiver: new anchor.web3.PublicKey(getLendingReserve(reserveName).liquidity_fee_receiver),
                borrowAuthorizer: borrowAuthorizer,
                lpPythPriceAccount: new anchor.web3.PublicKey(getPriceFeedsForReserve(assetSymbol).price_account),
                vaultAccount: new anchor.web3.PublicKey(vaultAccount),
              },
              remainingAccounts: [
                {
                  isSigner: false,
                  isWritable: true,
                  pubkey: new anchor.web3.PublicKey(getLendingReserve(reserveName).account),
                },
                {
                  isSigner: false,
                  isWritable: false,
                  pubkey: new anchor.web3.PublicKey(getPriceFeedsForReserve(reserveName).price_account),
                },
                // {
                //   isSigner: false,
                //   isWritable: true,
                //   pubkey: new anchor.web3.PublicKey(getLendingReserve(reserveNotBorrowing.symbol).account),
                // },
                // {
                //   isSigner: false,
                //   isWritable: false,
                //   pubkey: new anchor.web3.PublicKey(getPriceFeedsForReserve(reserveNotBorrowing.symbol).price_account),
                // },
                // refresh the reserve we are borrowing from
                {
                  isSigner: false,
                  isWritable: true,
                  pubkey: new anchor.web3.PublicKey(getLendingReserve(reserveName).account),
                },
                // {
                //   isSigner: false,
                //   isWritable: true,
                //   pubkey: new anchor.web3.PublicKey(getLendingReserve(reserveNotBorrowing.symbol).account),
                // },
              ],
            }
        )
    );

    if (baseToken.symbol === "SOL") {
      txn.add(
          Token.createCloseAccountInstruction(
              TOKEN_PROGRAM_ID,
              coinSourceTokenAccount,
              wallet.publicKey,
              wallet.publicKey,
              []
          )
      );
    }

    if (quoteToken.symbol === "SOL") {
      txn.add(
          Token.createCloseAccountInstruction(
              TOKEN_PROGRAM_ID,
              pcSourceTokenAccount,
              wallet.publicKey,
              wallet.publicKey,
              []
          )
      );
    }

    return [txn, signers];
  },

  async swapTokens (assetSymbol, reserveName, obligationIdx) {
    let anchor = anchorold;

    const { wallet, tokenAccounts } = getStore('WalletStore'),
      walletToInitialize = {
        signTransaction: wallet.signTransaction,
        signAllTransactions: wallet.signAllTransactions,
        publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
      },
      provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment });

    anchor.setProvider(provider);

    // Address of the deployed program.
    const vaultProgramId = new anchor.web3.PublicKey(getLendingFarmProgramId());
    // Generate the program client from IDL.
    const vaultProgram = new anchor.Program(farmIdl, vaultProgramId);

    const farm = getFarmBySymbol(assetSymbol),
        reserve = getReserveByName(reserveName),
        baseToken = farm.coins[0],
        quoteToken = farm.coins[1];

    const farmDetails = getStore('FarmStore').getFarm(farm.mintAddress);
    const { userFarmInfo } = farmDetails || {};

    const [userFarm, nonce2] = await findUserFarmAddress(
      provider.wallet.publicKey,
      new anchor.web3.PublicKey(getLendingFarmProgramId()),
      new anchor.BN(0),
      new anchor.BN(farm.marginIndex)
    );

    let serumMarketKey = new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).serum_market);
    let serumMarketVaultSigner = new anchor.web3.PublicKey(getVaultSerumVaultSigner(assetSymbol));
    let openOrdersAccountFarm =  new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).farm_open_orders);
    let marketAccountInfo = await provider.connection.getAccountInfo(
        serumMarketKey
    );
    let dexProgramId = new anchor.web3.PublicKey(
      getLendingFarmAccount(assetSymbol).serum_dex_program // lendingInfo -> farm -> accounts -> serumDexProgram
    );
    const decoded = await serum.Market.getLayout(dexProgramId).decode(
      marketAccountInfo.data
    );

    const [userObligationAcct1, obligationNonce] = await findUserFarmObligationAddress(
        provider.wallet.publicKey,
        userFarm,
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(obligationIdx) // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
    );

    const solfarmVaultProgramId = new anchor.web3.PublicKey(
        (
            farm.platform === FARM_PLATFORMS.ORCA ?
                getOrcaVaultProgramId() :
                getVaultProgramId()
        )
    );

    const [leveragedFarm] = await findLeveragedFarmAddress(
        solfarmVaultProgramId,
        new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).serum_market),
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(farm.marginIndex)
    );

    const lendingProgramId = new anchor.web3.PublicKey(
        getLendingProgramId()
    );

    const lendingMarketAccount = new anchor.web3.PublicKey(getLendingMarketAccount());

    const [derivedLendingMarketAuthority, nonce] = await anchor.web3.PublicKey.findProgramAddress(
        [lendingMarketAccount.toBytes()],
        lendingProgramId
    );

    let requestQueue = decoded.requestQueue;
    let eventQueue = decoded.eventQueue;
    let marketBids = decoded.bids;
    let marketAsks = decoded.asks;
    let baseVault = decoded.baseVault;
    let quoteVault = decoded.quoteVault;

    if ( farm.platform === FARM_PLATFORMS.ORCA ) {
      baseVault = new anchor.web3.PublicKey(getOrcaFarmPoolCoinTokenaccount(assetSymbol));
      quoteVault =  new anchor.web3.PublicKey(getOrcaFarmPoolPcTokenaccount(assetSymbol));
      requestQueue = new anchor.web3.PublicKey(
          "11111111111111111111111111111111"
      );
    }

    let marketAccountsBids = {
      market: serumMarketKey,
      requestQueue: requestQueue,
      eventQueue: eventQueue,
      bids: marketBids,
      asks: marketAsks,
      coinVault: baseVault,
      pcVault: quoteVault,
      vaultSigner: serumMarketVaultSigner,
      openOrders: openOrdersAccountFarm,
      orderPayerTokenAccount: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).farm_base_token_account),
      coinWallet: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).farm_base_token_account),
    };

    let remainingAccounts = [
      {
        isSigner: false,
        isWritable: true,
        pubkey: new anchor.web3.PublicKey(
            getLendingFarmAccount(assetSymbol).serum_fee_recipient
        ),
      },
      {pubkey: lendingMarketAccount, isWritable: true, isSigner: false},
      {pubkey: derivedLendingMarketAuthority, isWritable: true, isSigner: false},
      {pubkey: lendingProgramId, isWritable: false, isSigner: false},
    ];

    let swapTokens;

    switch (farm.platform) {
      case FARM_PLATFORMS.RAYDIUM: {
        swapTokens = vaultProgram.transaction.swapTokensExperimental;

        break;
      }

      case FARM_PLATFORMS.ORCA: {
        swapTokens = vaultProgram.transaction.swapTokensAddCollateralSplTokenSwap;
        remainingAccounts.push(
            {pubkey: new anchor.web3.PublicKey(getOrcaLpMintAddress(assetSymbol)), isWritable: true, isSigner: false}
        );
        break;
      }

      // Someday
      case FARM_PLATFORMS.SABER: {
        break;
      }
    }

    const txn = await swapTokens(
      new anchor.BN(obligationIdx),
      {
        accounts: {
          authority: provider.wallet.publicKey,
          leveragedFarm: leveragedFarm,
          userFarm: userFarm,
          userFarmObligation: userObligationAcct1,
          pcWallet: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).farm_quote_token_account),
          market: marketAccountsBids,
          tokenProgram: splToken.TOKEN_PROGRAM_ID,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          dexProgram: dexProgramId,
          vaultSigner: serumMarketVaultSigner,
        },
        remainingAccounts: remainingAccounts,
      }
    );

    return txn;
  },

  async addLiquidity (assetSymbol, reserveName, obligationIdx, checkLpTokenAccount = false) {
    let anchor = anchorold;

    const { wallet, tokenAccounts } = getStore('WalletStore'),
        walletToInitialize = {
          signTransaction: wallet.signTransaction,
          signAllTransactions: wallet.signAllTransactions,
          publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
        },
        provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment });

    anchor.setProvider(provider);

    // Address of the deployed program.
    const vaultProgramId = new anchor.web3.PublicKey(getLendingFarmProgramId());
    // Generate the program client from IDL.
    const vaultProgram = new anchor.Program(farmIdl, vaultProgramId);

    const farm = getFarmBySymbol(assetSymbol),
        reserve = getReserveByName(reserveName),
        tulipTokenMint = new anchor.web3.PublicKey(TOKENS.TULIP.mintAddress),
        baseToken = farm.coins[0],
        quoteToken = farm.coins[1];

    const farmDetails = getStore('FarmStore').getFarm(farm.mintAddress);
    const {
      userFarmInfo,
      baseTokenTotal,
      quoteTokenTotal,
      needTakePnlCoin,
      needTakePnlPc,
    } = farmDetails || {};

    const [userFarmManager, nonce1] = await findUserFarmManagerAddress(
        provider.wallet.publicKey,
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(farm.marginIndex)
    );

    const [userFarm, nonce2] = await findUserFarmAddress(
        provider.wallet.publicKey,
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(0),
        new anchor.BN(farm.marginIndex)
    );

    const solfarmVaultProgramId = new anchor.web3.PublicKey(
        (
            farm.platform === FARM_PLATFORMS.ORCA ?
                getOrcaVaultProgramId() :
                getVaultProgramId()
        )
    );

    const [leveragedFarm] = await findLeveragedFarmAddress(
        solfarmVaultProgramId,
        new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).serum_market),
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(farm.marginIndex)
    );

    const [userObligationAcct1, obligationNonce] = await findUserFarmObligationAddress(
        provider.wallet.publicKey,
        userFarm,
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(obligationIdx) // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
    );

    const lendingProgramId = new anchor.web3.PublicKey(
        getLendingProgramId()
    );

    const lendingMarketAccount = new anchor.web3.PublicKey(getLendingMarketAccount());

    const [derivedLendingMarketAuthority, nonce] = await anchor.web3.PublicKey.findProgramAddress(
        [lendingMarketAccount.toBytes()],
        lendingProgramId
    );

    const serumMarketKey =       new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).serum_market);
    const serumMarketVaultSigner = new anchor.web3.PublicKey(getVaultSerumVaultSigner(assetSymbol));
    // let openOrdersAccountFarm =  new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).farm_open_orders);
    // const marketAccountInfo = await provider.connection.getAccountInfo(
    //     serumMarketKey
    // );

    let [obligationVaultAccount, obligationVaultNonce] = await findObligationVaultAddress(
        userFarm,
        new anchor.BN(obligationIdx), // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
        new anchor.web3.PublicKey(getLendingFarmProgramId())
    );

    const obligationLpTokenAccount = await createAssociatedTokenAccount(
        provider,
        obligationVaultAccount,
        new anchor.web3.PublicKey(farm.mintAddress),
    );

    let dexProgramId = new anchor.web3.PublicKey(
        getLendingFarmAccount(assetSymbol).serum_dex_program // lendingInfo -> farm -> accounts -> serumDexProgram
    );



    const txn = new anchor.web3.Transaction();

    if (checkLpTokenAccount) {
      const [
        obligationLPTokenAccountInfo,
      ]  =  await getMultipleAccounts(window.$web3, [obligationLpTokenAccount], commitment);

      if (!obligationLPTokenAccountInfo) {
        txn.add(
            await serumAssoToken.createAssociatedTokenAccount(
                provider.wallet.publicKey,
                obligationVaultAccount,
                new anchor.web3.PublicKey(farm.mintAddress)
            ),
        );
      }
    }

    txn.add(
        vaultProgram.instruction.addLiquidityAddCollateral(
        new anchor.BN(obligationIdx),
        {
          accounts: {
            authority: provider.wallet.publicKey,
            userFarm: userFarm,
            leveragedFarm: leveragedFarm,
            liquidityProgramId: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_liquidity_program),
            ammId: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_amm_id),
            ammAuthority: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_amm_authority),
            ammOpenOrders: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_amm_open_orders),
            ammQuantitiesOrTargetOrders:
                new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_amm_quantities_or_target_orders),
            lpMintAddress: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_lp_mint_address),
            poolCoinTokenAccount: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_coin_token_account),
            poolPcTokenAccount: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_pc_token_account),
            poolWithdrawQueue: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_pool_withdraw_queue),
            serumMarket: serumMarketKey,
            serumVaultSigner: serumMarketVaultSigner,
            tokenProgram: splToken.TOKEN_PROGRAM_ID,
            levFarmCoinTokenAccount: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).farm_base_token_account),
            levFarmPcTokenAccount: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).farm_quote_token_account),
            userLpTokenAccount: obligationLpTokenAccount,
            clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
            pythPriceAccount: new anchor.web3.PublicKey(getPriceFeedsForReserve(assetSymbol).price_account),
            lendingMarketAccount: lendingMarketAccount,
            userFarmObligation: userObligationAcct1,
            derivedLendingMarketAuthority: derivedLendingMarketAuthority,
            lendingProgram: lendingProgramId,
            dexProgram: dexProgramId,
          },
        },
      )
    );

    return txn;
  },

  async depositMarginLpTokens (assetSymbol, reserveName, obligationIdx) {
    let anchor = anchorold;

    const { wallet, isMintAddressExisting } = getStore('WalletStore'),
        walletToInitialize = {
          signTransaction: wallet.signTransaction,
          signAllTransactions: wallet.signAllTransactions,
          publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
        },
        provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment });

    anchor.setProvider(provider);

    // Address of the deployed program.
    const vaultProgramId = new anchor.web3.PublicKey(getLendingFarmProgramId());
    // Generate the program client from IDL.
    const vaultProgram = new anchor.Program(farmIdl, vaultProgramId);

    const farm = getFarmBySymbol(assetSymbol),
        reserve = getReserveByName(reserveName),
        tulipTokenMint = new anchor.web3.PublicKey(TOKENS.TULIP.mintAddress),
        baseToken = farm.coins[0],
        quoteToken = farm.coins[1],
        isTulipRewardAccountValid = isMintAddressExisting(TOKENS.TULIP.mintAddress);

    const farmDetails = getStore('FarmStore').getFarm(farm.mintAddress);
    const { userFarmInfo } = farmDetails || {};

    let [userFarm] = await findUserFarmAddress(
        provider.wallet.publicKey,
        new anchor.web3.PublicKey(getLendingFarmProgramId()), // lending_info.json -> programs -> farm -> id
        new anchor.BN(0),
        new anchor.BN(farm.marginIndex)
    );

    let [obligationVaultAccount] = await findObligationVaultAddress(
        userFarm,
        new anchor.BN(obligationIdx), // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
        new anchor.web3.PublicKey(getLendingFarmProgramId())
    );

    let [userObligationAcct1] = await findUserFarmObligationAddress(
        provider.wallet.publicKey,
        userFarm,
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(obligationIdx) // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
    );

    // console.log("user farm: ", userFarm.toString());
    // console.log("user farm obligation (0): ", userObligationAcct1.toString());
    // console.log("user farm obligation (0) vault account: ", obligationVaultAccount.toString());

    const vaultAccount = new anchor.web3.PublicKey(getVaultAccount(assetSymbol));
    const vaultPdaAccount = new anchor.web3.PublicKey(
        getVaultPdaAccount(assetSymbol)
    );


    const solfarmVaultProgramId = new anchor.web3.PublicKey(
        (
            farm.platform === FARM_PLATFORMS.ORCA ?
                getOrcaVaultProgramId() :
                getVaultProgramId()
        )
    );

    const lendingProgramId = new anchor.web3.PublicKey(
        getLendingProgramId()
    );

    const [leveragedFarm] = await findLeveragedFarmAddress(
        solfarmVaultProgramId,
        new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).serum_market),
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(farm.marginIndex)
    );


    const lendingMarketAccount = new anchor.web3.PublicKey(getLendingMarketAccount());

    const [derivedLendingMarketAuthority] = await anchor.web3.PublicKey.findProgramAddress(
        [lendingMarketAccount.toBytes()],
        lendingProgramId
    );

    const userFarmManagerLpTokenAccount = await createAssociatedTokenAccount(
        provider,
        obligationVaultAccount,
        new anchor.web3.PublicKey(farm.mintAddress),
    );

    let txn;

    switch (farm.platform) {
      case FARM_PLATFORMS.RAYDIUM: {
        const vaultInfoAccountPda = new anchor.web3.PublicKey(
            getVaultInfoAccount(assetSymbol)
        );

        const vaultLpTokenAccount = await createAssociatedTokenAccount(
            provider,
            vaultPdaAccount,
            new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_lp_mint_address),
        );

        let [userFarmManagerVaultBalanceAccount, nonce3] = await anchor.web3.PublicKey.findProgramAddress(
            [
              new anchor.web3.PublicKey(getVaultOldInfoAccount(assetSymbol)).toBytes(),
              obligationVaultAccount.toBuffer()
            ],
            solfarmVaultProgramId
        );
        const vaultBalanceNonce = nonce3;
        let [userFarmManagerVaultBalanceMetadataAccount, nonce4] = await anchor.web3.PublicKey.findProgramAddress(
            [
              userFarmManagerVaultBalanceAccount.toBuffer(),
              obligationVaultAccount.toBuffer(),
            ],
            solfarmVaultProgramId
        );
        const vaultMetaNonce = nonce4;


        let [userFarmManagerVaultTulipRewardAccount, vaultTulipRewardNonce] = await anchor.web3.PublicKey.findProgramAddress(
            [
              userFarmManagerVaultBalanceMetadataAccount.toBuffer(),
              obligationVaultAccount.toBuffer(),
            ],
            solfarmVaultProgramId,
        );

        const userFarmManagerTulipAccount = await createAssociatedTokenAccount(
            provider,
            obligationVaultAccount,
            tulipTokenMint,
        );

        const userTulipAccount = await createAssociatedTokenAccount(
            provider,
            provider.wallet.publicKey,
            tulipTokenMint,
        );
        const instructions = [];

        instructions.push(
            vaultProgram.instruction.harvestTulips(
                {
                  nonce: vaultBalanceNonce,
                  metaNonce: vaultMetaNonce,
                  rewardNonce: vaultTulipRewardNonce,
                },
                new anchor.BN(obligationIdx),
                {
                  accounts: {
                    authority: provider.wallet.publicKey,
                    obligationVaultAddress: obligationVaultAccount,
                    userFarm: userFarm,
                    leveragedFarm: leveragedFarm,
                    vaultProgram: solfarmVaultProgramId,
                    vault: vaultAccount,
                    vaultPdaAccount: vaultPdaAccount,
                    userInfoAccount: vaultInfoAccountPda,
                    userBalanceAccount: userFarmManagerVaultBalanceAccount,
                    userTulipRewardMetadata: userFarmManagerVaultTulipRewardAccount,
                    vaultTulipTokenAccount: new anchor.web3.PublicKey(
                        getVaultTulipTokenAccount(assetSymbol)
                    ),
                    userTulipTokenAccount: userFarmManagerTulipAccount,
                    authorityTulipTokenAccount: userTulipAccount,
                    tokenProgramId: splToken.TOKEN_PROGRAM_ID,
                    clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
                    rent: anchor.web3.SYSVAR_RENT_PUBKEY,
                    systemProgram: new anchor.web3.PublicKey(
                        "11111111111111111111111111111111"
                    ),
                  },
                }
            )
        );

        const depositAccounts = {
          authority: provider.wallet.publicKey,
          userFarm: userFarm,
          obligationVaultAddress: obligationVaultAccount,
          leveragedFarm: leveragedFarm,
          vaultProgram: solfarmVaultProgramId,
          vault: vaultAccount,
          lpTokenAccount: vaultLpTokenAccount,
          // todo(bonedaddy): set to the correct one
          authorityTokenAccount: userFarmManagerLpTokenAccount,
          // need to figure these out from raydium
          stakeProgramId: new anchor.web3.PublicKey(getFarmProgramId(assetSymbol)),
          vaultPdaAccount: vaultPdaAccount,
          // need to figure these out from raydium
          poolId: new anchor.web3.PublicKey(getFarmPoolId(assetSymbol)),
          poolAuthority: new anchor.web3.PublicKey(getFarmPoolAuthority(assetSymbol)),
          userInfoAccount: vaultInfoAccountPda,
          // userLpTokenAccount: vaultLpTokenAccount,
          poolLpTokenAccount: new anchor.web3.PublicKey(
              getFarmPoolLpTokenAccount(assetSymbol)
          ),
          // since this is for a non-fusion pool reward use the same address
          userRewardATokenAccount: new anchor.web3.PublicKey(
              getVaultRewardAccountA(assetSymbol)
          ),
          poolRewardATokenAccount: new anchor.web3.PublicKey(
              getFarmPoolRewardATokenAccount(assetSymbol)
          ),
          userRewardBTokenAccount: new anchor.web3.PublicKey(
              getVaultRewardAccountA(assetSymbol)
          ),
          poolRewardBTokenAccount: new anchor.web3.PublicKey(
              getFarmPoolRewardBTokenAccount(assetSymbol)
          ),
          userBalanceAccount: userFarmManagerVaultBalanceAccount,
          userBalanceMetadata: userFarmManagerVaultBalanceMetadataAccount,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          tokenProgramId: serum.TokenInstructions.TOKEN_PROGRAM_ID,
          systemProgram: new anchor.web3.PublicKey(
              "11111111111111111111111111111111"
          ),
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        };

        if (getFarmFusion(assetSymbol)) {
          depositAccounts.userRewardBTokenAccount = new anchor.web3.PublicKey(
              getVaultRewardAccountB(assetSymbol)
          );
        }
        txn = await vaultProgram.transaction.depositVault(
          {
            nonce: vaultBalanceNonce,
            metaNonce: vaultMetaNonce,
          },
          new anchor.BN(obligationIdx),
          {
            accounts: depositAccounts,
            remainingAccounts: [
              {pubkey: lendingMarketAccount, isWritable: true, isSigner: false},
              {pubkey: userObligationAcct1, isWritable: true, isSigner: false},
              {pubkey: derivedLendingMarketAuthority, isWritable: true, isSigner: false},
              {pubkey: lendingProgramId, isWritable: false, isSigner: false},
            ],
            instructions,
          }
        );

        break;
      }

      case FARM_PLATFORMS.ORCA: {
        let [orcaVaultUserAccountAddress, orcaVaultUserAccountNonce] = await deriveVaultUserAccount(
            new anchor.web3.PublicKey(getOrcaVaultAccount(assetSymbol)),
            obligationVaultAccount,
            solfarmVaultProgramId,
        );

        let vaultBaseTokenAccount =  await serumAssoToken.getAssociatedTokenAddress(vaultPdaAccount,  new anchor.web3.PublicKey(getOrcaVaultLpMint(assetSymbol)));
        let vaultFarmTokenAccount = await serumAssoToken.getAssociatedTokenAddress(vaultPdaAccount,  new anchor.web3.PublicKey(getOrcaVaultFarmMint(assetSymbol)));
        let vaultRewardTokenAccount = await serumAssoToken.getAssociatedTokenAddress(vaultPdaAccount,  new anchor.web3.PublicKey(getOrcaVaultRewardMint(assetSymbol)));

        let orcaGlobalFarm = new anchor.web3.PublicKey(getOrcaVaultGlobalFarm(assetSymbol));

        let [orcaUserFarm, _] = await findOrcaUserFarmAddress(orcaGlobalFarm, vaultPdaAccount, TOKEN_PROGRAM_ID, AQUAFARM_PROGRAM_ID);
        const depositAccounts = {
          authority: provider.wallet.publicKey,
          vaultAccount: vaultAccount,
          vaultUserAccount: orcaVaultUserAccountAddress,
          tokenProgram: serum.TokenInstructions.TOKEN_PROGRAM_ID,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          vaultPda: vaultPdaAccount,
          systemProgram: new anchor.web3.PublicKey(
              "11111111111111111111111111111111"
          ),
          userFarmOwner: vaultPdaAccount,
          userTransferAuthority: vaultPdaAccount,
          userBaseTokenAccount: vaultBaseTokenAccount,
          userFarmTokenAccount: vaultFarmTokenAccount,
          userRewardTokenAccount: vaultRewardTokenAccount,
          globalBaseTokenVault: new anchor.web3.PublicKey(getOrcaVaultGlobalBaseTokenVault(assetSymbol)),
          farmTokenMint: new anchor.web3.PublicKey(getOrcaVaultFarmMint(assetSymbol)),
          globalFarm: orcaGlobalFarm,
          orcaUserFarm: orcaUserFarm,
          globalRewardTokenVault: new anchor.web3.PublicKey(getOrcaVaultGlobalRewardTokenVault(assetSymbol)),
          convertAuthority: new anchor.web3.PublicKey(getOrcaVaultConvertAuthority(assetSymbol)),
          aquaFarmProgram: AQUAFARM_PROGRAM_ID,
          fundingTokenAccount: userFarmManagerLpTokenAccount,
          solfarmVaultProgram: solfarmVaultProgramId,
          leveragedFarm: leveragedFarm,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          obligationVaultAddress: obligationVaultAccount,
          leveragedUserFarm: userFarm,
        };

        // console.log("$$ orca accounts", depositAccounts);
        txn = await vaultProgram.transaction.depositOrcaVault(
            {
              accountNonce: orcaVaultUserAccountNonce,
            },
            new anchor.BN(obligationIdx),
            {
              accounts: depositAccounts,
              remainingAccounts: [
                {pubkey: lendingMarketAccount, isWritable: true, isSigner: false},
                {pubkey: userObligationAcct1, isWritable: true, isSigner: false},
                {pubkey: derivedLendingMarketAuthority, isWritable: true, isSigner: false},
                {pubkey: lendingProgramId, isWritable: false, isSigner: false},
              ],
            }
        );

        break;
      }

      // Someday
      case FARM_PLATFORMS.SABER: {
        break;
      }
    }

    return txn;
  },

  async openMarginPosition (assetSymbol, reserveName, baseTokenAmount, quoteTokenAmount, leverageValue, obligationIndex = -2) {
    let anchor = anchorold;

    const transactions = [];

    const { wallet } = getStore('WalletStore');
    const farm = getFarmBySymbol(assetSymbol);
    const farmDetails = getStore('FarmStore').getFarm(farm.mintAddress);
    const { userFarmInfo } = farmDetails || {};
    const { obligations } = userFarmInfo || {};

    let obligationIdx;
    if (obligationIndex !== -2) {
      obligationIdx = obligationIndex;
    } else {
      obligationIdx = findIndex(obligations, (obligation) => {
        return obligation.positionState.hasOwnProperty("opening")
            || obligation.positionState.hasOwnProperty("borrowed")
            || obligation.positionState.hasOwnProperty("swapped")
            || obligation.positionState.hasOwnProperty("addedLiquidity")
            || obligation.positionState.hasOwnProperty("withdrawn")
            || obligation.positionState.hasOwnProperty("exitingAndLiquidated");
      })
    }

    let obligationPositionState = {"opening": {}};
    if (obligationIdx !== -1) {
      obligationPositionState = obligations[obligationIdx].positionState;
    }

    const { isUserFarmValid } = farmDetails || {};
    let createAccounts = false;
    let extraSigners = [];

    if (!isUserFarmValid) {

      createAccounts = true;
      obligationIdx = 0;

      const createUserFarmManagerTxn = this.createUserFarm(assetSymbol, obligationIdx);
      transactions.push(createUserFarmManagerTxn);
      extraSigners.push([]);
    } else {
      if (obligations[obligationIdx].obligationAccount.toBase58() === "11111111111111111111111111111111") {
        // console.log("create user farm obligation")
        createAccounts = true;
        transactions.push(this.createUserFarmObligation(assetSymbol, obligationIdx));
        extraSigners.push([]);
      }
    }

    // console.log("obligation idx:", obligationIdx);
    let obligationProgress = 0;
    if (obligationPositionState.hasOwnProperty("opening") || obligationPositionState.hasOwnProperty("withdrawn")
        || obligationPositionState.hasOwnProperty("exitingAndLiquidated")) {
      obligationProgress = 1;
    } else if (obligationPositionState.hasOwnProperty("borrowed")) {
      obligationProgress = 2;
    } else if (obligationPositionState.hasOwnProperty("swapped")) {
      obligationProgress = 3;
    } else if (obligationPositionState.hasOwnProperty("addedLiquidity")) {
      obligationProgress = 4;
    }

    if (!createAccounts && obligationProgress < 4) {
      // console.log("$$$ create open orders");
      transactions.push(this.createOpenOrdersAccount(assetSymbol, obligationIdx));
      extraSigners.push([]);
    }
    if (obligationProgress > 0 && obligationProgress < 2) {
      const [depositBorrowTxn, signer] = await this.depositBorrow(assetSymbol, reserveName, baseTokenAmount, quoteTokenAmount, leverageValue, obligationIdx, createAccounts);
      transactions.push(depositBorrowTxn);
      extraSigners.push(signer);
    }

    if (obligationProgress > 0 && obligationProgress < 3) {
      transactions.push(this.swapTokens(assetSymbol, reserveName, obligationIdx));
      extraSigners.push([]);
    }

    if (obligationProgress > 0 && obligationProgress < 4) {
      transactions.push(this.addLiquidity(assetSymbol, reserveName, obligationIdx));
      extraSigners.push([]);
    }

    if (obligationProgress > 0 && obligationProgress < 5) {
      transactions.push(this.depositMarginLpTokens(assetSymbol, reserveName, obligationIdx));
      extraSigners.push([]);
    }

    return Promise.all(transactions).then((fulfilledTransactions) => sendAllTransactions(window.$web3, wallet, fulfilledTransactions, [], extraSigners));
  },

  async addCollateralPosition (assetSymbol, obligationIdx, reserveName, baseTokenAmount, quoteTokenAmount) {
    let anchor = anchorold;

    const transactions = [];

    const { wallet } = getStore('WalletStore');
    const farm = getFarmBySymbol(assetSymbol);
    const farmDetails = getStore('FarmStore').getFarm(farm.mintAddress);
    const { userFarmInfo } = farmDetails || {};
    const { obligations } = userFarmInfo || {};


    const obligationPositionState = obligations[obligationIdx].positionState;

    let extraSigners = [];

    let obligationProgress = 0;
    if (obligationPositionState.hasOwnProperty("opened")) {
      obligationProgress = 1;
    } else if (obligationPositionState.hasOwnProperty("topUp")) {
      obligationProgress = 2;
    } else if (obligationPositionState.hasOwnProperty("topUpSwapped")) {
      obligationProgress = 3;
    } else if (obligationPositionState.hasOwnProperty("topUpAddedLiquidity")) {
      obligationProgress = 4;
    }

    if (obligationProgress > 0 && obligationProgress < 2) {
      const [topUpTxn, signer] =  await this.topUpPosition(assetSymbol, reserveName, baseTokenAmount, quoteTokenAmount, obligationIdx);
      transactions.push(topUpTxn)
      extraSigners.push(signer);
    }

    if (obligationProgress > 0 && obligationProgress < 3) {
      transactions.push(this.swapTokens(assetSymbol, reserveName, obligationIdx));
      extraSigners.push([]);
    }

    if (obligationProgress > 0 && obligationProgress < 4) {
      transactions.push(this.addLiquidity(assetSymbol, reserveName, obligationIdx, true));
      extraSigners.push([]);
    }

    if (obligationProgress > 0 && obligationProgress < 5) {
      transactions.push(this.depositMarginLpTokens(assetSymbol, reserveName, obligationIdx));
      extraSigners.push([]);
    }

    return Promise.all(transactions).then((fulfilledTransactions) => sendAllTransactions(window.$web3, wallet, fulfilledTransactions, [], extraSigners));

    return sendAllTransactions(window.$web3, wallet, transactions, []);
  },

  async withdrawMarginLpTokens (assetSymbol, obligationIdx) { // Step 1 of 5
    let anchor = anchorold;

    const { wallet, tokenAccounts } = getStore('WalletStore'),
        walletToInitialize = {
          signTransaction: wallet.signTransaction,
          signAllTransactions: wallet.signAllTransactions,
          publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
        },
        provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment });

    anchor.setProvider(provider);

    // Address of the deployed program.
    const vaultProgramId = new anchor.web3.PublicKey(getLendingFarmProgramId());
    // Generate the program client from IDL.
    const vaultProgram = new anchor.Program(farmIdl, vaultProgramId);

    const farm = getFarmBySymbol(assetSymbol),
        tulipTokenMint = new anchor.web3.PublicKey(TOKENS.TULIP.mintAddress);


    const solfarmVaultProgramId = new anchor.web3.PublicKey(
        (
            farm.platform === FARM_PLATFORMS.ORCA ?
                getOrcaVaultProgramId() :
                getVaultProgramId()
        )
    );

    let [userFarm] = await findUserFarmAddress(
        provider.wallet.publicKey,
        new anchor.web3.PublicKey(getLendingFarmProgramId()), // lending_info.json -> programs -> farm -> id
        new anchor.BN(0),
        new anchor.BN(farm.marginIndex)
    );

    let [obligationVaultAccount] = await findObligationVaultAddress(
        userFarm,
        new anchor.BN(obligationIdx), // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
        new anchor.web3.PublicKey(getLendingFarmProgramId())
    );

    let [userObligationAcct1] = await findUserFarmObligationAddress(
        provider.wallet.publicKey,
        userFarm,
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(obligationIdx) // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
    );

    const vaultAccount = new anchor.web3.PublicKey(getVaultAccount(assetSymbol));
    const vaultPdaAccount = new anchor.web3.PublicKey(
        getVaultPdaAccount(assetSymbol)
    );

    const [leveragedFarm] = await findLeveragedFarmAddress(
        solfarmVaultProgramId,
        new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).serum_market),
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(farm.marginIndex)
    );

    const userFarmManagerLpTokenAccount = await createAssociatedTokenAccount(
        provider,
        obligationVaultAccount,
        new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_lp_mint_address),
    );

    const lendingProgramId = new anchor.web3.PublicKey(
        getLendingProgramId()
    );

    const lendingMarketAccount = new anchor.web3.PublicKey(getLendingMarketAccount());

    const [derivedLendingMarketAuthority] = await anchor.web3.PublicKey.findProgramAddress(
        [lendingMarketAccount.toBytes()],
        lendingProgramId
    );

    let txn;
    switch (farm.platform) {
      case FARM_PLATFORMS.RAYDIUM: {
        const vaultInfoAccountPda = new anchor.web3.PublicKey(
            getVaultInfoAccount(assetSymbol)
        );

        const vaultLpTokenAccount = await createAssociatedTokenAccount(
            provider,
            vaultPdaAccount,
            new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_lp_mint_address),
        );

        let [userFarmManagerVaultBalanceAccount, nonce3] = await anchor.web3.PublicKey.findProgramAddress(
            [
              new anchor.web3.PublicKey(getVaultOldInfoAccount(assetSymbol)).toBytes(),
              obligationVaultAccount.toBuffer()
            ],
            solfarmVaultProgramId
        );
        const vaultBalanceNonce = nonce3;
        let [userFarmManagerVaultBalanceMetadataAccount, nonce4] = await anchor.web3.PublicKey.findProgramAddress(
            [
              userFarmManagerVaultBalanceAccount.toBuffer(),
              obligationVaultAccount.toBuffer(),
            ],
            solfarmVaultProgramId
        );
        const vaultMetaNonce = nonce4;

        let [userFarmManagerVaultTulipRewardAccount, vaultTulipRewardNonce] = await anchor.web3.PublicKey.findProgramAddress(
            [
              userFarmManagerVaultBalanceMetadataAccount.toBuffer(),
              obligationVaultAccount.toBuffer(),
            ],
            solfarmVaultProgramId,
        );

        const userFarmManagerTulipAccount = await createAssociatedTokenAccount(
            provider,
            obligationVaultAccount,
            tulipTokenMint,
        );

        const userTulipAccount = await createAssociatedTokenAccount(
            provider,
            provider.wallet.publicKey,
            tulipTokenMint,
        );
        const instructions = [];

        instructions.push(
            vaultProgram.instruction.harvestTulips({
              nonce: vaultBalanceNonce,
              metaNonce: vaultMetaNonce,
              rewardNonce: vaultTulipRewardNonce,
            }, new anchor.BN(obligationIdx), {
              accounts: {
                authority: provider.wallet.publicKey,
                obligationVaultAddress: obligationVaultAccount,
                userFarm: userFarm,
                leveragedFarm: leveragedFarm,
                vaultProgram: solfarmVaultProgramId,
                vault: vaultAccount,
                vaultPdaAccount: vaultPdaAccount,
                userInfoAccount: vaultInfoAccountPda,
                userBalanceAccount: userFarmManagerVaultBalanceAccount,
                userTulipRewardMetadata: userFarmManagerVaultTulipRewardAccount,
                vaultTulipTokenAccount: new anchor.web3.PublicKey(
                    getVaultTulipTokenAccount(assetSymbol)
                ),
                userTulipTokenAccount: userFarmManagerTulipAccount,
                authorityTulipTokenAccount: userTulipAccount,
                tokenProgramId: splToken.TOKEN_PROGRAM_ID,
                clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
                rent: anchor.web3.SYSVAR_RENT_PUBKEY,
                systemProgram: new anchor.web3.PublicKey(
                    "11111111111111111111111111111111"
                ),
              },
            })
        );

        let withdrawAccounts = {
          authority: provider.wallet.publicKey,
          userFarm: userFarm,
          obligationVaultAddress: obligationVaultAccount,
          leveragedFarm: leveragedFarm,
          vaultProgram: solfarmVaultProgramId,
          vault: vaultAccount,
          userLpTokenAccount: vaultLpTokenAccount,
          authorityTokenAccount: userFarmManagerLpTokenAccount,
          vaultPdaAccount: vaultPdaAccount,
          userBalanceAccount: userFarmManagerVaultBalanceAccount,
          userBalanceMeta: userFarmManagerVaultBalanceMetadataAccount,
          stakeProgramId: new anchor.web3.PublicKey(getFarmProgramId(assetSymbol)),
          poolId: new anchor.web3.PublicKey(getFarmPoolId(assetSymbol)),
          poolAuthority: new anchor.web3.PublicKey(getFarmPoolAuthority(assetSymbol)),
          userInfoAccount: vaultInfoAccountPda,
          poolLpTokenAccount: new anchor.web3.PublicKey(
              getFarmPoolLpTokenAccount(assetSymbol)
          ),
          // since this is for a non-fusion pool reward use the same address
          userRewardATokenAccount: new anchor.web3.PublicKey(
              getVaultRewardAccountA(assetSymbol)
          ),
          poolRewardATokenAccount: new anchor.web3.PublicKey(
              getFarmPoolRewardATokenAccount(assetSymbol)
          ),
          userRewardBTokenAccount: new anchor.web3.PublicKey(
              getVaultRewardAccountA(assetSymbol)
          ),
          poolRewardBTokenAccount: new anchor.web3.PublicKey(
              getFarmPoolRewardBTokenAccount(assetSymbol)
          ),
          tokenProgramId: serum.TokenInstructions.TOKEN_PROGRAM_ID,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
        };

        if (getFarmFusion(assetSymbol)) {
          withdrawAccounts.userRewardBTokenAccount = new anchor.web3.PublicKey(
              getVaultRewardAccountB(assetSymbol)
          );
        }

        txn = await vaultProgram.transaction.withdrawVault(
          {
            metaNonce: vaultMetaNonce,
            nonce: vaultBalanceNonce,
          },
          new anchor.BN(obligationIdx),
          {
            accounts: withdrawAccounts,
            remainingAccounts: [
              {pubkey: lendingMarketAccount, isWritable: true, isSigner: false},
              {pubkey: userObligationAcct1, isWritable: true, isSigner: false},
              {pubkey: derivedLendingMarketAuthority, isWritable: true, isSigner: false},
              {pubkey: lendingProgramId, isWritable: false, isSigner: false},
            ],
            instructions: instructions
          }
        );

        break;
      }

      case FARM_PLATFORMS.ORCA: {
        let [orcaVaultUserAccountAddress, _] = await deriveVaultUserAccount(
            new anchor.web3.PublicKey(getOrcaVaultAccount(assetSymbol)),
            obligationVaultAccount,
            solfarmVaultProgramId,
        );

        let vaultBaseTokenAccount =  await serumAssoToken.getAssociatedTokenAddress(vaultPdaAccount,  new anchor.web3.PublicKey(getOrcaVaultLpMint(assetSymbol)));
        let vaultFarmTokenAccount = await serumAssoToken.getAssociatedTokenAddress(vaultPdaAccount,  new anchor.web3.PublicKey(getOrcaVaultFarmMint(assetSymbol)));
        let vaultRewardTokenAccount = await serumAssoToken.getAssociatedTokenAddress(vaultPdaAccount,  new anchor.web3.PublicKey(getOrcaVaultRewardMint(assetSymbol)));

        let orcaGlobalFarm = new anchor.web3.PublicKey(getOrcaVaultGlobalFarm(assetSymbol));

        let [orcaUserFarm, orcaUserFarmNonce] = await findOrcaUserFarmAddress(orcaGlobalFarm, vaultPdaAccount, TOKEN_PROGRAM_ID, AQUAFARM_PROGRAM_ID);
        const withdrawAccounts = {
          authority: provider.wallet.publicKey,
          vaultAccount: vaultAccount,
          vaultUserAccount: orcaVaultUserAccountAddress,
          tokenProgram: serum.TokenInstructions.TOKEN_PROGRAM_ID,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          vaultPda: vaultPdaAccount,
          systemProgram: new anchor.web3.PublicKey(
              "11111111111111111111111111111111"
          ),
          userFarmOwner: vaultPdaAccount,
          userTransferAuthority: vaultPdaAccount,
          userBaseTokenAccount: vaultBaseTokenAccount,
          userFarmTokenAccount: vaultFarmTokenAccount,
          userRewardTokenAccount: vaultRewardTokenAccount,
          globalBaseTokenVault: new anchor.web3.PublicKey(getOrcaVaultGlobalBaseTokenVault(assetSymbol)),
          farmTokenMint: new anchor.web3.PublicKey(getOrcaVaultFarmMint(assetSymbol)),
          globalFarm: orcaGlobalFarm,
          orcaUserFarm: orcaUserFarm,
          globalRewardTokenVault: new anchor.web3.PublicKey(getOrcaVaultGlobalRewardTokenVault(assetSymbol)),
          convertAuthority: new anchor.web3.PublicKey(getOrcaVaultConvertAuthority(assetSymbol)),
          aquaFarmProgram: AQUAFARM_PROGRAM_ID,
          receivingTokenAccount: userFarmManagerLpTokenAccount,
          solfarmVaultProgram: solfarmVaultProgramId,
          leveragedFarm: leveragedFarm,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          obligationVaultAddress: obligationVaultAccount,
          leveragedUserFarm: userFarm,
        };

        txn = await vaultProgram.transaction.withdrawOrcaVault(
            new anchor.BN(obligationIdx),
            {
              accounts: withdrawAccounts,
              remainingAccounts: [
                {pubkey: lendingMarketAccount, isWritable: true, isSigner: false},
                {pubkey: userObligationAcct1, isWritable: true, isSigner: false},
                {pubkey: derivedLendingMarketAuthority, isWritable: true, isSigner: false},
                {pubkey: lendingProgramId, isWritable: false, isSigner: false},
              ],
            }
        );

        break;
      }

        // Someday
      case FARM_PLATFORMS.SABER: {
        break;
      }
    }

    return txn
  },

  async removeLiquidity (assetSymbol, obligationIdx) {
    let anchor = anchorold;

    // console.log("obligation index", obligationIdx);
    const { wallet, tokenAccounts } = getStore('WalletStore'),
        walletToInitialize = {
          signTransaction: wallet.signTransaction,
          signAllTransactions: wallet.signAllTransactions,
          publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
        },
        provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment });

    anchor.setProvider(provider);

    // Address of the deployed program.
    const vaultProgramId = new anchor.web3.PublicKey(getLendingFarmProgramId());
    // Generate the program client from IDL.
    const vaultProgram = new anchor.Program(farmIdl, vaultProgramId);

    const farm = getFarmBySymbol(assetSymbol),
        baseToken = farm.coins[0],
        quoteToken = farm.coins[1];


    const [userFarm, nonce2] = await findUserFarmAddress(
        provider.wallet.publicKey,
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(0),
        new anchor.BN(farm.marginIndex)
    );

    const solfarmVaultProgramId = new anchor.web3.PublicKey(
        (
            farm.platform === FARM_PLATFORMS.ORCA ?
                getOrcaVaultProgramId() :
                getVaultProgramId()
        )
    );

    const [leveragedFarm] = await findLeveragedFarmAddress(
        solfarmVaultProgramId,
        new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).serum_market),
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(farm.marginIndex)
    );

    const [userObligationAcct1, obligationNonce] = await findUserFarmObligationAddress(
        provider.wallet.publicKey,
        userFarm,
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(obligationIdx) // @todo: fix this for the position being closed
    );


    const lendingProgramId = new anchor.web3.PublicKey(
        getLendingProgramId()
    );

    const lendingMarketAccount = new anchor.web3.PublicKey(getLendingMarketAccount());

    const [derivedLendingMarketAuthority, nonce] = await anchor.web3.PublicKey.findProgramAddress(
        [lendingMarketAccount.toBytes()],
        lendingProgramId
    );

    const serumMarketKey =       new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).serum_market);
    let serumMarketVaultSigner = new anchor.web3.PublicKey(getVaultSerumVaultSigner(assetSymbol));
    // let openOrdersAccountFarm =  new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).farm_open_orders);
    // const marketAccountInfo = await provider.connection.getAccountInfo(
    //     serumMarketKey
    // );

    let [obligationVaultAccount] = await findObligationVaultAddress(
        userFarm,
        new anchor.BN(obligationIdx), // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
        new anchor.web3.PublicKey(getLendingFarmProgramId())
    );

    const obligationLpTokenAccount = await createAssociatedTokenAccount(
        provider,
        obligationVaultAccount,
        new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_lp_mint_address),
    );

    let dexProgramId = new anchor.web3.PublicKey(
        getLendingFarmAccount(assetSymbol).serum_dex_program // lendingInfo -> farm -> accounts -> serumDexProgram
    );

    if ( farm.platform === FARM_PLATFORMS.ORCA ) {
      serumMarketVaultSigner = new anchor.web3.PublicKey(getOrcaVaultFeeAccount(assetSymbol));
    }

    const removeLiquidityAccounts = {
      userFarm: userFarm,
      obligationVaultAddress: obligationVaultAccount,
      leveragedFarm: leveragedFarm,
      liquidityProgramId: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_liquidity_program),
      ammId: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_amm_id),
      ammAuthority: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_amm_authority),
      ammOpenOrders: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_amm_open_orders),
      ammQuantitiesOrTargetOrders:
          new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_amm_quantities_or_target_orders),
      lpMintAddress: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_lp_mint_address),
      poolCoinTokenAccount: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_coin_token_account),
      poolPcTokenAccount: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_pc_token_account),
      poolWithdrawQueue: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_pool_withdraw_queue),
      poolTempLpTokenAccount: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_pool_temp_token_account),
      serumProgramId: dexProgramId,
      serumMarket: serumMarketKey,
      serumCoinVaultAccount: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).serum_coin_vault_account),
      serumPcVaultAccount: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).serum_pc_vault_account),
      serumVaultSigner: serumMarketVaultSigner,
      tokenProgram: splToken.TOKEN_PROGRAM_ID,
      levFarmCoinTokenAccount: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).farm_base_token_account),
      levFarmPcTokenAccount: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).farm_quote_token_account),
      userLpTokenAccount: obligationLpTokenAccount,
      clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
    };

    const remainAccounts = [
      {pubkey: provider.wallet.publicKey, isWritable: true, isSigner: true},
      {pubkey: lendingMarketAccount, isWritable: true, isSigner: false},
      {pubkey: userObligationAcct1, isWritable: true, isSigner: false},
      {pubkey: derivedLendingMarketAuthority, isWritable: true, isSigner: false},
      {pubkey: lendingProgramId, isWritable: false, isSigner: false},
    ];

    const tx = await vaultProgram.transaction.removeLiquidity(
        new anchor.BN(obligationIdx), // @todo: fix this for the position being closed
        {
          accounts: removeLiquidityAccounts,
          remainingAccounts: remainAccounts,
        }
    );

    return tx;
  },

  async swapTokensForRepaying (assetSymbol, obligationIdx, obligationBorrowReserves, selectedOption = 0) {
    let anchor = anchorold;

    const { wallet, tokenAccounts } = getStore('WalletStore'),
        walletToInitialize = {
          signTransaction: wallet.signTransaction,
          signAllTransactions: wallet.signAllTransactions,
          publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
        },
        provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment });

    anchor.setProvider(provider);

    // Address of the deployed program.
    const vaultProgramId = new anchor.web3.PublicKey(getLendingFarmProgramId());
    // Generate the program client from IDL.
    const vaultProgram = new anchor.Program(farmIdl, vaultProgramId);

    const farm = getFarmBySymbol(assetSymbol),
        // tulipTokenMint = new anchor.web3.PublicKey(TOKENS.TULIP.mintAddress),
        baseToken = farm.coins[0],
        quoteToken = farm.coins[1],
        coinReserve = getReserveByName(baseToken.symbol),
        pcReserve = getReserveByName(quoteToken.symbol);

    const solfarmVaultProgramId = new anchor.web3.PublicKey(
        (
            farm.platform === FARM_PLATFORMS.ORCA ?
                getOrcaVaultProgramId() :
                getVaultProgramId()
        )
    );

    // const farmDetails = getStore('FarmStore').getFarm(farm.mintAddress);
    // const { userFarmInfo, userFarmManagerInfo } = farmDetails || {};

    let [userFarm] = await findUserFarmAddress(
        provider.wallet.publicKey,
        new anchor.web3.PublicKey(getLendingFarmProgramId()), // lending_info.json -> programs -> farm -> id
        new anchor.BN(0),
        new anchor.BN(farm.marginIndex)
    );

    // let [obligationVaultAccount] = await findObligationVaultAddress(
    //     userFarm,
    //     new anchor.BN(obligationIdx), // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
    //     new anchor.web3.PublicKey(getLendingFarmProgramId())
    // );

    let [userObligationAcct1] = await findUserFarmObligationAddress(
        provider.wallet.publicKey,
        userFarm,
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(obligationIdx) // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
    );

    const [leveragedFarm] = await findLeveragedFarmAddress(
        solfarmVaultProgramId,
        new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).serum_market),
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(farm.marginIndex)
    );

    const lendingProgramId = new anchor.web3.PublicKey(
        getLendingProgramId()
    );

    const lendingMarketAccount = new anchor.web3.PublicKey(getLendingMarketAccount());

    const [derivedLendingMarketAuthority] = await anchor.web3.PublicKey.findProgramAddress(
        [lendingMarketAccount.toBytes()],
        lendingProgramId
    );

    const serumMarketKey = new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).serum_market);
    const serumMarketVaultSigner = new anchor.web3.PublicKey(getVaultSerumVaultSigner(assetSymbol));
    let openOrdersAccountFarm =  new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).farm_open_orders);
    const marketAccountInfo = await provider.connection.getAccountInfo(
        serumMarketKey
    );

    let dexProgramId = new anchor.web3.PublicKey(
        getLendingFarmAccount(assetSymbol).serum_dex_program // lendingInfo -> farm -> accounts -> serumDexProgram
    );
    const decoded = await serum.Market.getLayout(dexProgramId).decode(
        marketAccountInfo.data
    );


    let firstReserve, secondReserve;
    if (obligationBorrowReserves.length === 0) {
      firstReserve = coinReserve;
      secondReserve = pcReserve;
    } else {
      firstReserve = getLendingReserveFromKey(obligationBorrowReserves[0].toBase58());

      secondReserve = (firstReserve.account === coinReserve.account) ? pcReserve : coinReserve;
    }


    let remainingAccounts = [
      {
        isSigner: false,
        isWritable: true,
        pubkey: new anchor.web3.PublicKey(
            getLendingFarmAccount(assetSymbol).serum_fee_recipient
        ),
      },

      {pubkey: lendingMarketAccount, isWritable: true, isSigner: false},
      {pubkey: derivedLendingMarketAuthority, isWritable: true, isSigner: false},
      {pubkey: lendingProgramId, isWritable: false, isSigner: false},
      {pubkey: new anchor.web3.PublicKey(getPriceFeedsForReserve(assetSymbol).price_account), isWritable: true, isSigner: false},
      {pubkey: new anchor.web3.PublicKey(getPriceFeedsForReserve(baseToken.symbol).price_account), isWritable: true, isSigner: false},
      {pubkey: new anchor.web3.PublicKey(getPriceFeedsForReserve(quoteToken.symbol).price_account), isWritable: true, isSigner: false},
      {pubkey: new anchor.web3.PublicKey(getVaultAccount(assetSymbol)), isWritable: true, isSigner: false},
      {isSigner: false,
        isWritable: true,
        pubkey: new anchor.web3.PublicKey(firstReserve.account),
      },
      {
        isSigner: false,
        isWritable: false,
        pubkey: new anchor.web3.PublicKey(getPriceFeedsForReserve(firstReserve.name).price_account),
      },
      {
        isSigner: false,
        isWritable: true,
        pubkey: new anchor.web3.PublicKey(secondReserve.account),
      },
      {
        isSigner: false,
        isWritable: false,
        pubkey: new anchor.web3.PublicKey(getPriceFeedsForReserve(secondReserve.name).price_account),
      },
    ];


    let requestQueue = decoded.requestQueue;
    let eventQueue = decoded.eventQueue;
    let marketBids = decoded.bids;
    let marketAsks = decoded.asks;
    let baseVault = decoded.baseVault;
    let quoteVault = decoded.quoteVault;

    if ( farm.platform === FARM_PLATFORMS.ORCA ) {
      baseVault = new anchor.web3.PublicKey(getOrcaFarmPoolCoinTokenaccount(assetSymbol));
      quoteVault =  new anchor.web3.PublicKey(getOrcaFarmPoolPcTokenaccount(assetSymbol));
      requestQueue = new anchor.web3.PublicKey(
          "11111111111111111111111111111111"
      );

      remainingAccounts = [
        {
          isSigner: false,
          isWritable: true,
          pubkey: new anchor.web3.PublicKey(
              getLendingFarmAccount(assetSymbol).serum_fee_recipient
          ),
        },

        {pubkey: lendingMarketAccount, isWritable: true, isSigner: false},
        {pubkey: derivedLendingMarketAuthority, isWritable: true, isSigner: false},
        {pubkey: lendingProgramId, isWritable: false, isSigner: false},
        {pubkey: new anchor.web3.PublicKey(getPriceFeedsForReserve(assetSymbol).price_account), isWritable: true, isSigner: false},
        {pubkey: new anchor.web3.PublicKey(getPriceFeedsForReserve(baseToken.symbol).price_account), isWritable: true, isSigner: false},
        {pubkey: new anchor.web3.PublicKey(getPriceFeedsForReserve(quoteToken.symbol).price_account), isWritable: true, isSigner: false},
        {pubkey: new anchor.web3.PublicKey(getVaultAccount(assetSymbol)), isWritable: true, isSigner: false},
        {pubkey: new anchor.web3.PublicKey(getOrcaLpMintAddress(assetSymbol)), isWritable: true, isSigner: false},
        {isSigner: false,
          isWritable: true,
          pubkey: new anchor.web3.PublicKey(firstReserve.account),
        },
        {
          isSigner: false,
          isWritable: false,
          pubkey: new anchor.web3.PublicKey(getPriceFeedsForReserve(firstReserve.name).price_account),
        },
        {
          isSigner: false,
          isWritable: true,
          pubkey: new anchor.web3.PublicKey(secondReserve.account),
        },
        {
          isSigner: false,
          isWritable: false,
          pubkey: new anchor.web3.PublicKey(getPriceFeedsForReserve(secondReserve.name).price_account),
        },
      ];
    }

    let marketAccountsBids = {
      market: serumMarketKey,
      requestQueue: requestQueue,
      eventQueue: eventQueue,
      bids: marketBids,
      asks: marketAsks,
      coinVault: baseVault,
      pcVault: quoteVault,
      vaultSigner: serumMarketVaultSigner,
      openOrders: openOrdersAccountFarm,
      orderPayerTokenAccount: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).farm_base_token_account),
      coinWallet: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).farm_base_token_account),
    };

    let swapTokens;

    switch (farm.platform) {
      case FARM_PLATFORMS.RAYDIUM: {
        swapTokens = vaultProgram.transaction.swapTokensForRepayingExperimental;

        break;
      }

      case FARM_PLATFORMS.ORCA: {
        swapTokens = vaultProgram.transaction.swapTokensForRepayingExperimentalSplTokenSwap;
        break;
      }

        // Someday
      case FARM_PLATFORMS.SABER: {
        break;
      }
    }

    const tx = await swapTokens(
        [
          new anchor.web3.PublicKey(firstReserve.account),
          new anchor.web3.PublicKey(secondReserve.account),
        ],
        new anchor.BN(obligationIdx),

        // 0 or 1 depending on 'Minimize Trading' selected or 'Convert to {{borrowedReserve}}'
        new anchor.BN(selectedOption),
        {
          accounts: {
            authority: provider.wallet.publicKey,
            leveragedFarm: leveragedFarm,
            userFarm: userFarm,
            userFarmObligation: userObligationAcct1,
            pcWallet: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).farm_quote_token_account),
            market: marketAccountsBids,
            tokenProgram: splToken.TOKEN_PROGRAM_ID,
            rent: anchor.web3.SYSVAR_RENT_PUBKEY,
            clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
            dexProgram: dexProgramId,
            vaultSigner: serumMarketVaultSigner,
          },
          remainingAccounts: remainingAccounts,
        }
    );

    return tx;
  },

  async repayObligationLiquidity (assetSymbol, obligationIdx, obligationBorrowReserves) {
    let anchor = anchorold;

    const { wallet, tokenAccounts } = getStore('WalletStore'),
        walletToInitialize = {
          signTransaction: wallet.signTransaction,
          signAllTransactions: wallet.signAllTransactions,
          publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
        },
        provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment });

    anchor.setProvider(provider);


    // Address of the deployed program.
    const vaultProgramId = new anchor.web3.PublicKey(getLendingFarmProgramId());
    // Generate the program client from IDL.
    const vaultProgram = new anchor.Program(farmIdl, vaultProgramId);

    const farm = getFarmBySymbol(assetSymbol),
        // tulipTokenMint = new anchor.web3.PublicKey(TOKENS.TULIP.mintAddress),
        baseToken = farm.coins[0],
        quoteToken = farm.coins[1],
        coinReserve = getReserveByName(baseToken.symbol),
        pcReserve = getReserveByName(quoteToken.symbol);

    const solfarmVaultProgramId = new anchor.web3.PublicKey(
        (
            farm.platform === FARM_PLATFORMS.ORCA ?
                getOrcaVaultProgramId() :
                getVaultProgramId()
        )
    );

    let [userFarm] = await findUserFarmAddress(
        provider.wallet.publicKey,
        new anchor.web3.PublicKey(getLendingFarmProgramId()), // lending_info.json -> programs -> farm -> id
        new anchor.BN(0),
        new anchor.BN(farm.marginIndex)
    );

    // let [obligationVaultAccount] = await findObligationVaultAddress(
    //     userFarm,
    //     new anchor.BN(obligationIdx), // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
    //     new anchor.web3.PublicKey(getLendingFarmProgramId())
    // );

    let [userObligationAcct1] = await findUserFarmObligationAddress(
        provider.wallet.publicKey,
        userFarm,
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(obligationIdx) // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
    );

    const vaultAccount = new anchor.web3.PublicKey(getVaultAccount(assetSymbol));
    const vaultPdaAccount = new anchor.web3.PublicKey(
        getVaultPdaAccount(assetSymbol)
    );

    // const vaultLpTokenAccount = await createAssociatedTokenAccount(
    //     provider,
    //     vaultPdaAccount,
    //     new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_lp_mint_address),
    // );
    //
    // let [userFarmManagerVaultBalanceAccount, nonce3] = await anchor.web3.PublicKey.findProgramAddress(
    //     [
    //       new anchor.web3.PublicKey(getVaultOldInfoAccount(assetSymbol)).toBytes(),
    //       obligationVaultAccount.toBuffer()
    //     ],
    //     solfarmVaultProgramId
    // );
    // const vaultBalanceNonce = nonce3;

    // let [userFarmManagerVaultBalanceMetadataAccount, nonce4] = await anchor.web3.PublicKey.findProgramAddress(
    //     [
    //       userFarmManagerVaultBalanceAccount.toBuffer(),
    //       obligationVaultAccount.toBuffer(),
    //     ],
    //     solfarmVaultProgramId
    // );
    // const vaultMetaNonce = nonce4;

    const [leveragedFarm] = await findLeveragedFarmAddress(
        solfarmVaultProgramId,
        new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).serum_market),
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(farm.marginIndex)
    );

    const lendingProgramId = new anchor.web3.PublicKey(
        getLendingProgramId()
    );

    const lendingMarketAccount = new anchor.web3.PublicKey(getLendingMarketAccount());

    const [derivedLendingMarketAuthority] = await anchor.web3.PublicKey.findProgramAddress(
        [lendingMarketAccount.toBytes()],
        lendingProgramId
    );

    // let [userFarmManagerVaultTulipRewardAccount, vaultTulipRewardNonce] = await anchor.web3.PublicKey.findProgramAddress(
    //     [
    //       userFarmManagerVaultBalanceMetadataAccount.toBuffer(),
    //       obligationVaultAccount.toBuffer(),
    //     ],
    //     solfarmVaultProgramId,
    // );
    //
    // const userFarmManagerTulipAccount = await createAssociatedTokenAccount(
    //     provider,
    //     obligationVaultAccount,
    //     tulipTokenMint,
    // );

    // const serumMarketKey =       new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).serum_market);
    // const serumMarketVaultSigner = new anchor.web3.PublicKey(getVaultSerumVaultSigner(assetSymbol));
    // const openOrdersAccountFarm = new anchor.web3.PublicKey(getVaultSerumOpenOrdersAccount(assetSymbol));
    // const marketAccountInfo = await provider.connection.getAccountInfo(
    //     serumMarketKey
    // );

    // const userFarmManagerLpTokenAccount = await createAssociatedTokenAccount(
    //     provider,
    //     userObligationAcct1,
    //     new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).raydium_lp_mint_address),
    // );
    //
    // let dexProgramId = new anchor.web3.PublicKey(
    //     getLendingFarmAccount(assetSymbol).serum_dex_program // lendingInfo -> farm -> accounts -> serumDexProgram
    // );
    // const decoded = await serum.Market.getLayout(dexProgramId).decode(
    //     marketAccountInfo.data
    // );

    let firstReserve, secondReserve;
    if (obligationBorrowReserves.length === 0) {
      firstReserve = coinReserve;
      secondReserve = pcReserve;
    } else {
      firstReserve = getLendingReserveFromKey(obligationBorrowReserves[0].toBase58());

      secondReserve = (firstReserve.account === coinReserve.account) ? pcReserve : coinReserve;
    }
    let userCoinTokenAccount = new anchor.web3.PublicKey(tokenAccounts[baseToken.mintAddress]?.tokenAccountAddress)
    let userPcTokenAccount = new anchor.web3.PublicKey(tokenAccounts[quoteToken.mintAddress]?.tokenAccountAddress)

    const txn = new anchor.web3.Transaction();
    let signers = [];
    if (baseToken.symbol === "SOL") {
      const lamportsToCreateAccount = await window.$web3.getMinimumBalanceForRentExemption(
          ACCOUNT_LAYOUT.span,
          commitment
      );

      const newAccount = new anchor.web3.Account();
      signers.push(newAccount);

      userCoinTokenAccount = newAccount.publicKey
      txn.add(
          SystemProgram.createAccount({
            fromPubkey: wallet.publicKey,
            newAccountPubkey: userCoinTokenAccount,
            lamports: lamportsToCreateAccount,
            space: ACCOUNT_LAYOUT.span,
            programId: TOKEN_PROGRAM_ID,
          })
      );

      txn.add(
          Token.createInitAccountInstruction(
              TOKEN_PROGRAM_ID,
              new PublicKey(TOKENS.WSOL.mintAddress),
              userCoinTokenAccount,
              wallet.publicKey
          )
      );
    }

    if (quoteToken.symbol === "SOL") {
      const lamportsToCreateAccount = await window.$web3.getMinimumBalanceForRentExemption(
          ACCOUNT_LAYOUT.span,
          commitment
      );

      const newAccount = new anchor.web3.Account();
      signers.push(newAccount);

      userPcTokenAccount = newAccount.publicKey
      txn.add(
          SystemProgram.createAccount({
            fromPubkey: wallet.publicKey,
            newAccountPubkey: userPcTokenAccount,
            lamports: lamportsToCreateAccount,
            space: ACCOUNT_LAYOUT.span,
            programId: TOKEN_PROGRAM_ID,
          })
      );

      txn.add(
          Token.createInitAccountInstruction(
              TOKEN_PROGRAM_ID,
              new PublicKey(TOKENS.WSOL.mintAddress),
              userPcTokenAccount,
              wallet.publicKey
          )
      );
    }

    // console.log("repaying "+ firstReserve.name + " obligation num: ", obligationIdx);
    const repayAccounts = {
      authority: provider.wallet.publicKey,
      userFarm: userFarm,
      leveragedFarm: leveragedFarm,
      userFarmObligation: userObligationAcct1,
      coinSourceTokenAccount: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).farm_base_token_account),
      coinDestinationTokenAccount: new anchor.web3.PublicKey(getLendingReserve(baseToken.symbol).liquidity_supply_token_account),
      pcSourceTokenAccount: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).farm_quote_token_account),
      pcDestinationTokenAccount: new anchor.web3.PublicKey(getLendingReserve(quoteToken.symbol).liquidity_supply_token_account),
      coinReserveAccount:  new anchor.web3.PublicKey(getLendingReserve(baseToken.symbol).account),
      pcReserveAccount:  new anchor.web3.PublicKey(getLendingReserve(quoteToken.symbol).account),
      lendingMarketAccount: lendingMarketAccount,
      clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
      tokenProgram: splToken.TOKEN_PROGRAM_ID,
      lendingProgram: lendingProgramId,
      lpPythPriceAccount: new anchor.web3.PublicKey(getPriceFeedsForReserve(assetSymbol).price_account),
      coinPriceAccount: new anchor.web3.PublicKey(getPriceFeedsForReserve(baseToken.symbol).price_account),
      pcPriceAccount: new anchor.web3.PublicKey(getPriceFeedsForReserve(quoteToken.symbol).price_account),
      vaultAccount: new anchor.web3.PublicKey(getVaultAccount(assetSymbol)),
      derivedLendingMarketAuthority: derivedLendingMarketAuthority,
      userCoinTokenAccount:  userCoinTokenAccount,
      userPcTokenAccount: userPcTokenAccount,
    };

    // console.log("$$ repay account", repayAccounts);
    txn.add(
        vaultProgram.transaction.repayObligationLiquidity(
            [
              new anchor.web3.PublicKey(firstReserve.account),
              new anchor.web3.PublicKey(secondReserve.account),
            ],
            new anchor.BN(obligationIdx),
            {
              confirmOptions: {
                skipPreflight: true,
              },
              accounts: repayAccounts,
              remainingAccounts: [
                {
                  isSigner: false,
                  isWritable: true,
                  pubkey: new anchor.web3.PublicKey(firstReserve.account),
                },
                {
                  isSigner: false,
                  isWritable: false,
                  pubkey: new anchor.web3.PublicKey(getPriceFeedsForReserve(firstReserve.name).price_account),
                },
                {
                  isSigner: false,
                  isWritable: true,
                  pubkey: new anchor.web3.PublicKey(secondReserve.account),
                },
                {
                  isSigner: false,
                  isWritable: false,
                  pubkey: new anchor.web3.PublicKey(getPriceFeedsForReserve(secondReserve.name).price_account),
                },
                {
                  isSigner: false,
                  isWritable: true,
                  pubkey: new anchor.web3.PublicKey(firstReserve.account),
                },
                {
                  isSigner: false,
                  isWritable: true,
                  pubkey: new anchor.web3.PublicKey(secondReserve.account),
                },

              ],
            }
        )
    );

    if (baseToken.symbol === "SOL") {
      txn.add(
          Token.createCloseAccountInstruction(
              TOKEN_PROGRAM_ID,
              userCoinTokenAccount,
              wallet.publicKey,
              wallet.publicKey,
              []
          )
      );
    }

    if (quoteToken.symbol === "SOL") {
      txn.add(
          Token.createCloseAccountInstruction(
              TOKEN_PROGRAM_ID,
              userPcTokenAccount,
              wallet.publicKey,
              wallet.publicKey,
              []
          )
      );
    }

    return [txn, signers];
  },

  async closeMarginPosition (assetSymbol, obligationIdx, borrows, selectedOption = 0) {
    let anchor = anchorold;

    const { wallet } = getStore('WalletStore');
    const farm = getFarmBySymbol(assetSymbol);
    const farmDetails = getStore('FarmStore').getFarm(farm.mintAddress);
    const { userFarmInfo } = farmDetails || {};
    const { obligations } = userFarmInfo || {};

    let obligationPositionState = obligations[obligationIdx].positionState;

    let transactions = [] ;
    let extraSigners = [] ;
    let obligationProgress = 0;
    if (obligationPositionState.hasOwnProperty("opened")) {
      obligationProgress = 1;
    } else if (obligationPositionState.hasOwnProperty("withdrawing")) {
      obligationProgress = 2;
    } else if (obligationPositionState.hasOwnProperty("removedLiquidity")) {
      obligationProgress = 3;
    } else if (obligationPositionState.hasOwnProperty("swappedForRepaying")) {
      obligationProgress = 4;
    }

    let obligationBorrows = [];
    borrows.forEach((borrow) => {
      if (new TokenAmount(borrow.borrowedAmountWads).isNullOrZero() === false) {
        obligationBorrows.push(borrow.borrowReserve);
      }
    });


    transactions.push(this.createOpenOrdersAccount(assetSymbol, obligationIdx));
    extraSigners.push([]);
    if (obligationProgress > 0 && obligationProgress < 2) {
      transactions.push(this.withdrawMarginLpTokens(assetSymbol, obligationIdx));
      extraSigners.push([]);
    }

    if (obligationProgress > 0 && obligationProgress < 3) {
      transactions.push(this.removeLiquidity(assetSymbol, obligationIdx));
      extraSigners.push([]);
    }

    if (obligationProgress > 0 && obligationProgress < 5) {
      transactions.push(this.swapTokensForRepaying(assetSymbol, obligationIdx, obligationBorrows, selectedOption));
      extraSigners.push([]);
    }

    if (obligationProgress > 0 && obligationProgress < 5) {
      let [repayTxn, signer] = await this.repayObligationLiquidity(assetSymbol, obligationIdx, obligationBorrows);
      transactions.push(repayTxn);
      extraSigners.push(signer);
    }

    return Promise.all(transactions).then((fulfilledTransactions) => sendAllTransactions(window.$web3, wallet, fulfilledTransactions, [], extraSigners));
  },

  async refreshObligationInstruction ({ obligationPubKey, pythPriceAccount, coinPriceAccount, pcPriceAccount, vaultAccount }) {
    let anchor = anchorold;

    const dataLayout = BufferLayout.struct([
      BufferLayout.u8("instruction"),
    ]);

    const data = Buffer.alloc(dataLayout.span);
    dataLayout.encode(
      {
        instruction: LendingInstruction.RefreshObligation,
      },
      data
    );

    const keys = [
      { pubkey: obligationPubKey, isSigner: false, isWritable: true },
      { pubkey: SYSVAR_CLOCK_PUBKEY, isSigner: false, isWritable: false },
      { pubkey: pythPriceAccount, isSigner: false, isWritable: true },
      { pubkey: coinPriceAccount, isSigner: false, isWritable: true },
      { pubkey: pcPriceAccount, isSigner: false, isWritable: true },
      { pubkey: vaultAccount, isSigner: false, isWritable: true },
    ];

    return new TransactionInstruction({
      keys,
      programId: LENDING_PROGRAM_ID,
      data,
    });
  },

  async topUpPosition(assetSymbol, reserveName, baseTokenAmount, quoteTokenAmount, obligationIdx) {
    let anchor = anchorold;

    const { wallet, tokenAccounts, isMintAddressExisting } = getStore('WalletStore'),
        walletToInitialize = {
          signTransaction: wallet.signTransaction,
          signAllTransactions: wallet.signAllTransactions,
          publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
        },
        provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment }),
        tulipTokenMint = new anchor.web3.PublicKey(TOKENS.TULIP.mintAddress),
        farm = getFarmBySymbol(assetSymbol),
        reserve = getReserveByName(reserveName),
        baseToken = farm.coins[0], // base / coin
        quoteToken = farm.coins[1]; // quote / pc | @to-do: change coins[0] and coins[1] to baseToken and quoteToken

    anchor.setProvider(provider);

    // Address of the deployed program.
    const vaultProgramId = new anchor.web3.PublicKey(getLendingFarmProgramId());
    // Generate the program client from IDL.
    const vaultProgram = new anchor.Program(farmIdl, vaultProgramId);

    const [userFarm, nonce2] = await findUserFarmAddress(
        provider.wallet.publicKey,
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(0),
        new anchor.BN(farm.marginIndex)
    );

    const solfarmVaultProgramId = new anchor.web3.PublicKey(
        (
            farm.platform === FARM_PLATFORMS.ORCA ?
                getOrcaVaultProgramId() :
                getVaultProgramId()
        )
    );

    const [leveragedFarm] = await findLeveragedFarmAddress(
        solfarmVaultProgramId,
        new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).serum_market),
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(farm.marginIndex)
    );

    const [userObligationAcct1, obligationNonce] = await findUserFarmObligationAddress(
        provider.wallet.publicKey,
        userFarm,
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(obligationIdx) // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
    );

    const lendingProgramId = new anchor.web3.PublicKey(
        getLendingProgramId()
    );

    const lendingMarketAccount = new anchor.web3.PublicKey(getLendingMarketAccount());

    const [derivedLendingMarketAuthority, nonce] = await anchor.web3.PublicKey.findProgramAddress(
        [lendingMarketAccount.toBytes()],
        lendingProgramId
    );

    const txn = new anchor.web3.Transaction();

    let coinSourceTokenAccount, pcSourceTokenAccount;

    if (baseToken.symbol !== "SOL"  && !isMintAddressExisting(baseToken.mintAddress)) {
      txn.add(
          await serumAssoToken.createAssociatedTokenAccount(
              // who will pay for the account creation
              wallet.publicKey,

              // who is the account getting created for
              wallet.publicKey,

              // what mint address token is being created
              new anchor.web3.PublicKey(baseToken.mintAddress)
          )
      );

      coinSourceTokenAccount = await createAssociatedTokenAccount(
          provider,
          provider.wallet.publicKey,
          new anchor.web3.PublicKey(baseToken.mintAddress),
      );
    } else {
      coinSourceTokenAccount = new anchor.web3.PublicKey(tokenAccounts[baseToken.mintAddress]?.tokenAccountAddress)
    }

    if (quoteToken.symbol !== "SOL"  && !isMintAddressExisting(quoteToken.mintAddress)) {
      txn.add(
          await serumAssoToken.createAssociatedTokenAccount(
              // who will pay for the account creation
              provider.wallet.publicKey,

              // who is the account getting created for
              provider.wallet.publicKey,

              // what mint address token is being created
              new anchor.web3.PublicKey(quoteToken.mintAddress)
          )
      );

      pcSourceTokenAccount = await createAssociatedTokenAccount(
          provider,
          provider.wallet.publicKey,
          new anchor.web3.PublicKey(quoteToken.mintAddress),
      );
    } else {
      pcSourceTokenAccount = new anchor.web3.PublicKey(tokenAccounts[quoteToken.mintAddress]?.tokenAccountAddress)
    }

    let signers = [];

    if (baseToken.symbol === "SOL") {
      const lamportsToCreateAccount = await window.$web3.getMinimumBalanceForRentExemption(
          ACCOUNT_LAYOUT.span,
          commitment
      );

      const newAccount = new anchor.web3.Account();
      signers.push(newAccount);

      coinSourceTokenAccount = newAccount.publicKey
      txn.add(
          SystemProgram.createAccount({
            fromPubkey: wallet.publicKey,
            newAccountPubkey: coinSourceTokenAccount,
            lamports: ( baseTokenAmount ) * Math.pow(10, baseToken.decimals) + lamportsToCreateAccount,
            space: ACCOUNT_LAYOUT.span,
            programId: TOKEN_PROGRAM_ID,
          })
      );

      txn.add(
          Token.createInitAccountInstruction(
              TOKEN_PROGRAM_ID,
              new PublicKey(TOKENS.WSOL.mintAddress),
              coinSourceTokenAccount,
              wallet.publicKey
          )
      );
    }

    if (quoteToken.symbol === "SOL") {
      const lamportsToCreateAccount = await window.$web3.getMinimumBalanceForRentExemption(
          ACCOUNT_LAYOUT.span,
          commitment
      );

      const newAccount = new anchor.web3.Account();
      signers.push(newAccount);

      pcSourceTokenAccount = newAccount.publicKey
      txn.add(
          SystemProgram.createAccount({
            fromPubkey: wallet.publicKey,
            newAccountPubkey: pcSourceTokenAccount,
            lamports: ( quoteTokenAmount ) * Math.pow(10, quoteToken.decimals) + lamportsToCreateAccount,
            space: ACCOUNT_LAYOUT.span,
            programId: TOKEN_PROGRAM_ID,
          })
      );

      txn.add(
          Token.createInitAccountInstruction(
              TOKEN_PROGRAM_ID,
              new PublicKey(TOKENS.WSOL.mintAddress),
              pcSourceTokenAccount,
              wallet.publicKey
          )
      );
    }

    txn.add(
        vaultProgram.instruction.topUpPosition(
            new anchor.BN(baseTokenAmount * Math.pow(10, baseToken.decimals)),
            new anchor.BN(quoteTokenAmount * Math.pow(10, quoteToken.decimals)),
            new anchor.BN(obligationIdx),
            {
              accounts: {
                authority: provider.wallet.publicKey,
                userFarm: userFarm,
                leveragedFarm: leveragedFarm,
                userFarmObligation: userObligationAcct1,
                coinSourceTokenAccount,
                coinDestinationTokenAccount: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).farm_base_token_account),
                coinDepositReserveAccount: new anchor.web3.PublicKey(getLendingReserve(baseToken.symbol).account),
                pcSourceTokenAccount,
                pcDestinationTokenAccount: new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).farm_quote_token_account),
                pcDepositReserveAccount: new anchor.web3.PublicKey(getLendingReserve(quoteToken.symbol).account),
                coinReserveLiquidityOracle: new anchor.web3.PublicKey(getPriceFeedsForReserve(baseToken.symbol).price_account),
                pcReserveLiquidityOracle: new anchor.web3.PublicKey(getPriceFeedsForReserve(quoteToken.symbol).price_account),
                lendingMarketAccount: lendingMarketAccount,
                derivedLendingMarketAuthority: derivedLendingMarketAuthority,
                clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
                tokenProgram: splToken.TOKEN_PROGRAM_ID,
                lendingProgram: lendingProgramId,
              },
            }
        )
    );

    if (baseToken.symbol === "SOL") {
      txn.add(
          Token.createCloseAccountInstruction(
              TOKEN_PROGRAM_ID,
              coinSourceTokenAccount,
              wallet.publicKey,
              wallet.publicKey,
              []
          )
      );
    }

    if (quoteToken.symbol === "SOL") {
      txn.add(
          Token.createCloseAccountInstruction(
              TOKEN_PROGRAM_ID,
              pcSourceTokenAccount,
              wallet.publicKey,
              wallet.publicKey,
              []
          )
      );
    }

    return [txn, signers];
  },
  async handleTulipHarvestMargin (assetSymbol, obligationIdx) {
    let anchor = anchorold;

    const { wallet, isMintAddressExisting } = getStore('WalletStore'),
        walletToInitialize = {
          signTransaction: wallet.signTransaction,
          signAllTransactions: wallet.signAllTransactions,
          publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
        },
        provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment });

    anchor.setProvider(provider);

    // Address of the deployed program.
    const vaultProgramId = new anchor.web3.PublicKey(getLendingFarmProgramId());
    // Generate the program client from IDL.
    const vaultProgram = new anchor.Program(farmIdl, vaultProgramId);

    const solfarmVaultProgramId = new anchor.web3.PublicKey(
        getVaultProgramId()
    );

    const farm = getFarmBySymbol(assetSymbol),
        tulipTokenMint = new anchor.web3.PublicKey(TOKENS.TULIP.mintAddress);


    let [userFarm] = await findUserFarmAddress(
      provider.wallet.publicKey,
      new anchor.web3.PublicKey(getLendingFarmProgramId()), // lending_info.json -> programs -> farm -> id
      new anchor.BN(0),
      new anchor.BN(farm.marginIndex)
    );

    let [obligationVaultAccount] = await findObligationVaultAddress(
        userFarm,
        new anchor.BN(obligationIdx), // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
        new anchor.web3.PublicKey(getLendingFarmProgramId())
    );

    let [userObligationAcct1] = await findUserFarmObligationAddress(
        provider.wallet.publicKey,
        userFarm,
        new anchor.web3.PublicKey(getLendingFarmProgramId()),
        new anchor.BN(obligationIdx) // userFarm has `numberOfObligations`, so we'll do `numberOfObligations + 1` here
    );

    const vaultAccount = new anchor.web3.PublicKey(getVaultAccount(assetSymbol));
    const [vaultPdaAccount] = await anchor.web3.PublicKey.findProgramAddress(
        [vaultAccount.toBytes(), Buffer.from("pda")],
        solfarmVaultProgramId
    );

    const vaultInfoAccountPda = new anchor.web3.PublicKey(
        getVaultInfoAccount(assetSymbol)
    );

    let [userFarmManagerVaultBalanceAccount, nonce3] = await anchor.web3.PublicKey.findProgramAddress(
        [ new anchor.web3.PublicKey(getVaultOldInfoAccount(assetSymbol)).toBytes(), obligationVaultAccount.toBuffer()],
        solfarmVaultProgramId
    );
    const vaultBalanceNonce = nonce3;

    let [userFarmManagerVaultBalanceMetadataAccount, nonce4] = await anchor.web3.PublicKey.findProgramAddress(
        [
          userFarmManagerVaultBalanceAccount.toBuffer(),
          obligationVaultAccount.toBuffer(),
        ],
        solfarmVaultProgramId
    );
    const vaultMetaNonce = nonce4;

    let [userFarmManagerVaultTulipRewardAccount, vaultTulipRewardNonce] = await anchor.web3.PublicKey.findProgramAddress(
      [
        userFarmManagerVaultBalanceMetadataAccount.toBuffer(),
        obligationVaultAccount.toBuffer(),
      ],
      solfarmVaultProgramId,
    );

    const [leveragedFarm] = await findLeveragedFarmAddress(
      solfarmVaultProgramId,
      new anchor.web3.PublicKey(getLendingFarmAccount(assetSymbol).serum_market),
      new anchor.web3.PublicKey(getLendingFarmProgramId()),
      new anchor.BN(farm.marginIndex)
    );

    const userFarmManagerTulipAccount = await createAssociatedTokenAccount(
      provider,
      obligationVaultAccount,
      tulipTokenMint,
    );

    const userTulipAccount = await createAssociatedTokenAccount(
        provider,
        provider.wallet.publicKey,
        tulipTokenMint,
    );

    const txn = new anchor.web3.Transaction();

    if (!isMintAddressExisting(TOKENS.TULIP.mintAddress)) {
      txn.add(
        await serumAssoToken.createAssociatedTokenAccount(
          // who will pay for the account creation
          wallet.publicKey,

          // who is the account getting created for
          wallet.publicKey,

          // what mint address token is being created
          tulipTokenMint
        )
      );
    }

    txn.add(
      vaultProgram.instruction.harvestTulips(
        {
          nonce: vaultBalanceNonce,
          metaNonce: vaultMetaNonce,
          rewardNonce: vaultTulipRewardNonce,
        },

        new anchor.BN(obligationIdx),

        {
          accounts: {
            authority: provider.wallet.publicKey,
            obligationVaultAddress: obligationVaultAccount,
            userFarm: userFarm,
            leveragedFarm: leveragedFarm,
            vaultProgram: solfarmVaultProgramId,
            vault: vaultAccount,
            vaultPdaAccount: vaultPdaAccount,
            userInfoAccount: vaultInfoAccountPda,
            userBalanceAccount: userFarmManagerVaultBalanceAccount,
            userTulipRewardMetadata: userFarmManagerVaultTulipRewardAccount,
            vaultTulipTokenAccount: new anchor.web3.PublicKey(
                getVaultTulipTokenAccount(assetSymbol)
            ),
            userTulipTokenAccount: userFarmManagerTulipAccount,
            authorityTulipTokenAccount: userTulipAccount,
            tokenProgramId: splToken.TOKEN_PROGRAM_ID,
            clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
            rent: anchor.web3.SYSVAR_RENT_PUBKEY,
            systemProgram: new anchor.web3.PublicKey(
              "11111111111111111111111111111111"
            ),
          },
        }
      )
    );

    return sendTransaction(window.$web3, wallet, txn, []);
  }
};
