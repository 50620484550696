import {NATIVE_SOL, TOKENS} from "./tokens";
import paiLogo from "../coins/pai.png";
import usdcLogo from "../coins/usdc.png";
import usdtLogo from "../coins/usdt.png";
import usdt2Logo from "../coins/usdt2.png";
import ustLogo from "../coins/ust.png";
import husdLogo from "../coins/husd.png";
import usdkLogo from "../coins/usdk.png";
import daiLogo from "../coins/dai.png";
import busdLogo from "../coins/busd.png";
import fraxLogo from "../coins/frax.png";
import renBTCLogo from "../coins/renBTC.png";
import btcLogo from "../coins/btc.png";
import solLogo from "../coins/sol.png";
import msolLogo from "../coins/msol.png";
import wfttLogo from "../coins/wftt.png";
import fttLogo from "../coins/ftt.png";
import srmLogo from "../coins/srm.png";
import maiLogo from "../coins/mai.png";
import prtSolLogo from "../coins/prtsol.png";
import stSolLogo from "../coins/stsol.png";
import socnLogo from "../coins/socn.png";
import cusdc from "../coins/cusd.png";
import abBUSD from "../coins/abBUSD.png";
import { FARM_PLATFORMS } from "../constants/farmConstants";

export const SABER_FARMS = [
    {
        symbol: 'mSOL-SOL',
        name: 'mSOL-SOL LP',
        coin: {  ...TOKENS.MSOL },
        pc: { ...TOKENS.WSOL},
        coins: [TOKENS.MSOL, TOKENS.WSOL],
        reward: { ...TOKENS.RAY },
        rewardB: { ...TOKENS.USDC },

        mintAddress: 'SoLEao8wTzSfqhuou8rcYsVoLjthVmiXuEjzdNPMnCz',
        sunnyMintAddress: 'SoLEao8wTzSfqhuou8rcYsVoLjthVmiXuEjzdNPMnCz',
        decimals: TOKENS.MSOL.decimals,
        logos: [msolLogo, solLogo],
        dualYield: false,
        liquidityMining: false,
        totalTulipEmission: 0,
        totalSBREmission: 99000,
        totalSunnyEmission: 0,
        saber: true,
        sunny: false,
        link: 'https://app.saber.so/#/farms/msol_sol/stake',
        platform: FARM_PLATFORMS.SABER
    },
    {
        symbol: 'wFTT-FTT',
        name: 'wFTT-FTT LP',
        coin: { ...TOKENS.FTT },
        pc: {  ...TOKENS.FTT },
        coins: [TOKENS.FTT, TOKENS.FTT],
        reward: { ...TOKENS.RAY },
        rewardB: { ...TOKENS.USDC },

        mintAddress: 'FTXdV5wFFhceKjcd1JRrRQTT2uB7ruMerAqbj2rj1Mz7',
        sunnyMintAddress: 'FTXdV5wFFhceKjcd1JRrRQTT2uB7ruMerAqbj2rj1Mz7',
        decimals: TOKENS.FTT.decimals,
        logos: [wfttLogo, fttLogo],
        dualYield: false,
        liquidityMining: false,
        totalTulipEmission: 0,
        totalSBREmission: 46000,
        totalSunnyEmission: 0,
        saber: true,
        sunny: false,
        link: 'https://app.saber.so/#/farms/ftt/stake',
        platform: FARM_PLATFORMS.SABER
    },
    {
        symbol: 'wSRM-SRM',
        name: 'wSRM-SRM LP',
        coin: { ...TOKENS.SRM },
        pc: {  ...TOKENS.SRM },
        coins: [TOKENS.SRM, TOKENS.SRM],
        reward: { ...TOKENS.RAY },
        rewardB: { ...TOKENS.USDC },

        mintAddress: 'SRMKjSJpBHJ5gSVTrimci49SnXc1LVkBi9TGF9RNYdp',
        sunnyMintAddress: 'SRMKjSJpBHJ5gSVTrimci49SnXc1LVkBi9TGF9RNYdp',
        decimals: TOKENS.SRM.decimals,
        logos: [srmLogo, srmLogo],
        dualYield: false,
        dualYield: false,
        liquidityMining: false,
        totalTulipEmission: 0,
        totalSBREmission: 52000,
        totalSunnyEmission: 0,
        saber: true,
        sunny: false,
        link: 'https://app.saber.so/#/farms/srm/stake',
        platform: FARM_PLATFORMS.SABER
    },
    {
        symbol: 'BTC-renBTC',
        name: 'BTC-renBTC LP',
        coin: { ...TOKENS.renBTC },
        pc: {  ...TOKENS.saberBTC },
        coins: [TOKENS.renBTC, TOKENS.saberBTC],
        reward: { ...TOKENS.RAY },
        rewardB: { ...TOKENS.USDC },

        mintAddress: 'SLPbsNrLHv8xG4cTc4R5Ci8kB9wUPs6yn6f7cKosoxs',
        sunnyMintAddress: 'SLPbsNrLHv8xG4cTc4R5Ci8kB9wUPs6yn6f7cKosoxs',
        decimals: TOKENS.saberBTC.decimals,
        logos: [renBTCLogo, btcLogo],
        dualYield: false,
        liquidityMining: false,
        totalTulipEmission: 0,
        totalSBREmission: 137700,
        totalSunnyEmission: 0,
        saber: true,
        sunny: false,
        link: 'https://app.saber.so/#/farms/btc/stake',
        platform: FARM_PLATFORMS.SABER
    },
    {
        symbol: 'PAI-USDC',
        name: 'PAI-USDC LP',
        coin: { ...TOKENS.USDC },
        pc: {  ...TOKENS.USDC },
        coins: [TOKENS.PAI, TOKENS.USDC],
        reward: { ...TOKENS.RAY },
        rewardB: { ...TOKENS.USDC },

        mintAddress: 'PaiYwHYxr4SsEWox9YmyBNJmxVG7GdauirbBcYGB7cJ',
        sunnyMintAddress: 'PaiYwHYxr4SsEWox9YmyBNJmxVG7GdauirbBcYGB7cJ',
        decimals: TOKENS.USDC.decimals,
        logos: [paiLogo, usdcLogo],
        dualYield: false,
        liquidityMining: false,
        totalTulipEmission: 0,
        totalSBREmission: 42900,
        totalSunnyEmission: 0,
        saber: true,
        sunny: false,
        link: 'https://app.saber.so/#/pools/usdc_pai/deposit',
        platform: FARM_PLATFORMS.SABER
    },
    {
        symbol: 'USDT-USDC',
        name: 'USDT-USDC LP',
        coin: { ...TOKENS.USDT },
        pc: {  ...TOKENS.USDC },
        coins: [TOKENS.USDT, TOKENS.USDC],
        reward: { ...TOKENS.RAY },
        rewardB: { ...TOKENS.USDC },

        mintAddress: '2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf',
        sunnyMintAddress: 'DtYJRBbkHbSP1H8Gc3iAAEeBWfm3cPHqQqRW8Xrmu5xs',
        decimals: TOKENS.USDC.decimals,
        logos: [usdt2Logo, usdcLogo],
        dualYield: false,
        liquidityMining: false,
        totalTulipEmission: 0,
        totalSBREmission: 200000,
        totalSunnyEmission: 0,
        saber: true,
        sunny: false,
        link: 'https://app.saber.so/#/pools/usdc_usdt/deposit',
        tooltipText: 'Saber has a 0.5% withdrawal fee for USDT-USDC LP',
        platform: FARM_PLATFORMS.SABER
    },
    {
        symbol: 'UST-USDC',
        name: 'UST-USDC LP',
        coin: { ...TOKENS.saberUSDC },
        pc: {  ...TOKENS.UST },
        reward: { ...TOKENS.RAY },
        rewardB: { ...TOKENS.USDC },
        coins: [TOKENS.UST, TOKENS.USDC],

        mintAddress: 'UST32f2JtPGocLzsL41B3VBBoJzTm1mK1j3rwyM3Wgc',
        sunnyMintAddress: '2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf',
        decimals: 9,
        logos: [ustLogo, usdcLogo],
        dualYield: false,
        liquidityMining: false,
        totalTulipEmission: 0,
        totalSBREmission: 220000,
        totalSunnyEmission: 0,
        saber: true,
        sunny: false,
        link: 'https://app.saber.so/#/pools/ust/deposit',
        platform: FARM_PLATFORMS.SABER
    },
    {
        symbol: 'apUSDC-USDC',
        name: 'apUSDC-USDC LP',
        coin: { ...TOKENS.apUSDC },
        pc: {  ...TOKENS.USDC },
        reward: { ...TOKENS.RAY },
        rewardB: { ...TOKENS.USDC },
        coins: [TOKENS.apUSDC, TOKENS.USDC],

        mintAddress: 'APUVVYA8Xf7T1PqLyDvNxLtwQ9rRDf3RUxfMttreVzHP',
        sunnyMintAddress: '2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf',
        decimals: 6,
        logos: [usdcLogo, usdcLogo],
        dualYield: false,
        liquidityMining: false,
        totalTulipEmission: 0,
        totalSBREmission: 50000,
        totalSunnyEmission: 0,
        saber: true,
        sunny: false,
        link: 'https://app.saber.so/#/pools/apusdc/deposit',
        platform: FARM_PLATFORMS.SABER
    },
    // {
    //     symbol: 'apUSDT-USDT',
    //     name: 'apUSDT-USDT LP',
    //     coin: { ...TOKENS.apUSDT },
    //     pc: {  ...TOKENS.USDT },
    //     reward: { ...TOKENS.RAY },
    //     rewardB: { ...TOKENS.USDT },
    //     coins: [TOKENS.apUSDT, TOKENS.USDT],
    //
    //     mintAddress: 'PLYJZgSkcV8UXTWhTyf2WLCMeBoZum1Y4rXgXkoYiNj',
    //     sunnyMintAddress: '2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf',
    //     decimals: 6,
    //     logos: [usdtLogo, usdt2Logo],
    //     dualYield: false,
    //     liquidityMining: false,
    //     totalTulipEmission: 0,
    //     totalSBREmission: 175000,
    //     totalSunnyEmission: 0,
    //     saber: true,
    //     sunny: false,
    //     link: 'https://app.saber.so/#/pools/apusdt/deposit',
    // },
    {
        symbol: 'MAI-USDC',
        name: 'MAI-USDC LP',
        coin: { ...TOKENS.MAI },
        pc: {  ...TOKENS.saberUSDC },
        reward: { ...TOKENS.RAY },
        rewardB: { ...TOKENS.saberUSDC },
        coins: [TOKENS.MAI, TOKENS.saberUSDC],

        mintAddress: 'MAiP3Zmjhc6NYiCb2xK2893ifvTTDHciCS57Kga39pC',
        sunnyMintAddress: '2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf',
        decimals: 9,
        logos: [maiLogo, usdcLogo],
        dualYield: false,
        liquidityMining: false,
        totalTulipEmission: 0,
        totalSBREmission: 50000,
        totalSunnyEmission: 0,
        saber: true,
        sunny: false,
        link: 'https://app.saber.so/#/pools/mai/deposit',
        platform: FARM_PLATFORMS.SABER
    },
    {
        symbol: 'pSOL-SOL',
        name: 'prtSOL-SOL LP',
        coin: { ...TOKENS.prtSOL },
        pc: {  ...TOKENS.WSOL },
        reward: { ...TOKENS.RAY },
        rewardB: { ...TOKENS.WSOL },
        coins: [TOKENS.prtSOL, TOKENS.WSOL],

        mintAddress: 'PrsVdKtXDDf6kJQu5Ff6YqmjfE4TZXtBgHM4bjuvRnR',
        sunnyMintAddress: '2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf',
        decimals: 9,
        logos: [prtSolLogo, solLogo],
        dualYield: false,
        liquidityMining: false,
        totalTulipEmission: 0,
        totalSBREmission: 50000,
        totalSunnyEmission: 0,
        saber: true,
        sunny: false,
        link: 'https://app.saber.so/#/pools/prtsol/deposit',
        platform: FARM_PLATFORMS.SABER
    },
    {
        symbol: 'stSOL-SOL',
        name: 'stSOL-SOL LP',
        coin: { ...TOKENS.stSOL },
        pc: {  ...TOKENS.WSOL },
        reward: { ...TOKENS.RAY },
        rewardB: { ...TOKENS.WSOL },
        coins: [TOKENS.stSOL, TOKENS.WSOL],

        mintAddress: 'stSjCmjQ96BiGhTk8gkU22j1739R8YBQVMq7KXWTqUV',
        sunnyMintAddress: '2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf',
        decimals: 9,
        logos: [stSolLogo, solLogo],
        dualYield: false,
        liquidityMining: false,
        totalTulipEmission: 0,
        totalSBREmission: 51000,
        totalSunnyEmission: 0,
        saber: true,
        sunny: false,
        link: 'https://app.saber.so/#/pools/stsol/deposit',
        platform: FARM_PLATFORMS.SABER
    },
    {
        symbol: 'SOCN-SOL-SABER',
        name: 'SOCN-SOL LP',
        coin: { ...TOKENS.socn },
        pc: {  ...TOKENS.WSOL },
        reward: { ...TOKENS.RAY },
        rewardB: { ...TOKENS.WSOL },
        coins: [TOKENS.socn, TOKENS.WSOL],

        mintAddress: 'SoCJs5Qw1D3fjGbTqxxovK15FVnYVrwvTbYcBBrZmWj',
        sunnyMintAddress: '2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf',
        decimals: 9,
        logos: [socnLogo, solLogo],
        dualYield: false,
        liquidityMining: false,
        totalTulipEmission: 0,
        totalSBREmission: 42000,
        totalSunnyEmission: 0,
        saber: true,
        sunny: false,
        link: 'https://app.saber.so/#/pools/socean/deposit',
        platform: FARM_PLATFORMS.SABER
    },
    {
        symbol: 'acUSD-USDC',
        name: 'acUSD-USDC LP',
        coin: { ...TOKENS.acUSDC },
        pc: {  ...TOKENS.saberUSDC },
        reward: { ...TOKENS.RAY },
        rewardB: { ...TOKENS.WSOL },
        coins: [TOKENS.acUSDC, TOKENS.saberUSDC],

        mintAddress: 'cUSDDDBZRhpDW7eyUUPMuw6u1SiMnzu6i7movwf5jxk',
        sunnyMintAddress: '2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf',
        decimals: 9,
        logos: [cusdc, usdcLogo],
        dualYield: false,
        liquidityMining: false,
        totalTulipEmission: 0,
        totalSBREmission: 200000,
        totalSunnyEmission: 0,
        saber: true,
        sunny: false,
        link: 'https://app.saber.so/#/pools/acusd/deposit',
        platform: FARM_PLATFORMS.SABER
    },
    {
        symbol: 'abBUSD-USDC',
        name: 'abBUSD-USDC LP',
        coin: { ...TOKENS.abBUSD },
        pc: {  ...TOKENS.saberUSDC },
        reward: { ...TOKENS.RAY },
        rewardB: { ...TOKENS.WSOL },
        coins: [TOKENS.abBUSD, TOKENS.saberUSDC],

        mintAddress: 'BUSDjE9NEQ15aRFTxKFAjUf5vzqBhEgTNbYevWcSB5qp',
        sunnyMintAddress: '2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf',
        decimals: 9,
        logos: [abBUSD, usdcLogo],
        dualYield: false,
        liquidityMining: false,
        totalTulipEmission: 0,
        totalSBREmission: 40000,
        totalSunnyEmission: 0,
        saber: true,
        sunny: false,
        link: 'https://app.saber.so/#/pools/abbusd/deposit',
        platform: FARM_PLATFORMS.SABER
    },
    {
        symbol: 'USDK-USDC',
        name: 'USDK-USDC LP',
        coin: { ...TOKENS.USDK },
        pc: {  ...TOKENS.saberUSDC },
        reward: { ...TOKENS.RAY },
        rewardB: { ...TOKENS.USDC },
        coins: [TOKENS.USDK, TOKENS.USDC],

        mintAddress: 'uSdKg2Cs5bCtFSeNXs7aRVNzZJauX58eCkdsfssxTdW',
        sunnyMintAddress: '2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf',
        decimals: 9,
        logos: [usdkLogo, usdcLogo],
        dualYield: false,
        liquidityMining: false,
        totalTulipEmission: 0,
        totalSBREmission: 0,
        totalSunnyEmission: 0,
        saber: true,
        sunny: false,
        link: 'https://app.saber.so/#/pools/usdk/deposit',
        platform: FARM_PLATFORMS.SABER,
        disabled: true,
    },
    {
        symbol: 'DAI-USDC',
        name: 'wDAI-USDC LP',
        coin: { ...TOKENS.saberDAI },
        pc: {  ...TOKENS.saberUSDC },
        reward: { ...TOKENS.RAY },
        rewardB: { ...TOKENS.USDC },
        coins: [TOKENS.saberDAI, TOKENS.USDC],

        mintAddress: 'Daimhb91DY4e3aVaa7YCW5GgwaMT9j1ALSi2GriBvDNh',
        sunnyMintAddress: '2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf',
        decimals: 9,
        logos: [daiLogo, usdcLogo],
        dualYield: false,
        liquidityMining: false,
        totalTulipEmission: 0,
        totalSBREmission: 0,
        totalSunnyEmission: 0,
        saber: true,
        sunny: false,
        link: 'https://app.saber.so/#/pools/dai/deposit',
        platform: FARM_PLATFORMS.SABER,
        disabled: true,
    },
    {
        symbol: 'BUSD-USDC',
        name: 'wBUSD-USDC LP',
        coin: { ...TOKENS.saberBUSD },
        pc: {  ...TOKENS.saberUSDC },
        reward: { ...TOKENS.RAY },
        rewardB: { ...TOKENS.USDC },
        coins: [TOKENS.saberBUSD, TOKENS.USDC],

        mintAddress: 'BUSDaZjarCrQJLeHpWi7aLaKptdR1S8DFpwdDuuZu9p3',
        sunnyMintAddress: '2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf',
        decimals: 9,
        logos: [busdLogo, usdcLogo],
        dualYield: false,
        liquidityMining: false,
        totalTulipEmission: 0,
        totalSBREmission: 0,
        totalSunnyEmission: 0,
        saber: true,
        sunny: false,
        link: 'https://app.saber.so/#/pools/busd/deposit',
        platform: FARM_PLATFORMS.SABER,
        disabled: true,
    },
    {
        symbol: 'FRAX-USDC',
        name: 'wFRAX-USDC LP',
        coin: { ...TOKENS.saberFRAX },
        pc: {  ...TOKENS.saberUSDC },
        reward: { ...TOKENS.RAY },
        rewardB: { ...TOKENS.USDC },
        coins: [TOKENS.saberFRAX, TOKENS.USDC],

        mintAddress: 'FRAXXvt2ucEsxYPK4nufDy5zKhb2xysieqRBE1dQTqnK',
        sunnyMintAddress: '2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf',
        decimals: 9,
        logos: [fraxLogo, usdcLogo],
        dualYield: false,
        liquidityMining: false,
        totalTulipEmission: 0,
        totalSBREmission: 0,
        totalSunnyEmission: 0,
        saber: true,
        sunny: false,
        link: 'https://app.saber.so/#/pools/frax/deposit',
        platform: FARM_PLATFORMS.SABER,
        disabled: true,
    },
    {
        symbol: 'HUSD-USDC',
        name: 'HUSD-USDC LP',
        coin: { ...TOKENS.wHUSD },
        pc: {  ...TOKENS.saberWUSDC },
        reward: { ...TOKENS.RAY },
        rewardB: { ...TOKENS.USDC },
        coins: [TOKENS.wHUSD, TOKENS.USDC],

        mintAddress: 'HUSDgP5YieANhAAHD42yivX9aFS1zbodTut2Dvvkj8QS',
        sunnyMintAddress: '2poo1w1DL6yd2WNTCnNTzDqkC6MBXq7axo77P16yrBuf',
        decimals: 8,
        logos: [husdLogo, usdcLogo],
        dualYield: false,
        liquidityMining: false,
        totalTulipEmission: 0,
        totalSBREmission: 0,
        totalSunnyEmission: 0,
        saber: true,
        sunny: false,
        link: 'https://app.saber.so/#/pools/husd/deposit',
        platform: FARM_PLATFORMS.SABER,
        disabled: true,
    },
];