import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { CSSTransitionGroup } from 'react-transition-group';

// Icons
import refreshIcon from '../../icons/refresh.svg';
import infoIcon from '../../icons/info.svg';
import skullIcon from '../../icons/skull.svg';
import tulipIcon from '../../icons/SolFarmLogo.png';
import errorIcon from '../../icons/error.svg';
import upDownCaretIcon from '../../icons/up-down-caret.svg';
import upArrow from '../../icons/up-arrow.svg';
import downArrow from '../../icons/down-arrow.svg';
import downCaret from '../../icons/down-caret.svg';
import lineChart from '../../icons/line-chart.svg';
import rewardIcon from '../../icons/reward.svg';

// Store
import { getStore } from '../../stores/get-store';

// Components
import IconWithTooltip from '../../base-components/IconWithTooltip';
import { Loader } from '../../base-components/Loader';
import { Tooltip } from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Badge from '../../base-components/Badge';
import AddCollateralModal from './AddCollateralModal';
import { PageHeading } from '../PageHeading';
import ClosePositionModal from './ClosePositionModal';

// Lodash
import { isNil, debounce, isFunction, isEmpty, orderBy, get, last, camelCase } from 'lodash';

// Web3
import * as anchor from '@project-serum/anchor';

// Utils
import {getFormattedNumber, TokenAmount} from '../../utils/safe-math';
import { TOKENS } from '../../utils/tokens';
import { WAD } from '../../utils/layouts';
import { getFarmByMintAddress, getFarmBySymbol } from '../../utils/farms';
import { getLendingReserveByAccount } from '../../utils/config';
import { getReserveByName } from '../../utils/lendingReserves';

// Services
import { TransactionService } from '../../services/TransactionService';

// Constants
const CustomSlider = withStyles({
  root: {
    // color: '#52af77',
    height: 4,
    // margin: '8px 4px'
    marginLeft: 4
  },
  thumb: {
    height: 16,
    width: 16,
    // backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -6,
    marginLeft: -8,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% - 4px)',
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
})(Slider);

const ZERO = 0;

const tableColumns = ['Position Value', 'Debt Value', 'Equity Value', 'Kill Buffer', 'Details'],
  sortableColumns = ['Asset', 'APY'],
  disabledColumnsWithoutConnection = [],
  SORT_ORDER = ['desc', 'asc', null],
  headerToPropertyMap = {
    'Asset': 'tvlInNumber',
    'APY': 'apy'
  },
  columnHeaderTooltipText = {
    'Position Value': 'Total value of your farming position',
    'Debt Value': 'Total debt value of your farming position',
    'Equity Value': 'Estimated value you would get if you close your position',
    'Kill Buffer': 'Buffer between the current Debt Ratio and the Kill Threshold (85%). Position gets liquidated when kill buffer hits 0%.'
  },
  columnHeaderTooltipIcons = {
    'Position Value': infoIcon,
    'Debt Value': infoIcon,
    'Equity Value': infoIcon,
    'Rewards': infoIcon,
    'Kill Buffer': skullIcon
  },
  detailTooltipText = {
    'Debt Ratio': 'Debt / Position Value',
    'Kill Threshold': 'Debt Ratio threshold. Beyond this limit, your position could be liquidated'
  },
  detailTooltipIcons = {
    'Debt Ratio': infoIcon,
    'Kill Threshold': infoIcon
  },
  feeText = `Controller Fee: 0.01% 
  Platform fee: 0.1% 
  Deposit Fee: 0.0%
  Withdrawal Fee: 0.0%`,
  transactionToast = (tx) => (
    <div style={{ fontSize: '12px'}}>
      <div style={{ marginBottom: '8px', fontWeight: '600' }}>Transaction has been sent</div>
      <div>Confirmation is in progress. Check your transaction on <a className='link' href={`https://explorer.solana.com/tx/${tx}`} target='_blank'>here</a>.</div>
    </div>
  ),
  getRefreshTooltip = (timeToRefresh) => `Auto refresh in ${timeToRefresh} seconds, you can click to update manually\nAutomatically refreshes when the current pool had changed`,
  getRewardTooltipContent = (amountInTokens, amountInUsd) => {
    return (
      <div>
        <div style={{ marginBottom: '0.5rem' }}>
          Rewards since last deposit/withdraw
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ fontWeight: '600' }}>
            {amountInTokens}
          </span>
          <span style={{ fontSize: '0.8rem', marginLeft: '0.3rem' }}>
            (${amountInUsd})
          </span>
        </div>
      </div>
    )
  };

const NUMBER_OF_PERIODS_IN_A_WEEK = 24 * 7,
  NUMBER_OF_PERIODS_IN_A_YEAR = 24 * 365;

const getAPY = (periodicRate, numberOfPeriods) => {
  return (Math.pow((1 + (periodicRate/100)), numberOfPeriods) - 1);
}

class YourPositionsTableHeader extends React.Component {
  constructor () {
    super();

    this.handleRefresh = this.handleRefresh.bind(this);
    this.getTooltipText = this.getTooltipText.bind(this);
    this.handleHeaderClick = this.handleHeaderClick.bind(this);
  }

  componentDidMount () {
    this.intervalId = setInterval(() => {
      const { wallet } = getStore('WalletStore'),
        { timeToRefresh, setTimeToRefresh } = getStore('UIStore');

      if (!wallet) {
        return;
      }

      if (timeToRefresh - 1) {
        return setTimeToRefresh(timeToRefresh - 1);
      }

      this.handleRefresh(null, true);
    }, 1000);
  }

  componentWillUnmount () {
    this.intervalId && clearInterval(this.intervalId);
  }

  async handleRefresh (e, silent = false) {
    // If current tab is not visible, then don't refresh
    if (window?.document?.hidden) {
      return;
    }

    const { wallet } = getStore('WalletStore'),
      { setIsRefreshing, resetRefreshState } = getStore('UIStore');

    if (!wallet) {
      return;
    }

    setIsRefreshing(true);

    await Promise.all([
      getStore('WalletStore').setTokenAccounts(),
      getStore('FarmStore').setPrice(),
      getStore('ReserveStore').init(),
      getStore('PriceStore').init()
    ]);

    !silent && this.props.toast('Values refreshed.');
    resetRefreshState();
  }

  getTooltipText () {
    const { wallet } = getStore('WalletStore'),
      { timeToRefresh } = getStore('UIStore');

    if (!wallet) {
      return 'Wallet not connected';
    }

    // return `Refreshing in ${this.state.timeToRefresh}s`;
    return getRefreshTooltip(timeToRefresh);
  }

  handleHeaderClick (column) {
    const { wallet } = getStore('WalletStore');

    if (!wallet && disabledColumnsWithoutConnection.includes(column)) {
      return;
    }

    if (!sortableColumns.includes(column)) {
      return;
    }

    this.props.setSortBy(headerToPropertyMap[column]);
  }

  render () {
    const { wallet } = getStore('WalletStore'),
        {farmDetails} = getStore('FarmStore'),
      { isRefreshing, isSilentRefresh } = getStore('UIStore'),
      { sortedBy, sortOrder } = this.props,
      { isMobile } = getStore('ResponsiveStore');

    return (
      <div className='your-positions-table__header'>
        {/* <div
          style={{ flex: '0.5', justifyContent: 'flex-start' }}
          className={
            classnames(
              'your-positions-table__header-cell',
              'position-number',
              {
                'sortable': sortableColumns.includes('PositionNumber'),
                'disabled': !wallet && disabledColumnsWithoutConnection.includes('PositionNumber')
              }
            )
          }
          onClick={this.handleHeaderClick.bind(this, 'PositionNumber')}
        >
          {'#'}
          {
            sortableColumns.includes('PositionNumber') &&
            <div
              className='your-positions-table__header-cell__sort-btn'
            >
              <img src={
                sortedBy === headerToPropertyMap['PositionNumber'] ?
                (
                  sortOrder === 'asc' ? upArrow : downArrow
                ) :
                upDownCaretIcon
              } />
            </div>
          }
        </div> */}
        <div
          className='your-positions-table__header-asset'
          className={
            classnames(
              'your-positions-table__header-asset',
              {
                'sortable': sortableColumns.includes('Asset')
              }
            )
          }
          onClick={this.handleHeaderClick.bind(this, 'Asset')}
        >
          Vault
          {
            <div
              className='your-positions-table__header-cell__sort-btn'
            >
              <img src={
                sortedBy === headerToPropertyMap['Asset'] ?
                (
                  sortOrder === 'asc' ? upArrow : downArrow
                ) :
                upDownCaretIcon
              } />
            </div>
          }
        </div>
        {
          !isMobile &&
          tableColumns.map((tableColumn) => (
            <div
              key={tableColumn}
              className={
                classnames(
                  'your-positions-table__header-cell',
                  {
                    'sortable': sortableColumns.includes(tableColumn),
                    'disabled': !wallet && disabledColumnsWithoutConnection.includes(tableColumn)
                  },
                  [camelCase(tableColumn)]
                )
              }
              onClick={this.handleHeaderClick.bind(this, tableColumn)}
            >
              {tableColumn}
              {
                sortableColumns.includes(tableColumn) &&
                <div
                  className='your-positions-table__header-cell__sort-btn'
                >
                  <img src={
                    sortedBy === headerToPropertyMap[tableColumn] ?
                    (
                      sortOrder === 'asc' ? upArrow : downArrow
                    ) :
                    upDownCaretIcon
                  } />
                </div>
              }
              {
                columnHeaderTooltipText[tableColumn] &&
                <IconWithTooltip
                  icon={columnHeaderTooltipIcons[tableColumn]}
                  className='your-positions-table__header-cell__info-btn'
                  tooltipText={columnHeaderTooltipText[tableColumn]}
                  placement='bottom'
                />
              }
            </div>
          ))
        }
        <div
          onClick={this.handleRefresh}
          className={
            classnames(
              'your-positions-table__header-refresh',
              {
                'disabled': !wallet
              }
            )
          }
        >
          <IconWithTooltip
            icon={refreshIcon}
            className={
              classnames(
                'your-positions-table__header-refresh__btn',
                {
                  'is-refreshing': isRefreshing || isSilentRefresh
                }
              )
            }
            tooltipText={this.getTooltipText()}
          />
        </div>
      </div>
    );
  }
}

class YourPositionsTableRow extends React.Component {
  constructor () {
    super();

    this.state = {
      isExpanded: false,
      leverageValue: 2,
      withdrawValue: '',
      isDepositing: false,
      isWithdrawing: false,
      withdrawSliderValue: 0,
      depositSliderValue: 0,
      isLiquidityMiningInfoTooltipVisible: false,
      isErrorTooltipVisible: false,
      isHarvesting: false,
      isClosingPosition: false
    };

    this.toggleRow = this.toggleRow.bind(this);
    this.handleDeposit = this.handleDeposit.bind(this);
    this.handleLeverageChange = this.handleLeverageChange.bind(this);
    this.handleWithdraw = this.handleWithdraw.bind(this);
    this.handleWithdrawWithWarnings = this.handleWithdrawWithWarnings.bind(this);
    this.handleWithdrawChange = this.handleWithdrawChange.bind(this);
    this.handleDepositSliderChange = this.handleDepositSliderChange.bind(this);
    this.handleWithdrawSliderChange = this.handleWithdrawSliderChange.bind(this);
    this.handleFixTokenAccount = this.handleFixTokenAccount.bind(this);
    this.handleTulipHarvest = this.handleTulipHarvest.bind(this);

    this.debouncedWithdrawSliderChange = debounce(this.handleWithdrawSliderChange, 300);
    this.debouncedDepositSliderChange = debounce(this.handleDepositSliderChange, 100);

    this.toggleLiquidityMiningInfoTooltip = this.toggleLiquidityMiningInfoTooltip.bind(this);
    this.toggleErrorTooltip = this.toggleErrorTooltip.bind(this);

    this.handleClosePosition = this.handleClosePosition.bind(this);
    this.handleHarvestTulip = this.handleHarvestTulip.bind(this);
  }

  toggleLiquidityMiningInfoTooltip (value) {
    const isLiquidityMiningInfoTooltipVisible = isNil(value) ? !this.state.isLiquidityMiningInfoTooltipVisible : value;

    this.setState({ isLiquidityMiningInfoTooltipVisible });
  }

  toggleErrorTooltip (value) {
    const isErrorTooltipVisible = isNil(value) ? !this.state.isErrorTooltipVisible : value;

    this.setState({ isErrorTooltipVisible });
  }

  handleWithdrawSliderChange (e, value) {
    let { deposited, decimals } = this.props.data || {};

    deposited = Number(deposited);

    const withdrawValue = !value ? '' : Math.min(deposited, ((deposited * value)/100)).toFixed(decimals);

    this.setState({
      withdrawSliderValue: value,
      withdrawValue
    });
  }

  handleDepositSliderChange (e, value) {
    let { balance, decimals } = this.props.data || {};

    balance = Number(balance);

    const depositValue = !value ? '' : Math.min(balance, ((balance * value)/100)).toFixed(decimals);

    this.setState({
      depositSliderValue: value,
      depositValue
    });
  }

  toggleRow () {
    this.setState((prevState) => {
      return {
        isExpanded: !prevState.isExpanded
      };
    });
  }

  async handleDeposit () {
    this.setState({ isDepositing: true });

    try {
      const tx = await this.props.onDeposit(this.state.depositValue);

      this.props.toast(transactionToast(tx));
  
      this.setState({ isDepositing: false });
      this.handleDepositChange({ target: { value: '' } });
    } catch (err) {

      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleDeposit~err:', err);

      this.setState({ isDepositing: false });
    }
  }

  async handleTulipHarvest () {
    this.setState({ isHarvesting: true });

    try {
      const tx = await this.props.onTulipHarvest();

      this.props.toast(transactionToast(tx));
  
      this.setState({ isHarvesting: false });
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleTulipHarvest~err:', err);

      this.setState({ isHarvesting: false });
    }
  }

  handleLeverageChange (e) {
    let newValue = e?.target?.value;

    this.setState({
      leverageValue: Number(newValue)
    });
  }

  async handleWithdrawWithWarnings () {
    const isWithdrawUnsafe = await this.props.checkUnsafeWithdraw();

    if (isWithdrawUnsafe) {
      this.props.toggleModal(true);

      return this.props.setSuccessCallback(this.handleWithdraw);
    }

    return this.handleWithdraw();
  }

  async handleWithdraw () {
    this.setState({ isWithdrawing: true });

    // this.props.toast(`Withdraw Successful: ${tx}`);

    // Making transaction

    // Transaction has been sent
    // Confirmation is in progress. Check your transaction on here.

    // Transaction has been confirmed
    // Stake 0.000200 RAY-SOL LP

    const withdrawMax = (this.state.withdrawSliderValue === 100);

    try {
      const tx = await this.props.onWithdraw(this.state.withdrawValue, withdrawMax);

      this.props.toast(transactionToast(tx));
  
      this.setState({ isWithdrawing: false });
      this.handleWithdrawChange({ target: { value: '' } });
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleWithdraw~err:', err);

      this.setState({ isWithdrawing: false });
    }
  }

  handleWithdrawChange (e) {
    let newValue = e?.target?.value,
      { deposited } = this.props.data || {},
      valueForSlider = (newValue === '') ? 0 : newValue;

    deposited = Number(deposited);

    this.setState({
      withdrawValue: newValue,
      withdrawSliderValue: !deposited ? 0 : Math.round((valueForSlider * 100)/deposited)
    });
  }

  async handleFixTokenAccount (e) {
    // Stop bubbling of click event, to prevent list-item from expanding/collapsing
    e && e.stopPropagation();

    try {
      const tx = await this.props.onFixTokenAccount();

      this.props.toast(transactionToast(tx));
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleFixTokenAccount~err:', err);
    }
  }

  async handleClosePosition () {
    const { data } = this.props,
      obligationIdx = get(data, 'obligation.obligationIdx'),
      borrows = get(data, 'obligation.borrows');

    this.setState({ isClosingPosition: true });
    try {
      const transactions = await TransactionService.closeMarginPosition(
        data.assetSymbol,
        obligationIdx,
        borrows
      );

      const tx = await last(transactions);

      this.props.toast(transactionToast(tx));

      const signatureStatus = await window.$web3.getSignatureStatus(tx, {searchTransactionHistory: true});

      if (signatureStatus.value?.err) {
        throw new Error('Failed to close position. Please retry.');
      }

      this.setState({ isClosingPosition: false });
      this.props.toast('Position closed successfully.');

      // Refresh obligations
      setTimeout(getStore('WalletStore').setTokenAccounts, 9000);

      // this.props.history.push(APP_ROUTES.YOUR_POSITIONS.route);
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';


      this.setState({ isClosingPosition: false });
      this.props.toast(errorToast, 'error');
      err && console.error('handleClosePosition~err:', err);
    }
  }

  async handleHarvestTulip () {
    const { wallet, setTokenAccounts } = getStore('WalletStore');

    // Bail-out if wallet is not connected
    if (!wallet) {
      return;
    }

    const { assetSymbol, obligation } = this.props.data;
    const { obligationIdx } = obligation || {};

    this.props.toast('Harvesting...');
    this.setState({ isHarvesting: true });

    try {
      const tx = await TransactionService.handleTulipHarvestMargin(assetSymbol, obligationIdx);

      console.log('$$$tx', tx);

      this.props.toast(transactionToast(tx));
  
      this.setState({ isHarvesting: false });

      // Refresh token balances
      setTimeout(() => {
        setTokenAccounts();
        getStore('UIStore').resetRefreshState();
      }, 9000);
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleDeposit~err:', err);

      this.setState({ isHarvesting: false });
    }
  }

  render () {
    const {
      assetName,
      tvl,
      deposited,
      balance,
      dailyAPR,
      weeklyAPY,
      yearlyAPY,
      logos,
      price,
      dualYield,
      decimals,
      // rewardSinceLastDeposit,
      lastDepositTime,
      isUserBalanceAccountValid,
      liquidityMining,
      tulipAPR,
      isTokenAccountMissing,
      // tulipEarned,
      disabled,
      obligation,
      mintAddress,
      coinBorrowingInterest,
      pcBorrowingInterest,
      tradingFees,
      dailyAPRInNumber,
    } = this.props.data || {},
    {
      isExpanded,
      withdrawValue,
      isDepositing,
      isWithdrawing,
      withdrawSliderValue,
      depositSliderValue,
      isHarvesting,
      isClosingPosition
    } = this.state,
    { wallet } = getStore('WalletStore'),

    { isRefreshing } = getStore('UIStore');

    const {
      depositsMarketValue,
      borrowedValue,
      lpTokens,
      coinDeposits,
      pcDeposits,
      vaultShares,
      borrows,
      tulipEarned,
      lastDepositedAmount
    } = obligation;

    const {
      totalVaultBalance,
      totalVlpShares,
      totalLpTokens,
      price: farmPrice,
      coinInLp,
      pcInLp
    } = getStore('FarmStore').getFarm(mintAddress) || {};

    const farm = getFarmByMintAddress(mintAddress);
    const coin = farm.coins[0];
    const pc = farm.coins[1];

    const coinPrice = Number(getStore('PriceStore').getTokenPrice(coin.symbol));
    const pcPrice = Number(getStore('PriceStore').getTokenPrice(pc.symbol));

    let debtValue = 0;
    let borrowedReserve;
    let borrowedAmountInLp;
    let cumulativeBorrowRateWads;

    const reserveInfo = getLendingReserveByAccount(borrows[0]?.borrowReserve?.toBase58());
    const reserve = getReserveByName(reserveInfo?.name);
    const { getReserve } = getStore('ReserveStore');
    const { cumulativeBorrowRate, newCumulativeBorrowRate } = getReserve(reserve?.mintAddress) || {};

    borrows.forEach((borrow) => {
      const reserveInfo = getLendingReserveByAccount(borrow.borrowReserve.toBase58());
      const reservePrice = Number(getStore('PriceStore').getTokenPrice(reserveInfo.name));
      const reserve = getReserveByName(reserveInfo.name);
      // const borrowedAmount = (borrow.borrowedAmountWads.div(WAD)).toNumber() / Math.pow(10, reserve.decimals);
      const borrowedAmount = new TokenAmount(borrow.borrowedAmountWads.div(WAD), reserve.decimals);
      const oldBorrowRate = new TokenAmount(borrow.cumulativeBorrowRateWads);
      const newBorrowRate = new TokenAmount(newCumulativeBorrowRate);
      let borrowDebtValue = borrowedAmount.wei.times(newBorrowRate.wei).div(oldBorrowRate.wei).div(Math.pow(10, reserve.decimals));
      debtValue += borrowDebtValue.times(reservePrice).toNumber();

      // Store which reserve was borrowed and its amount
      if (borrowedAmount) {
        borrowedReserve = reserve;
        borrowedAmountInLp = borrowDebtValue.toNumber();
        cumulativeBorrowRateWads = borrow.cumulativeBorrowRateWads;
      }
    });

    // console.log('$obligations:', obligation);

    const lpTokensDecimals = Math.pow(10, decimals);
    // console.log("$$$ orca positions", farm, vaultShares.toString(), totalVaultBalance.toString(), totalVlpShares.toString(), lpTokensDecimals)
    const vaultShareTokens = (((vaultShares).mul(totalVaultBalance)).div(totalVlpShares)).toNumber() / lpTokensDecimals;

    const lpTokensValue = (lpTokens).toNumber() / lpTokensDecimals;
    const coinDepositsValue = (coinDeposits).toNumber() / Math.pow(10, coin.decimals);
    const pcDepositsValue = (pcDeposits).toNumber() / Math.pow(10, pc.decimals);
    const positionValue = (coinDepositsValue*coinPrice) + (pcDepositsValue*pcPrice) + ((vaultShareTokens + lpTokensValue) *Number(farmPrice));


    const totalCoin = (vaultShareTokens * coinInLp) + coinDepositsValue;
    const totalPc = (vaultShareTokens * pcInLp) + pcDepositsValue;

    // console.log("$$$ shares, lp, total coin, total pc", farm.symbol, vaultShares.toString(), vaultShareTokens, (vaultShareTokens * coinInLp), coinInLp, (vaultShareTokens * pcInLp), pcInLp);
    const equityValue = Math.abs(positionValue - debtValue);

    const debtRatio = (debtValue/positionValue) * 100;
    const killThreshold = 85;
    const killBuffer = killThreshold - debtRatio;

    // Current APY calculations
    const leverageValue = positionValue/equityValue;

    const adjustedBorrowingInterest = (leverageValue > 1 ) ? cumulativeBorrowRate * (leverageValue - 1): 0;

    const adjustedLeverageAPY = (100 * getAPY((dailyAPRInNumber * leverageValue)/(24), NUMBER_OF_PERIODS_IN_A_YEAR));

    const adjustedTotalAPY = (adjustedLeverageAPY) + (leverageValue * (tradingFees + tulipAPR)) - adjustedBorrowingInterest;

    const totalAPR = (
      (((dailyAPRInNumber * 365) + tradingFees + tulipAPR) * leverageValue) - (adjustedBorrowingInterest)
    );

    const adjustedDailyAPR = totalAPR / 365;

    // console.log('$$$lastDepositedAmount', lastDepositedAmount);
    // console.log('$$$positionValue:', positionValue);
    // console.log('$$$farmPrice', farmPrice);

    // console.log("$$ APY", adjustedLeverageAPY, tradingFees, tulipAPR, adjustedBorrowingInterest)
    const lpTokenPositionValue = ((vaultShareTokens + lpTokensValue) *Number(farmPrice));
    const rewardSinceLastDeposit = (lpTokenPositionValue - (lastDepositedAmount * farmPrice));
    const lastDepositTimeObj = lastDepositTime && new Date(lastDepositTime * 1000);

    // console.log("$$$ debt", new anchor.BN(debtValue), cumulativeBorrowRateWads, newCumulativeBorrowRate);

    const { isMobile } = getStore('ResponsiveStore');

    return (
      <div className={`your-positions-table__row ${isExpanded ? 'is-expanded': ''} ${!liquidityMining || !tulipEarned ? 'no-harvest' : ''}`}>
        <div onClick={this.toggleRow} className='your-positions-table__row-item'>

          {/* Position Number */}
          {/* <div style={{ flex: '0.5', textAlign: 'left' }} className='your-positions-table__row-item__cell'>
            12345
          </div> */}

          {/* Asset Details */}
          <div className='your-positions-table__row-item__asset'>
            <div style={{ display: 'flex', marginBottom: 8 }}>
            <div className='your-positions-table__row-item__asset__logos'>
              {
                logos.map((logo, index) => (
                  <img key={index} src={logo} className='your-positions-table__row-item__asset__logos-item' />
                ))
              }
            </div>
            <div className='your-positions-table__row-item__asset__text'>
              <div className='your-positions-table__row-item__asset__text-name'>
                {assetName}
                {/* {
                  dualYield &&
                  <IconWithTooltip
                    icon={infoIcon}
                    className='your-positions-table__header-cell__info-btn'
                    tooltipText='Dual Yield'
                  />
                }
                {
                  liquidityMining &&
                  <div
                    className='your-positions-table__header-cell__tulip-badge'
                    onMouseOver={this.toggleLiquidityMiningInfoTooltip.bind(this, true)}
                    onMouseLeave={this.toggleLiquidityMiningInfoTooltip.bind(this, false)}
                    id={`tulipIcon${this.props.rowIndex}`}
                  >
                    <img src={tulipIcon} />
                    <span>{getFormattedNumber(tulipAPR)}% APR</span>
                  </div>
                } */}
              </div>
              {/*<div className='your-positions-table__row-item__asset__text-tvl'>*/}
              {/*  TVL: { isRefreshing ? <Loader /> : (<span>$<span>{tvl}</span></span>) }*/}
              {/*</div>*/}
            </div>
            </div>
            {!isMobile && tulipEarned > 0 &&
            <Button
                color='primary'
                onClick={this.handleHarvestTulip}
                disabled={!tulipEarned}
                className='tulip-harvest-btn'
                style={{
                  fontSize: '0.8rem'
                }}
            >
              <img
                  src={tulipIcon}
                  style={{
                    height: '16px',
                    width: '11px',
                    marginRight: '6px'
                  }}
              />
              Harvest ({getFormattedNumber(tulipEarned, TOKENS.TULIP.decimals, TOKENS.TULIP.decimals)})
            </Button>
            }
          </div>

          {/* Mobile Top Level Row */}
          {
            isMobile && !isExpanded &&
              <div className='your-positions-table__row-item__cell mobile-details'>
                {/* Position Value */}
                <div>
                  Position Value
                  <div>${getFormattedNumber(positionValue)}</div>
                </div>

                {/* Equity Value */}
                <div>
                  Equity Value
                  <div>{positionValue < debtValue ? '-' : ''}${getFormattedNumber(equityValue)}</div>
                </div>

                {/* Kill Buffer */}
                <div>
                  Kill Buffer
                  <div>{getFormattedNumber(killBuffer)}%</div>
                </div>

                {/* Rewards */}
                <div>
                  Rewards
                  <div className='rewards'>${getFormattedNumber(rewardSinceLastDeposit)}</div>
                </div>
              </div>
          }

           {/* Item Toggle */}
           {
            isMobile &&
              <div className='your-positions-table__row-item__toggle'>
                <div className='your-positions-table__row-item__toggle__btn'>
                  <img src={downCaret} />
                </div>
              </div>
          }

          {/* Position Value */}
          {
            !isMobile &&
              <div className='your-positions-table__row-item__cell'>
                <div>${getFormattedNumber(positionValue)}</div>
                {/* <Badge
                  tooltipText='Rewards'
                >
                  <img src={lineChart} />
                  <span>${getFormattedNumber(rewardSinceLastDeposit)}</span>
                </Badge> */}

                <div className='position-breakup'>
                  <div className='position-breakup__item'>
                    <div className='position-breakup__item-label'>
                      <img src={logos[0]} />
                      {coin.symbol}
                    </div>
                    <div className='position-breakup__item-value'>
                      {getFormattedNumber(totalCoin)}
                    </div>
                  </div>
                  <div className='position-breakup__item'>
                    <div className='position-breakup__item-label'>
                      <img src={logos[1]} />
                      {pc.symbol}
                    </div>
                    <div className='position-breakup__item-value'>
                      {getFormattedNumber(totalPc)}
                    </div>
                  </div>
                </div>
              </div>
          }

          {/* Debt Value */}
          {
            !isMobile &&
              <div className='your-positions-table__row-item__cell'>
                ${getFormattedNumber(debtValue)}

                { borrowedReserve &&
                <div className='position-breakup'>
                  <div className='position-breakup__item'>
                    <div className='position-breakup__item-label'>
                      <img src={borrowedReserve.logo} />
                      {borrowedReserve.name}
                    </div>
                    <div className='position-breakup__item-value'>
                      {getFormattedNumber(borrowedAmountInLp)}
                    </div>
                  </div>
                </div>
                }
              </div>
          }

          {/* Equity Value */}
          {
            !isMobile &&
              <div className='your-positions-table__row-item__cell'>
                <div>{positionValue < debtValue ? '-' : ''}${getFormattedNumber(equityValue)}</div>
                <div className='rewards'>
                    ${getFormattedNumber(rewardSinceLastDeposit)}
                    {
                      rewardSinceLastDeposit &&
                        <IconWithTooltip
                          icon={rewardIcon}
                          className='your-positions-table__header-cell__info-btn'
                          tooltipText={
                            <div>
                              Rewards since last deposit on {lastDepositTimeObj ? lastDepositTimeObj.toLocaleDateString() : null}
                              <div style={{ marginTop: '0.5rem', textAlign: 'center', fontSize: '0.7rem', color: '#8ED3E5', fontWeight: 600 }}>
                                <span>
                                  Note: Rewards are included in Equity value.
                                </span>
                              </div>
                            </div>
                          }
                          placement='bottom'
                        />
                    }
                  </div>
                {/* TODO: Add Hover here rewards since last deposit tooltip*/}
              </div>
          }


          {/* Kill Buffer */}
          {
            !isMobile &&
              <div className='your-positions-table__row-item__cell'>
                {/* {
                  isRefreshing ? <Loader /> :
                  (yearlyAPY ? `${yearlyAPY} %` : '-')
                } */}
                <div>{getFormattedNumber(killBuffer)} %</div>
              </div>
          }

          {/* Details */}
          {
            !isMobile &&
              <div className='your-positions-table__row-item__cell details'>
                {/* {
                  isRefreshing ? <Loader /> :
                  (yearlyAPY ? `${yearlyAPY} %` : '-')
                } */}
                <div className='detail-line'>
                  <span className='detail-name'>Daily APR:</span>
                  <div>{adjustedDailyAPR ? (<span><span>{getFormattedNumber(adjustedDailyAPR)}</span> %</span>) : '-'}</div>
                </div>
                <div className='detail-line'>
                  <span className='detail-name'>APY:</span>
                  <div>{adjustedTotalAPY ? (<span><span>{getFormattedNumber(adjustedTotalAPY)}</span> %</span>) : '-'}</div>
                </div>
                <div className='detail-line'>
                  <span className='detail-name'>Leverage:</span>
                  <div>{leverageValue ? (<span><span>{getFormattedNumber(leverageValue)}</span>x</span>) : '-'}</div>
                </div>
                <div className='detail-line'>
                  <span className='detail-name'>
                    Debt Ratio:
                    {
                      detailTooltipText['Debt Ratio'] &&
                        <IconWithTooltip
                          icon={detailTooltipIcons['Debt Ratio']}
                          className='your-positions-table__header-cell__info-btn'
                          tooltipText={detailTooltipText['Debt Ratio']}
                          placement='bottom'
                        />
                    }
                  </span>
                  <div>{getFormattedNumber(debtRatio)} %</div>
                </div>

                {/* Current Borrow Interest */}
                <div className='detail-line'>
                  <span className='detail-name'>
                    Borrow Rate:
                  </span>
                  <div>{getFormattedNumber(cumulativeBorrowRate)} %</div>
                </div>

              </div>
          }


          {/* Kill Threshold */}
          {/* <div className='your-positions-table__row-item__cell'>
            {getFormattedNumber(killThreshold)}%
          </div> */}

          {/* Close Position Button */}
          {
            !isMobile &&
              <div className={
                classnames(
                  'your-positions-table__row-item__cell actions'
                )
              }>
                {/* {
                  isRefreshing ? <Loader /> :
                  (weeklyAPY ? `${weeklyAPY} %` : '-')
                } */}
                {/* {weeklyAPY ? (<span><span>{weeklyAPY}</span> %</span>) : '-'} */}
                <div className='add-collateral'>
                  <Button
                    onClick={this.props.toggleModal}
                  >
                    Add collateral
                  </Button>
                </div>
                <div className='close-position'>
                  <Button
                    onClick={this.props.toggleClosePositionModal}
                  >
                    { isClosingPosition ? <Loader /> : 'Close' }
                  </Button>
                </div>
              </div>
          }
        </div>

        {/* Expanded Mobile View */}
        {
          <CSSTransitionGroup
            transitionName='slide-in'
            transitionEnterTimeout={50}
            transitionLeaveTimeout={50}
          >
          {
            isExpanded && isMobile &&
              <div className={`your-positions-table__row-expanded ${isExpanded ? '' : 'is-hidden'} `}>
                <div className='your-positions-table__row-item__cell mobile-details-expanded'>
                  {/* Position Value */}
                  <div>
                    Position Value
                    <div>
                      <div className='position-value-text'>${getFormattedNumber(positionValue)}</div>
                      <div>({getFormattedNumber(totalCoin)} {coin.symbol} + {getFormattedNumber(totalPc)} {pc.symbol})</div>
                    </div>
                  </div>

                  {/* Debt Value */}
                  {
                    borrowedReserve &&
                    <div>
                      Debt Value
                      <div>${getFormattedNumber(debtValue)} ({getFormattedNumber(borrowedAmountInLp)} {borrowedReserve.name})</div>
                    </div>
                  }
                  {/* Equity Value */}
                  <div>
                    Equity Value
                    <div>
                      {positionValue < debtValue ? '-' : ''}${getFormattedNumber(equityValue)}
                    </div>
                  </div>

                  {/* Rewards */}
                  <div>
                    Rewards
                    <div className='rewards'>${getFormattedNumber(rewardSinceLastDeposit)}</div>
                  </div>

                  {/* Daily APR*/}
                  <div>
                    Daily APR
                    <div>
                      {adjustedDailyAPR ? (<span><span>{getFormattedNumber(adjustedDailyAPR)}</span> %</span>) : '-'}
                    </div>
                  </div>

                  {/* Current APY */}
                  <div>
                    Current APY
                    <div>
                      {adjustedTotalAPY ? (<span><span>{getFormattedNumber(adjustedTotalAPY)}</span> %</span>) : '-'}
                    </div>
                  </div>

                 {/* Leverage */}
                  <div>
                    Leverage
                    <div>
                      {leverageValue ? (<span><span>{getFormattedNumber(leverageValue)}</span>x</span>) : '-'}
                    </div>
                  </div>

                  {/* Debt Ratio */}
                  <div>
                    Debt Ratio
                    <div>{getFormattedNumber(debtRatio)}%</div>
                  </div>

                  {/* Kill Buffer */}
                  <div>
                    Kill Buffer
                    <div>{getFormattedNumber(killBuffer)}%</div>
                  </div>



                  {/* Add Collateral Button */}
                  <div className='add-collateral'>
                    <Button
                      onClick={this.props.toggleModal}
                    >
                      Add collateral
                    </Button>
                  </div>

                  {/* Close Position Button */}
                  <div className='close-position'>
                    <Button
                      onClick={this.props.toggleClosePositionModal}
                    >
                      { isClosingPosition ? <Loader /> : 'Close' }
                    </Button>
                  </div>

                  {/* Tulip Harvest Button */}
                  <div>
                  {
                    tulipEarned > 0 &&
                      <Button
                          color='primary'
                          onClick={this.handleHarvestTulip}
                          disabled={!tulipEarned}
                          className='tulip-harvest-btn'
                      >
                        <img
                            src={tulipIcon}
                            style={{
                              height: '16px',
                              width: '11px',
                              marginRight: '6px'
                            }}
                        />
                        Harvest ({getFormattedNumber(tulipEarned, TOKENS.TULIP.decimals, TOKENS.TULIP.decimals)})
                      </Button>
                  }
                  </div>
                </div>
              </div>
          }
          </CSSTransitionGroup>
        }

        {/* {
          liquidityMining &&
          <Tooltip
            target={`tulipIcon${this.props.rowIndex}`}
            isOpen={this.state.isLiquidityMiningInfoTooltipVisible}
            hideArrow
            placement='bottom'
            innerClassName='your-positions-table__tooltip'
          >
            Liquidity Mining
          </Tooltip>
        } */}
      </div>
    );
  }
}

const Header = observer(YourPositionsTableHeader);
const Row = observer(YourPositionsTableRow);

class YourPositionsTable extends React.Component {
  constructor () {
    super();

    this.state = {
      isModalOpen: false,
      isClosePositionModalOpen: false,
      activeModal: null,
      sortedBy: null,
      sortOrder: '',
      activeLeverageVault: {},
      leverageValue: 1
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.toggleClosePositionModal = this.toggleClosePositionModal.bind(this);
    this.setSuccessCallback = this.setSuccessCallback.bind(this);
    this.handleModalSuccess = this.handleModalSuccess.bind(this);
    this.getRowsInOrder = this.getRowsInOrder.bind(this);
    this.setSortBy = this.setSortBy.bind(this);

    this.modalSuccessCallback = null;
  }

  toggleModal (e, leverageVault = {}, value) {
    isNil(value) && (value = !this.state.isModalOpen);

    // console.log('I was called', e);

    this.setState({ isModalOpen: value, activeLeverageVault: leverageVault });
  }

  toggleClosePositionModal (e, leverageVault = {}, value) {
    isNil(value) && (value = !this.state.isClosePositionModalOpen);

    this.setState({
      isClosePositionModalOpen: value,
      activeLeverageVault: leverageVault
    });
  }

  setSuccessCallback (cb) {
    this.modalSuccessCallback = cb;
  }

  handleModalSuccess () {
    this.toggleModal(false);

    isFunction(this.modalSuccessCallback) && this.modalSuccessCallback();

    // Clear success callback after calling it
    this.setSuccessCallback(null);
  }

  getRowsInOrder (data) {
    const { sortedBy, sortOrder } = this.state;

    // If both `sortedBy` and `sortOrder` are not present,
    // then we fallback to the default order
    if (!(sortedBy && sortOrder)) {
      return data;
    }

    return orderBy(data, [sortedBy], [sortOrder]);
  }

  setSortBy (headerName) {
    if (!headerName) {
      return;
    }

    const { sortedBy, sortOrder } = this.state;

    // If the same header is being clicked multiple times,
    // we change it from 'desc' to 'asc' to `null` and so on.
    if (sortedBy === headerName) {
      const currentOrderIndex = SORT_ORDER.indexOf(sortOrder),
        nextOrder = SORT_ORDER[(currentOrderIndex + 1) % 3],
        nextState = { sortOrder: nextOrder };

      // If the next order is `null`, then we also reset `sortedBy`
      !nextOrder && (nextState.sortedBy = null);

      return this.setState(nextState);
    }

    // This is a new header, so we start with 'desc' again
    return this.setState({
      sortedBy: headerName,
      sortOrder: SORT_ORDER[0]
    });
  }

  render () {
    const {
        data = [],
        onWithdraw,
        onDeposit,
        checkUnsafeWithdraw,
        onFixTokenAccount,
        onTulipHarvest
      } = this.props || {},
      { isRefreshing, isSilentRefresh } = getStore('UIStore'),
      rowsInOrder = this.getRowsInOrder(data),
      {
        sortedBy,
        sortOrder,
        isModalOpen,
        isClosePositionModalOpen,
        activeLeverageVault,
        leverageValue
      } = this.state,
      { wallet } = getStore('WalletStore');

    return (
      <div className='your-positions-table'>
        <Header
          toast={this.props.toast}
          setSortBy={this.setSortBy}
          sortedBy={sortedBy}
          sortOrder={sortOrder}
        />
        {
          isEmpty(data) ? 
            (
              isRefreshing || isSilentRefresh ?
                <Loader /> :
                <div className='your-positions-table__empty'>
                  {
                    wallet
                      ? isRefreshing || isSilentRefresh
                        ? 'Please wait, we\'re refreshing the data. Try a cookie maybe? 🍪'
                        : 'You have no active positions.'
                      : 'Please connect your wallet to view your active positions.'
                  }
                </div>
            ) :
            rowsInOrder.map((row, index) => (
              <Row
                data={row}
                onWithdraw={onWithdraw.bind(this, row.assetSymbol)}
                onDeposit={onDeposit.bind(this, row.assetSymbol)}
                toast={this.props.toast}
                key={`${row.assetSymbol}-${row.obligation.obligationIdx}`}
                toggleModal={this.toggleModal.bind(this, null, row)}
                toggleClosePositionModal={this.toggleClosePositionModal.bind(this, null, row)}
                setSuccessCallback={this.setSuccessCallback}
                checkUnsafeWithdraw={checkUnsafeWithdraw.bind(this, row.assetSymbol)}
                rowIndex={index}
                onFixTokenAccount={onFixTokenAccount.bind(this, row.assetSymbol)}
                onTulipHarvest={onTulipHarvest.bind(this, row.assetSymbol)}
              />
            ))
        }
        <ClosePositionModal
          isOpen={isClosePositionModalOpen}
          onToggle={this.toggleClosePositionModal}
          activeLeverageVault={activeLeverageVault}
          initialLeverageValue={leverageValue}
          toast={this.props.toast}
        />
        <AddCollateralModal
          isOpen={isModalOpen}
          onToggle={this.toggleModal}
          activeLeverageVault={activeLeverageVault}
          initialLeverageValue={leverageValue}
          toast={this.props.toast}
        />
      </div>
    );
  }
}

export default observer(YourPositionsTable);
