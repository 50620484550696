import UIStore from './UIStore';
import WalletStore from './WalletStore';
import ConnectionStore from './ConnectionStore';
import FarmStore from './FarmStore';
import UserPreferenceStore from './UserPreferenceStore';
import ReserveStore from './ReserveStore';
import LeverageVaultStore from './LeverageVaultStore';
import PriceStore from './PriceStore';
import FilterStore from './FilterStore';
import InfobarStore from './InfobarStore';
import ModalStore from './ModalStore';
import ResponsiveStore from './ResponsiveStore';
import LeverageFilterStore from './LeverageFilterStore';
import ObligationStore from './ObligationStore';

const stores = {
  UIStore,
  WalletStore,
  ConnectionStore,
  FarmStore,
  UserPreferenceStore,
  ReserveStore,
  LeverageVaultStore,
  PriceStore,
  FilterStore,
  InfobarStore,
  ModalStore,
  ResponsiveStore,
  LeverageFilterStore,
  ObligationStore,
};

export default stores;
