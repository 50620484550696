import { head, remove, concat, transform, cloneDeep, map, uniqBy, sortBy, toLower } from 'lodash';
import { SABER_FARMS } from './saber_farms';
import { FARMS } from './farms';

import saberLogo from '../coins/saber.svg';
import rayLogo from '../coins/ray.png';
import orcaLogo from '../coins/orca.png';
import { isSupportedLendingFarm } from './config';
import { ORCA_FARMS } from './orcaFarms';
import { FARM_PLATFORMS } from '../constants/farmConstants';

const ALL_TOKEN_FARM = {
  logo: null,
  token: 'ALL',
  farms: [],
  platform: 'ALL'
};

/**
 * @description Get the farms meta information such as
 * token, logo, and platform.
 *
 * @param {Array} farms
 * @returns {Object}
 */
function _getFarmsMetaCoin (farms) {
  return farms.map((farm) => {
    const [token] = farm.coins,
      [logo] = farm.logos;

    return {
      logo,
      token: token.symbol,
      farms: [farm.symbol],
      platform: farm.saber ? 'SABER' : 'RAY'
    }
  });
}

/**
 * @description Get the farms meta information such as
 * token, logo, and platform.
 *
 * @param {Array} farms
 * @returns {Object}
 */
 function _getFarmsMetaPC (farms) {
  return farms.map((farm) => {
    const [_, token] = farm.coins,
      [__, logo] = farm.logos;

    return {
      logo,
      token: token.symbol,
      farms: [farm.symbol],
      platform: farm.saber ? 'SABER' : 'RAY'
    }
  });
}

/**
 * Get the farms meta data for filtering
 *
 * @returns {Object}
 */
const FILTER_CONFIG = (function getFarms () {
  const saberFarmCoins = _getFarmsMetaCoin(SABER_FARMS),
    saberFarmPCs = _getFarmsMetaPC(SABER_FARMS),
    rayFarmCoins = _getFarmsMetaCoin(FARMS),
    rayFarmPCs = _getFarmsMetaPC(FARMS),
    orcaFarmCoins = _getFarmsMetaCoin(ORCA_FARMS),
    orcaFarmPCs = _getFarmsMetaPC(ORCA_FARMS);

  return {
    all: {
      platform: 'all',
      farms: concat(ALL_TOKEN_FARM, orcaFarmCoins, orcaFarmPCs, saberFarmCoins, saberFarmPCs, rayFarmCoins, rayFarmPCs)
    },
    orca: {
      platform: FARM_PLATFORMS.ORCA,
      farms: concat(orcaFarmCoins, orcaFarmPCs),
      logo: orcaLogo
    },
    saber: {
      platform: 'saber',
      farms: concat(saberFarmCoins, saberFarmPCs),
      logo: saberLogo
    },
    ray: {
      platform: 'ray',
      farms: concat(rayFarmCoins, rayFarmPCs),
      logo: rayLogo
    },
    orca: {
      platform: FARM_PLATFORMS.ORCA,
      farms: concat(orcaFarmCoins, orcaFarmPCs),
      logo: orcaLogo
    }
  };
})();


/**
 * Get the farms meta data for filtering
 *
 * @returns {Object}
 */
 const LEVERAGE_FARMS_CONFIG = (function getFarms () {
  const rayFarmCoins = _getFarmsMetaCoin(FARMS),
    rayFarmPCs = _getFarmsMetaPC(FARMS),
    orcaFarmCoins = _getFarmsMetaCoin(ORCA_FARMS),
    orcaFarmPCs = _getFarmsMetaPC(ORCA_FARMS);

  const supportedRayLendingFarms = concat(rayFarmCoins, rayFarmPCs)
  .filter((f) => isSupportedLendingFarm(head(f.farms)));

  const supportedOrcaLendingFarms = concat(orcaFarmCoins, orcaFarmPCs)
  .filter((f) => isSupportedLendingFarm(head(f.farms)));

  return {
    all: {
      platform: 'all',
      farms: concat(ALL_TOKEN_FARM, supportedRayLendingFarms, supportedOrcaLendingFarms)
    },
    ray: {
      platform: 'ray',
      farms: supportedRayLendingFarms,
      logo: rayLogo
    },
    orca: {
      platform: FARM_PLATFORMS.ORCA,
      farms: supportedOrcaLendingFarms,
      logo: orcaLogo
    }
  };
})();

function sortAndMoveAllToFirst (items) {
  const sortedItems = sortBy(items, ({ name }) => toLower(name));
  const all = remove(sortedItems, ({ name }) => toLower(name) === 'all');

  return [...all, ...sortedItems];
}

const ALL_PLATFORMS = sortAndMoveAllToFirst(map(FILTER_CONFIG, (p) => ({ name: p.platform, logo: p.logo })));

const ALL_LEVERAGE_PLATFORMS = sortAndMoveAllToFirst(map(LEVERAGE_FARMS_CONFIG, (p) => ({ name: p.platform, logo: p.logo })));

const FARM_TOKENS = sortAndMoveAllToFirst(uniqBy(map(FILTER_CONFIG.all.farms, (t) => ({ name: t.token, logo: t.logo })), 'name'));

const RAY_TOKENS = sortAndMoveAllToFirst(uniqBy(map(FILTER_CONFIG.ray.farms, (t) => ({ name: t.token, logo: t.logo })), 'name'));

const SABER_TOKENS = sortAndMoveAllToFirst(uniqBy(map(FILTER_CONFIG.saber.farms, (t) => ({ name: t.token, logo: t.logo })), 'name'));

const ORCA_TOKENS = sortAndMoveAllToFirst(uniqBy(map(FILTER_CONFIG.orca.farms, (t) => ({ name: t.token, logo: t.logo })), 'name'));

const LEVERAGE_FARM_TOKENS = sortAndMoveAllToFirst(uniqBy(map(LEVERAGE_FARMS_CONFIG.all.farms, (t) => ({ name: t.token, logo: t.logo })), 'name'));

const RAY_LEV_TOKENS = sortAndMoveAllToFirst(uniqBy(map(LEVERAGE_FARMS_CONFIG.ray.farms, (t) => ({ name: t.token, logo: t.logo })), 'name'));

const ORCA_LEV_TOKENS = sortAndMoveAllToFirst(uniqBy(map(LEVERAGE_FARMS_CONFIG.orca.farms, (t) => ({ name: t.token, logo: t.logo })), 'name'));

function getTokens (farms) {
  return transform(farms, (acc, farm) => {
    if (acc[farm.token]) {
      acc[farm.token].farms.push(...farm.farms);
    }
    else {
      // Clone the object to prevent modifying the source object
      acc[farm.token] = cloneDeep(farm);
    }
  
    return acc;
  }, {});
}

const ALL_TOKENS = getTokens(FILTER_CONFIG.all.farms);
const ALL_LEVERAGE_TOKENS = getTokens(LEVERAGE_FARMS_CONFIG.all.farms);

export {
  FILTER_CONFIG,
  ALL_TOKENS,
  ALL_PLATFORMS,
  FARM_TOKENS,
  RAY_TOKENS,
  SABER_TOKENS,
  ORCA_TOKENS,
  ALL_LEVERAGE_TOKENS,
  LEVERAGE_FARM_TOKENS,
  ALL_LEVERAGE_PLATFORMS,
  LEVERAGE_FARMS_CONFIG,
  RAY_LEV_TOKENS,
  ORCA_LEV_TOKENS
};
