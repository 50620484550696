import dayjs from "dayjs";

export const isFarmNew = (endDate) => {
  const [date, month, year] = endDate.split('/');
  const endDateInUSFormat = `${month}-${date}-${year}`;

  const endingDate = dayjs(endDateInUSFormat);
  const today = dayjs();

  return (today.isBefore(endingDate));
}