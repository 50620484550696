import React from 'react';
import { observer } from 'mobx-react';
import { getStore } from '../../stores/get-store';

// Material UI
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import classnames from 'classnames';

const CustomSwitch = withStyles({
  switchBase: {
    // color: purple[300],
    // color: '#5FBAD6',
    color: '#C1EBF4',
    '&$checked': {
      // color: purple[500],
      // color: '#1C88B4',
      // color: 'linear-gradient(315deg, #C61FB5 -6.25%, #2CABC7 100%)',
      color: '#C61FB5',
      // color: '#2CABC7',
    },
    '&$checked + $track': {
      // backgroundColor: purple[500],
      // backgroundColor: '#1C88B4',
      // backgroundColor: 'linear-gradient(315deg, #C61FB5 -6.25%, #2CABC7 100%)',
      backgroundColor: '#C61FB5',
      // backgroundColor: '#2CABC7',
    },
  },
  checked: {},
  track: {},
})(Switch);

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 38,
    height: 22,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        // backgroundColor: '#52d869',
        backgroundColor: '#5FBAD6',
        // backgroundColor: '#1C88B4',
        opacity: 1,
        border: `1px solid ${theme.palette.grey[400]}`,
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 26 / 2,
    border: 'none',
    // backgroundColor: theme.palette.grey[50],
    backgroundColor: '#146C98',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

class VaultsFilters extends React.Component {
  constructor () {
    super();

    this.handleShowDepositedToggle = this.handleShowDepositedToggle.bind(this);
  }

  handleShowDepositedToggle () {
    const preferenceStore = getStore('UserPreferenceStore'),
      showDeposited = preferenceStore.get('showDeposited');

    preferenceStore.set('showDeposited', !showDeposited);
  }

  render () {
    const userPreferenceStore = getStore('UserPreferenceStore'),
      showDeposited = userPreferenceStore.get('showDeposited'),
      { wallet } = getStore('WalletStore');

    return (
      <div className={classnames('filters', 'lend')}>
        <div className='filters__toggle'>
          <IOSSwitch
            checked={wallet ? showDeposited : false}
            onChange={this.handleShowDepositedToggle}
            color="primary"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            disabled={!wallet}
          />
          <span>Show Deposited</span>
        </div>
      </div>
    )
  }
}

export default observer(VaultsFilters);
