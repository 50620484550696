import React from 'react';
import { observer } from 'mobx-react';

// Store
import { getStore } from '../../stores/get-store';

// Components
import { WarningBanner } from '../EmptyStates/WarningBanner';
import YourPositionsTable from './YourPositionsTable';

// Services
import { TransactionService } from '../../services/TransactionService';

// Utils
import { getFormattedNumber } from '../../utils/safe-math';
import { PageHeading } from '../PageHeading';
import {LEVERAGE_FARMS} from '../../utils/farms';
import Infobar from '../../base-components/Infobar';

// Constants
const ZERO = 0;

class YourPositionsBody extends React.Component {
  constructor () {
    super();

    this.handleDeposit = this.handleDeposit.bind(this);
    this.handleWithdraw = this.handleWithdraw.bind(this);
    this.checkUnsafeWithdraw = this.checkUnsafeWithdraw.bind(this);
    this.handleTulipHarvest = this.handleTulipHarvest.bind(this);
    this.handleBulkTulipHarvest = this.handleBulkTulipHarvest.bind(this);
    this.handleFixTulipRewardTokenAccount = this.handleFixTulipRewardTokenAccount.bind(this);
  }

  async handleWithdraw (assetSymbol, value, withdrawMax = false) {
    const { wallet, setTokenAccounts } = getStore('WalletStore');

    // Bail-out if wallet is not connected
    if (!wallet) {
      return;
    }

    this.props.toast('Withdrawing...');

    // Refresh token balances
    setTimeout(() => {
      setTokenAccounts();
      getStore('UIStore').resetRefreshState();
    }, 9000);

    return TransactionService.handleWithdrawFromVault(assetSymbol, value, withdrawMax);
  }

  handleDeposit (assetSymbol, value) {
    const { wallet, setTokenAccounts } = getStore('WalletStore');

    // Bail-out if wallet is not connected
    if (!wallet) {
      return;
    }

    this.props.toast('Depositing...');

    // Refresh token balances
    setTimeout(() => {
      setTokenAccounts();
      getStore('UIStore').resetRefreshState();
    }, 9000);

    return TransactionService.handleDepositToVault(assetSymbol, value);
  }

  async checkUnsafeWithdraw (assetSymbol) {
    const { wallet } = getStore('WalletStore');

    // Bail-out if wallet is not connected
    if (!wallet) {
      return;
    }

    return TransactionService.checkUnsafeWithdraw(assetSymbol);
  }

  async handleFixTokenAccount (assetSymbol) {
    const { wallet } = getStore('WalletStore');

    // Bail-out if wallet is not connected
    if (!wallet) {
      return;
    }

    return TransactionService.handleFixTokenAccount(assetSymbol);
  }

  async handleFixTulipRewardTokenAccount () {
    const { wallet } = getStore('WalletStore');

    // Bail-out if wallet is not connected
    if (!wallet) {
      return;
    }

    // Refresh token balances
    setTimeout(() => {
      getStore('WalletStore').setTokenAccounts();
      getStore('UIStore').resetRefreshState();
    }, 9000);

    return TransactionService.handleFixTulipRewardTokenAccount();
  }

  handleTulipHarvest (assetSymbol) {
    return this.handleBulkTulipHarvest([assetSymbol]);
  }

  async handleBulkTulipHarvest (farms) {
    const { wallet, setTokenAccounts } = getStore('WalletStore');

    // Bail-out if wallet is not connected
    if (!wallet) {
      return;
    }

    this.props.toast('Harvesting...');

    // Refresh token balances
    setTimeout(() => {
      setTokenAccounts();
      getStore('UIStore').resetRefreshState();
    }, 9000);

    return TransactionService.handleBulkTulipHarvest(farms);
  }

  render () {
    const { wallet, tokenAccounts, isTokenAccountInvalid } = getStore('WalletStore'),
        { getFarm } = getStore('FarmStore'),
        tableData = [];


    LEVERAGE_FARMS.forEach((farm) => {
      // Don't show anything if wallet is not connected
      if (!wallet) {
        return;
      }

      if (farm.symbol !== 'RAY-SRM-DUAL') {
        let balance = '-',
            deposited = '-',
            rewardSinceLastDeposit = ZERO,
            tulipEarned = ZERO;

        if (wallet) {
          if (tokenAccounts[farm.mintAddress]) {
            balance = Number(tokenAccounts[farm.mintAddress].balance?.fixed());
            deposited = tokenAccounts[farm.mintAddress].deposited || ZERO.toFixed(farm.decimals);
            rewardSinceLastDeposit = tokenAccounts[farm.mintAddress]?.rewardSinceLastDeposit;
            tulipEarned = tokenAccounts[farm.mintAddress]?.tulipEarned;
          } else {
            balance = ZERO.toFixed(farm.decimals);
            deposited = ZERO.toFixed(farm.decimals);
          }
        }

        const {
          tvl = 0,
          weeklyAPY = 0,
          yearlyAPY = 0,
          dailyAPR = 0,
          price = 0,
          isUserBalanceAccountValid = false,
          tulipAPR = 0,
          userFarmInfo,
          farmObligationAccounts,
          yieldBreakdown
        } = getFarm(farm.mintAddress) || {};

        // console.log("$$ your positons processing ", farm, farmObligationAccounts);
        const {obligations} = userFarmInfo || {};
        if (userFarmInfo) {
          if (farmObligationAccounts.hasOwnProperty("length") && farmObligationAccounts.length > 0) {
            farmObligationAccounts.forEach((farmObligationAccount, index) => {
              // if (
              //   farmObligationAccount.positionState.hasOwnProperty("opened") ||
              //   farmObligationAccount.positionState.hasOwnProperty("withdrawing") ||
              //   farmObligationAccount.positionState.hasOwnProperty("removedLiquidity") ||
              //   farmObligationAccount.positionState.hasOwnProperty("swappedForRepaying") ||
              //   farmObligationAccount.positionState.hasOwnProperty("repaid") ||
              //   farmObligationAccount.positionState.hasOwnProperty("swapped")
              // ) {
              //   console.log('valid obligation:', farmObligationAccount);
              // }

              const {getReserve} = getStore('ReserveStore');
              const {cumulativeBorrowRate: coinBorrowingInterest} = getReserve(farm?.coins[0]?.mintAddress) || {};
              const {cumulativeBorrowRate: pcBorrowingInterest} = getReserve(farm?.coins[1]?.mintAddress) || {};
              const {getPair} = getStore('PriceStore');
              const {apy: tradingFees = 0} = getPair(farm.mintAddress) || {};

              tableData.push({
                assetName: farm.name,
                assetSymbol: farm.symbol,
                tvl: getFormattedNumber(tvl),
                weeklyAPY: getFormattedNumber(yieldBreakdown.weeklyYield),
                yearlyAPY: getFormattedNumber(yieldBreakdown.yearlyYield),
                dailyAPR: getFormattedNumber(yieldBreakdown.dailyYield),
                balance,
                deposited,
                logos: farm.logos,
                decimals: farm.decimals,
                price,
                dualYield: Boolean(farm.dualYield),
                rewardSinceLastDeposit,
                isUserBalanceAccountValid,
                liquidityMining: Boolean(farm.liquidityMining),
                tulipAPR,
                isTokenAccountMissing: isTokenAccountInvalid(farm.mintAddress, farm?.platform),
                tvlInNumber: tvl,
                depositedInUsd: deposited * price,
                balanceInUsd: balance * price,
                yearlyAPYInNumber: yieldBreakdown.yearlyYield,
                tulipEarned,
                disabled: Boolean(farm.disabled),
                obligation: farmObligationAccount,
                mintAddress: farm.mintAddress,
                coins: farm.coins,
                coinBorrowingInterest,
                pcBorrowingInterest,
                tradingFees: tradingFees,
                dailyAPRInNumber: yieldBreakdown.dailyYield,
                maxPositionLimitInUsd: farm.maxPositionLimitInUsd,
                lastDepositTime: farmObligationAccount.lastDepositTime
              });
            });
          }
        }
      }
    });

    const { isDesktop } = getStore('ResponsiveStore');

    return (
      <div className='app-body'>
        <Infobar />
        {/* <WarningBanner /> */}
        {/* <VaultsFilters
          onBulkTulipHarvest={this.handleBulkTulipHarvest}
          toast={this.props.toast}
          onFixTulipRewardTokenAccount={this.handleFixTulipRewardTokenAccount}
        /> */}
        {
          !isDesktop && <PageHeading />
        }
        <YourPositionsTable
          toast={this.props.toast}
          data={tableData}
          onDeposit={this.handleDeposit}
          onWithdraw={this.handleWithdraw}
          checkUnsafeWithdraw={this.checkUnsafeWithdraw}
          onFixTokenAccount={this.handleFixTokenAccount}
          onTulipHarvest={this.handleTulipHarvest}
        />
      </div>
    )
  }
}

export default observer(YourPositionsBody);
