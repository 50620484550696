import React from 'react';
import classnames from 'classnames';
import { isNil } from 'lodash';
import { Tooltip } from 'reactstrap';
import infoIcon from '../icons/info.svg';

export default class IconWithTooltip extends React.Component {
  constructor () {
    super();

    this.state = {
      isTooltipVisible: false
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.getIconRef = this.getIconRef.bind(this);
  }

  toggleTooltip (value) {
    const isTooltipVisible = isNil(value) ? !this.state.isTooltipVisible : value;

    this.setState({ isTooltipVisible });
  }

  getIconRef () {
    return this.iconRef;
  }

  render () {
    const {
      icon,
      tooltipText,
      className,
      placement
    } = this.props;

    return (
      <>
        <div
          className={
            classnames(
              'icon-with-tooltip',
              className
            )
          }
          onMouseOver={this.toggleTooltip.bind(this, true)}
          onMouseLeave={this.toggleTooltip.bind(this, false)}
          ref={(ref) => this.iconRef = ref}
        >
          <img src={icon} />
        </div>
        <Tooltip
          target={this.getIconRef}
          isOpen={this.state.isTooltipVisible}
          hideArrow
          placement={placement || 'bottom-end'}
          innerClassName='base-tooltip'
        >
          {tooltipText}
        </Tooltip>
      </>
    );
  }
}

IconWithTooltip.defaultProps = {
  icon: infoIcon
}
