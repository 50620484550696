import React from 'react';
import AppHeader from '../AppHeader';
import { useHistory, useLocation } from 'react-router-dom';
import { WorkInProgress } from '../EmptyStates/WorkInProgress';
import { AppFooter } from '../AppFooter';
import LeverageFarmingBody from './LeverageFarmingBody';

export const LeverageFarmingPage = (props) => {
  const { notify } = props;
  const location = useLocation();
  const history = useHistory();

  return (
    <div className='app'>
      <AppHeader
        path={location.pathname}
        toast={notify}
        history={history}
      />
      {/* <WorkInProgress /> */}
      <LeverageFarmingBody
        toast={notify}
        history={history}
      />
      <AppFooter />
    </div>
  );
}