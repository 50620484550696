/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable default-case */
import classnames from 'classnames';
import React from 'react';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import leftArrow from '../../icons/left-arrow.svg';
import rightArrow from '../../icons/right-arrow.svg';
import downCaret from '../../icons/down-caret.svg';
import infoIcon from '../../icons/info.svg';
import { CSSTransitionGroup } from 'react-transition-group'
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { findIndex, get, isNil, last, map } from 'lodash';
import rayLogo from '../../coins/ray.png'
import { getFarmBySymbol } from '../../utils/farms';
import { getStore } from '../../stores/get-store';
import { TransactionService } from '../../services/TransactionService';
import { APP_ROUTES } from '../../constants/routes';
import { observer } from 'mobx-react';
import { getFormattedNumber, TokenAmount } from '../../utils/safe-math';
import CustomNumberInput from '../../base-components/CustomNumberInput';
import { Loader } from '../../base-components/Loader';
import { getLendingReserveByAccount } from '../../utils/config';
import { getReserveByName } from '../../utils/lendingReserves';
import { WAD } from '../../utils/layouts';
import IconWithTooltip from '../../base-components/IconWithTooltip';
import { getDefaultSelectedCoinIndex } from '../../utils/leverageFarmUtils';
import { MAX_POSITIONS_PER_FARM, MIN_SOL_BALANCE_AFTER_DEPOSIT, UTILIZATION_THRESHOLD } from '../../constants/leverageFarmingConstants';
import { NATIVE_SOL } from '../../utils/tokens';


// Constants
const CustomSlider = withStyles({
  root: {
    // color: '#52af77',
    height: 4,
    // margin: '8px 4px'
    marginLeft: 4
  },
  thumb: {
    height: 16,
    width: 16,
    // backgroundColor: '#fff',
    background: 'linear-gradient(315deg, #C61FB5 -6.25%, #2CABC7 100%)',
    // border: '2px solid currentColor',
    marginTop: -6,
    marginLeft: -4,
    '&:focus, &$active': {
      boxShadow: 'inherit',
    },
    '&:hover, &$active': {
      boxShadow: '0 0 0 4px rgba(255, 255, 255, 0.2)',
      borderRadius: '50%'
    },
    '&.Mui-disabled': {
      marginLeft: 0
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50%)',
  },
  track: {
    height: 4,
    borderRadius: 4,
    backgroundColor: '#1C88B4'
  },
  rail: {
    height: 4,
    borderRadius: 4,
    backgroundColor: '#146C98'
  },
  mark: {
    backgroundColor: '#FFF',
    height: 8,
    width: 8,
    borderRadius: '50%',
    marginTop: -2,
  },
  markActive: {
    opacity: 1,
    backgroundColor: '#FFF',
  }
})(Slider);

const marks = [
  {
    value: 1,
    // label: '1x',
  },
  {
    value: 2,
    // label: '1.5x',
  },
  {
    value: 3,
    // label: '2x',
  },
  // {
  //   value: 100,
  //   label: '100°C',
  // },
];

const META = 'meta',
  ACTIONS = 'actions',
  VIEWS = [META, ACTIONS],
  initialState = {
    activeView: VIEWS[0],
    coinAValue: 0,
    coinBValue: 0,
    isLoading: false
  },
  MAX_LEVERAGE_VALUE = 3,
  MIN_LEVERAGE_VALUE = 1,
  LEVERAGE_STEP = 0.1,
  transactionToast = (tx) => (
    <div style={{ fontSize: '12px'}}>
      <div style={{ marginBottom: '8px', fontWeight: '600' }}>Transaction has been sent</div>
      <div>Confirmation is in progress. Check your transaction on <a className='link' href={`https://explorer.solana.com/tx/${tx}`} target='_blank'>here</a>.</div>
    </div>
  ),
  goToYourPositionsPageToast = (cb) => (
    <div style={{ fontSize: '12px'}}>
      <div style={{ marginBottom: '8px', fontWeight: '600' }}>Your Position has been opened.</div>
      <div onClick={cb} style={{ color: '#5FBAD6' }}>Click here to check your positions.</div>
    </div>
  );


const NUMBER_OF_PERIODS_IN_A_WEEK = 24 * 7,
  NUMBER_OF_PERIODS_IN_A_YEAR = 24 * 365;

const getAPY = (periodicRate, numberOfPeriods) => {
  return (Math.pow((1 + (periodicRate/100)), numberOfPeriods) - 1);
}

const MIN_DEPOSIT = 0;
const MAX_DEPOSIT = 100000;
const SET_TOKEN_ACC_DELAY_ON_FAILURE = 2 * 1000;

class LeverageFarmingModal extends React.Component {
  constructor () {
    super();

    this.state = {
      ...initialState
    };

    this.handleViewChange = this.handleViewChange.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleLeverageChange = this.handleLeverageChange.bind(this);
    this.toggleLevSelectorDropdown = this.toggleLevSelectorDropdown.bind(this);
    this.handleCoinAChange = this.handleCoinAChange.bind(this);
    this.handleCoinBChange = this.handleCoinBChange.bind(this);
    this.handleCoinSelect = this.handleCoinSelect.bind(this);
    this.handleOpenPosition = this.handleOpenPosition.bind(this);
    this.handleCreateUserFarmManager = this.handleCreateUserFarmManager.bind(this);
    this.handleDepositObligationCollateral = this.handleDepositObligationCollateral.bind(this);
    this.handleBorrowObligationLiquidity = this.handleBorrowObligationLiquidity.bind(this);
    this.handleSwapLpToken = this.handleSwapLpToken.bind(this);
    this.handleCreateLpToken = this.handleCreateLpToken.bind(this);
    this.handleDepositIntoVault = this.handleDepositIntoVault.bind(this);
  }

  handleViewChange (view) {
    this.setState({ activeView: view });
  }

  handleCoinSelect (selectedCoinIndex) {
    const { borrowDisabledCoinIndex } = this.props.activeLeverageVault || {};

    if (selectedCoinIndex === borrowDisabledCoinIndex) {
      return;
    }

    const { setLeverageVault } = getStore('LeverageVaultStore'),
      { activeLeverageVault } = this.props;

    setLeverageVault(activeLeverageVault.mintAddress, { selectedCoinIndex });
  }

  handleToggle (e) {
    // Reset state
    this.setState(initialState);

    // @todo: Add logic to prevent toggle when modal is in dirty state
    this.props.onToggle();
  }

  handleCoinAChange (e) {
    let {
        activeLeverageVault
      } = this.props,
      farm = getFarmBySymbol(activeLeverageVault.assetSymbol),
      { wallet, tokenAccounts } = getStore('WalletStore'),
      mintAddress = get(farm, ['coins', 0, 'mintAddress']),
      decimals = get(farm, ['coins', 0, 'decimals']),
      coinABalance = wallet && tokenAccounts[mintAddress]?.balance,
      value = e.target.value;

    let coinAValue;

    coinABalance = Number(coinABalance?.fixed());

    // If token being deposited is SOL, then balance available to
    // withdraw is 0.05 SOL less than total amount
    if (mintAddress === NATIVE_SOL.mintAddress) {
      coinABalance = Number(new TokenAmount(coinABalance - MIN_SOL_BALANCE_AFTER_DEPOSIT, decimals, false).fixed());
    }

    if (value > coinABalance) {
      coinAValue = coinABalance;
    } else if (value < 0) {
      coinAValue = 0;
    } else {
      coinAValue = value;
    }

    this.setState({ coinAValue });
  }

  handleCoinBChange (e) {
    let {
        activeLeverageVault
      } = this.props,
      farm = getFarmBySymbol(activeLeverageVault.assetSymbol),
      { wallet, tokenAccounts } = getStore('WalletStore'),
      mintAddress = get(farm, ['coins', 1, 'mintAddress']),
      decimals = get(farm, ['coins', 1, 'decimals']),
      coinBBalance = wallet && tokenAccounts[mintAddress]?.balance,
      value = e.target.value;

    let coinBValue;

    coinBBalance = Number(coinBBalance?.fixed());

    // If token being deposited is SOL, then balance available to
    // withdraw is 0.05 SOL less than total amount
    if (mintAddress === NATIVE_SOL.mintAddress) {
      coinBBalance = Number(new TokenAmount(coinBBalance - MIN_SOL_BALANCE_AFTER_DEPOSIT, decimals, false).fixed());
    }

    if (value > coinBBalance) {
      coinBValue = coinBBalance;
    } else if (value < 0) {
      coinBValue = 0;
    } else {
      coinBValue = value;
    }

    this.setState({ coinBValue });
  }

  async handleOpenPosition () {
    const { activeLeverageVault } = this.props,
      { borrowDisabledCoinIndex } = activeLeverageVault || {},
      { coinAValue, coinBValue } = this.state,
      { getLeverageVault } = getStore('LeverageVaultStore'),
      { selectedCoinIndex = getDefaultSelectedCoinIndex(borrowDisabledCoinIndex), leverageValue = 3 } = getLeverageVault(activeLeverageVault.mintAddress) || {},
      farm = getFarmBySymbol(activeLeverageVault?.assetSymbol),
      reserveName = farm?.coins[selectedCoinIndex]?.symbol;

    this.setState({ isLoading: true });

    try {
      const transactions = await TransactionService.openMarginPosition(
        activeLeverageVault.assetSymbol,
        reserveName,
        coinAValue,
        coinBValue,
        leverageValue
      );

      const tx = await last(transactions);
      this.props.toast(transactionToast(tx));

      const signatureStatus = await window.$web3.getSignatureStatus(tx, {searchTransactionHistory: true});

      // const signatureStatus = await window.$web3.getSignatureStatus('3HxQYtRLexDAu2mxn71CA9G6FT5Do4gvxE7xej4xAya6QbHP1zfVQvaYK3WGi2pv5XoWEsSL86oNaESs6w4sB4cW', {searchTransactionHistory: true});

      // console.log('signatureStatus', signatureStatus);

      if (signatureStatus.value?.err) {
        throw new Error('Failed to open position. Please retry.');
      }

      this.setState({ isLoading: false });

      this.props.toast(goToYourPositionsPageToast(() => this.props.history.push(APP_ROUTES.YOUR_POSITIONS.route)));

      // Close modal on success
      this.handleToggle();

      // Refresh obligations
      setTimeout(getStore('WalletStore').setTokenAccounts, 9000);
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      // Refresh the token accounts in case of failure
      getStore('UIStore').setIsRefreshing(true);

      setTimeout(async () => {
        await getStore('WalletStore').setTokenAccounts();
        getStore('UIStore').resetRefreshState();
      }, SET_TOKEN_ACC_DELAY_ON_FAILURE);


      this.props.toast(errorToast, 'error');
      this.setState({ isLoading: false });
      err && console.error('handleOpenPosition~err:', err);
    }
  }

  async handleCreateUserFarmManager () {
    const { activeLeverageVault } = this.props;

    try {
      const tx = await TransactionService.createUserFarmManager(activeLeverageVault.assetSymbol);

      this.props.toast(transactionToast(tx));
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleCreateUserFarmManager~err:', err);
    }
  }

  async handleDepositObligationCollateral () {
    const { activeLeverageVault } = this.props,
      { coinAValue, coinBValue } = this.state;

    try {
      const tx = await TransactionService.depositObligationCollateral(activeLeverageVault.assetSymbol, coinAValue, coinBValue);

      this.props.toast(transactionToast(tx));
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleDepositObligationCollateral~err:', err);
    }
  }

  async handleBorrowObligationLiquidity () {
    const { activeLeverageVault } = this.props,
      { coinAValue, coinBValue } = this.state,
      { getLeverageVault } = getStore('LeverageVaultStore'),
      { selectedCoinIndex, leverageValue } = getLeverageVault(activeLeverageVault.mintAddress) || {},
      farm = getFarmBySymbol(activeLeverageVault?.assetSymbol),
      reserveName = farm?.coins[selectedCoinIndex]?.symbol;

    try {
      const tx = await TransactionService.borrowObligationLiquidity(
        activeLeverageVault.assetSymbol,
        reserveName,
        coinAValue,
        coinBValue,
        leverageValue
      );

      this.props.toast(transactionToast(tx));
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleBorrowObligationLiquidity~err:', err);
    }
  }

  async handleSwapLpToken () {
    const { activeLeverageVault } = this.props,
      { getLeverageVault } = getStore('LeverageVaultStore'),
      { selectedCoinIndex } = getLeverageVault(activeLeverageVault.mintAddress) || {},
      farm = getFarmBySymbol(activeLeverageVault?.assetSymbol),
      reserveName = farm?.coins[selectedCoinIndex]?.symbol;

    try {
      const tx = await TransactionService.swapTokens(activeLeverageVault.assetSymbol, reserveName);

      this.props.toast(transactionToast(tx));
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleSwapLpToken~err:', err);
    }
  }

  async handleCreateLpToken () {
    const { activeLeverageVault } = this.props,
      { getLeverageVault } = getStore('LeverageVaultStore'),
      { selectedCoinIndex } = getLeverageVault(activeLeverageVault.mintAddress) || {},
      farm = getFarmBySymbol(activeLeverageVault?.assetSymbol),
      reserveName = farm?.coins[selectedCoinIndex]?.symbol;

    try {
      const tx = await TransactionService.addLiquidity(activeLeverageVault.assetSymbol, reserveName);

      this.props.toast(transactionToast(tx));
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleCreateLpToken~err:', err);
    }
  }

  async handleDepositIntoVault () {
    const { activeLeverageVault } = this.props,
      { getLeverageVault } = getStore('LeverageVaultStore'),
      { selectedCoinIndex } = getLeverageVault(activeLeverageVault.mintAddress) || {},
      farm = getFarmBySymbol(activeLeverageVault?.assetSymbol),
      reserveName = farm?.coins[selectedCoinIndex]?.symbol;

    try {
      const tx = await TransactionService.depositMarginLpTokens(activeLeverageVault.assetSymbol, reserveName);

      this.props.toast(transactionToast(tx));
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleDepositIntoVault~err:', err);
    }
  }

  getHeader () {
    const { activeView } = this.state;

    switch (activeView) {
      case META: {
        return null;
      }

      case ACTIONS: {
        // return (
        //   <ModalHeader>
        //     Actions
        //   </ModalHeader>
        // );

        return null;
      }
    }
  }

  handleLeverageChange (e, value) {
    let leverageValue = value || e?.target?.value,
      { setLeverageValue } = getStore('LeverageVaultStore'),
      { activeLeverageVault } = this.props;

    setLeverageValue(activeLeverageVault.mintAddress, leverageValue);
  }


  toggleLevSelectorDropdown () {
    this.setState((prevState) => ({
      isLevSelectorDropdownOpen: !prevState.isLevSelectorDropdownOpen
    }));
  }

  getBody () {
    const {
        activeView,
        isLevSelectorDropdownOpen,
        coinAValue,
        coinBValue
      } = this.state,
      {
        activeLeverageVault
      } = this.props,
      {
        yearlyAPYInNumber,
        coins,
        tulipAPR,
        coinBorrowingInterest,
        pcBorrowingInterest,
        tradingFees,
        dailyAPRInNumber,
        borrowDisabledCoinIndex,
        slippageWarning,
        maxPositionLimitInUsd
      } = activeLeverageVault,
      { getLeverageVault } = getStore('LeverageVaultStore'),
      {
        selectedCoinIndex = getDefaultSelectedCoinIndex(borrowDisabledCoinIndex),
        leverageValue = 3
      } = getLeverageVault(activeLeverageVault.mintAddress) || {},
      farm = getFarmBySymbol(activeLeverageVault.assetSymbol),
      coin = farm?.coin,
      pc = farm?.pc,
      { wallet, tokenAccounts } = getStore('WalletStore'),
      coinABalance = wallet && tokenAccounts[get(farm, ['coins', 0, 'mintAddress'])]?.balance,
      coinBBalance = wallet && tokenAccounts[get(farm, ['coins', 1, 'mintAddress'])]?.balance,
      { getTokenPrice } = getStore('PriceStore'),
      coinPrice = getTokenPrice(coin?.symbol),
      pcPrice = getTokenPrice(pc?.symbol);

    const farmDetails = getStore('FarmStore').getFarm(farm?.mintAddress);
    const { userFarmInfo, isUserFarmValid, farmPendingObligationAccounts, price: farmPrice } = farmDetails || {};
    const { obligations } = userFarmInfo || {};

    let hasIncompleteTransaction = true;

    let obligationIdx = findIndex(obligations, (obligation) => {
      return obligation.positionState.hasOwnProperty("opening")
          || obligation.positionState.hasOwnProperty("borrowed")
          || obligation.positionState.hasOwnProperty("swapped")
          || obligation.positionState.hasOwnProperty("addedLiquidity")
          || obligation.positionState.hasOwnProperty("withdrawn")
          || obligation.positionState.hasOwnProperty("exitingAndLiquidated");
    });

    let obligationPositionState = {"opening": {}};
    if (obligationIdx !== -1) {
      obligationPositionState = obligations[obligationIdx].positionState;
    }

    let obligationProgress = 0;
    if (obligationPositionState.hasOwnProperty("opening") || obligationPositionState.hasOwnProperty("withdrawn")
        || obligationPositionState.hasOwnProperty("exitingAndLiquidated")) {
      obligationProgress = 1;
    } else if (obligationPositionState.hasOwnProperty("borrowed")) {
      obligationProgress = 2;
    } else if (obligationPositionState.hasOwnProperty("swapped")) {
      obligationProgress = 3;
    } else if (obligationPositionState.hasOwnProperty("addedLiquidity")) {
      obligationProgress = 4;
    }

    if (!isUserFarmValid || obligationProgress === 1 || (obligationIdx === -1) || (obligations[obligationIdx].obligationAccount.toBase58() === "11111111111111111111111111111111")) {
      hasIncompleteTransaction = false;
    }

    // console.log({
    //   coinABalance,
    //   coinBBalance,
    //   tokenAccounts,
    //   farm
    // });

    const adjustedCoinBorrowingInterest = coinBorrowingInterest * (leverageValue - 1),
      adjustedPcBorrowingInterest = pcBorrowingInterest * (leverageValue - 1);

    const initialAPR = (
        (dailyAPRInNumber * 365) + tradingFees + tulipAPR
      ),
      initialDailyAPR = initialAPR / 365;

    const totalAPR = (
            (((dailyAPRInNumber * 365) + tradingFees + tulipAPR) * leverageValue) - (selectedCoinIndex === 0 ? adjustedCoinBorrowingInterest : adjustedPcBorrowingInterest)
        ),
        dailyAPR = totalAPR / 365;

    const initialTotalAPY = (
      (100 * getAPY(dailyAPRInNumber/(24), NUMBER_OF_PERIODS_IN_A_YEAR)) + tradingFees + tulipAPR
    );


    const adjustedLeverageAPY = (100 * getAPY((dailyAPRInNumber * leverageValue)/(24), NUMBER_OF_PERIODS_IN_A_YEAR));

    const adjustedTotalAPY = (adjustedLeverageAPY) + (leverageValue * (tradingFees + tulipAPR)) - (selectedCoinIndex === 0 ? adjustedCoinBorrowingInterest : adjustedPcBorrowingInterest);

    const selectedCoinPrice = selectedCoinIndex === 0 ? coinPrice : pcPrice;

    const borrowAmount = (((Number(coinAValue) * Number(coinPrice)) + (Number(coinBValue) * Number(pcPrice))) * (Number(leverageValue) - 1)) / Number(selectedCoinPrice);
    const borrowAmountInUSD = borrowAmount * selectedCoinPrice;

    let coinAmount,
      pcAmount,
      leverageValueToShow,
      selectedCoin = 0;

    if (hasIncompleteTransaction && farmPendingObligationAccounts && farmPendingObligationAccounts.length > 0) {
      const {
        lpTokens,
        coinDeposits,
        pcDeposits,
        vaultShares,
        borrows
      } = farmPendingObligationAccounts[0];

      let debtValue = 0;

      borrows.forEach((borrow) => {
        const reserveInfo = getLendingReserveByAccount(borrow.borrowReserve.toBase58());
        const reservePrice = Number(getStore('PriceStore').getTokenPrice(reserveInfo.name));
        const reserve = getReserveByName(reserveInfo.name);
        const borrowedAmount = (borrow.borrowedAmountWads.div(WAD)).toNumber() / Math.pow(10, reserve.decimals);

        if (borrowedAmount) {
          if (reserve.name === farm.coins[0].symbol) {
            selectedCoin = 0;
          } else {
            selectedCoin = 1;
          }
        }

        debtValue += borrowedAmount * reservePrice;
      });

      const lpTokensDecimals = Math.pow(10, farm.decimals);
      const vaultShareTokens = 0;
      const lpTokensValue = (lpTokens).toNumber() / lpTokensDecimals;
      const coinDepositsValue = (coinDeposits).toNumber() / Math.pow(10, coin.decimals);
      const pcDepositsValue = (pcDeposits).toNumber() / Math.pow(10, pc.decimals);
      const positionValue = (coinDepositsValue*coinPrice) + (pcDepositsValue*pcPrice) + ((vaultShareTokens + lpTokensValue) *Number(farmPrice));
      const equityValue = Math.abs(positionValue - debtValue);

      leverageValueToShow = positionValue/equityValue;
      coinAmount = (obligations[obligationIdx].coinAmount.toNumber() / Math.pow(10, coin.decimals)).toString();
      pcAmount = (obligations[obligationIdx].pcAmount.toNumber() / Math.pow(10, pc.decimals)).toString();
    } else {
      coinAmount = coinAValue;
      pcAmount = coinBValue;
      leverageValueToShow = leverageValue;
      selectedCoin = selectedCoinIndex;
    }

    // Disable Farm for insufficient funds
    const { getReserve } = getStore('ReserveStore'),
      {
        borrowedAmount: totalCoinBorrow = 0,
        totalSupply: totalCoinSupply,
      } = getReserve(coin?.mintAddress) || {},
      {
        borrowedAmount: totalPcBorrow = 0,
        totalSupply: totalPcSupply,
      } = getReserve(pc?.mintAddress) || {},
      newUtilizationRate = (
        (selectedCoinIndex === 0) ?
          ((Number(borrowAmount) + Number(totalCoinBorrow)) * 100) / totalCoinSupply:
          ((Number(borrowAmount) + Number(totalPcBorrow)) * 100) / totalPcSupply
      ),
      isDisabledDueToInsufficientFunds = (selectedCoinIndex === 0) && (newUtilizationRate > UTILIZATION_THRESHOLD),
      totalDepositInUsd = ((Number(coinAValue) * Number(coinPrice)) + (Number(coinBValue) * Number(pcPrice)));

    const isBelowMinimumAmount = (totalDepositInUsd < MIN_DEPOSIT);
    const isAboveMaximumAmount = (totalDepositInUsd > MAX_DEPOSIT);
    const isAboveMaxPositionLimit = ((totalDepositInUsd + borrowAmountInUSD) > maxPositionLimitInUsd);
    // console.log("$$ total deposit, borrow amount", totalDepositInUsd, borrowAmountInUSD)
    const hasMaxPositionsForCurrentFarm = (
      getStore('ObligationStore').getOpenedObligationsForFarm(farm?.mintAddress).length >= MAX_POSITIONS_PER_FARM
    );

    switch (activeView) {
      case META: {

        return (
          <ModalBody className='leverage-modal-meta'>
            <div className='leverage-modal__heading'>
              Farm {activeLeverageVault.assetName}
            </div>

            {
              (hasIncompleteTransaction) &&
              <div
                className='leverage-modal-meta__info_tip'
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  width: '100%',
                  borderRadius: '8px',
                  padding: '8px',
                  boxSizing: 'border-box',
                  margin: '8px 0 16px',
                  textAlign: 'center'
                }}
              >
                Your previous transaction did not complete successfully. Please click Retry to continue with the same.
              </div>
            }

            {
              (hasMaxPositionsForCurrentFarm) &&
              <div
                className='leverage-modal-meta__info_tip'
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.03)',
                  width: '100%',
                  borderRadius: '8px',
                  padding: '8px',
                  boxSizing: 'border-box',
                  margin: '8px 0 16px',
                  textAlign: 'center',
                  color: '#EA232F',
                }}
              >
                You are at the maximum (3) amount of positions for this farm.
              </div>
            }

            {/* Balances */}
            <div className='leverage-modal-meta__balances'>
              <div className='leverage-modal-meta__balances-item'>
                <div className='leverage-modal-meta__balances-item__available'>
                  Balance: {coinABalance?.fixed()} ({get(farm, ['coins', 0, 'symbol'], null)})
                </div>
                {/* <Input
                  type='number'
                  // placeholder='Enter deposit amount...'
                  value={coinAValue}
                  onChange={this.handleCoinAChange}
                  // disabled={!wallet || disabled}
                /> */}
                <CustomNumberInput
                  value={coinAmount}
                  onChange={this.handleCoinAChange}
                  icon={farm?.logos[0]}
                  withArrows
                  rightButtonLabel='MAX'
                  onRightButtonClick={this.handleCoinAChange.bind(this, { target: { value: 10000000 }})}
                  disabled={hasIncompleteTransaction || hasMaxPositionsForCurrentFarm}
                />
                <div className='leverage-modal-meta__balances-item__price'>
                  1 {get(farm, ['coins', 0, 'symbol'], null)} ≈ ${getFormattedNumber(coinPrice)}
                </div>
              </div>
              <div className='leverage-modal-meta__balances-item'>
                <div className='leverage-modal-meta__balances-item__available'>
                  Balance: {coinBBalance?.fixed()} ({get(farm, ['coins', 1, 'symbol'], null)})
                </div>
                {/* <Input
                  type='number'
                  // placeholder='Enter deposit amount...'
                  value={coinBValue}
                  onChange={this.handleCoinBChange}
                  // disabled={!wallet || disabled}
                /> */}
                <CustomNumberInput
                  value={pcAmount}
                  onChange={this.handleCoinBChange}
                  icon={farm?.logos[1]}
                  withArrows
                  rightButtonLabel='MAX'
                  onRightButtonClick={this.handleCoinBChange.bind(this, { target: { value: 10000000 }})}
                  disabled={hasIncompleteTransaction || hasMaxPositionsForCurrentFarm}
                />
                <div className='leverage-modal-meta__balances-item__price'>
                  1 {get(farm, ['coins', 1, 'symbol'], null)} ≈ ${getFormattedNumber(pcPrice)}
                </div>
              </div>
            </div>

            {/* Leverage */}
            <div className='leverage-modal-meta__lev'>
              <div className='leverage-modal-meta__lev-box'>
                <div className='leverage-modal-meta__lev-box__input'>
                  <div className='leverage-modal-meta__lev-box__input-label'>
                    Leverage
                  </div>
                  {/* <Input
                    type='number'
                    // placeholder='Enter deposit amount...'
                    value={leverageValue}
                    onChange={this.handleLeverageChange}
                    step={LEVERAGE_STEP}
                    // disabled={!wallet || disabled}
                  /> */}
                  <CustomNumberInput
                    value={leverageValue}
                    onChange={this.handleLeverageChange}
                    step={LEVERAGE_STEP}
                    withArrows
                    disabled={hasIncompleteTransaction || hasMaxPositionsForCurrentFarm}
                  />
                  <div className='slider-container'>
                    <CustomSlider
                      valueLabelDisplay='auto'
                      aria-label='pretto slider'
                      defaultValue={leverageValue}
                      min={MIN_LEVERAGE_VALUE}
                      max={MAX_LEVERAGE_VALUE}
                      step={LEVERAGE_STEP}
                      onChange={this.handleLeverageChange}
                      // disabled={!wallet || disabled}
                      value={leverageValue}
                      marks={marks}
                      disabled={hasIncompleteTransaction || hasMaxPositionsForCurrentFarm}
                    />
                    <div className='slider-container__label'>
                      {/* {leverageValue || initialLeverageValue}x */}
                      <div>1x</div>
                      <div>2x</div>
                      <div>3x</div>
                    </div>
                  </div>
                </div>
                <div className='leverage-modal-meta__lev-box__selector'>
                  <div className='leverage-modal-meta__lev-box__selector-label'>
                    <span>Borrow Asset</span>
                    {/* <IconWithTooltip
                      icon={infoIcon}
                      className='leverage-farming-table__header-cell__info-btn'
                      tooltipText='Please keep in mind that when you leverage above 2x, you will have a slight short on the borrowed asset. The other paired asset will have typical long exposure, so choose which asset you borrow wisely.'
                      placement='bottom'
                    /> */}
                  </div>
                  <Dropdown
                    isOpen={isLevSelectorDropdownOpen}
                    toggle={this.toggleLevSelectorDropdown}
                    // direction={this.state.isEndpointDropdownOpen ? 'up' : 'down'}
                    // disabled={hasIncompleteTransaction}
                    disabled={!isNil(borrowDisabledCoinIndex) || hasMaxPositionsForCurrentFarm}
                  >
                    <DropdownToggle
                      // caret
                      // outline
                      // color='primary'
                      className='lev-selector'
                    >
                      <span style={{
                        maxWidth: '160px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'pre',
                        display: 'inline-flex',
                      }}>
                        <img
                          src={farm?.logos[selectedCoin]}
                          style={{
                            height: 20,
                            width: 20,
                            margin: '3px 0'
                          }}
                        />
                        <span
                          style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'pre',
                          margin: '2px 8px 2px 8px'
                        }}>
                          {farm?.coins[selectedCoin].symbol}
                        </span>
                        {
                          !(!isNil(borrowDisabledCoinIndex) || hasMaxPositionsForCurrentFarm) &&
                            <img
                              src={downCaret}
                              style={{
                                transform: isLevSelectorDropdownOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
                                transition: 'transform 0.3s'
                              }}
                            />
                        }
                      </span>
                    </DropdownToggle>
                    <DropdownMenu right>
                      {
                        map(farm?.coins, (coin, index) => (
                          <DropdownItem
                            onClick={this.handleCoinSelect.bind(this, index)}
                            key={index}
                            style={{ padding: '0.5rem 3rem 0.5rem 2rem' }}
                          >
                            <img
                              src={farm.logos[index]}
                              style={{
                                width: 20,
                                height: 20
                              }}
                            />
                            {coin.symbol}
                          </DropdownItem>
                        ))
                      }
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>

            {/* Leverage Warning */}
            {
              !hasMaxPositionsForCurrentFarm &&
              <div
                className='leverage-modal-meta__info_tip'
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.03)',
                  width: '100%',
                  borderRadius: '8px',
                  padding: '8px',
                  boxSizing: 'border-box',
                  margin: '8px 0 16px',
                  // textAlign: 'center',
                  color: '#EA232F',
                  fontSize: '0.8rem'
                }}
              >
                Please keep in mind that when you leverage above 2x, you will have a slight short on the borrowed asset. The other paired asset will have typical long exposure, so choose which asset you borrow wisely.
              </div>
            }

            {
              (!hasIncompleteTransaction && isBelowMinimumAmount) && !hasMaxPositionsForCurrentFarm &&
                <div
                  className='leverage-modal-meta__info_tip'
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    width: '100%',
                    borderRadius: '8px',
                    padding: '8px',
                    boxSizing: 'border-box',
                    margin: '8px 0 16px',
                    textAlign: 'center'
                  }}
                >
                  Total deposit amount should be at least ${getFormattedNumber(MIN_DEPOSIT, 0, 0)}
                </div>
            }
             {
              (!hasIncompleteTransaction && slippageWarning) &&
              <div
                className='leverage-modal-meta__info_tip'
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  width: '100%',
                  borderRadius: '8px',
                  padding: '8px',
                  boxSizing: 'border-box',
                  margin: '8px 0 16px',
                  textAlign: 'center'
                }}
              >
               This farm can have slippage.
              </div>
            }

            {
              isDisabledDueToInsufficientFunds && !hasMaxPositionsForCurrentFarm &&
              <div
                className='leverage-modal-meta__info_tip'
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  width: '100%',
                  borderRadius: '8px',
                  padding: '8px',
                  boxSizing: 'border-box',
                  margin: '8px 0 16px',
                  textAlign: 'center'
                }}
              >
                Your borrow amount exceeds our maximum utilization limit ({UTILIZATION_THRESHOLD}%). Kindly try a lower borrow amount.
              </div>
            }
            {
              (!hasIncompleteTransaction && isAboveMaximumAmount) && !hasMaxPositionsForCurrentFarm &&
              <div
                className='leverage-modal-meta__info_tip'
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  width: '100%',
                  borderRadius: '8px',
                  padding: '8px',
                  boxSizing: 'border-box',
                  margin: '8px 0 16px',
                  textAlign: 'center'
                }}
              >
                Total deposit amount should not exceed ${getFormattedNumber(MAX_DEPOSIT, 0, 0)}
              </div>
            }
            {
              (!hasIncompleteTransaction && isAboveMaxPositionLimit) && !hasMaxPositionsForCurrentFarm &&
              <div
                className='leverage-modal-meta__info_tip'
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  width: '100%',
                  borderRadius: '8px',
                  padding: '8px',
                  boxSizing: 'border-box',
                  margin: '8px 0 16px',
                  textAlign: 'center'
                }}
              >
                Total position amount should not exceed ${getFormattedNumber(maxPositionLimitInUsd, 0, 0)}
              </div>
            }

            {/* Yield */}
            {
              !hasIncompleteTransaction && !hasMaxPositionsForCurrentFarm &&
              (
                <div className='leverage-modal-meta__lev__yield'>
                  <div className='yield-line'>
                    <div className='yield-line-name'>
                      Yield Farming:
                    </div>
                    <div className='yield-line-value'>
                      {getFormattedNumber(dailyAPRInNumber * 365)}%
                      <img src={rightArrow} />
                      {getFormattedNumber(dailyAPRInNumber * 365 * leverageValue)}%
                    </div>
                  </div>
                  <div className='yield-line'>
                    <div className='yield-line-name'>
                      Trading Fees:
                    </div>
                    <div className='yield-line-value'>
                      {getFormattedNumber(tradingFees)}%
                      <img src={rightArrow} />
                      {getFormattedNumber(tradingFees * leverageValue)}%
                    </div>
                  </div>
                  <div className='yield-line'>
                    <div className='yield-line-name'>
                      Tulip Reward:
                    </div>
                    <div className='yield-line-value'>
                      {getFormattedNumber(tulipAPR)}%
                      <img src={rightArrow} />
                      {getFormattedNumber(tulipAPR * leverageValue)}%
                    </div>
                  </div>
                  <div className='yield-line'>
                    <div className='yield-line-name'>
                      Borrowing Interest:
                    </div>
                    <div className='yield-line-value'>
                      {/* {selectedCoinIndex === 0 ? getFormattedNumber(coinBorrowingInterest) : getFormattedNumber(pcBorrowingInterest)}%
                      <img src={rightArrow} /> */}
                      {leverageValue > 1 ? '-' : null}{selectedCoin === 0 ? getFormattedNumber(adjustedCoinBorrowingInterest) : getFormattedNumber(adjustedPcBorrowingInterest)}%
                    </div>
                  </div>
                  <div className='yield-line'>
                    <div className='yield-line-name'>
                      Total APR:
                    </div>
                    <div className='yield-line-value'>
                      {getFormattedNumber(initialAPR)}%
                      <img src={rightArrow} />
                      {getFormattedNumber(totalAPR)}%
                    </div>
                  </div>
                  {/* <div className='yield-line'>
                    <div className='yield-line-name'>
                      Daily APR:
                    </div>
                    <div className='yield-line-value'>
                      {getFormattedNumber(initialDailyAPR)}%
                      <img src={rightArrow} />
                      {getFormattedNumber(dailyAPR * leverageValue)}%
                    </div>
                  </div> */}
                  <div className='yield-line'>
                    <div className='yield-line-name'>
                      Total APY:
                    </div>
                    <div className='yield-line-value'>
                      {getFormattedNumber(initialTotalAPY)}%
                      <img src={rightArrow} />
                      {getFormattedNumber(adjustedTotalAPY)}%
                    </div>
                  </div>
                </div>
              )
            }

            {/* Summary */}
            {
              (!hasIncompleteTransaction) && !hasMaxPositionsForCurrentFarm &&
              (
                <div className='leverage-modal-meta__lev__summary'>
                  <div className='leverage-modal-meta__lev__summary-heading'>
                    Summary
                  </div>

                  <div className='summary-line'>
                    <div className='summary-line-name'>
                      Assets supplied:
                    </div>
                    <div className='summary-line-value'>
                      {coinAValue} {coin?.symbol} + {coinBValue} {pc?.symbol}
                    </div>
                  </div>

                  <div className='summary-line'>
                    <div className='summary-line-name'>
                      Assets borrowed:
                    </div>
                    <div className='summary-line-value'>
                      {getFormattedNumber(borrowAmount, farm?.coins[selectedCoin].decimals, farm?.coins[selectedCoin].decimals)} {farm?.coins ? farm?.coins[selectedCoin].symbol : null}
                    </div>
                  </div>

                  {/* <div className='summary-line'>
                    <div className='summary-line-name'>
                      Update debt ratio:
                    </div>
                    <div className='summary-line-value'>
                      61.83%
                      <img src={rightArrow} />
                      192.59%
                    </div>
                  </div>
                  <div className='summary-line'>
                    <div className='summary-line-name'>
                      Update kill buffer:
                    </div>
                    <div className='summary-line-value'>
                      17.97%
                      <img src={rightArrow} />
                      25.59%
                    </div>
                  </div>
                  <div className='summary-line'>
                    <div className='summary-line-name'>
                      Update debt ratio:
                    </div>
                    <div className='summary-line-value'>
                      28.62%
                      <img src={rightArrow} />
                      54.59%
                    </div>
                  </div>
                  <div className='summary-line'>
                    <div className='summary-line-name'>
                      Price impact and trading fees:
                    </div>
                    <div className='summary-line-value'>
                      14.36%
                      <img src={rightArrow} />
                      115.59%
                    </div>
                  </div>
                  <div className='summary-line'>
                    <div className='summary-line-name'>
                      Assets to be added to position:
                    </div>
                    <div className='summary-line-value'>
                      40.00 RAY
                      <img src={rightArrow} />
                      2.00 SOL
                    </div>
                  </div> */}
                </div>
              )
            }
          </ModalBody>
        );
      }

      case ACTIONS: {

        return (
          <ModalBody className='leverage-modal-actions'>
            <div className='leverage-modal__heading'>
              Farm {activeLeverageVault.assetName}
            </div>
            <div
              onClick={this.handleViewChange.bind(this, VIEWS[0])}
              className='back-btn'
            >
              <img
                src={leftArrow}
                style={{
                  marginRight: '4px'
                }}
              />
              Back
            </div>
            <div className='leverage-modal-actions__container'>
              <div className='leverage-modal-actions__container__item'>
                <div className='leverage-modal-actions__container__item-label'>
                  1 of 6
                </div>
                <div className='leverage-modal-actions__container__item-box'>
                  <div className='leverage-modal-actions__container__item-box__desc'>
                    Create user farm manager
                  </div>
                  <Button
                    color='primary'
                    onClick={this.handleCreateUserFarmManager}
                  >
                    Approve
                  </Button>
                </div>
              </div>
              <div className='leverage-modal-actions__container__item'>
                <div className='leverage-modal-actions__container__item-label'>
                  2 of 6
                </div>
                <div className='leverage-modal-actions__container__item-box'>
                  <div className='leverage-modal-actions__container__item-box__desc'>
                    Deposit obligation collateral
                  </div>
                  <Button
                    color='primary'
                    onClick={this.handleDepositObligationCollateral}
                  >
                    Approve
                  </Button>
                </div>
              </div>
              <div className='leverage-modal-actions__container__item'>
                <div className='leverage-modal-actions__container__item-label'>
                  3 of 6
                </div>
                <div className='leverage-modal-actions__container__item-box'>
                  <div className='leverage-modal-actions__container__item-box__desc'>
                    Borrow obligation liquity
                  </div>
                  <Button
                    color='primary'
                    onClick={this.handleBorrowObligationLiquidity}
                  >
                    Approve
                  </Button>
                </div>
              </div>
              <div className='leverage-modal-actions__container__item'>
                <div className='leverage-modal-actions__container__item-label'>
                  4 of 6
                </div>
                <div className='leverage-modal-actions__container__item-box'>
                  <div className='leverage-modal-actions__container__item-box__desc'>
                    Swap LP token
                  </div>
                  <Button
                    color='primary'
                    onClick={this.handleSwapLpToken}
                  >
                    Approve
                  </Button>
                </div>
              </div>
              <div className='leverage-modal-actions__container__item'>
                <div className='leverage-modal-actions__container__item-label'>
                  5 of 6
                </div>
                <div className='leverage-modal-actions__container__item-box'>
                  <div className='leverage-modal-actions__container__item-box__desc'>
                    Create LP token
                  </div>
                  <Button
                    color='primary'
                    onClick={this.handleCreateLpToken}
                  >
                    Approve
                  </Button>
                </div>
              </div>
              <div className='leverage-modal-actions__container__item'>
                <div className='leverage-modal-actions__container__item-label'>
                  6 of 6
                </div>
                <div className='leverage-modal-actions__container__item-box'>
                  <div className='leverage-modal-actions__container__item-box__desc'>
                    Deposit into vault
                  </div>
                  <Button
                    color='primary'
                    onClick={this.handleDepositIntoVault}
                  >
                    Approve
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        );
      }
    }
  }

  getFooter () {
    const {
      activeView,
      isLoading,
      coinAValue,
      coinBValue
    } = this.state;
    const { activeLeverageVault } = this.props;
    const { maxPositionLimitInUsd } = activeLeverageVault || {};
    const farm = getFarmBySymbol(activeLeverageVault.assetSymbol);
    const farmDetails = getStore('FarmStore').getFarm(farm?.mintAddress);
    const { userFarmInfo, isUserFarmValid } = farmDetails || {};
    const { obligations } = userFarmInfo || {};
    const hasMaxPositionsForCurrentFarm = (
      getStore('ObligationStore').getOpenedObligationsForFarm(farm?.mintAddress).length >= MAX_POSITIONS_PER_FARM
    );
    const { isRefreshing, isSilentRefresh } = getStore('UIStore');

    let hasIncompleteTransaction = true;

    let obligationIdx = findIndex(obligations, (obligation) => {
      return obligation.positionState.hasOwnProperty("opening")
          || obligation.positionState.hasOwnProperty("borrowed")
          || obligation.positionState.hasOwnProperty("swapped")
          || obligation.positionState.hasOwnProperty("addedLiquidity")
          || obligation.positionState.hasOwnProperty("withdrawn")
          || obligation.positionState.hasOwnProperty("exitingAndLiquidated");
    });

    let obligationPositionState = {"opening": {}};
    if (obligationIdx !== -1) {
      obligationPositionState = obligations[obligationIdx].positionState;
    }

    let obligationProgress = 0;
    if (obligationPositionState.hasOwnProperty("opening") || obligationPositionState.hasOwnProperty("withdrawn") ||
      obligationPositionState.hasOwnProperty("exitingAndLiquidated")) {
      obligationProgress = 1;
    } else if (obligationPositionState.hasOwnProperty("borrowed")) {
      obligationProgress = 2;
    } else if (obligationPositionState.hasOwnProperty("swapped")) {
      obligationProgress = 3;
    } else if (obligationPositionState.hasOwnProperty("addedLiquidity")) {
      obligationProgress = 4;
    }

    if (!isUserFarmValid || obligationProgress === 1 || (obligationIdx === -1) || (obligations[obligationIdx].obligationAccount.toBase58() === "11111111111111111111111111111111")) {
      hasIncompleteTransaction = false;
    }

    // Disable Farm for insufficient funds
    const { borrowDisabledCoinIndex } = activeLeverageVault || {};
    const { getLeverageVault } = getStore('LeverageVaultStore'),
      { selectedCoinIndex = getDefaultSelectedCoinIndex(borrowDisabledCoinIndex), leverageValue = 3 } = getLeverageVault(activeLeverageVault.mintAddress) || {};

    const coin = farm?.coin,
      pc = farm?.pc,
      { getTokenPrice } = getStore('PriceStore'),
      coinPrice = getTokenPrice(coin?.symbol),
      pcPrice = getTokenPrice(pc?.symbol);

    const selectedCoinPrice = selectedCoinIndex === 0 ? coinPrice : pcPrice;
    const borrowAmount = (((Number(coinAValue) * Number(coinPrice)) + (Number(coinBValue) * Number(pcPrice))) * (Number(leverageValue) - 1)) / Number(selectedCoinPrice);
    const borrowAmountInUSD = borrowAmount * selectedCoinPrice;

    const { getReserve } = getStore('ReserveStore'),
      {
        borrowedAmount: totalCoinBorrow = 0,
        totalSupply: totalCoinSupply,
      } = getReserve(coin?.mintAddress) || {},
      {
        borrowedAmount: totalPcBorrow = 0,
        totalSupply: totalPcSupply,
      } = getReserve(pc?.mintAddress) || {},
      newUtilizationRate = (
        (selectedCoinIndex === 0) ?
          ((Number(borrowAmount) + Number(totalCoinBorrow)) * 100) / totalCoinSupply:
          ((Number(borrowAmount) + Number(totalPcBorrow)) * 100) / totalPcSupply
      ),
      isDisabledDueToInsufficientFunds = (selectedCoinIndex === 0) && (newUtilizationRate > UTILIZATION_THRESHOLD),
      totalDepositInUsd = ((Number(coinAValue) * Number(coinPrice)) + (Number(coinBValue) * Number(pcPrice)));

    const isBelowMinimumAmount = (totalDepositInUsd < MIN_DEPOSIT);
    const isAboveMaximumAmount = (totalDepositInUsd > MAX_DEPOSIT);
    const isAboveMaxPositionLimit = ((totalDepositInUsd + borrowAmountInUSD) > maxPositionLimitInUsd);

    switch (activeView) {
      case META: {
        return (
          <ModalFooter>
              <Button color='secondary' onClick={this.handleToggle}>Cancel</Button>
              <Button
                disabled={
                  isRefreshing ||
                  isSilentRefresh ||
                  isDisabledDueToInsufficientFunds ||
                  hasMaxPositionsForCurrentFarm ||
                  (!hasIncompleteTransaction &&
                    (isBelowMinimumAmount || isAboveMaximumAmount || isAboveMaxPositionLimit)
                  )
                }
                color='primary'
                onClick={this.handleOpenPosition}
              >
                {
                  isLoading ? <Loader /> :
                  (hasIncompleteTransaction) ? 'Retry' : 'Farm'
                }
              </Button>
          </ModalFooter>
        );
      }

      case ACTIONS: {
        return (
          <ModalFooter>
            <Button color='primary' onClick={this.handleToggle}>Finish</Button>
          </ModalFooter>
        )
      }
    }
  }

  render () {
    const {
      isOpen
    } = this.props;


    return (
      <Modal
        isOpen={isOpen}
        toggle={this.handleToggle}
        className={
          classnames(
            'app-body__modal',
            'leverage-modal'
          )
        }
        centered
      >
        {this.getHeader()}
        {this.getBody()}
        {this.getFooter()}
      </Modal>
    );
  }
}

export default observer(LeverageFarmingModal);
