import { observable, makeObservable, action, computed } from 'mobx';
import { FILTER_CONFIG, ALL_TOKENS } from '../utils/vaultFilterUtils';
import { transform, forEach, filter } from 'lodash';

export default class FilterStore {
  constructor () {
    this.activePlatforms = [FILTER_CONFIG.all];
    this.activeTokens = ALL_TOKENS;
    this.selectedTokens = ['ALL'];
    this.selectedPlatforms = ['all'];

    makeObservable(this, {
      activePlatforms: observable,
      selectedPlatforms: observable,
      activeTokens: observable,
      selectedTokens: observable,
      activeFarms: computed,
      isFilterActive: computed,
      setToken: action.bound,
      setPlatform: action.bound
    });
  }

  get activeFarms () {
    const activePlatforms = transform(this.activePlatforms, (acc, platform) => {
      forEach(platform.farms, (farm) => {
        forEach(farm.farms, (farm) => {
          acc[farm] = true;
        })
      });
    }, {});

    // Honor the active tokens
    const farms = transform(this.activeTokens, (acc, token) => {
      forEach(token.farms, (farm) => {
        if (activePlatforms[farm]) {
          acc[farm] = true;
        }
      })

      return acc;
    }, {});

    return farms;
  }

  get isFilterActive () {
    return !(this.selectedTokens.includes('ALL') && this.selectedPlatforms.includes('all'));
  }

  get platforms () {
    return this.activePlatforms;
  }

  setPlatform (platform) {
    // If all is selected, then reset the entire state
    if (platform === FILTER_CONFIG.all.platform) {
      this.activePlatforms = [FILTER_CONFIG.all];
      this.selectedPlatforms = [FILTER_CONFIG.all.platform];

      return;
    }

    // Remove 'ALL' from the selected platforms in case some other platform has been selected
    this.selectedPlatforms = filter(this.selectedPlatforms, (t) => t !== FILTER_CONFIG.all.platform);

    if (this.selectedPlatforms.includes(platform)) {
      this.selectedPlatforms = filter(this.selectedPlatforms, (p) => p !== platform);
    }
    else {
      this.selectedPlatforms.push(platform);
    }

    // If no platform is being selected, then default it to 'ALL'
    if (!this.selectedPlatforms.length) {
      this.setPlatform(FILTER_CONFIG.all.platform);

      return;
    }

    this.activePlatforms = [];

    this.selectedPlatforms.forEach((platform) => {
      if (platform === FILTER_CONFIG.all.platform) {
        return;
      }

      this.activePlatforms.push(FILTER_CONFIG[platform]);
    });
  }

  setToken (token) {
    if (token === 'ALL') {
      // Add support for active platform here
      this.activeTokens = ALL_TOKENS;
      this.selectedTokens = ['ALL'];

      return;
    }

    // Remove 'ALL' from the selected tokens in case some other token has been selected
    this.selectedTokens = filter(this.selectedTokens, (t) => t !== 'ALL');

    if (this.selectedTokens.includes(token)) {
      this.selectedTokens = filter(this.selectedTokens, (t) => t !== token);
    }
    else {
      this.selectedTokens.push(token);
    }

    // If no platform is being selected, then default it to 'ALL'
    if (!this.selectedTokens.length) {
      this.setToken('ALL');

      return;
    }

    // Clear out the active Tokens
    this.activeTokens = [];

    // Add all the tokens that should exist now based on selected
    this.selectedTokens.forEach((token) => {
      if (token === 'ALL') {
        return;
      }

      this.activeTokens.push(ALL_TOKENS[token]);
    })
  }
}