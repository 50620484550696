import React from 'react';
import classnames from 'classnames';

// Components
import { ToastContainer, toast, Slide } from 'react-toastify';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { LandingPage } from './LandingPage/LandingPage';
import { LendPage } from './LendPage/LendPage';
import { AboutUs } from './AboutUs/AboutUs';
import { VaultsPage } from './VaultsPage/VaultsPage';
import { LeverageFarmingPage } from './LeverageFarmingPage/LeverageFarmingPage';
import ModalContainer from './Modal/ModalContainer';

// Styles
import 'react-toastify/dist/ReactToastify.css';

// Constants
import { TOAST_TYPES } from '../constants/toastConstants';
import { YourPositionsPage } from './YourPositionsPage/YourPositionsPage';
import { getStore } from '../stores/get-store';

function App() {
  const notify = (text, type) => {
    const options = {
        className: classnames({
          'app-toast__toast': type !== TOAST_TYPES.ERROR,
          'app-toast__toast-error': type === TOAST_TYPES.ERROR
        })
      };

    toast(text, options);
  };

  return (
    <Router>
      <Switch>
        <Route path='/vaults'>
          <VaultsPage
            notify={notify}
          />
        </Route>
        <Route path='/lend'>
          <LendPage
            notify={notify}
          />
        </Route>
        <Route path='/leverage'>
          <LeverageFarmingPage
            notify={notify}
          />
        </Route>
        <Route path='/your-positions'>
          <YourPositionsPage
            notify={notify}
          />
        </Route>
        {/* <Route path='/all-positions'>
          <YourPositionsPage
            notify={notify}
          />
        </Route> */}
        <Route path='/about'>
          <AboutUs />
        </Route>
        <Route path='/'>
          <LandingPage />
        </Route>
      </Switch>
      <ToastContainer
        position='bottom-left'
        hideProgressBar
        className='app-toast'
        toastClassName='app-toast__toast'
        bodyClassName='app-toast__body'
        progressClassName='app-toast__progress'
        limit={5}
        autoClose={7000}
        transition={Slide}
      />
      <ModalContainer
        toast={notify}
      />
    </Router>
  );
}

export default App;

