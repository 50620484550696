import React from 'react';
import classnames from 'classnames';

export default function MenuIcon ({ onClick, isOpen}) {
  return (
    <div className='menu-icon-container'>
      <ul
        onClick={onClick}
        className={classnames('menu-icon', { 'active': isOpen })}
      >
        <li className='brick-1'></li>
        <li className='brick-2'></li>
        <li className='brick-3'></li>
        <li className='brick-4'></li>
      </ul>
    </div>
  );
}
