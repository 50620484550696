import React from 'react';
import { observer } from 'mobx-react';
import { getStore } from '../../stores/get-store';

// Material UI
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { purple, blue } from '@material-ui/core/colors';
import { Checkbox } from '@material-ui/core';

// Lodash
import { isNil, map, some } from 'lodash';

// Components
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Loader } from '../../base-components/Loader';
import Badge from '../../base-components/Badge';
import Logo from '../../base-components/Logo';

// Icons
import tulipIcon from '../../icons/SolFarmLogo.png';

// Utils
import { FARMS } from '../../utils/farms';
import { TOKENS } from '../../utils/tokens';
import { ALL_PLATFORMS, FARM_TOKENS, ORCA_TOKENS, RAY_TOKENS, SABER_TOKENS } from '../../utils/vaultFilterUtils';
import classNames from 'classnames';
import SelectDropdown from '../../base-components/SelectDropdown';


const CustomCheckbox = withStyles({
  root: {
    color: blue[400],
    '&$checked': {
      color: blue[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


const CustomSwitch = withStyles({
  switchBase: {
    // color: purple[300],
    // color: '#5FBAD6',
    color: '#C1EBF4',
    '&$checked': {
      // color: purple[500],
      // color: '#1C88B4',
      // color: 'linear-gradient(315deg, #C61FB5 -6.25%, #2CABC7 100%)',
      color: '#C61FB5',
      // color: '#2CABC7',
    },
    '&$checked + $track': {
      // backgroundColor: purple[500],
      // backgroundColor: '#1C88B4',
      // backgroundColor: 'linear-gradient(315deg, #C61FB5 -6.25%, #2CABC7 100%)',
      backgroundColor: '#C61FB5',
      // backgroundColor: '#2CABC7',
    },
  },
  checked: {},
  track: {},
})(Switch);

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 38,
    height: 22,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        // backgroundColor: '#52d869',
        backgroundColor: '#5FBAD6',
        // backgroundColor: '#1C88B4',
        opacity: 1,
        border: `1px solid ${theme.palette.grey[400]}`,
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 26 / 2,
    border: 'none',
    // backgroundColor: theme.palette.grey[50],
    backgroundColor: '#146C98',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const transactionToast = (tx) => (
  <div style={{ fontSize: '12px'}}>
    <div style={{ marginBottom: '8px', fontWeight: '600' }}>Transaction has been sent</div>
    <div>Confirmation is in progress. Check your transaction on <a className='link' href={`https://explorer.solana.com/tx/${tx}`} target='_blank'>here</a>.</div>
  </div>
);

const FILTER_PLATFORMS = map(ALL_PLATFORMS, ({ name, logo}) => {
  return {
    label: name,
    value: name,
    logo
  };
});


class VaultsFilters extends React.Component {
  constructor () {
    super();

    this.state = {
      isModalOpen: false,
      selectedFarms: new Map(),
      isHarvesting: false
    }

    this.handleShowStakedToggle = this.handleShowStakedToggle.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleHarvest = this.handleHarvest.bind(this);
    this.handleCheckboxToggle = this.handleCheckboxToggle.bind(this);
    this.handleTulipTokenAccountFix = this.handleTulipTokenAccountFix.bind(this);
  }

  toggleModal (e, value) {
    isNil(value) && (value = !this.state.isModalOpen);

    this.setState({ isModalOpen: value, selectedFarms: new Map() });
  }

  handleShowStakedToggle () {
    const preferenceStore = getStore('UserPreferenceStore'),
      showStaked = preferenceStore.get('showStaked');

    preferenceStore.set('showStaked', !showStaked);
  }

  async handleHarvest () {
    this.setState({ isHarvesting: true });

    try {
      const tx = await this.props.onBulkTulipHarvest(Array.from(this.state.selectedFarms.keys()));

      this.props.toast(transactionToast(tx));
  
      this.setState({ isHarvesting: false, selectedFarms: new Map() });
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleHarvest~err:', err);

      this.setState({ isHarvesting: false });
    }
  }

  async handleTulipTokenAccountFix () {
    try {
      const tx = await this.props.onFixTulipRewardTokenAccount();

      this.props.toast(transactionToast(tx));
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleTulipTokenAccountFix~err:', err);
    }
  }

  handleCheckboxToggle (assetSymbol, shouldSelect) {
    // If maximum number of selections reached, bail-out
    if (shouldSelect && (this.state.selectedFarms.size > 2)) {
      return;
    }

    return this.setState((prevState) => {
      if (shouldSelect) {
        prevState.selectedFarms.set(assetSymbol, true);
      } else {
        prevState.selectedFarms.delete(assetSymbol);
      }

      return prevState;
    });
  }

  handlePlatform (platform) {
    getStore('FilterStore').setPlatform(platform);
  }

  handleToken (token) {
    getStore('FilterStore').setToken(token);
  }

  getFilterDropdown ({ title, placeholder, items, selectedItems, onClick }) {
    return (
      <SelectDropdown
        title={title}
        placeholder={placeholder}
        isSelected={(value) => some(selectedItems, (i) => i?.toUpperCase() === value?.toUpperCase())}
        items={items}
        itemRender={({ label, logo }) => {
          return (
            <div>
              {logo && <img src={logo} height='16px' width='16px' alt='farm-logo'/> }
              {label?.toUpperCase()}
            </div>
          );
        }}
        onClick={onClick}
      />
    )
  }

  getActiveTokensList () {
    const { selectedPlatforms } = getStore('FilterStore');

    if (selectedPlatforms.includes('all') || (selectedPlatforms.includes('ray') && selectedPlatforms.includes('saber'))) {
      return FARM_TOKENS;
    }
    else if (selectedPlatforms.includes('ray')) {
      return RAY_TOKENS;
    }
    else if (selectedPlatforms.includes('saber')) {
      return SABER_TOKENS;
    }
    else {
      return ORCA_TOKENS;
    }
  }

  getActiveTokensListForDropdown () {
    return map(this.getActiveTokensList(), ({ name, logo }) => {
      return {
        label: name,
        value: name,
        logo
      };
    });
  }

  render () {
    const userPreferenceStore = getStore('UserPreferenceStore'),
      showStaked = userPreferenceStore.get('showStaked'),
      selectedTokens = getStore('FilterStore').selectedTokens,
      selectedPlatforms  = getStore('FilterStore').selectedPlatforms,
      {
        wallet,
        totalTulipPending,
        tokenAccounts,
        hasTulipRewardPending,
        tulipRewardTokenAccountInfo
      } = getStore('WalletStore'),
      { isRefreshing } = getStore('UIStore'),
      { isDesktop } = getStore('ResponsiveStore'),
      { selectedFarms, isHarvesting } = this.state,
      modalBody = [];

    if (!tulipRewardTokenAccountInfo) {
      modalBody.push(
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px'
          }}
        >
          TULIP token account does not exist
        </div>
      );
    } else {
      modalBody.push(
        <div
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            width: '100%',
            borderRadius: '8px',
            padding: '8px',
            boxSizing: 'border-box',
            margin: '8px 0',
            textAlign: 'center'
          }}
        >
          Note: Due to current limitations of the contract, you can only harvest TULIP for up to 3 vaults at once.
        </div>
      );

      FARMS.forEach((farm) => {
        if (!hasTulipRewardPending(farm.symbol)) {
          return;
        }

        const tulipEarned = tokenAccounts[farm.mintAddress]?.tulipEarned;

        modalBody.push(
          <div
            onClick={this.handleCheckboxToggle.bind(this, farm.symbol, !selectedFarms.has(farm.symbol))}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              padding: '8px 12px 8px 0',
              flex: '1',
              width: '100%',
            }}
            className='harvest-list-item'
          >
            <CustomCheckbox
              checked={selectedFarms.has(farm.symbol)}
              color='primary'
              // inputProps={{ 'aria-label': 'secondary checkbox' }}
              // onChange={this.handleCheckboxToggle.bind(this, farm.symbol)}
              disabled={!selectedFarms.has(farm.symbol) && (selectedFarms.size > 2)}
            />
            {
              map(farm.logos, (logo) => (
                <img
                  src={logo}
                  style={{
                    height: '24px'
                  }}
                />
              ))
            }
            <div
              style={{
                flex: '5.5',
                marginLeft: '8px',
                fontWeight: '600'
              }}
            >
              {farm.symbol}
            </div>
            <div
              style={{
                flex: '2',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >

              <div
                style={{
                  flex: '1'
                }}
              >
              <img
                src={tulipIcon}
                style={{
                  height: '18px',
                  width: '12px',
                  // marginRight: '8px'
                }}
              />
              </div>
              <div
                style={{
                  flex: '5',
                  textAlign: 'right'
                }}
              >
                {tulipEarned?.toFixed(6) || '0.000000'}
              </div>
            </div>
          </div>
        );
      });
    }

    return (
      <div className='filters_container'>
        <div className='filters'>
          <div className='filters__claim-all'>
            {/* Total TULIP Pending: {totalTulipPending.toFixed(TOKENS.TULIP.decimals)} */}
              {totalTulipPending>0 &&
              <Button
                  color='primary'
                  onClick={this.toggleModal}
                  disabled={!wallet || !totalTulipPending}
                  className='tulip-harvest-btn'
              >
                  <img
                      src={tulipIcon}
                      style={{
                          height: '18px',
                          width: '12px',
                          marginRight: '8px'
                      }}
                  />
                  Harvest ({totalTulipPending.toFixed(TOKENS.TULIP.decimals)})
              </Button>
              }
            <Modal
              isOpen={this.state.isModalOpen}
              toggle={this.toggleModal}
              className='app-body__modal'
              centered
            >
              <ModalHeader toggle={this.toggleModal}>Harvest TULIP</ModalHeader>
              <ModalBody
                style={{
                  padding: '2rem 1rem',
                  maxHeight: '70vh',
                  overflow: 'auto'
                }}
              >
                {
                  isRefreshing ?
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '3rem',
                      }}
                    >
                      <Loader />
                    </div>
                    :
                    modalBody
                }
              </ModalBody>
              <ModalFooter>
                <Button className='modal__cancel-btn' color='secondary' onClick={this.toggleModal}>Cancel</Button>
                <Button
                  className='modal__success-btn'
                  color='primary'
                  onClick={!tulipRewardTokenAccountInfo ? this.handleTulipTokenAccountFix : this.handleHarvest}
                  disabled={!tulipRewardTokenAccountInfo ? false : !selectedFarms.size}
                >
                  {
                    !tulipRewardTokenAccountInfo ?
                    'Fix'
                    :
                    (
                      isHarvesting ?
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '0.5rem 1.5rem',
                        }}
                      >
                        <Loader />
                      </div>
                      :
                      'Harvest'
                    )
                  }
                </Button>{' '}
              </ModalFooter>
            </Modal>
          </div>
        </div>
        <div className={classNames('category_filters', { 'desktop': isDesktop })}>
          {
            !isDesktop &&
              <div className='filters__toggle'>
                <IOSSwitch
                  checked={wallet ? showStaked : false}
                  onChange={this.handleShowStakedToggle}
                  color="primary"
                  name="checkedB"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  disabled={!wallet}
                />
                <span>Show Staked</span>
              </div>
          }
          {
            !isDesktop &&
              <div className='category_filters__dropdown'>
                {
                  this.getFilterDropdown({
                    title: 'Platforms',
                    placeholder: 'Search',
                    items: FILTER_PLATFORMS,
                    selectedItems: selectedPlatforms,
                    onClick: this.handlePlatform
                  })
                }
                {
                  this.getFilterDropdown({
                    title: 'Tokens',
                    placeholder: 'Search',
                    items: this.getActiveTokensListForDropdown(),
                    selectedItems: selectedTokens,
                    onClick: this.handleToken
                  })
                }
              </div>
          }
          {
            isDesktop &&
              <>
                <div className='category_filters__platforms'>
                  <div className='category_filters__title staked-filter'>
                    Filter by Platforms:
                    <div className='filters__toggle'>
                      <IOSSwitch
                        checked={wallet ? showStaked : false}
                        onChange={this.handleShowStakedToggle}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        disabled={!wallet}
                      />
                      <span>Show Staked</span>
                    </div>
                  </div>
                  {
                    map(ALL_PLATFORMS, ({ name, logo }) => {
                      const isSelected = some(selectedPlatforms, (p) => p?.toUpperCase() === name?.toUpperCase());

                      return (
                        <Badge
                          className={classNames('category_filters--badge', { 'active': isSelected })}
                          key={name}
                        >
                          <div onClick={this.handlePlatform.bind(this, name)}>
                            {logo && <img src={logo} height='16px' width='16px' alt='platform'/> }
                            {name?.toUpperCase()}
                          </div>
                        </Badge>
                      );
                    })
                  }
                </div>
                <div className='category_filters__tokens'>
                  <div className='category_filters__title'>Filter by Tokens:</div>
                  {
                    map(this.getActiveTokensList(), ({ name, logo }) => {
                      const isSelected = some(selectedTokens, (t) => t?.toUpperCase() === name?.toUpperCase());

                      return (
                        <Badge
                          className={classNames('category_filters--badge', { 'active': isSelected })}
                          key={name}
                        >
                          <div onClick={this.handleToken.bind(this, name)}>
                            <Logo logo={logo} alt='token'/>
                            {name?.toUpperCase()}
                          </div>
                        </Badge>
                      );
                    })
                  }
                </div>
              </>
          }
        </div>
      </div>
    )
  }
}

export default observer(VaultsFilters);
