import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './view/App';
import { bootConnection } from './services/ConnectionService';
import { getStore } from './stores/get-store';

// Establish web3 connection before rendering
bootConnection();

// Fetch prices
getStore('PriceStore');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
