import React from "react";
import classnames from "classnames";
import { isNil } from "lodash";
import { Tooltip } from "reactstrap";
import ReactstrapButton from '../reactstrap-components/ReactstrapButton';

export default class Button extends React.Component {
  constructor() {
    super();

    this.state = {
      isTooltipVisible: false,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.getButtonRef = this.getButtonRef.bind(this);
  }

  toggleTooltip(value) {
    const isTooltipVisible = isNil(value) ? !this.state.isTooltipVisible : value;

    this.setState({ isTooltipVisible });
  }

  getButtonRef() {
    return this.buttonRef;
  }

  render() {
    const {
      // Tooltip props
      disabledTooltip,
      tooltipPlacement = "bottom",
      
      // Button props
      className,
      children,

      // Reactstrap Button props
      ...buttonProps
    } = this.props;

    return (
      <>
        <div
          ref={(ref) => this.buttonRef = ref}
          onMouseOver={this.toggleTooltip.bind(this, true)}
          onMouseLeave={this.toggleTooltip.bind(this, false)}
        >
          <ReactstrapButton
            className={classnames("base-button", className)}
            {...buttonProps}
          >
            {children}
          </ReactstrapButton>
        </div>
        
        {
          buttonProps.disabled && disabledTooltip &&
          <Tooltip
            target={this.getButtonRef}
            isOpen={this.state.isTooltipVisible}
            hideArrow
            placement={tooltipPlacement}
            innerClassName="base-tooltip"
          >
            {disabledTooltip}
          </Tooltip>
        }
      </>
    );
  }
}
