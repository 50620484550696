import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { CSSTransitionGroup } from 'react-transition-group';

// Icons
import refreshIcon from '../../icons/refresh.svg';
import infoIcon from '../../icons/info.svg';
import tulipIcon from '../../icons/SolFarmLogo.png';
import errorIcon from '../../icons/error.svg';
import upDownCaretIcon from '../../icons/up-down-caret.svg';
import upArrow from '../../icons/up-arrow.svg';
import downArrow from '../../icons/down-arrow.svg';
import downCaret from '../../icons/down-caret.svg';
import rayLogo from '../../coins/ray.png';
import saberLogo from '../../coins/saber.svg';
import linkIcon from '../../icons/link.svg';
import rewardIcon from '../../icons/reward.svg';

// Store
import { getStore } from '../../stores/get-store';

// Components
import IconWithTooltip from '../../base-components/IconWithTooltip';
import { Loader } from '../../base-components/Loader';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Tooltip } from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

// Lodash
import { isNil, debounce, isFunction, isEmpty, orderBy, get, capitalize, isNumber, map } from 'lodash';

// Utils
import { getFormattedNumber, TokenAmount } from '../../utils/safe-math';
import { NATIVE_SOL, TOKENS } from '../../utils/tokens';
import Badge from '../../base-components/Badge';
import CustomNumberInput from '../../base-components/CustomNumberInput';
import LowSolWarningAlert from '../../components/LowSolWarningAlert/LowSolWarningAlert';
import { VAULTS_MIN } from '../../constants/minSolBalance';
import { DEPOSIT_TYPES, FARM_PLATFORMS } from '../../constants/farmConstants';
import { MIN_SOL_BALANCE_AFTER_DEPOSIT } from '../../constants/lendConstants';
import { getFarmBySymbol } from '../../utils/farms';
import SelectDropdown from '../../base-components/SelectDropdown';
import { Switch } from '@material-ui/core';

// Constants
const CustomSlider = withStyles({
  root: {
    // color: '#52af77',
    height: 4,
    // margin: '8px 4px'
    marginLeft: 4
  },
  thumb: {
    height: 16,
    width: 16,
    // backgroundColor: '#fff',
    background: 'linear-gradient(315deg, #C61FB5 -6.25%, #2CABC7 100%)',
    // border: '2px solid currentColor',
    marginTop: -6,
    marginLeft: -8,
    '&:focus, &$active': {
      boxShadow: 'inherit',
    },
    '&:hover, &$active': {
      boxShadow: '0 0 0 4px rgba(255, 255, 255, 0.2)',
      borderRadius: '50%'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50%)',
  },
  track: {
    height: 4,
    borderRadius: 4,
    backgroundColor: '#1C88B4'
  },
  rail: {
    height: 4,
    borderRadius: 4,
    backgroundColor: '#146C98'
  },
})(Slider);

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 38,
    height: 22,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        // backgroundColor: '#52d869',
        backgroundColor: '#5FBAD6',
        // backgroundColor: '#1C88B4',
        opacity: 1,
        border: `1px solid ${theme.palette.grey[400]}`,
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 26 / 2,
    border: 'none',
    // backgroundColor: theme.palette.grey[50],
    backgroundColor: '#146C98',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const ZERO = 0;

const tableColumns = ['Wallet Balance', 'Deposited', 'Daily APR', 'Weekly APY', 'Yearly APY'],
  sortableColumns = ['Asset', 'Wallet Balance', 'Deposited', 'Yearly APY'],
  mobileSortableColumns = [
    {
      label: 'Yearly APY',
      value: 'Yearly APY'
    },
    {
      label: 'Wallet Balance',
      value: 'Wallet Balance'
    },
    {
      label: 'Deposited',
      value: 'Deposited'
    }
  ],
  disabledColumnsWithoutConnection = ['Wallet Balance', 'Deposited'],
  SORT_ORDER = ['desc', 'asc', null],
  headerToPropertyMap = {
    'Asset': 'tvlInNumber',
    'Wallet Balance': 'balanceInUsd',
    'Deposited': 'depositedInUsd',
    'Daily APR': 'dailyAPR',
    'Weekly APY': 'weeklyAPY',
    'Yearly APY': 'yearlyAPYInNumber'
  },
  getFeeText = (controllerFee, platformFee) => {
    return (
      <div className='vaults-table__fee-text'>
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <div>
            Controller Fee:
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 4 }}>
            {getFormattedNumber(controllerFee)} %*
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <div>Platform Fee:</div>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 4 }}>
            {getFormattedNumber(platformFee)} %*
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <div>Deposit Fee:</div>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 4 }}>0.0 %</div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <div>Withdrawal Fee:</div>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 4 }}>0.0 %</div>
        </div>
        <div style={{ marginTop: '8px', fontWeight: 600 }}>
          *Fee is only applicable on rewards.
        </div>
      </div>
    );
  },
  transactionToast = (tx) => (
    <div style={{ fontSize: '12px'}}>
      <div style={{ marginBottom: '8px', fontWeight: '600' }}>Transaction has been sent</div>
      <div>Confirmation is in progress. Check your transaction on <a className='link' href={`https://explorer.solana.com/tx/${tx}`} target='_blank'>here</a>.</div>
    </div>
  ),
  getRefreshTooltip = (timeToRefresh) => `Auto refresh in ${timeToRefresh} seconds, you can click to update manually\nAutomatically refreshes when the current pool had changed`,
  getRewardTooltipContent = (amountInTokens, amountInUsd, time) => {
    return (
      <div>
        <div style={{ textAlign: 'center', fontSize: '0.8rem' }}>
          Rewards since last deposit/withdraw
        </div>
        <div style={{ marginBottom: '0.5rem', textAlign: 'center', fontSize: '0.8rem' }}>
          {
            time ? time.toLocaleDateString() : null
          }
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ fontWeight: '600' }}>
            {amountInTokens} LP
          </span>
          <span style={{ fontSize: '0.8rem', marginLeft: '0.3rem' }}>
            (${amountInUsd})
          </span>
        </div>
        <div style={{ marginTop: '0.5rem', textAlign: 'center', fontSize: '0.7rem', color: '#8ED3E5', fontWeight: 600 }}>
          <span>
            Note: This does not include profit from fees.
          </span>
        </div>
      </div>
    )
  };

class VaultsTableHeader extends React.Component {
  constructor () {
    super();

    this.handleRefresh = this.handleRefresh.bind(this);
    this.getTooltipText = this.getTooltipText.bind(this);
    this.handleHeaderClick = this.handleHeaderClick.bind(this);
  }

  componentDidMount () {
    this.intervalId = setInterval(() => {
      const { wallet } = getStore('WalletStore'),
        { timeToRefresh, setTimeToRefresh } = getStore('UIStore');

      if (!wallet) {
        return;
      }

      if (timeToRefresh - 1) {
        return setTimeToRefresh(timeToRefresh - 1);
      }

      this.handleRefresh(null, true);
    }, 1000);
  }

  componentWillUnmount () {
    this.intervalId && clearInterval(this.intervalId);
  }

  async handleRefresh (e, silent = false) {

    // If current tab is not visible, then don't refresh
    if (window?.document?.hidden) {
      return;
    }

    const { wallet } = getStore('WalletStore'),
      { setIsRefreshing, resetRefreshState } = getStore('UIStore');

    if (!wallet) {
      return;
    }

    setIsRefreshing(true);

    await Promise.all([
      getStore('WalletStore').setTokenAccounts(),
      getStore('FarmStore').setPrice(),
      getStore('ReserveStore').init(),
      getStore('PriceStore').init()
    ]);

    !silent && this.props.toast('Values refreshed.');
    resetRefreshState();
  }

  getTooltipText () {
    const { wallet } = getStore('WalletStore'),
      { timeToRefresh } = getStore('UIStore');

    if (!wallet) {
      return 'Wallet not connected';
    }

    // return `Refreshing in ${this.state.timeToRefresh}s`;
    return getRefreshTooltip(timeToRefresh);
  }

  handleHeaderClick (column) {
    const { wallet } = getStore('WalletStore');

    if (!wallet && disabledColumnsWithoutConnection.includes(column)) {
      return;
    }

    if (!sortableColumns.includes(column)) {
      return;
    }

    this.props.setSortBy(headerToPropertyMap[column]);
  }

  getFilterDropdown ({ title, placeholder, items, selectedItems, onClick }) {
    return (
      <SelectDropdown
        className='vaults-table__header-sort-by'
        disableSearch
        right
        title={title}
        placeholder={placeholder}
        isSelected={(value) => this.props.sortedBy === headerToPropertyMap[value]}
        items={items}
        itemRender={({ label, value }) => {
          return (
            <div>
              {<img src={
                this.props.sortedBy === headerToPropertyMap[value] ?
                (
                  this.props.sortOrder === 'asc' ? upArrow : downArrow
                ) :
                upDownCaretIcon
              } height='16px' width='16px' alt='farm-logo'/> }
              {label?.toUpperCase()}
            </div>
          );
        }}
        onClick={onClick}
      />
    )
  }

  render () {
    const { wallet } = getStore('WalletStore'),
      { isRefreshing, isSilentRefresh } = getStore('UIStore'),
      { sortedBy, sortOrder } = this.props,
      { isMobile } = getStore('ResponsiveStore');

    return (
      <div className='vaults-table__header'>
        <div
          className='vaults-table__header-asset'
          className={
            classnames(
              'vaults-table__header-asset',
              {
                'sortable': sortableColumns.includes('Asset')
              }
            )
          }
          onClick={this.handleHeaderClick.bind(this, 'Asset')}
        >
          Vault
          {
            <div
              className='vaults-table__header-cell__sort-btn'
            >
              <img src={
                sortedBy === headerToPropertyMap['Asset'] ?
                (
                  sortOrder === 'asc' ? upArrow : downArrow
                ) :
                upDownCaretIcon
              } />
            </div>
          }
        </div>
        {
          isMobile &&
            this.getFilterDropdown({
              title: 'Sort By',
              items: mobileSortableColumns,
              onClick: this.handleHeaderClick
            })
        }
        {
          !isMobile &&
          tableColumns.map((tableColumn) => (
            <div
              key={tableColumn}
              className={
                classnames(
                  'vaults-table__header-cell',
                  {
                    'sortable': sortableColumns.includes(tableColumn),
                    'disabled': !wallet && disabledColumnsWithoutConnection.includes(tableColumn)
                  }
                )
              }
              onClick={this.handleHeaderClick.bind(this, tableColumn)}
            >
              {tableColumn}
              {
                sortableColumns.includes(tableColumn) &&
                <div
                  className='vaults-table__header-cell__sort-btn'
                >
                  <img src={
                    sortedBy === headerToPropertyMap[tableColumn] ?
                    (
                      sortOrder === 'asc' ? upArrow : downArrow
                    ) :
                    upDownCaretIcon
                  } />
                </div>
              }
              {
                tableColumn === 'Yearly APY' &&
                <IconWithTooltip
                  icon={infoIcon}
                  className='vaults-table__header-cell__info-btn'
                  tooltipText='Yearly APY is indicative and should not be used as a performance measure.'
                />
              }
            </div>
          ))
        }
        <div
          onClick={this.handleRefresh}
          className={
            classnames(
              'vaults-table__header-refresh',
              {
                'disabled': !wallet
              }
            )
          }
        >
          <IconWithTooltip
            icon={refreshIcon}
            className={
              classnames(
                'vaults-table__header-refresh__btn',
                {
                  'is-refreshing': isRefreshing || isSilentRefresh
                }
              )
            }
            tooltipText={this.getTooltipText()}
          />
        </div>
      </div>
    );
  }
}

class VaultsTableRow extends React.Component {
  constructor () {
    super();

    this.state = {
      isExpanded: false,
      depositValue: '',
      withdrawValue: '',
      isDepositing: false,
      isWithdrawing: false,
      withdrawSliderValue: 0,
      depositSliderValue: 0,
      isLiquidityMiningInfoTooltipVisible: false,
      isAddLiquidityTooltipVisible: false,
      isErrorTooltipVisible: false,
      isHarvesting: false,
      depositType: DEPOSIT_TYPES.DUAL,
      isCoinSelectorOpen: false,
      selectedCoinIndex: 0
    };

    this.rowRef = React.createRef();

    this.toggleRow = this.toggleRow.bind(this);
    this.handleDeposit = this.handleDeposit.bind(this);
    this.handleDepositChange = this.handleDepositChange.bind(this);
    this.handleWithdraw = this.handleWithdraw.bind(this);
    this.handleWithdrawWithWarnings = this.handleWithdrawWithWarnings.bind(this);
    this.handleWithdrawChange = this.handleWithdrawChange.bind(this);
    this.handleDepositSliderChange = this.handleDepositSliderChange.bind(this);
    this.handleWithdrawSliderChange = this.handleWithdrawSliderChange.bind(this);
    this.handleFixTokenAccount = this.handleFixTokenAccount.bind(this);
    this.handleTulipHarvest = this.handleTulipHarvest.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handleDepositTypeChange = this.handleDepositTypeChange.bind(this);

    this.debouncedWithdrawSliderChange = debounce(this.handleWithdrawSliderChange, 300);
    this.debouncedDepositSliderChange = debounce(this.handleDepositSliderChange, 100);

    this.toggleLiquidityMiningInfoTooltip = this.toggleLiquidityMiningInfoTooltip.bind(this);
    this.toggleAddLiquidityTooltip = this.toggleAddLiquidityTooltip.bind(this);
    this.toggleErrorTooltip = this.toggleErrorTooltip.bind(this);
    this.toggleCoinSelectorOpen = this.toggleCoinSelectorOpen.bind(this);

    this.handleCoinAChange = this.handleCoinAChange.bind(this);
    this.handleCoinBChange = this.handleCoinBChange.bind(this);

    this.handleCoinASingleDepositChange = this.handleCoinASingleDepositChange.bind(this);
    this.handleCoinBSingleDepositChange = this.handleCoinBSingleDepositChange.bind(this);
  }

  toggleLiquidityMiningInfoTooltip (value) {
    const isLiquidityMiningInfoTooltipVisible = isNil(value) ? !this.state.isLiquidityMiningInfoTooltipVisible : value;

    this.setState({ isLiquidityMiningInfoTooltipVisible });
  }

  toggleAddLiquidityTooltip (value) {
    const isAddLiquidityTooltipVisible = isNil(value) ? !this.state.isAddLiquidityTooltipVisible : value;

    this.setState({ isAddLiquidityTooltipVisible });
  }

  toggleErrorTooltip (value) {
    const isErrorTooltipVisible = isNil(value) ? !this.state.isErrorTooltipVisible : value;

    this.setState({ isErrorTooltipVisible });
  }

  handleDepositTypeChange () {
    this.setState((prevState) => {
      return {
        depositType: prevState.depositType === DEPOSIT_TYPES.DUAL
          ? DEPOSIT_TYPES.SINGLE
          : DEPOSIT_TYPES.DUAL
      }
    });
  }

  handleCoinSelect (selectedCoinIndex) {
    this.setState({ selectedCoinIndex, coinAValue: '', coinBValue: '' });
  }

  toggleCoinSelectorOpen () {
    this.setState((prevState) => ({
      isCoinSelectorOpen: !prevState.isCoinSelectorOpen
    }));
  }

  handleWithdrawSliderChange (e, value) {
    let { deposited, decimals, depositedInUsd, platform } = this.props.data || {};

    deposited = Number(deposited);

    // if (platform === FARM_PLATFORMS.ORCA) {
    //   deposited = Number(depositedInUsd);
    //   decimals = 2;
    // }

    const withdrawValue = !value ? '' : Math.min(deposited, ((deposited * value)/100)).toFixed(decimals);

    this.setState({
      withdrawSliderValue: value,
      withdrawValue
    });
  }

  handleDepositSliderChange (e, value) {
    let { balance, decimals } = this.props.data || {};

    balance = Number(balance);

    const depositValue = !value ? '' : Math.min(balance, ((balance * value)/100)).toFixed(decimals);

    this.setState({
      depositSliderValue: value,
      depositValue
    });
  }

  toggleRow () {
    this.setState((prevState) => {
      return {
        isExpanded: !prevState.isExpanded
      };
    }, () => {
      if (getStore('ResponsiveStore').isMobile) {
        this.state.isExpanded && this.rowRef?.current?.scrollIntoView(true);
      }
    });
  }

  async handleDeposit () {
    this.setState({ isDepositing: true });

    try {
      const { platform } =  this.props.data;
      const { coinAValue, coinBValue, depositValue, depositType } = this.state;
      console.log("deposit state", this.state);
      const args = (platform === FARM_PLATFORMS.ORCA) ? [coinAValue, coinBValue, depositType] : [depositValue];

      const tx = await this.props.onDeposit(...args);

      this.props.toast(transactionToast(tx));
  
      this.setState({ isDepositing: false });

      if (platform === FARM_PLATFORMS.ORCA) {
        this.handleCoinAChange({ target: { value: '' } });
        this.handleCoinBChange({ target: { value: '' } });
      } else {
        this.handleDepositChange({ target: { value: '' } });
      }
    } catch (err) {

      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleDeposit~err:', err);

      this.setState({ isDepositing: false });
    }
  }

  async handleTulipHarvest () {
    this.setState({ isHarvesting: true });

    try {
      const tx = await this.props.onTulipHarvest();

      this.props.toast(transactionToast(tx));
  
      this.setState({ isHarvesting: false });
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleTulipHarvest~err:', err);

      this.setState({ isHarvesting: false });
    }
  }

  handleDepositChange (e) {
    let newValue = e?.target?.value,
      { balance } = this.props.data || {};

    balance = Number(balance);

    // Sanitize new value
    if (newValue > balance) {
      newValue = balance;
    } else if (newValue < 0) {
      newValue = 0;
    }

    const valueForSlider = (newValue === '') ? 0 : newValue;

    this.setState({
      depositValue: newValue,
      depositSliderValue: !balance ? 0 : Math.round((valueForSlider * 100)/balance)
    });
  }

  async handleWithdrawWithWarnings () {
    const isWithdrawUnsafe = await this.props.checkUnsafeWithdraw();

    if (isWithdrawUnsafe) {
      this.props.toggleModal(true);

      return this.props.setSuccessCallback(this.handleWithdraw);
    }

    return this.handleWithdraw();
  }

  async handleWithdraw () {
    this.setState({ isWithdrawing: true });

    // this.props.toast(`Withdraw Successful: ${tx}`);

    // Making transaction

    // Transaction has been sent
    // Confirmation is in progress. Check your transaction on here.

    // Transaction has been confirmed
    // Stake 0.000200 RAY-SOL LP

    const withdrawMax = (this.state.withdrawSliderValue === 100);

    try {
      const tx = await this.props.onWithdraw(this.state.withdrawValue, withdrawMax);

      this.props.toast(transactionToast(tx));
  
      this.setState({ isWithdrawing: false });
      this.handleWithdrawChange({ target: { value: '' } });
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleWithdraw~err:', err);

      this.setState({ isWithdrawing: false });
    }
  }

  handleWithdrawChange (e) {
    let newValue = e?.target?.value,
      { deposited, depositedInUsd, platform } = this.props.data || {};

    deposited = Number(deposited);

    // if (platform === FARM_PLATFORMS.ORCA) {
    //   deposited = Number(depositedInUsd);
    // }

    // Sanitize new value
    if (newValue > deposited) {
      newValue = deposited;
    } else if (newValue < 0) {
      newValue = 0;
    }
      
    const valueForSlider = (newValue === '') ? 0 : newValue;

    this.setState({
      withdrawValue: newValue,
      withdrawSliderValue: !deposited ? 0 : Math.round((valueForSlider * 100)/deposited)
    });
  }

  handleLinkClick (e) {
    // Prevent the propagation of the event so that the entire view of the vault
    // doesn't expand, since user's intent is to just open the hyperlink
    e && e.stopPropagation();
  }

  async handleFixTokenAccount (e) {
    // Stop bubbling of click event, to prevent list-item from expanding/collapsing
    e && e.stopPropagation();

    try {
      const tx = await this.props.onFixTokenAccount();

      this.props.toast(transactionToast(tx));
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleFixTokenAccount~err:', err);
    }
  }

  handleCoinASingleDepositChange (e) {
    let {
        assetSymbol
      } = this.props.data,
      farm = getFarmBySymbol(assetSymbol),
      { wallet, tokenAccounts } = getStore('WalletStore'),
      coinAMintAddress = get(farm, ['coins', 0, 'mintAddress']),
      coinADecimals = get(farm, ['coins', 0, 'decimals']),
      decimals = get(farm, ['coins', 0, 'decimals']),
      coinABalance = wallet && tokenAccounts[coinAMintAddress]?.balance,
      value = e.target.value;

    let coinAValue;

    coinABalance = Number(coinABalance?.fixed());

    // If token being deposited is SOL, then balance available to
    // withdraw is 0.05 SOL less than total amount
    if (coinAMintAddress === NATIVE_SOL.mintAddress) {
      coinABalance = new TokenAmount(coinABalance - MIN_SOL_BALANCE_AFTER_DEPOSIT, decimals, false).fixed();
    }

    if (value > coinABalance) {
      coinAValue = coinABalance;
    } else if (value < 0) {
      coinAValue = 0;
    } else {
      coinAValue = value;
    }

    if (isNumber(coinAValue)) {
      coinAValue = coinAValue?.toFixed(coinADecimals);
    }

    this.setState({ coinAValue });
  }


  handleCoinBSingleDepositChange (e) {
    let {
        assetSymbol
      } = this.props.data,
      farm = getFarmBySymbol(assetSymbol),
      { wallet, tokenAccounts } = getStore('WalletStore'),
      coinBMintAddress = get(farm, ['coins', 1, 'mintAddress']),
      coinBDecimals = get(farm, ['coins', 1, 'decimals']),
      decimals = get(farm, ['coins', 0, 'decimals']),
      coinBBalance = wallet && tokenAccounts[coinBMintAddress]?.balance,
      value = e.target.value;

    let coinBValue;

    coinBBalance = Number(coinBBalance?.fixed());

    // If token being deposited is SOL, then balance available to
    // withdraw is 0.05 SOL less than total amount
    if (coinBMintAddress === NATIVE_SOL.mintAddress) {
      coinBBalance = new TokenAmount(coinBBalance - MIN_SOL_BALANCE_AFTER_DEPOSIT, decimals, false).fixed();
    }

    if (value > coinBBalance) {
      coinBValue = coinBBalance;
    } else if (value < 0) {
      coinBValue = 0;
    } else {
      coinBValue = value;
    }

    if (isNumber(coinBValue)) {
      coinBValue = coinBValue?.toFixed(coinBDecimals);
    }

    this.setState({ coinBValue });
  }

  handleCoinAChange (e) {
    let {
        assetSymbol
      } = this.props.data,
      farm = getFarmBySymbol(assetSymbol),
      { wallet, tokenAccounts } = getStore('WalletStore'),
      coinAMintAddress = get(farm, ['coins', 0, 'mintAddress']),
      coinBMintAddress = get(farm, ['coins', 1, 'mintAddress']),
      coinADecimals = get(farm, ['coins', 0, 'decimals']),
      coinBDecimals = get(farm, ['coins', 1, 'decimals']),
      decimals = get(farm, ['coins', 0, 'decimals']),
      coinABalance = wallet && tokenAccounts[coinAMintAddress]?.balance,
      coinBBalance = wallet && tokenAccounts[coinBMintAddress]?.balance,
      value = e.target.value,
      farmDetails = getStore('FarmStore').getFarm(farm.mintAddress),
      { coinToPcRatio = 1 } = farmDetails || {};

    let coinAValue;
    let coinBValue;

    coinABalance = Number(coinABalance?.fixed());
    coinBBalance = Number(coinBBalance?.fixed());

    // If token being deposited is SOL, then balance available to
    // withdraw is 0.05 SOL less than total amount
    if (coinAMintAddress === NATIVE_SOL.mintAddress) {
      coinABalance = new TokenAmount(coinABalance - MIN_SOL_BALANCE_AFTER_DEPOSIT, decimals, false).fixed();
    }

    // If token being deposited is SOL, then balance available to
    // withdraw is 0.05 SOL less than total amount
    if (coinBMintAddress === NATIVE_SOL.mintAddress) {
      coinBBalance = new TokenAmount(coinBBalance - MIN_SOL_BALANCE_AFTER_DEPOSIT, decimals, false).fixed();
    }

    if (value > coinABalance) {
      coinAValue = coinABalance;
    } else if (value < 0) {
      coinAValue = 0;
    } else {
      coinAValue = value;
    }

    if ((coinAValue / coinToPcRatio) > coinBBalance) {
      coinBValue = coinBBalance;
      coinAValue = coinBBalance * coinToPcRatio;
    } else {
      coinBValue = coinAValue / coinToPcRatio;
    }

    if (isNumber(coinAValue)) {
      coinAValue = coinAValue?.toFixed(coinADecimals);
    }

    if (isNumber(coinBValue)) {
      coinBValue = coinBValue?.toFixed(coinBDecimals);
    }

    this.setState({ coinAValue, coinBValue });
  }

  handleCoinBChange (e) {
    console.log({ coinB: e });
    let {
        assetSymbol
      } = this.props.data,
      farm = getFarmBySymbol(assetSymbol),
      { wallet, tokenAccounts } = getStore('WalletStore'),
      coinAMintAddress = get(farm, ['coins', 0, 'mintAddress']),
      coinBMintAddress = get(farm, ['coins', 1, 'mintAddress']),
      coinADecimals = get(farm, ['coins', 0, 'decimals']),
      coinBDecimals = get(farm, ['coins', 1, 'decimals']),
      decimals = get(farm, ['coins', 0, 'decimals']),
      coinABalance = wallet && tokenAccounts[coinAMintAddress]?.balance,
      coinBBalance = wallet && tokenAccounts[coinBMintAddress]?.balance,
      value = e.target.value,
      farmDetails = getStore('FarmStore').getFarm(farm.mintAddress),
      { coinToPcRatio = 1 } = farmDetails || {};

    let coinAValue;
    let coinBValue;

    coinABalance = Number(coinABalance?.fixed());
    coinBBalance = Number(coinBBalance?.fixed());

    // If token being deposited is SOL, then balance available to
    // withdraw is 0.05 SOL less than total amount
    if (coinAMintAddress === NATIVE_SOL.mintAddress) {
      coinABalance = new TokenAmount(coinABalance - MIN_SOL_BALANCE_AFTER_DEPOSIT, decimals, false).fixed();
    }

    // If token being deposited is SOL, then balance available to
    // withdraw is 0.05 SOL less than total amount
    if (coinBMintAddress === NATIVE_SOL.mintAddress) {
      coinBBalance = new TokenAmount(coinBBalance - MIN_SOL_BALANCE_AFTER_DEPOSIT, decimals, false).fixed();
    }

    if (value > coinBBalance) {
      coinBValue = coinBBalance;
    } else if (value < 0) {
      coinBValue = 0;
    } else {
      coinBValue = value;
    }

    if ((coinBValue * coinToPcRatio) > coinABalance) {
      coinAValue = coinABalance;
      coinBValue = coinABalance / coinToPcRatio;
    } else {
      coinAValue = coinBValue * coinToPcRatio;
    }

    if (isNumber(coinAValue)) {
      coinAValue = coinAValue?.toFixed(coinADecimals);
    }

    if (isNumber(coinBValue)) {
      coinBValue = coinBValue?.toFixed(coinBDecimals);
    }

    this.setState({ coinAValue, coinBValue });
  }

  getDepositBox ({
    wallet,
    balance,
    deposited,
    depositValue,
    depositValueInUSD,
    disabled,
    depositSliderValue,
    isDepositDisabled,
    isDepositing,
    isExpanded,
    isMobile,
    assetName,
    link,
    platform,
    logos,
    coinABalance,
    coinBBalance,
    coins,
    depositType,
    isCoinSelectorOpen,
    selectedCoinIndex
  }) {
    if (platform === FARM_PLATFORMS.ORCA) {
      const { coinAValue, coinBValue } = this.state;
      const { getTokenPrice } = getStore('PriceStore');
      const coinAPrice = getTokenPrice(get(coins, [0, 'symbol']));
      const coinBPrice = getTokenPrice(get(coins, [1, 'symbol']));
      const totalDepositInUsd = depositType === DEPOSIT_TYPES.DUAL
        ? (Number(coinAValue) * coinAPrice) + (Number(coinBValue) * coinBPrice)
        : selectedCoinIndex === 0
          ? (Number(coinAValue) * coinAPrice)
          : (Number(coinBValue) * coinBPrice);

      // console.log({ coinAValue, coinBValue, totalDepositInUsd });
      return (
        <div
          className='vaults-table__row-expanded__deposit'
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          {/* <div className='vaults-table__row-expanded__deposit__dual-switch'> */}
            {/*<IOSSwitch*/}
            {/*  checked={wallet ? depositType === DEPOSIT_TYPES.SINGLE : false}*/}
            {/*  onChange={this.handleDepositTypeChange}*/}
            {/*  color="primary"*/}
            {/*  name="checkedB"*/}
            {/*  inputProps={{ 'aria-label': 'primary checkbox' }}*/}
            {/*  disabled={!wallet}*/}
            {/*/>*/}
            {/*<span>Single Deposit</span>*/}
          {/* </div> */}
          {
            depositType === DEPOSIT_TYPES.DUAL &&
              <>
                <div className='leverage-modal-meta__balances'>
                  <div className='leverage-modal-meta__balances-item'>
                    <div className='leverage-modal-meta__balances-item__available'>
                      Balance: {coinABalance?.fixed()} ({get(coins, [0, 'symbol'], null)})
                    </div>
                    <CustomNumberInput
                      value={coinAValue}
                      onChange={this.handleCoinAChange}
                      icon={logos[0]}
                      withArrows
                      rightButtonLabel='MAX'
                      onRightButtonClick={this.handleCoinAChange.bind(this, { target: { value: 10000000 }})}
                      // disabled={hasIncompleteTransaction || hasMaxPositionsForCurrentFarm}
                    />
                  </div>
                  <div className='leverage-modal-meta__balances-item'>
                    <div className='leverage-modal-meta__balances-item__available'>
                      Balance: {coinBBalance?.fixed()} ({get(coins, [1, 'symbol'], null)})
                    </div>
                    <CustomNumberInput
                      value={coinBValue}
                      onChange={this.handleCoinBChange}
                      icon={logos[1]}
                      withArrows
                      rightButtonLabel='MAX'
                      onRightButtonClick={this.handleCoinBChange.bind(this, { target: { value: 10000000 }})}
                      // disabled={hasIncompleteTransaction || hasMaxPositionsForCurrentFarm}
                    />
                  </div>
                </div>
                <Button
                  color='primary'
                  onClick={this.handleDeposit}
                  disabled={isDepositDisabled}
                >
                {
                  isDepositing ?
                    <Loader /> :
                    `Deposit${Number(coinAValue) ? ` ($${getFormattedNumber(totalDepositInUsd)})` : ''}`
                }
                </Button>
              </>
          }
          {
            depositType === DEPOSIT_TYPES.SINGLE &&
              <>
                <div className='leverage-modal-meta__single_deposit'>
                  <div className='leverage-modal-meta__single_deposit__input'>
                    <div className='leverage-modal-meta__balances-item'>
                      <div className='leverage-modal-meta__balances-item__available'>
                        Balance: {selectedCoinIndex === 0 ? coinABalance?.fixed() : coinBBalance?.fixed()}
                        ({get(coins, [selectedCoinIndex, 'symbol'], null)})
                      </div>
                      <CustomNumberInput
                        value={selectedCoinIndex === 0 ? coinAValue : coinBValue}
                        onChange={selectedCoinIndex === 0 ? this.handleCoinASingleDepositChange : this.handleCoinBSingleDepositChange}
                        icon={logos[selectedCoinIndex]}
                        withArrows
                        rightButtonLabel='MAX'
                        onRightButtonClick={selectedCoinIndex === 0
                          ? this.handleCoinASingleDepositChange.bind(this, { target: { value: 10000000 }})
                          : this.handleCoinBSingleDepositChange.bind(this, { target: { value: 10000000 }})
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <Dropdown
                      isOpen={isCoinSelectorOpen}
                      toggle={this.toggleCoinSelectorOpen}
                      // direction={this.state.isEndpointDropdownOpen ? 'up' : 'down'}
                    >
                      <DropdownToggle className='coin-selector'>
                        <span style={{
                          maxWidth: '140px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'pre',
                          display: 'inline-flex',
                          alignItems: 'center'
                        }}>
                          <img
                            src={downCaret}
                            style={{
                              transform: isCoinSelectorOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
                              transition: 'transform 0.3s',
                              height: '14px'
                            }}
                            alt='coin-selector'
                          />
                          <span
                            style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'pre',
                            margin: '2px 0px 2px 8px',
                            display: 'inline-flex',
                            alignItems: 'center'
                          }}>
                            <img style={{ height: '14px', margin: '0 4px 0 0' }} src={logos[selectedCoinIndex]} />
                            <span style={{ margin: '0 8px 0 0' }}>{coins[selectedCoinIndex].symbol}</span>
                          </span>
                        </span>
                      </DropdownToggle>
                      <DropdownMenu right>
                        {
                          map(coins, (coin, index) => (
                            <DropdownItem
                              onClick={this.handleCoinSelect.bind(this, index)}
                              key={index}
                              style={{
                                padding: '0.3rem 1rem 0.3rem 1rem',
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '0.8rem',
                                textAlign: 'right',
                                // justifyContent: 'flex-end'
                              }}
                            >
                              <img style={{ height: '14px', width: '14px', margin: '0 4px 0 0', justifySelf: 'flex-start' }} src={logos[index]} />
                              <span style={{ margin: '0 8px 0 0', justifySelf: 'flex-start' }}>{coin.symbol}</span>
                            </DropdownItem>
                          ))
                        }
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
                <Button
                    color='primary'
                    onClick={this.handleDeposit}
                    disabled={isDepositDisabled}
                  >
                  {
                    isDepositing ?
                      <Loader /> :
                      `Deposit${
                        selectedCoinIndex === 0
                          ? Number(coinAValue) ? ` ($${getFormattedNumber(totalDepositInUsd)})` : ''
                          : Number(coinBValue) ? ` ($${getFormattedNumber(totalDepositInUsd)})` : ''
                      }`
                  }
                  </Button>
                  <div className='single-deposit-warning'>
                    *Single deposit will incur slippage and trading fees.
                  </div>
              </>
          }
          {
            isExpanded &&
              <LowSolWarningAlert
                className='shift-up-16'
                minBalanceRequired={VAULTS_MIN}
              />
          }
        </div>
      )
    }

    return (
      <div className='vaults-table__row-expanded__deposit'>
      {
        // Show deposit box if
        // 1. Wallet is not connected
        // 2. User has some LP token balance
        // 3. User has LP tokens deposited (they know how to get LP tokens)
        (!wallet || (balance > 0) || (deposited > 0)) ?
          (
            <>
              <CustomNumberInput
                placeholder='Enter deposit amount...'
                value={depositValue}
                onChange={this.handleDepositChange}
                withArrows={false}
                rightButtonLabel='MAX'
                onRightButtonClick={this.handleDepositChange.bind(this, { target: { value: Number(balance) }})}
                disabled={!wallet || disabled}
              />
              <div className='slider-container'>
                <CustomSlider
                  valueLabelDisplay='auto'
                  aria-label='pretto slider'
                  defaultValue={0}
                  onChange={this.handleDepositSliderChange}
                  disabled={!wallet || disabled}
                  value={depositSliderValue}
                />
                <span className='slider-container__label'>
                  {depositSliderValue}%
                </span>
              </div>
              <Button
                color='primary'
                className='deposit-btn'
                onClick={this.handleDeposit}
                disabled={isDepositDisabled}
              >
              {
                isDepositing ?
                  <Loader /> :
                  `Deposit${Number(depositValueInUSD) ? ` ($${getFormattedNumber(depositValueInUSD)})` : ''}`
              }
              </Button>
              {
                isExpanded &&
                  <LowSolWarningAlert
                    className='shift-up-16'
                    minBalanceRequired={VAULTS_MIN}
                  />
              }
          </>
        ) :
        // Otherwise teach them how to get LP tokens
        (
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'left',
              fontSize: isMobile ? '0.7rem' : '0.8rem'
            }}>
            {
              (platform === FARM_PLATFORMS.SABER) &&
              <span>
                Saber {assetName} can be obtained by&nbsp;
                <a target="_blank" rel="noopener noreferrer" href={link}>providing liquidity on Saber using their app.</a> 
                &nbsp;If you already have {assetName} staked in a Saber farm, you will need to&nbsp;
                <a target="_blank" rel="noopener noreferrer" href='https://app.saber.so/#/farms'>unstake from Saber</a> 
                &nbsp;before staking into SolFarm.
              </span>
            }
            {
              (platform === FARM_PLATFORMS.RAYDIUM) &&
              <span>
                Raydium {assetName} can be obtained by&nbsp;
                <a target="_blank" rel="noopener noreferrer" href={link}>providing liquidity on Raydium using their app.</a> 
                &nbsp;If you already have {assetName} staked in a Raydium farm, you will need to&nbsp;
                <a target="_blank" rel="noopener noreferrer" href='https://raydium.io/farms/'>unstake from Raydium</a> 
                &nbsp;before staking into SolFarm.
              </span>
            }
            <Button
              color='primary'
              onClick={() => window.open(link, '_blank')}
              style={{
                marginTop: 32
              }}
            >
              Get {assetName}
            </Button>
          </div>
        )
      }
      </div>
    )
  }

  getWithdrawBox ({
    isTokenAccountMissing,
    withdrawValue,
    withdrawValueInUSD,
    wallet,
    deposited,
    withdrawSliderValue,
    isWithdrawDisabled,
    isWithdrawing,
    isExpanded
  }) {
    return (
      <div className='vaults-table__row-expanded__withdraw'>
      {
        isTokenAccountMissing ?
        (
          <div style={{ flex: 1 }} className='vaults-table__row-expanded__withdraw__token-missing'>
            Token account missing. Click to fix.
          </div>
        ) :
        (
          <>
            <CustomNumberInput
              placeholder='Enter withdraw amount...'
              value={withdrawValue}
              onChange={this.handleWithdrawChange}
              withArrows={false}
              rightButtonLabel='MAX'
              onRightButtonClick={this.handleWithdrawChange.bind(this, { target: { value: Number(deposited) }})}
              disabled={!wallet}
            />
            <div className='slider-container'>
              <CustomSlider
                valueLabelDisplay='auto'
                aria-label='pretto slider'
                defaultValue={0}
                onChange={this.handleWithdrawSliderChange}
                disabled={!wallet}
                value={withdrawSliderValue}
              />
              <span className='slider-container__label'>
                {withdrawSliderValue}%
              </span>
            </div>
          </>
        )
      }
      <Button
        color='primary'
        onClick={isTokenAccountMissing ? this.handleFixTokenAccount : this.handleWithdrawWithWarnings}
        disabled={!isTokenAccountMissing && isWithdrawDisabled}
      >
        {
          isWithdrawing ?
            <Loader /> :
            (
              isTokenAccountMissing ?
              'Fix Token Account' :
              `Withdraw${Number(withdrawValueInUSD) ? ` ($${getFormattedNumber(withdrawValueInUSD)})` : ''}`
            )
        }
      </Button>
      {
        isExpanded &&
          <LowSolWarningAlert
            className='shift-up-16'
            minBalanceRequired={VAULTS_MIN}
          />
      }
      </div>
    );
  }

  render () {
    const {
      assetName,
      tvl,
      deposited,
      balance,
      dailyAPR,
      weeklyAPY,
      yearlyAPY,
      logos,
      price,
      dualYield,
      decimals,
      rewardSinceLastDeposit,
      isUserBalanceAccountValid,
      liquidityMining,
      tulipAPR,
      isTokenAccountMissing,
      tulipEarned,
      disabled,
      controllerFee = 0.1,
      platformFee = 1.4,
      saber,
      sunny,
      link,
      tooltipText,
      lastDepositTime,
      yieldBreakdown,
      platform,
      coinABalance,
      coinBBalance,
      coins,
      isNew
    } = this.props.data || {},
    {
      isExpanded,
      depositValue,
      withdrawValue,
      isDepositing,
      isWithdrawing,
      withdrawSliderValue,
      depositSliderValue,
      isHarvesting,
      coinAValue,
      coinBValue,
      depositType,
      isCoinSelectorOpen,
      selectedCoinIndex
    } = this.state,
    { wallet } = getStore('WalletStore'),
    areBothCoinValuesValid = !Number(coinAValue) || !Number(coinBValue),
    isSingleDeposit = depositType === DEPOSIT_TYPES.SINGLE,
    isDepositDisabled = (
      platform === FARM_PLATFORMS.ORCA ?
      (!wallet || (!isSingleDeposit && areBothCoinValuesValid) || isDepositing || disabled) :
      !wallet || !balance || isDepositing || !depositValue || !Number(depositValue) || Number(depositValue) > Number(balance) || disabled
    ),
    isWithdrawDisabled = (
      !wallet || !deposited || isWithdrawing || !withdrawValue || !Number(withdrawValue) || Number(withdrawValue) > Number(deposited)
    ),
    isHarvestDisabled = !wallet || !Number(tulipEarned?.toFixed(6)) || isHarvesting,
    depositedInUsd = deposited === '-' ? '' : deposited * price,
    balanceInUsd = balance === '-' ? '' : balance * price,
    rewardSinceLastDepositInUsd = rewardSinceLastDeposit * price,
    { isRefreshing } = getStore('UIStore'),
    lastDepositTimeObj = lastDepositTime && new Date(lastDepositTime * 1000),
    { isMobile } = getStore('ResponsiveStore'),
    isInfoTipVisible = !(!wallet || (balance > 0) || (deposited > 0)),
    withdrawValueInUSD = withdrawValue * price,
    depositValueInUSD = depositValue * price,

    // UI boxes
    depositBox = this.getDepositBox({
      wallet,
      balance,
      deposited,
      depositValue,
      depositValueInUSD,
      disabled,
      depositSliderValue,
      isDepositDisabled,
      isDepositing,
      isExpanded,
      isMobile,
      assetName,
      link,
      platform,
      logos,
      coinABalance,
      coinBBalance,
      coins,
      depositType,
      isCoinSelectorOpen,
      selectedCoinIndex
    }),
    withdrawBox = this.getWithdrawBox({
      isTokenAccountMissing,
      withdrawValue,
      withdrawValueInUSD,
      wallet,
      deposited,
      withdrawSliderValue,
      isWithdrawDisabled,
      isWithdrawing,
      isExpanded,
      platform
    });

    return (
      <div
        className={
          classnames(
            'vaults-table__row',
            {
              'is-expanded': isExpanded,
              'no-harvest': (isMobile || !liquidityMining || !tulipEarned),
              'is-info-tip': isInfoTipVisible,
              'is-token-account-missing': isTokenAccountMissing
            }
          )
        }
        ref={this.rowRef}
      >
        <div
          className={
            classnames(
              'vaults-table__row-item',
              {
                'is-new': isNew && isMobile
              }
            )
          }
          onClick={this.toggleRow}
        >

          {/* Asset Details */}
          <div className='vaults-table__row-item__asset'>
            <div className='vaults-table__row-item__asset__logos'>
              {
                logos.map((logo, index) => (
                  <img key={index} src={logo} className='vaults-table__row-item__asset__logos-item' />
                ))
              }
            </div>
            <div className='vaults-table__row-item__asset__text'>
              <div className='vaults-table__row-item__asset__text-name'>
                {assetName}
                {
                   dualYield &&
                    <Badge
                      type='secondary'
                      tooltipText='Dual Yield'
                    >
                      DY
                    </Badge>
                }
                {
                  isNew &&
                  <Badge className={(isNew && isMobile) ? 'is-new' : ''} type='animated-success'>NEW</Badge>
                }
                {/*{*/}
                {/*  liquidityMining && !isMobile &&*/}
                {/*  <div*/}
                {/*    className='vaults-table__header-cell__tulip-badge'*/}
                {/*    onMouseOver={this.toggleLiquidityMiningInfoTooltip.bind(this, true)}*/}
                {/*    onMouseLeave={this.toggleLiquidityMiningInfoTooltip.bind(this, false)}*/}
                {/*    id={`tulipIcon${this.props.rowIndex}`}*/}
                {/*  >*/}
                {/*    <img src={tulipIcon} />*/}
                {/*    <span>{getFormattedNumber(tulipAPR)}% APR</span>*/}
                {/*  </div>*/}
                {/*}*/}
                {
                  disabled &&
                    <Badge
                      type='error'
                      tooltipText='Rewards for this pool have ended.'
                    >
                      ENDED
                    </Badge>
                }
                {/*{*/}
                {/*  !disabled && liquidityMining &&*/}
                {/*  <div*/}
                {/*    className='vaults-table__header-cell__tulip-badge'*/}
                {/*    onMouseOver={this.toggleLiquidityMiningInfoTooltip.bind(this, true)}*/}
                {/*    onMouseLeave={this.toggleLiquidityMiningInfoTooltip.bind(this, false)}*/}
                {/*    id={`tulipIcon${this.props.rowIndex}`}*/}
                {/*  >*/}
                {/*    <img src={tulipIcon} />*/}
                {/*    <span>{getFormattedNumber(tulipAPR)}% APR</span>*/}
                {/*  </div>*/}
                {/*}*/}
                {
                  tooltipText &&
                  <IconWithTooltip
                    icon={infoIcon}
                    className='vaults-table__header-cell__info-btn'
                    tooltipText={tooltipText}
                    placement='bottom'
                  />
                }
              </div>
              <div className='vaults-table__row-item__asset__text-tvl'>
                {saber ? (sunny) ? 'SaberxSunny' : 'Saber' : capitalize(platform)}
                {
                  link &&
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className='vaults-table__header-cell__tulip-link'
                      href={link}
                      id={`addLiquidity-${this.props.rowIndex}`}
                      onClick={this.handleLinkClick}
                      onMouseOver={this.toggleAddLiquidityTooltip.bind(this, true)}
                      onMouseLeave={this.toggleAddLiquidityTooltip.bind(this, false)}
                    >
                      <img height="12px" width="12px" src={linkIcon} alt='farm-logo'/>
                    </a>
                }
              </div>
              <div className='vaults-table__row-item__asset__text-tvl'>
                TVL: { isRefreshing ? <Loader /> : (<span>$<span>{tvl}</span></span>) }
                {
                  !(isMobile && !isExpanded) && (platform !== FARM_PLATFORMS.ORCA) &&
                  ` | 1LP = $${getFormattedNumber(price)}`
                }
              </div>
            </div>
          </div>

          {
            isMobile && !disabled && !isExpanded &&
              <div className={
                classnames(
                  'vaults-table__row-item__cell mobile-details'
                )
              }>
                {/* Balance */}
                <div>
                Balance&nbsp;
                {
                  (isRefreshing && wallet) ? <Loader /> :
                  (
                    <div>
                      <div className='vaults-table__row-item__cell-token'>
                        {wallet ? getFormattedNumber(Number(balance)) : balance} &nbsp;
                        {/* {wallet ? '| $' : null}{getFormattedNumber(balanceInUsd)} */}
                      </div>
                    </div>
                  )
                }
                </div>

                {/* Deposited */}
                <div>
                Deposited&nbsp;
                {
                  (isRefreshing && wallet) ? <Loader /> :
                  (
                    <div>
                      <div className='vaults-table__row-item__cell-token'>
                        {wallet ? getFormattedNumber(Number(deposited)) : deposited} &nbsp;
                        {/* {wallet ? '| $' : null}{getFormattedNumber(depositedInUsd)} */}
                      </div>
                    </div>
                  )
                }
                </div>

                {/* Yearly APY */}
                <div>
                  Yearly APY&nbsp;
                  {yearlyAPY ? (<span><span>{yearlyAPY}</span> %</span>) : '-'}
                </div>
              </div>
          }

          {/* Balance */}
          {
            !isMobile &&
            <div className={
              classnames(
                'vaults-table__row-item__cell',
                // {
                  // 'has-error': isTokenAccountMissing
                // }
              )
            }>
              {
                (isRefreshing && wallet) ? <Loader /> :
                (
                  (platform === FARM_PLATFORMS.ORCA) ?
                  <div>
                    <div className='vaults-table__row-item__cell-token' style={{ fontSize: 13 }}>
                      {wallet ? `${getFormattedNumber(coinABalance?.fixed(), get(coins, [0, 'decimals']), get(coins, [0, 'decimals']))} ${get(coins, [0, 'symbol'])}` : '-'}
                    </div>
                    <div className='vaults-table__row-item__cell-token' style={{ fontSize: 13 }}>
                      {wallet ? `${getFormattedNumber(coinBBalance?.fixed(), get(coins, [1, 'decimals']), get(coins, [1, 'decimals']))} ${get(coins, [1, 'symbol'])}` : null}
                    </div>
                  </div> :
                  <div>
                    <div className='vaults-table__row-item__cell-token'>
                      {wallet ? getFormattedNumber(balance, decimals, decimals) : balance}
                    </div>
                    <div className='vaults-table__row-item__cell-usd'>
                      {wallet ? '$' : null}{getFormattedNumber(balanceInUsd)}
                      {/* {
                        isTokenAccountMissing &&
                        <div
                          className='vaults-table__header-cell__info-btn'
                          onMouseOver={this.toggleErrorTooltip.bind(this, true)}
                          onMouseLeave={this.toggleErrorTooltip.bind(this, false)}
                          id={`errorIcon${this.props.rowIndex}`}
                          onClick={this.handleFixTokenAccount}
                        >
                          <img src={errorIcon} />
                        </div>
                      }
                      {
                        isTokenAccountMissing &&
                        <Tooltip
                          target={`errorIcon${this.props.rowIndex}`}
                          isOpen={this.state.isErrorTooltipVisible}
                          hideArrow
                          placement='bottom'
                          innerClassName='vaults-table__tooltip'
                        >
                          Token account missing. Click here to fix, to see your balance.
                        </Tooltip>
                      } */}
                    </div>
                  </div>
                )
              }
            </div>
          }

          {/* Deposited */}
          {!isMobile &&
            <div className='vaults-table__row-item__cell'>
              {
                (isRefreshing && wallet) ? <Loader /> :
                (
                  // (platform === FARM_PLATFORMS.ORCA) ?
                  // <div>
                  //   <div className='vaults-table__row-item__cell-token'>
                  //     {wallet ? 
                  //       <div style={{ display: 'flex' }}>
                  //         ${getFormattedNumber(depositedInUsd)}
                  //         {
                  //           isUserBalanceAccountValid &&
                  //           <IconWithTooltip
                  //             icon={rewardIcon}
                  //             placement='bottom'
                  //             tooltipText={
                  //               getRewardTooltipContent(
                  //                 getFormattedNumber(rewardSinceLastDeposit, decimals, decimals),
                  //                 rewardSinceLastDepositInUsd.toFixed(2),
                  //                 lastDepositTimeObj
                  //               )
                  //             }
                  //             className='vaults-table__header-cell__info-btn'
                  //           />
                  //         }
                  //       </div> :
                  //       '-'
                  //     }
                  //   </div>
                  // </div> :
                  <div>
                    <div className='vaults-table__row-item__cell-token'>
                      {wallet ? getFormattedNumber(deposited, decimals, decimals) : deposited}
                    </div>
                    <div className='vaults-table__row-item__cell-usd'>
                      {wallet ? '$' : null}{getFormattedNumber(depositedInUsd)}
                      {
                        wallet && isUserBalanceAccountValid &&
                        <IconWithTooltip
                          icon={rewardIcon}
                          placement='bottom'
                          tooltipText={
                            getRewardTooltipContent(
                              getFormattedNumber(rewardSinceLastDeposit, decimals, decimals),
                              rewardSinceLastDepositInUsd.toFixed(2),
                              lastDepositTimeObj
                            )
                          }
                          className='vaults-table__header-cell__info-btn'
                        />
                      }
                    </div>
                  </div>
                )
              }
            </div>
          }

          {/* Daily APR */}
          {
            !isMobile &&
              <div className='vaults-table__row-item__cell'>
              {/* {
                isRefreshing ? <Loader /> :
                (dailyAPR ? `${dailyAPR} %` : '-')
              } */}
              {dailyAPR ? (<span><span>{dailyAPR}</span> %</span>) : '-'}
              {
                !saber &&
                <IconWithTooltip
                  tooltipText={
                    `Fees: ${getFormattedNumber(yieldBreakdown.dailyTradingFees)}%
                    Yield: ${getFormattedNumber(yieldBreakdown.dailyYield)}%`
                  }
                  icon={infoIcon}
                  placement='bottom'
                />
              }
            </div>
          }

          {/* Weekly APY */}
          {
            !isMobile &&
            <div className='vaults-table__row-item__cell'>
              {/* {
                isRefreshing ? <Loader /> :
                (weeklyAPY ? `${weeklyAPY} %` : '-')
              } */}
              {weeklyAPY ? (<span><span>{weeklyAPY}</span> %</span>) : '-'}
              {
                !saber &&
                <IconWithTooltip
                  tooltipText={
                    `Fees: ${getFormattedNumber(yieldBreakdown.dailyTradingFees * 7)}%
                    Yield: ${getFormattedNumber(yieldBreakdown.weeklyYield)}%`
                  }
                  icon={infoIcon}
                  placement='bottom'
                />
              }
            </div>
          }

          {/* Yearly APY */}
          {
            !isMobile &&
            <div className='vaults-table__row-item__cell'>
              {/* {
                isRefreshing ? <Loader /> :
                (yearlyAPY ? `${yearlyAPY} %` : '-')
              } */}
              {yearlyAPY ? (<span><span>{yearlyAPY}</span> %</span>) : '-'}
              {
                !saber &&
                <IconWithTooltip
                  tooltipText={
                    `Fees: ${getFormattedNumber(yieldBreakdown.dailyTradingFees * 365)}%
                    Yield: ${getFormattedNumber(yieldBreakdown.yearlyYield)}%`
                  }
                  icon={infoIcon}
                  placement='bottom'
                />
              }
            </div>
          }

          {/* Item Toggle */}
          <div className='vaults-table__row-item__toggle'>
            <div className='vaults-table__row-item__toggle__btn'>
              <img src={downCaret} />
            </div>
          </div>
        </div>
        {
          <CSSTransitionGroup
            transitionName='slide-in'
            transitionEnterTimeout={50}
            transitionLeaveTimeout={50}
          >
            {
              isExpanded &&
                <div className={`vaults-table__row-expanded ${isExpanded ? '' : 'is-hidden'}`}>


                  {/* Harvest and Fee Section */}
                  <div className='vaults-table__row-expanded__left-wrapper'>

                    {/* Harvest */}
                    {
                      !isMobile && liquidityMining && tulipEarned > 0 &&
                      <div className='vaults-table__row-expanded__left-wrapper__harvest'>
                        <div className='vaults-table__row-expanded__left-wrapper__harvest__text'>
                          <div className='vaults-table__row-expanded__left-wrapper__harvest__text-label'>
                          TULIP Earned:
                          </div>
                          <div className='vaults-table__row-expanded__left-wrapper__harvest__text-value'>
                            {tulipEarned ? tulipEarned.toFixed(TOKENS.TULIP.decimals) : ZERO.toFixed(TOKENS.TULIP.decimals)}
                          </div>
                        </div>

                        <Button
                          color='primary'
                          onClick={this.handleTulipHarvest}
                          disabled={isHarvestDisabled}
                        >
                          {
                            isHarvesting ?
                              <Loader /> :
                              'Harvest'
                          }
                        </Button>
                      </div>
                    }

                    {/* Fee Details */}
                    <div className='vaults-table__row-expanded__left-wrapper__fees'>
                      <div className='vaults-table__row-expanded__left-wrapper__fees-header'>
                        Fees
                      </div>
                      <div className='vaults-table__row-expanded__left-wrapper__fees-text'>
                        {getFeeText(controllerFee, platformFee)}
                      </div>
                    </div>
                  </div>

                  {/* Withdraw and Deposit Boxes */}
                  {
                    isMobile ?
                      <>
                        {withdrawBox}
                        {depositBox}
                      </> :
                      <>
                        {depositBox}
                        {withdrawBox}
                      </>
                  }

                  {
                    isMobile &&
                      <div className='vaults-table__row-item__cell mobile-details-expanded'>
                        {/* Balance */}
                        <div>
                        Balance
                        {
                          (isRefreshing && wallet) ? <Loader /> :
                          (
                            <div>
                              <div className='vaults-table__row-item__cell-token'>
                                {wallet ? getFormattedNumber(Number(balance)) : balance} &nbsp;
                                {wallet ? '| $' : null}{getFormattedNumber(balanceInUsd)}
                              </div>
                            </div>
                          )
                        }
                        </div>

                        {/* Deposited */}
                        <div>
                        Deposited
                        {
                          (isRefreshing && wallet) ? <Loader /> :
                          (
                            <div>
                              <div className='vaults-table__row-item__cell-token' style={{ display: 'flex' }}>
                                {
                                  wallet && isUserBalanceAccountValid &&
                                  <IconWithTooltip
                                    icon={rewardIcon}
                                    placement='bottom'
                                    tooltipText={
                                      getRewardTooltipContent(
                                        getFormattedNumber(rewardSinceLastDeposit, decimals, decimals),
                                        rewardSinceLastDepositInUsd.toFixed(2),
                                        lastDepositTimeObj
                                      )
                                    }
                                    className='vaults-table__header-cell__info-btn'
                                  />
                                }
                                &nbsp;
                                {wallet ? getFormattedNumber(Number(deposited)) : deposited} &nbsp;
                                {wallet ? '| $' : null}{getFormattedNumber(depositedInUsd)}
                              </div>
                            </div>
                          )
                        }
                        </div>

                        {/* Daily APR */}
                        <div>
                          Daily APR
                          {dailyAPR ? (<span><span>{dailyAPR}</span> %</span>) : '-'}
                        </div>

                        {/* Weekly APY */}
                        <div>
                          Weekly APY
                          {weeklyAPY ? (<span><span>{weeklyAPY}</span> %</span>) : '-'}
                        </div>

                        {/* Yearly APY */}
                        <div>
                          Yearly APY
                          {yearlyAPY ? (<span><span>{yearlyAPY}</span> %</span>) : '-'}
                        </div>
                      </div>
                  }
                </div>
            }
          </CSSTransitionGroup>
          // this.state.isExpanded &&
        }
        {/*{*/}
        {/*  !disabled  && liquidityMining && !isMobile &&*/}
        {/*    <Tooltip*/}
        {/*      target={`tulipIcon${this.props.rowIndex}`}*/}
        {/*      isOpen={this.state.isLiquidityMiningInfoTooltipVisible}*/}
        {/*      hideArrow*/}
        {/*      placement='bottom'*/}
        {/*      innerClassName='vaults-table__tooltip'*/}
        {/*    >*/}
        {/*      Liquidity Mining*/}
        {/*    </Tooltip>*/}
        {/*}*/}
        {
          link &&
            <Tooltip
              target={`addLiquidity-${this.props.rowIndex}`}
              isOpen={this.state.isAddLiquidityTooltipVisible}
              hideArrow
              placement='bottom'
              innerClassName='vaults-table__tooltip'
            >
              Add liquidity
            </Tooltip>
        }
      </div>
    );
  }
}

const Header = observer(VaultsTableHeader);
const Row = observer(VaultsTableRow);

class VaultsTable extends React.Component {
  constructor () {
    super();

    this.state = {
      isModalOpen: false,
      sortedBy: null,
      sortOrder: ''
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.setSuccessCallback = this.setSuccessCallback.bind(this);
    this.handleModalSuccess = this.handleModalSuccess.bind(this);
    this.getRowsInOrder = this.getRowsInOrder.bind(this);
    this.setSortBy = this.setSortBy.bind(this);

    this.modalSuccessCallback = null;
  }

  toggleModal (e, value) {
    isNil(value) && (value = !this.state.isModalOpen);

    this.setState({ isModalOpen: value});
  }

  setSuccessCallback (cb) {
    this.modalSuccessCallback = cb;
  }

  handleModalSuccess () {
    this.toggleModal(false);

    isFunction(this.modalSuccessCallback) && this.modalSuccessCallback();

    // Clear success callback after calling it
    this.setSuccessCallback(null);
  }

  getRowsInOrder (data) {
    const { sortedBy, sortOrder } = this.state;

    // If both `sortedBy` and `sortOrder` are not present,
    // then we fallback to the default order
    if (!(sortedBy && sortOrder)) {
      return data;
    }

    return orderBy(data, [sortedBy], [sortOrder]);
  }

  setSortBy (headerName) {
    if (!headerName) {
      return;
    }

    const { sortedBy, sortOrder } = this.state;

    // If the same header is being clicked multiple times,
    // we change it from 'desc' to 'asc' to `null` and so on.
    if (sortedBy === headerName) {
      const currentOrderIndex = SORT_ORDER.indexOf(sortOrder),
        nextOrder = SORT_ORDER[(currentOrderIndex + 1) % 3],
        nextState = { sortOrder: nextOrder };

      // If the next order is `null`, then we also reset `sortedBy`
      !nextOrder && (nextState.sortedBy = null);

      return this.setState(nextState);
    }

    // This is a new header, so we start with 'desc' again
    return this.setState({
      sortedBy: headerName,
      sortOrder: SORT_ORDER[0]
    });
  }

  getEmptyText () {
    const { isRefreshing, isSilentRefresh } = getStore('UIStore');

    if (isRefreshing || isSilentRefresh) {
      return 'Please wait, we\'re refreshing the data. Try a cookie maybe? 🍪';
    }

    if (getStore('FilterStore').isFilterActive) {
      return 'You have no active farms for the selected filter(s).';
    }

    return 'You have no active farms.';
  }

  render () {
    const {
      data = [],
      onWithdraw,
      onDeposit,
      checkUnsafeWithdraw,
      onFixTokenAccount,
      onTulipHarvest
    } = this.props || {},
      { isRefreshing } = getStore('UIStore'),
      rowsInOrder = this.getRowsInOrder(data),
      { sortedBy, sortOrder } = this.state;

    return (
      <div className='vaults-table'>
        <Header
          toast={this.props.toast}
          setSortBy={this.setSortBy}
          sortedBy={sortedBy}
          sortOrder={sortOrder}
        />
        {
          isEmpty(data) ? 
            (
              isRefreshing ?
                <Loader /> :
                <div className='vaults-table__empty'>{this.getEmptyText()}</div>
            ) :
            rowsInOrder.map((row, index) => (
              <Row
                data={row}
                onWithdraw={onWithdraw.bind(this, row.assetSymbol)}
                onDeposit={onDeposit.bind(this, row.assetSymbol)}
                toast={this.props.toast}
                key={row.assetSymbol}
                toggleModal={this.toggleModal.bind(this, null)}
                setSuccessCallback={this.setSuccessCallback}
                checkUnsafeWithdraw={checkUnsafeWithdraw.bind(this, row.assetSymbol)}
                rowIndex={index}
                onFixTokenAccount={onFixTokenAccount.bind(this, row.assetSymbol)}
                onTulipHarvest={onTulipHarvest.bind(this, row.assetSymbol)}
              />
            ))
        }
        <Modal
          isOpen={this.state.isModalOpen}
          toggle={this.toggleModal}
          className='app-body__modal'
          centered
        >
          <ModalHeader toggle={this.toggleModal}>Warning</ModalHeader>
          <ModalBody>
            You will lose your rewards since the last deposit, if you withdraw within 2 hours of it.
          </ModalBody>
          <ModalFooter>
            <Button className='modal__success-btn' color='danger' onClick={this.handleModalSuccess}>Withdraw anyway</Button>{' '}
            <Button className='modal__cancel-btn' color='primary' onClick={this.toggleModal}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default observer(VaultsTable);
