import { FARM_PLATFORMS } from "../constants/farmConstants";

import {NATIVE_SOL, TOKENS} from "./tokens";

// Logos
import orcaLogo from "../coins/orca.png";
import usdcLogo from "../coins/usdc.png";
import usdtLogo from "../coins/usdt.png";
import solLogo from "../coins/sol.png";
import ethLogo from "../coins/eth.png";
import msolLogo from "../coins/msol.png";
import socnLogo from "../coins/socn.png";
import { isFarmNew } from "./farmUtils";

export const ORCA_FARMS = [
  {
    symbol: "USDC-USDT",
    name: "USDC-USDT LP",
    coin: { ...TOKENS.USDC },
    pc: { ...TOKENS.USDT },
    coins: [TOKENS.USDC, TOKENS.USDT],
    reward: { ...TOKENS.ORCA },
    rewardB: { ...TOKENS.ORCA },

    mintAddress: "H2uzgruPvonVpCRhwwdukcpXK8TG17swFNzYFr2rtPxy",
    decimals: TOKENS.ORCA.decimals,
    logos: [usdcLogo, usdtLogo],
    dualYield: false,
    liquidityMining: false,
    totalTulipEmission: 0,
    platform: FARM_PLATFORMS.ORCA,
    marginIndex: 255,
    maxPositionLimitInUsd: 0,
  },
  {
    symbol: "ORCA-USDC",
    name: "ORCA-USDC LP",
    coin: { ...TOKENS.ORCA },
    pc: { ...TOKENS.USDC },
    coins: [TOKENS.ORCA, TOKENS.USDC],
    reward: { ...TOKENS.ORCA },
    rewardB: { ...TOKENS.ORCA },

    mintAddress: "n8Mpu28RjeYD7oUX3LG1tPxzhRZh3YYLRSHcHRdS3Zx",
    decimals: TOKENS.ORCA.decimals,
    logos: [orcaLogo, usdcLogo],
    dualYield: false,
    liquidityMining: false,
    totalTulipEmission: 0,
    platform: FARM_PLATFORMS.ORCA,
    marginIndex: 17,
    maxPositionLimitInUsd: 100000,
  },
  {
    symbol: "ORCA-SOL",
    name: "ORCA-SOL LP",
    coin: { ...TOKENS.ORCA },
    pc: { ...TOKENS.WSOL },
    coins: [TOKENS.ORCA, NATIVE_SOL],
    reward: { ...TOKENS.ORCA },
    rewardB: { ...TOKENS.ORCA },

    mintAddress: "2uVjAuRXavpM6h1scGQaxqb6HVaNRn6T2X7HHXTabz25",
    decimals: TOKENS.ORCA.decimals,
    logos: [orcaLogo, solLogo],
    dualYield: false,
    liquidityMining: false,
    totalTulipEmission: 0,
    platform: FARM_PLATFORMS.ORCA,
    marginIndex: 18,
    maxPositionLimitInUsd: 100000,
  },
  {
    symbol: "SOL-USDC",
    name: "SOL-USDC LP",
    coin: { ...NATIVE_SOL },
    pc: { ...TOKENS.USDC },
    coins: [NATIVE_SOL, TOKENS.USDC],
    reward: { ...TOKENS.ORCA },
    rewardB: { ...TOKENS.ORCA },

    mintAddress: "APDFRM3HMr8CAGXwKHiu2f5ePSpaiEJhaURwhsRrUUt9",
    decimals: TOKENS.ORCA.decimals,
    logos: [solLogo, usdcLogo],
    dualYield: false,
    liquidityMining: false,
    totalTulipEmission: 0,
    platform: FARM_PLATFORMS.ORCA,
    marginIndex: 24,
    maxPositionLimitInUsd: 100000,
  },
  {
    symbol: "SOL-USDT",
    name: "SOL-USDT LP",
    coin: { ...NATIVE_SOL },
    pc: { ...TOKENS.USDT },
    coins: [NATIVE_SOL, TOKENS.USDT],
    reward: { ...TOKENS.ORCA },
    rewardB: { ...TOKENS.ORCA },

    mintAddress: "FZthQCuYHhcfiDma7QrX7buDHwrZEd7vL8SjS6LQa3Tx",
    decimals: TOKENS.ORCA.decimals,
    logos: [solLogo, usdtLogo],
    dualYield: false,
    liquidityMining: false,
    totalTulipEmission: 0,
    platform: FARM_PLATFORMS.ORCA,
    marginIndex: 25,
    maxPositionLimitInUsd: 100000,
  },
  {
    symbol: "WHETH-USDC",
    name: "whETH-USDC LP",
    coin: { ...TOKENS.whETH },
    pc: { ...TOKENS.USDC },
    coins: [TOKENS.whETH, TOKENS.USDC],
    reward: { ...TOKENS.ORCA },
    rewardB: { ...TOKENS.ORCA },

    mintAddress: "7NPtjjAP7vhp4t5NCLyY4DY5rurvyc8cgZ2a2rYabRia",
    decimals: TOKENS.ORCA.decimals,
    logos: [ethLogo, usdcLogo],
    dualYield: false,
    liquidityMining: false,
    totalTulipEmission: 0,
    platform: FARM_PLATFORMS.ORCA,
    marginIndex: 29,
    maxPositionLimitInUsd: 100000,
    isNew: isFarmNew('15/10/2021')
  },
  {
    symbol: "WHETH-SOL",
    name: "whETH-SOL LP",
    coin: { ...TOKENS.whETH },
    pc: { ...NATIVE_SOL },
    coins: [TOKENS.whETH, NATIVE_SOL],
    reward: { ...TOKENS.ORCA },
    rewardB: { ...TOKENS.ORCA },

    mintAddress: "7aYnrdmdCRodDy2Czn6keUquUhjF1jPEmfwZPh488z8U",
    decimals: TOKENS.ORCA.decimals,
    logos: [ethLogo, solLogo],
    dualYield: false,
    liquidityMining: false,
    totalTulipEmission: 0,
    platform: FARM_PLATFORMS.ORCA,
    marginIndex: 30,
    maxPositionLimitInUsd: 100000,
    isNew: isFarmNew('15/10/2021')
  },
  {
    symbol: "ETH-USDC",
    name: "ETH-USDC LP",
    coin: { ...TOKENS.ETH },
    pc: { ...TOKENS.USDC },
    coins: [TOKENS.ETH, TOKENS.USDC],
    reward: { ...TOKENS.ORCA },
    rewardB: { ...TOKENS.ORCA },

    mintAddress: "3e1W6Aqcbuk2DfHUwRiRcyzpyYRRjg6yhZZcyEARydUX",
    decimals: TOKENS.ORCA.decimals,
    logos: [ethLogo, usdcLogo],
    dualYield: false,
    liquidityMining: false,
    totalTulipEmission: 0,
    platform: FARM_PLATFORMS.ORCA,
    marginIndex: 23,
    maxPositionLimitInUsd: 100000,
  },
  {
    symbol: "ETH-SOL",
    name: "ETH-SOL LP",
    coin: { ...TOKENS.ETH },
    pc: { ...NATIVE_SOL },
    coins: [TOKENS.ETH, NATIVE_SOL],
    reward: { ...TOKENS.ORCA },
    rewardB: { ...TOKENS.ORCA },

    mintAddress: "71FymgN2ZUf7VvVTLE8jYEnjP3jSK1Frp2XT1nHs8Hob",
    decimals: TOKENS.ORCA.decimals,
    logos: [ethLogo, solLogo],
    dualYield: false,
    liquidityMining: false,
    totalTulipEmission: 0,
    platform: FARM_PLATFORMS.ORCA,
    marginIndex: 26,
    maxPositionLimitInUsd: 100000,
  },
  {
    symbol: "MSOL-SOL",
    name: "mSOL-SOL LP",
    coin: { ...TOKENS.MSOLORCA },
    pc: { ...NATIVE_SOL },
    coins: [TOKENS.MSOLORCA, NATIVE_SOL],
    reward: { ...TOKENS.ORCA },
    rewardB: { ...TOKENS.ORCA },

    mintAddress: "29cdoMgu6MS2VXpcMo1sqRdWEzdUR9tjvoh8fcK8Z87R",
    decimals: TOKENS.ORCA.decimals,
    logos: [msolLogo, solLogo],
    dualYield: false,
    liquidityMining: false,
    totalTulipEmission: 0,
    platform: FARM_PLATFORMS.ORCA,
    marginIndex: 255,
    maxPositionLimitInUsd: 0,
  },
  {
    symbol: "SOCN-USDC",
    name: "SOCN-USDC LP",
    coin: { ...TOKENS.socn },
    pc: { ...TOKENS.USDC },
    coins: [TOKENS.socn, TOKENS.USDC],
    reward: { ...TOKENS.ORCA },
    rewardB: { ...TOKENS.ORCA },

    mintAddress: "Dkr8B675PGnNwEr9vTKXznjjHke5454EQdz3iaSbparB",
    decimals: TOKENS.ORCA.decimals,
    logos: [socnLogo, usdcLogo],
    dualYield: false,
    liquidityMining: false,
    totalTulipEmission: 0,
    platform: FARM_PLATFORMS.ORCA,
    marginIndex: 255,
    maxPositionLimitInUsd: 0,
    isNew: isFarmNew('09/10/2021')
  },
  {
    symbol: "SOCN-SOL",
    name: "SOCN-SOL LP",
    coin: { ...TOKENS.socn },
    pc: { ...NATIVE_SOL },
    coins: [TOKENS.socn, NATIVE_SOL],
    reward: { ...TOKENS.ORCA },
    rewardB: { ...TOKENS.ORCA },

    mintAddress: "APNpzQvR91v1THbsAyG3HHrUEwvexWYeNCFLQuVnxgMc",
    decimals: TOKENS.ORCA.decimals,
    logos: [socnLogo, solLogo],
    dualYield: false,
    liquidityMining: false,
    totalTulipEmission: 0,
    platform: FARM_PLATFORMS.ORCA,
    marginIndex: 255,
    maxPositionLimitInUsd: 0,
    isNew: isFarmNew('09/10/2021')
  }
];
