import React from 'react';
import { observer } from 'mobx-react';
import { getStore } from '../../stores/get-store';

// Lodash
import { map, some } from 'lodash';

// Components
import Badge from '../../base-components/Badge';
import Logo from '../../base-components/Logo';

// Icons

// Utils
import {  ALL_LEVERAGE_PLATFORMS, LEVERAGE_FARM_TOKENS, ORCA_LEV_TOKENS, RAY_LEV_TOKENS } from '../../utils/vaultFilterUtils';
import classNames from 'classnames';
import SelectDropdown from '../../base-components/SelectDropdown';

const LEVERAGE_FILTER_PLATFORMS = map(ALL_LEVERAGE_PLATFORMS, ({ name, logo }) => {
  return {
    label: name,
    value: name,
    logo
  };
});

class LeverageFarmingFilters extends React.Component {
  handleToken (token) {
    getStore('LeverageFilterStore').setToken(token);
  }

  handlePlatform (platform) {
    getStore('LeverageFilterStore').setPlatform(platform);
  }

  getActiveTokensList () {
    const { selectedPlatforms } = getStore('LeverageFilterStore');

    if (selectedPlatforms.includes('all') || (selectedPlatforms.includes('ray') && selectedPlatforms.includes('orca'))) {
      return LEVERAGE_FARM_TOKENS;
    }
    else {
      return selectedPlatforms.includes('ray') ? RAY_LEV_TOKENS : ORCA_LEV_TOKENS;
    }
  }

  getActiveTokensListForDropdown () {
    return map(this.getActiveTokensList(), ({ name, logo }) => {
      return {
        label: name,
        value: name,
        logo
      };
    });
  }

  getFilterDropdown ({ title, placeholder, items, selectedItems, onClick }) {
    return (
        <SelectDropdown
            title={title}
            placeholder={placeholder}
            isSelected={(value) => some(selectedItems, (i) => i?.toUpperCase() === value?.toUpperCase())}
            items={items}
            itemRender={({ label, logo }) => {
              return (
                  <div>
                    {logo && <img src={logo} height='16px' width='16px' alt='tokens'/> }
                    {label?.toUpperCase()}
                  </div>
              );
            }}
            onClick={onClick}
        />
    )
  }

  render () {
    const { selectedTokens, selectedPlatforms } = getStore('LeverageFilterStore'),
        { isMobile } = getStore('ResponsiveStore');

    return (
      <div className='filters_container'>
        <div className={classNames('category_filters', { 'desktop': !isMobile })}>
            {
              isMobile &&
                <div className='category_filters__dropdown leverage-filter'>
                  {
                    this.getFilterDropdown({
                      title: 'Platforms',
                      placeholder: 'Search',
                      items: LEVERAGE_FILTER_PLATFORMS,
                      selectedItems: selectedPlatforms,
                      onClick: this.handlePlatform
                    })
                  }
                  {
                    this.getFilterDropdown({
                      title: 'Tokens',
                      placeholder: 'Search',
                      items: this.getActiveTokensListForDropdown(),
                      selectedItems: selectedTokens,
                      onClick: this.handleToken
                    })
                  }
                </div>
            }
            {
              !isMobile &&
              <>
                <div className='category_filters__platforms'>
                  <div className='category_filters__title'>Filter by Platforms:</div>
                  {
                    map(ALL_LEVERAGE_PLATFORMS, ({ name, logo }) => {
                      const isSelected = some(selectedPlatforms, (p) => p?.toUpperCase() === name?.toUpperCase());

                      return (
                        <Badge
                          className={classNames('category_filters--badge', { 'active': isSelected })}
                          key={name}
                        >
                          <div onClick={this.handlePlatform.bind(this, name)}>
                            {logo && <img src={logo} height='16px' width='16px' alt='platform'/> }
                            {name?.toUpperCase()}
                          </div>
                        </Badge>
                      );
                    })
                  }
                </div>
                <div className='category_filters__tokens'>
                  <div className='category_filters__title'>Filter by Tokens:</div>
                  {
                    map(this.getActiveTokensList(), ({ name, logo }) => {
                      const isSelected = some(selectedTokens, (t) => t?.toUpperCase() === name?.toUpperCase());

                      return (
                          <Badge
                              className={classNames('category_filters--badge', { 'active': isSelected })}
                              key={name}
                          >
                            <div onClick={this.handleToken.bind(this, name)}>
                              <Logo logo={logo} alt='token'/>
                              {name?.toUpperCase()}
                            </div>
                          </Badge>
                      );
                    })
                  }
                </div>
              </>
            }
        </div>
      </div>
    )
  }
}

export default observer(LeverageFarmingFilters);