import { observable, action, computed, values, makeObservable } from 'mobx';
import { assign, head, orderBy } from 'lodash';

class InfobarItemStore {
  id = '';

  // The priority is important to decide which info item will be currently shown in the UI
  // The higher the priority number is, the more precedence it takes compared to other
  // info items in the list.
  priority = 0;

  props = {};

  constructor (info) {
    this.id = info.id;
    this.update(info);
  }

  update (definition) {
    this.priority = definition.priority;
    this.props = definition;
  }
}

export default class InfobarStore {
  constructor () {
    // Stores all the info bar items which are of type InfobarListStore
    this.infoList = new Map();

    makeObservable(this, {
      infoList: observable,
      activeInfo: computed,
      _add: action.bound,
      _remove: action.bound
    });
  }

  /**
   * @description Finds and returns the item which has the max-priority to be shown to the user
   */
  get activeInfo () {
    // Reverse the list so that the order of insertion is by the time it was inserted
    // and then check the maximum by priority in case two items have equal priority
    const infoList = orderBy(values(this.infoList).reverse(), 'priority', ['desc']);

    return head(infoList);
  }

  /**
   * @private
   * @description Adds a new item to the info list
   *
   * @param {Object} info
   */
  _add (id, info) {
    this.infoList.set(id, info);
  }

  /**
   * @private
   * @description Removes the item from the info list
   *
   * @param {String} id
   */
  _remove (id) {
    this.infoList.delete(id);
  }

  /**
   * @description Clears the info list
   */
  clear () {
    // The dismiss function is called to make sure all the onDismiss functions
    // get called for this.
    this.infoList.forEach((info) => this.dismiss(info.id));
  }

  /**
   * @description Enqueue a new item to the info list to be shown to the user
   *
   * @param {Object} info
   */
  trigger (info) {
    // This ID can be provided (in cases they want to remove that banner) or generated by us as 'guid'
    let sanitizedInfo = assign(
      { priority: 0 },
      info,
      { id: info.id }
    );

    this._add(sanitizedInfo.id, new InfobarItemStore(sanitizedInfo));
  }

  /**
   * @description Dismiss a specific/active item from the info list
   *
   * @param {String} id - info item's ID
   */
  dismiss (id = null) {
    // If the ID is provided, then use that or else, use the activeInfo's ID
    const infoToBeRemoved = id ? this.infoList.get(id) : this.activeInfo;

    // If there's no active item, then bail out
    if (!infoToBeRemoved) {
      return;
    }

    this._remove(infoToBeRemoved.id);
  }
}
