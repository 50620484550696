import React from 'react';
import styles from '../../styles/static.css';
import solfarmLogo from '../../images/SolFarmLogo.png';
import solanaFoundationLogo from '../../images/solana-foundation.svg';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from '../../constants/routes';

function navigateTo (history, route) {
  return history.push(route);
}

export const LandingPage = (props) => {
  const history = useHistory();

  return (
    <div className='page-container'>
      <div className='page-content-container'>
        <div className='header'>
          <div className='header-logo'>
            <img src={solfarmLogo} className='solfarm-small-logo'/>
            SolFarm
          </div>
          <div className='header-links'>
            <div className='header-link' onClick={navigateTo.bind(this, history, APP_ROUTES.VAULTS.route)}>
              {/* <a href='./vaults'> */}
                Vaults
              {/* </a> */}
            </div>
            <div className='header-link'>
              <a href='https://solfarm.gitbook.io/solfarm/' target='_blank'>
                Docs
              </a>
            </div>
            <div className='header-link'>
              <a href='https://twitter.com/Solfarmio' target='_blank'>
                Twitter
              </a>
            </div>
            <div className='header-link'>
              <a href='https://discord.gg/vCaEUjnc28' target='_blank'>
                Discord
              </a>
            </div>
            <div className='header-link' onClick={navigateTo.bind(this, history, APP_ROUTES.ABOUT.route)}>
              {/* <a href='./about'> */}
                About Us
              {/* </a> */}
            </div>
          </div>
        </div>
        <div className='page-content'>
          <img src={solfarmLogo} className='solfarm-logo'/>
          <h1>The Efficient Solana Yield Aggregator</h1>
          {/* <a href='./vaults'> */}
            <div
              className='vault-button'
              onClick={navigateTo.bind(this, history, APP_ROUTES.VAULTS.route)}
            >
              Go to vaults
            </div>
          {/* </a> */}
        </div>
        <div className='partnership'>
          IN PARTNERSHIP WITH
          <img src={solanaFoundationLogo} className='solana-foundation-logo'/>
        </div>
      </div>
    </div>
  );
}
