import { observable, makeObservable, action, computed } from "mobx";
import { isNumber, isNil, assign } from "lodash";
import { getObligationId } from "../utils/obligations";
import { getStore } from "./get-store";
import { getFarmByMintAddress } from "../utils/farms";
import { getLendingReserveByAccount } from "../utils/config";
import { getReserveByName } from "../utils/lendingReserves";
import { TokenAmount } from "../utils/safe-math";
import { WAD } from "../utils/layouts";

class ObligationItemStore {
  constructor(obligation) {
    // Assign the ID
    assign(obligation, {
      id: getObligationId(obligation),
    });

    // Assign all obligation values to the Item
    assign(this, obligation);

    makeObservable(this, {

      // Obligation States
      isOpening: computed,
      isClosing: computed,
      isAddingCollateral: computed,
      isOpened: computed,

      // UI Values
      positionValue: computed,
      equityValue: computed,
      debtValue: computed,
      borrowedReserve: computed,
      borrowedAmount: computed,
      borrowedAmountInLp: computed,
      debtRatio: computed,
      leverageValue: computed,
      cumulativeBorrowRateWads: computed,
      lpTokensValue: computed,
      vaultShareTokens: computed,
      rewardSinceLastDeposit: computed,
    });
  }

  /**
   * @description Update a single obligation
   *
   * @param {Object} obligationDetails Obligation object
   */
  update(obligationDetails) {
    assign(this, obligationDetails);
  }

  //#region Obligation States
  get isOpening() {
    return (
      this.positionState?.hasOwnProperty("borrowed") ||
      this.positionState?.hasOwnProperty("swapped") ||
      this.positionState?.hasOwnProperty("addedLiquidity")
    );
  }

  get isClosing() {
    return (
      this.positionState?.hasOwnProperty("withdrawing") ||
      this.positionState?.hasOwnProperty("removedLiquidity") ||
      this.positionState?.hasOwnProperty("swappedForRepaying")
    );
  }

  get isAddingCollateral() {
    return (
      this.positionState?.hasOwnProperty("topUp") ||
      this.positionState?.hasOwnProperty("topUpSwapped") ||
      this.positionState?.hasOwnProperty("topUpAddedLiquidity")
    );
  }

  get isOpened() {
    return (
      this.positionState?.hasOwnProperty("opened") ||
      this.positionState?.hasOwnProperty("withdrawing") ||
      this.positionState?.hasOwnProperty("removedLiquidity") ||
      this.positionState?.hasOwnProperty("swappedForRepaying") ||
      this.positionState?.hasOwnProperty("topUp") ||
      this.positionState?.hasOwnProperty("topUpSwapped") ||
      this.positionState?.hasOwnProperty("topUpAddedLiquidity")
    );
  }
  //#endregion

  //#region UI Values
  get positionValue() {
    const { coinDeposits, pcDeposits } = this;
    const { coin, pc } = getFarmByMintAddress(this.farmMintAddress) || {};
    const coinDepositsValue =
      coinDeposits.toNumber() / Math.pow(10, coin.decimals);
    const pcDepositsValue = pcDeposits.toNumber() / Math.pow(10, pc.decimals);
    const coinPrice = Number(getStore("PriceStore").getTokenPrice(coin.symbol));
    const pcPrice = Number(getStore("PriceStore").getTokenPrice(pc.symbol));
    const { price: farmPrice } =
      getStore("FarmStore").getFarm(this.farmMintAddress) || {};

    return (
      coinDepositsValue * coinPrice +
      pcDepositsValue * pcPrice +
      (this.vaultShareTokens + this.lpTokensValue) * Number(farmPrice)
    );
  }

  get equityValue() {
    return Math.abs(this.positionValue - this.debtValue);
  }

  get debtValue() {
    let debtValue = 0;
    const { borrows } = this;
    const reserveInfo = getLendingReserveByAccount(
      borrows[0]?.borrowReserve?.toBase58()
    );
    const reserve = getReserveByName(reserveInfo?.name);
    const { getReserve } = getStore("ReserveStore");
    const { newCumulativeBorrowRate } = getReserve(reserve?.mintAddress) || {};

    borrows.forEach((borrow) => {
      const reserveInfo = getLendingReserveByAccount(
        borrow.borrowReserve.toBase58()
      );
      const reservePrice = Number(
        getStore("PriceStore").getTokenPrice(reserveInfo.name)
      );
      const reserve = getReserveByName(reserveInfo.name);
      const borrowedAmount = new TokenAmount(
        borrow.borrowedAmountWads.div(WAD),
        reserve.decimals
      );
      const oldBorrowRate = new TokenAmount(borrow.cumulativeBorrowRateWads);
      const newBorrowRate = new TokenAmount(newCumulativeBorrowRate);
      let borrowDebtValue = borrowedAmount.wei
        .times(newBorrowRate.wei)
        .div(oldBorrowRate.wei)
        .div(Math.pow(10, reserve.decimals));
      debtValue += borrowDebtValue.times(reservePrice).toNumber();
    });

    return debtValue;
  }

  get borrowedReserve() {
    let borrowedReserve;
    const { borrows } = this;

    borrows.forEach((borrow) => {
      const reserveInfo = getLendingReserveByAccount(
        borrow.borrowReserve.toBase58()
      );
      const reserve = getReserveByName(reserveInfo.name);
      const borrowedAmount = new TokenAmount(
        borrow.borrowedAmountWads.div(WAD),
        reserve.decimals
      );

      // Store which reserve was borrowed if there is a borrowAmount
      if (borrowedAmount) {
        borrowedReserve = reserve;
      }
    });

    return borrowedReserve;
  }

  get borrowedAmount() {}

  get borrowedAmountInLp() {
    let borrowedAmountInLp;
    const { borrows } = this;
    const reserveInfo = getLendingReserveByAccount(
      borrows[0]?.borrowReserve?.toBase58()
    );
    const reserve = getReserveByName(reserveInfo?.name);
    const { getReserve } = getStore("ReserveStore");
    const { newCumulativeBorrowRate } = getReserve(reserve?.mintAddress) || {};

    borrows.forEach((borrow) => {
      const reserveInfo = getLendingReserveByAccount(
        borrow.borrowReserve.toBase58()
      );
      const reserve = getReserveByName(reserveInfo.name);
      const borrowedAmount = new TokenAmount(
        borrow.borrowedAmountWads.div(WAD),
        reserve.decimals
      );
      const oldBorrowRate = new TokenAmount(borrow.cumulativeBorrowRateWads);
      const newBorrowRate = new TokenAmount(newCumulativeBorrowRate);
      let borrowDebtValue = borrowedAmount.wei
        .times(newBorrowRate.wei)
        .div(oldBorrowRate.wei)
        .div(Math.pow(10, reserve.decimals));

      // Store borrowedAmountInLp if there is a borrowedAmount
      if (borrowedAmount) {
        borrowedAmountInLp = borrowDebtValue.toNumber();
      }
    });

    return borrowedAmountInLp;
  }

  get debtRatio() {
    return (this.debtValue / this.positionValue) * 100;
  }

  get leverageValue() {
    return this.positionValue / this.equityValue;
  }

  get cumulativeBorrowRateWads() {
    let cumulativeBorrowRateWads;
    const { borrows } = this;

    borrows.forEach((borrow) => {
      const reserveInfo = getLendingReserveByAccount(
        borrow.borrowReserve.toBase58()
      );
      const reserve = getReserveByName(reserveInfo.name);
      const borrowedAmount = new TokenAmount(
        borrow.borrowedAmountWads.div(WAD),
        reserve.decimals
      );

      // Store cumulativeBorrowRateWads if there is a borrowAmount
      if (borrowedAmount) {
        cumulativeBorrowRateWads = borrow.cumulativeBorrowRateWads;
      }
    });

    return cumulativeBorrowRateWads;
  }

  get lpTokensValue() {
    const { lpTokens } = this;
    const { decimals } = getFarmByMintAddress(this.farmMintAddress) || {};
    const lpTokensDecimals = Math.pow(10, decimals);

    return lpTokens.toNumber() / lpTokensDecimals;
  }

  get vaultShareTokens() {
    const { vaultShares } = this;
    const { decimals } = getFarmByMintAddress(this.farmMintAddress) || {};
    const lpTokensDecimals = Math.pow(10, decimals);
    const { totalVaultBalance, totalVlpShares } =
      getStore("FarmStore").getFarm(this.farmMintAddress) || {};

    return (
      vaultShares.mul(totalVaultBalance).div(totalVlpShares).toNumber() /
      lpTokensDecimals
    );
  }

  get rewardSinceLastDeposit() {
    const { lastDepositedAmount } = this;
    const { price: farmPrice } =
      getStore("FarmStore").getFarm(this.farmMintAddress) || {};
    const lpTokenPositionValue =
      (this.vaultShareTokens + this.lpTokensValue) * Number(farmPrice);

    return lpTokenPositionValue - lastDepositedAmount * farmPrice;
  }
  //#endregion
}

export default class ObligationStore {
  constructor() {
    this.obligations = new Map();

    makeObservable(this, {
      obligations: observable,
      obligationsList: computed,
    });
  }

  /**
   * @description Add obligation(s)
   *
   * @param {Object[]} obligations Array of Obligations
   */
  add(obligations) {
    if (isNil(obligations)) {
      return;
    }

    if (!Array.isArray(obligations)) {
      obligations = [obligations];
    }

    obligations.forEach((obligation) => {
      const currentObligationItem = this.get(getObligationId(obligation));

      if (currentObligationItem) {
        return currentObligationItem.update(obligation);
      }

      this.obligations.set(
        getObligationId(obligation),
        new ObligationItemStore(obligation)
      );
    });
  }

  /**
   * @description Get a single obligation
   *
   * @param {String} obligationId ID of the Obligation
   *
   * @returns {ObligationItemStore} Obligation item
   */
  get(obligationId) {
    if (!obligationId) {
      return;
    }

    return this.obligations.get(obligationId);
  }

  /**
   * @description Get all obligations for a single farm
   *
   * @param {String} farmMintAddress `mintAddress` of the Farm
   *
   * @returns {ObligationItemStore[]} Array of Obligations
   */
  getObligationsForFarm(farmMintAddress) {
    if (!farmMintAddress) {
      return [];
    }

    const obligations = [];

    this.obligations.forEach((obligation) => {
      if (obligation.farmMintAddress === farmMintAddress) {
        obligations.push(obligation);
      }
    });

    return obligations;
  }

  /**
   * @description Get all opened obligations for a single farm
   *
   * @param {String} farmMintAddress `mintAddress` of the Farm
   *
   * @returns {ObligationItemStore[]} Array of Opened Obligations
   */
  getOpenedObligationsForFarm(farmMintAddress) {
    if (!farmMintAddress) {
      return [];
    }

    const obligations = [];

    this.obligations.forEach((obligation) => {
      if ((obligation.farmMintAddress === farmMintAddress) && obligation.isOpened) {
        obligations.push(obligation);
      }
    });

    return obligations;
  }

  /**
   * @description Obligations to show on the UI
   *
   * @returns {ObligationItemStore[]} Array of Obligations
   */
  get obligationsList() {
    const obligations = [];

    this.obligations.forEach((obligation) => {
      if (obligation.isOpened) {
        obligations.push(obligation);
      }
    });

    return obligations;
  }

  /**
   * @description Delete a single obligation from the store
   *
   * @param {String} obligationId ID of the Obligation
   *
   */
  delete(obligationId) {
    if (!obligationId) {
      return;
    }

    this.obligations.delete(obligationId);
  }

  /**
   * @description Clear all Obligations
   */
  clear() {
    this.obligations.clear();
  }
}
