/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { CSSTransitionGroup } from 'react-transition-group';

// Icons
import refreshIcon from '../../icons/refresh.svg';
import infoIcon from '../../icons/info.svg';
import tulipIcon from '../../icons/SolFarmLogo.png';
import errorIcon from '../../icons/error.svg';
import upDownCaretIcon from '../../icons/up-down-caret.svg';
import upArrow from '../../icons/up-arrow.svg';
import downArrow from '../../icons/down-arrow.svg';
import downCaret from '../../icons/down-caret.svg';

// Store
import { getStore } from '../../stores/get-store';

// Components
import IconWithTooltip from '../../base-components/IconWithTooltip';
import { Loader } from '../../base-components/Loader';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Tooltip } from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Button from '../../base-components/Button';

// Lodash
import { isNil, debounce, isFunction, isEmpty, orderBy, map, isNaN } from 'lodash';

// Utils
import { getFormattedNumber } from '../../utils/safe-math';
import {NATIVE_SOL, TOKENS} from '../../utils/tokens';
import { PageHeading } from '../PageHeading';
import LeverageFarmingModal from './LeverageFarmingModal';
import CustomNumberInput from '../../base-components/CustomNumberInput';
import { getDefaultSelectedCoinIndex } from '../../utils/leverageFarmUtils';
import SelectDropdown from '../../base-components/SelectDropdown';

// Constants
const CustomSlider = withStyles({
  root: {
    // color: '#52af77',
    height: 4,
    // margin: '8px 4px'
    marginLeft: 4
  },
  thumb: {
    height: 16,
    width: 16,
    // backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -6,
    marginLeft: -8,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% - 4px)',
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
})(Slider);

const ZERO = 0;

const tableColumns = ['APY', 'Yield (APR)', 'Leverage'],
  sortableColumns = ['APY'],
  mobileSortableColumns = [
    {
      label: 'APY',
      value: 'APY'
    }
  ],
  disabledColumnsWithoutConnection = [],
  SORT_ORDER = ['desc', 'asc', null],
  headerToPropertyMap = {
    'Asset': 'tvlInNumber',
    'APY': 'dailyAPRInNumber'
  },
  feeText = `Controller Fee: 0.01% 
  Platform fee: 0.1% 
  Deposit Fee: 0.0%
  Withdrawal Fee: 0.0%`,
  transactionToast = (tx) => (
    <div style={{ fontSize: '12px'}}>
      <div style={{ marginBottom: '8px', fontWeight: '600' }}>Transaction has been sent</div>
      <div>Confirmation is in progress. Check your transaction on <a className='link' href={`https://explorer.solana.com/tx/${tx}`} target='_blank'>here</a>.</div>
    </div>
  ),
  getRefreshTooltip = (timeToRefresh) => `Auto refresh in ${timeToRefresh} seconds, you can click to update manually\nAutomatically refreshes when the current pool had changed`,
  getRewardTooltipContent = (amountInTokens, amountInUsd) => {
    return (
      <div>
        <div style={{ marginBottom: '0.5rem' }}>
          Rewards since last deposit/withdraw
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ fontWeight: '600' }}>
            {amountInTokens}
          </span>
          <span style={{ fontSize: '0.8rem', marginLeft: '0.3rem' }}>
            (${amountInUsd})
          </span>
        </div>
      </div>
    )
  };

const NUMBER_OF_PERIODS_IN_A_WEEK = 24 * 7,
  NUMBER_OF_PERIODS_IN_A_YEAR = 24 * 365;

const getAPY = (periodicRate, numberOfPeriods) => {
  return (Math.pow((1 + (periodicRate/100)), numberOfPeriods) - 1);
}

class LeverageFarmingTableHeader extends React.Component {
  constructor () {
    super();

    this.handleRefresh = this.handleRefresh.bind(this);
    this.getTooltipText = this.getTooltipText.bind(this);
    this.handleHeaderClick = this.handleHeaderClick.bind(this);
  }

  componentDidMount () {
    this.intervalId = setInterval(() => {
      const { wallet } = getStore('WalletStore'),
        { timeToRefresh, setTimeToRefresh } = getStore('UIStore');

      if (!wallet) {
        return;
      }

      if (timeToRefresh - 1) {
        return setTimeToRefresh(timeToRefresh - 1);
      }

      this.handleRefresh(null, true);
    }, 1000);
  }

  componentWillUnmount () {
    this.intervalId && clearInterval(this.intervalId);
  }

  async handleRefresh (e, silent = false) {

    // If current tab is not visible, then don't refresh
    if (window?.document?.hidden) {
      return;
    }

    const { wallet } = getStore('WalletStore'),
      { setIsRefreshing, resetRefreshState } = getStore('UIStore');

    if (!wallet) {
      return;
    }

    setIsRefreshing(true);

    await Promise.all([
      getStore('WalletStore').setTokenAccounts(),
      getStore('FarmStore').setPrice(),
      getStore('ReserveStore').init(),
      getStore('PriceStore').init()
    ]);

    !silent && this.props.toast('Values refreshed.');
    resetRefreshState();
  }

  getTooltipText () {
    const { wallet } = getStore('WalletStore'),
      { timeToRefresh } = getStore('UIStore');

    if (!wallet) {
      return 'Wallet not connected';
    }

    // return `Refreshing in ${this.state.timeToRefresh}s`;
    return getRefreshTooltip(timeToRefresh);
  }

  handleHeaderClick (column) {
    const { wallet } = getStore('WalletStore');

    if (!wallet && disabledColumnsWithoutConnection.includes(column)) {
      return;
    }

    if (!sortableColumns.includes(column)) {
      return;
    }

    this.props.setSortBy(headerToPropertyMap[column]);
  }

  getFilterDropdown ({ title, placeholder, items, selectedItems, onClick }) {
    return (
      <SelectDropdown
        className='leverage-farming-table__header-sort-by'
        disableSearch
        right
        title={title}
        placeholder={placeholder}
        isSelected={(value) => this.props.sortedBy === headerToPropertyMap[value]}
        items={items}
        itemRender={({ label, value }) => {
          return (
            <div>
              {<img src={
                this.props.sortedBy === headerToPropertyMap[value] ?
                (
                  this.props.sortOrder === 'asc' ? upArrow : downArrow
                ) :
                upDownCaretIcon
              } height='16px' width='16px' alt='farm-logo'/> }
              {label?.toUpperCase()}
            </div>
          );
        }}
        onClick={onClick}
      />
    )
  }

  render () {
    const { wallet } = getStore('WalletStore'),
      { isRefreshing, isSilentRefresh } = getStore('UIStore'),
      { sortedBy, sortOrder } = this.props,
      { isMobile } =  getStore('ResponsiveStore');

    return (
      <div className='leverage-farming-table__header'>
        <div
          className={
            classnames(
              'leverage-farming-table__header-asset',
              {
                'sortable': sortableColumns.includes('Asset')
              }
            )
          }
          onClick={this.handleHeaderClick.bind(this, 'Asset')}
        >
          Vault
          {/* {
            <div
              className='leverage-farming-table__header-cell__sort-btn'
            >
              <img src={
                sortedBy === headerToPropertyMap['Asset'] ?
                (
                  sortOrder === 'asc' ? upArrow : downArrow
                ) :
                upDownCaretIcon
              } />
            </div>
          } */}
        </div>
        {
          isMobile &&
            this.getFilterDropdown({
              title: 'Sort By',
              items: mobileSortableColumns,
              onClick: this.handleHeaderClick
            })
        }
        {
          !isMobile && tableColumns.map((tableColumn) => (
            <div
              key={tableColumn}
              className={
                classnames(
                  'leverage-farming-table__header-cell',
                  {
                    'sortable': sortableColumns.includes(tableColumn),
                    'disabled': !wallet && disabledColumnsWithoutConnection.includes(tableColumn),
                    'yield-apr': tableColumn === 'Yield (APR)'
                  }
                )
              }
              onClick={this.handleHeaderClick.bind(this, tableColumn)}
            >
              {tableColumn}
              {
                sortableColumns.includes(tableColumn) &&
                <div
                  className='leverage-farming-table__header-cell__sort-btn'
                >
                  <img src={
                    sortedBy === headerToPropertyMap[tableColumn] ?
                    (
                      sortOrder === 'asc' ? upArrow : downArrow
                    ) :
                    upDownCaretIcon
                  } />
                </div>
              }
              {
                tableColumn === 'APY' &&
                <IconWithTooltip
                  icon={infoIcon}
                  className='leverage-farming-table__header-cell__info-btn'
                  tooltipText='Yearly APY is indicative and should not be used as a performance measure.'
                  placement='bottom'
                />
              }
            </div>
          ))
        }
        <div
          onClick={this.handleRefresh}
          className={
            classnames(
              'leverage-farming-table__header-refresh',
              {
                'disabled': !wallet
              }
            )
          }
        >
          <IconWithTooltip
            icon={refreshIcon}
            className={
              classnames(
                'leverage-farming-table__header-refresh__btn',
                {
                  'is-refreshing': isRefreshing || isSilentRefresh
                }
              )
            }
            tooltipText={this.getTooltipText()}
          />
        </div>
      </div>
    );
  }
}

class LeverageFarmingTableRow extends React.Component {
  constructor () {
    super();

    this.state = {
      isExpanded: false,
      withdrawValue: '',
      isDepositing: false,
      isWithdrawing: false,
      withdrawSliderValue: 0,
      depositSliderValue: 0,
      isLiquidityMiningInfoTooltipVisible: false,
      isErrorTooltipVisible: false,
      isHarvesting: false,
      isCoinSelectorOpen: false
    };

    this.rowRef = React.createRef();

    this.toggleRow = this.toggleRow.bind(this);
    this.handleDeposit = this.handleDeposit.bind(this);
    this.handleLeverageChange = this.handleLeverageChange.bind(this);
    this.handleWithdraw = this.handleWithdraw.bind(this);
    this.handleWithdrawWithWarnings = this.handleWithdrawWithWarnings.bind(this);
    this.handleWithdrawChange = this.handleWithdrawChange.bind(this);
    this.handleDepositSliderChange = this.handleDepositSliderChange.bind(this);
    this.handleWithdrawSliderChange = this.handleWithdrawSliderChange.bind(this);
    this.handleFixTokenAccount = this.handleFixTokenAccount.bind(this);
    this.handleTulipHarvest = this.handleTulipHarvest.bind(this);

    this.debouncedWithdrawSliderChange = debounce(this.handleWithdrawSliderChange, 300);
    this.debouncedDepositSliderChange = debounce(this.handleDepositSliderChange, 100);

    this.toggleLiquidityMiningInfoTooltip = this.toggleLiquidityMiningInfoTooltip.bind(this);
    this.toggleErrorTooltip = this.toggleErrorTooltip.bind(this);

    this.toggleCoinSelectorOpen = this.toggleCoinSelectorOpen.bind(this);
  }

  handleCoinSelect (selectedCoinIndex) {
    const { setLeverageVault } = getStore('LeverageVaultStore'),
      { mintAddress, borrowDisabledCoinIndex } = this.props.data;

    if (selectedCoinIndex === borrowDisabledCoinIndex) {
      return;
    }

    setLeverageVault(mintAddress, { selectedCoinIndex });
  }

  toggleCoinSelectorOpen () {
    this.setState((prevState) => ({
      isCoinSelectorOpen: !prevState.isCoinSelectorOpen
    }));
  }

  toggleLiquidityMiningInfoTooltip (value) {
    const isLiquidityMiningInfoTooltipVisible = isNil(value) ? !this.state.isLiquidityMiningInfoTooltipVisible : value;

    this.setState({ isLiquidityMiningInfoTooltipVisible });
  }

  toggleErrorTooltip (value) {
    const isErrorTooltipVisible = isNil(value) ? !this.state.isErrorTooltipVisible : value;

    this.setState({ isErrorTooltipVisible });
  }

  handleWithdrawSliderChange (e, value) {
    let { deposited, decimals } = this.props.data || {};

    deposited = Number(deposited);

    const withdrawValue = !value ? '' : Math.min(deposited, ((deposited * value)/100)).toFixed(decimals);

    this.setState({
      withdrawSliderValue: value,
      withdrawValue
    });
  }

  handleDepositSliderChange (e, value) {
    let { balance, decimals } = this.props.data || {};

    balance = Number(balance);

    const depositValue = !value ? '' : Math.min(balance, ((balance * value)/100)).toFixed(decimals);

    this.setState({
      depositSliderValue: value,
      depositValue
    });
  }

  toggleRow () {
    // Only allow the expanded view in mobile mode
    if (!getStore('ResponsiveStore').isMobile) {
      return;
    }

    this.setState((prevState) => {
      return {
        isExpanded: !prevState.isExpanded
      };
    }, () => {
      if (getStore('ResponsiveStore').isMobile) {
        this.state.isExpanded && this.rowRef?.current?.scrollIntoView(true);
      }
    });
  }

  async handleDeposit () {
    this.setState({ isDepositing: true });

    try {
      const tx = await this.props.onDeposit(this.state.depositValue);

      this.props.toast(transactionToast(tx));
  
      this.setState({ isDepositing: false });
      this.handleDepositChange({ target: { value: '' } });
    } catch (err) {

      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleDeposit~err:', err);

      this.setState({ isDepositing: false });
    }
  }

  async handleTulipHarvest () {
    this.setState({ isHarvesting: true });

    try {
      const tx = await this.props.onTulipHarvest();

      this.props.toast(transactionToast(tx));
  
      this.setState({ isHarvesting: false });
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleTulipHarvest~err:', err);

      this.setState({ isHarvesting: false });
    }
  }

  handleLeverageChange (e) {
    let newValue = e?.target?.value,
      { setLeverageValue } = getStore('LeverageVaultStore'),
      { mintAddress } = this.props.data;

    setLeverageValue(mintAddress, newValue);
  }

  async handleWithdrawWithWarnings () {
    const isWithdrawUnsafe = await this.props.checkUnsafeWithdraw();

    if (isWithdrawUnsafe) {
      this.props.toggleModal(true);

      return this.props.setSuccessCallback(this.handleWithdraw);
    }

    return this.handleWithdraw();
  }

  async handleWithdraw () {
    this.setState({ isWithdrawing: true });

    // this.props.toast(`Withdraw Successful: ${tx}`);

    // Making transaction

    // Transaction has been sent
    // Confirmation is in progress. Check your transaction on here.

    // Transaction has been confirmed
    // Stake 0.000200 RAY-SOL LP

    const withdrawMax = (this.state.withdrawSliderValue === 100);

    try {
      const tx = await this.props.onWithdraw(this.state.withdrawValue, withdrawMax);

      this.props.toast(transactionToast(tx));
  
      this.setState({ isWithdrawing: false });
      this.handleWithdrawChange({ target: { value: '' } });
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleWithdraw~err:', err);

      this.setState({ isWithdrawing: false });
    }
  }

  handleWithdrawChange (e) {
    let newValue = e?.target?.value,
      { deposited } = this.props.data || {},
      valueForSlider = (newValue === '') ? 0 : newValue;

    deposited = Number(deposited);

    this.setState({
      withdrawValue: newValue,
      withdrawSliderValue: !deposited ? 0 : Math.round((valueForSlider * 100)/deposited)
    });
  }

  async handleFixTokenAccount (e) {
    // Stop bubbling of click event, to prevent list-item from expanding/collapsing
    e && e.stopPropagation();

    try {
      const tx = await this.props.onFixTokenAccount();

      this.props.toast(transactionToast(tx));
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleFixTokenAccount~err:', err);
    }
  }

  render () {
    const {
      assetName,
      tvl,
      deposited,
      balance,
      // dailyAPR,
      weeklyAPY,
      yearlyAPY,
      yearlyAPYInNumber,
      dailyAPRInNumber,
      logos,
      price,
      dualYield,
      decimals,
      rewardSinceLastDeposit,
      isUserBalanceAccountValid,
      liquidityMining,
      tulipAPR = 0,
      isTokenAccountMissing,
      tulipEarned,
      disabled,
      coins,
      coinBorrowingInterest,
      pcBorrowingInterest,
      tradingFees = 0,
      mintAddress,
      whitelisted,
      borrowDisabledCoinIndex
    } = this.props.data || {},
    {
      isExpanded,
      withdrawValue,
      isDepositing,
      isWithdrawing,
      withdrawSliderValue,
      depositSliderValue,
      isHarvesting,
      isCoinSelectorOpen
    } = this.state,
    { wallet, tokenAccounts } = getStore('WalletStore'),
    { getLeverageVault } = getStore('LeverageVaultStore'),
    {
      selectedCoinIndex = getDefaultSelectedCoinIndex(borrowDisabledCoinIndex),
      leverageValue = 3
    } = getLeverageVault(mintAddress) || {},
    // isDepositDisabled = (
    //   !wallet || !balance || isDepositing || !depositValue || !Number(depositValue) || Number(depositValue) > Number(balance) || disabled
    // ),
    // isWithdrawDisabled = (
    //   !wallet || !deposited || isWithdrawing || !withdrawValue || !Number(withdrawValue) || Number(withdrawValue) > Number(deposited)
    // ),
    // isHarvestDisabled = !wallet || !Number(tulipEarned?.toFixed(6)) || isHarvesting,
    // depositedInUsd = deposited === '-' ? '' : deposited * price,
    // balanceInUsd = balance === '-' ? '' : balance * price,
    // rewardSinceLastDepositInUsd = rewardSinceLastDeposit * price,
    { isRefreshing } = getStore('UIStore'),
    { isMobile } = getStore('ResponsiveStore');


    const adjustedCoinBorrowingInterest = coinBorrowingInterest * (leverageValue - 1),
      adjustedPcBorrowingInterest = pcBorrowingInterest * (leverageValue - 1);

    const totalAPR = (
            (((dailyAPRInNumber * 365) + tradingFees + tulipAPR) * leverageValue) - (selectedCoinIndex === 0 ? adjustedCoinBorrowingInterest : adjustedPcBorrowingInterest)
      ),
      dailyAPR = totalAPR / 365;

    // console.log("$$$ APR", assetName, dailyAPRInNumber, totalAPR, dailyAPR)
    const initialTotalAPY = (
      (100 * getAPY(dailyAPRInNumber/(24), NUMBER_OF_PERIODS_IN_A_YEAR)) + tradingFees + tulipAPR
    );

    let lowSolBalance = false;
    if (wallet && tokenAccounts[NATIVE_SOL.mintAddress]) {
      lowSolBalance = Number(tokenAccounts[NATIVE_SOL.mintAddress].balance.fixed()) < 0.05;
    }
    const adjustedLeverageAPY = (100 * getAPY((dailyAPRInNumber * leverageValue)/(24), NUMBER_OF_PERIODS_IN_A_YEAR));

    const adjustedTotalAPY = (adjustedLeverageAPY) + (leverageValue * (tradingFees + tulipAPR)) - (selectedCoinIndex === 0 ? adjustedCoinBorrowingInterest : adjustedPcBorrowingInterest);

    // console.log("$$$ APY", assetName, initialTotalAPY, adjustedLeverageAPY, adjustedTotalAPY)

    return (
      <div
        className={`leverage-farming-table__row ${isExpanded ? 'is-expanded': ''} ${!liquidityMining ? 'no-harvest' : ''}`}
        ref={this.rowRef}
      >
        <div onClick={this.toggleRow} className='leverage-farming-table__row-item'>

          {/* Asset Details */}
          <div className='leverage-farming-table__row-item__asset'>
            <div className='leverage-farming-table__row-item__asset__logos'>
              {
                logos.map((logo, index) => (
                  <img key={index} src={logo} className='leverage-farming-table__row-item__asset__logos-item' />
                ))
              }
            </div>
            <div className='leverage-farming-table__row-item__asset__text'>
              <div className='leverage-farming-table__row-item__asset__text-name'>
                {assetName}
                {
                  dualYield &&
                  <IconWithTooltip
                    icon={infoIcon}
                    className='leverage-farming-table__header-cell__info-btn'
                    tooltipText='Dual Yield'
                  />
                }
                {/* {
                  !isMobile && liquidityMining &&
                  <div
                    className='leverage-farming-table__header-cell__tulip-badge'
                    onMouseOver={this.toggleLiquidityMiningInfoTooltip.bind(this, true)}
                    onMouseLeave={this.toggleLiquidityMiningInfoTooltip.bind(this, false)}
                    id={`tulipIcon${this.props.rowIndex}`}
                  >
                    <img src={tulipIcon} />
                    <span>{getFormattedNumber(tulipAPR)}% APR</span>
                  </div>
                } */}
              </div>
              <div className='leverage-farming-table__row-item__asset__text-tvl'>
                TVL: { isRefreshing ? <Loader /> : (<span>$<span>{tvl}</span></span>) }
              </div>
            </div>
          </div>

          {
            isMobile && !disabled && !isExpanded &&
              <div className='leverage-farming-table__row-item__cell mobile-details'>
                {/* APY */}
                <div>
                  APY
                  {adjustedTotalAPY ? (
                    <span>
                      <span className='adjusted-apy'>{getFormattedNumber(adjustedTotalAPY)}</span> %
                    </span>
                  ) : '-'}
                </div>

                {/* APR */}
                {/* TODO: Fix this section of showing APR */}
                {/* {
                  liquidityMining &&
                  <span
                    className='leverage-farming-table__header-cell__tulip-badge'
                    onMouseOver={this.toggleLiquidityMiningInfoTooltip.bind(this, true)}
                    onMouseLeave={this.toggleLiquidityMiningInfoTooltip.bind(this, false)}
                    id={`tulipIcon${this.props.rowIndex}`}
                  >
                    <img src={tulipIcon} />
                    <span>{getFormattedNumber(tulipAPR)}% APR</span>
                  </span>
                } */}
              </div>
          }

          {/* Item Toggle */}
          {
            isMobile &&
              <div className='leverage-farming-table__row-item__toggle'>
                <div className='leverage-farming-table__row-item__toggle__btn'>
                  <img src={downCaret} />
                </div>
              </div>
          }

          {/* APY */}
          {
            !isMobile &&
              <div className='leverage-farming-table__row-item__cell'>
                {/* {
                  isRefreshing ? <Loader /> :
                  (yearlyAPY ? `${yearlyAPY} %` : '-')
                } */}
                <div className='adjusted-apy'>{adjustedTotalAPY ? (<span><span>{getFormattedNumber(adjustedTotalAPY)}</span> %</span>) : '-'}</div>
                {
                  (leverageValue > 1) && (!isNaN(initialTotalAPY)) &&
                  <div className='initial-apy'>{initialTotalAPY ? (<span><span>{getFormattedNumber(initialTotalAPY)}</span> %</span>) : '-'}</div>
                }
              </div>
          }

          {/* Yield (APR) */}
          {
            !isMobile &&
              <div className={
                classnames(
                  'leverage-farming-table__row-item__cell',
                  'yield'
                )
              }>
                {/* {
                  isRefreshing ? <Loader /> :
                  (dailyAPR ? `${dailyAPR} %` : '-')
                } */}
                {/* {dailyAPR ? (<span><span>{dailyAPR}</span> %</span>) : '-'} */}
                <div className='yield-line'>
                  <div className='yield-line-name'>
                    Yield Farming:
                  </div>
                  <div className='yield-line-value'>
                    {getFormattedNumber(dailyAPRInNumber * 365 * leverageValue)}%
                  </div>
                </div>
                <div className='yield-line'>
                  <div className='yield-line-name'>
                    Trading Fees:
                  </div>
                  <div className='yield-line-value'>
                    {getFormattedNumber(tradingFees * leverageValue)}%
                  </div>
                </div>
                <div className='yield-line'>
                  <div className='yield-line-name'>
                    Tulip Reward:
                  </div>
                  <div className='yield-line-value'>
                    {getFormattedNumber(tulipAPR * leverageValue)}%
                  </div>
                </div>
                <div className='yield-line'>
                  <div className='yield-line-name'>
                    Borrowing Interest:
                  </div>
                  <div className={classnames('yield-line-value', 'selector')}>
                    <Dropdown
                      isOpen={isCoinSelectorOpen}
                      toggle={this.toggleCoinSelectorOpen}
                      // direction={this.state.isEndpointDropdownOpen ? 'up' : 'down'}
                      disabled={!isNil(borrowDisabledCoinIndex)}
                    >
                      <DropdownToggle
                        // caret
                        // outline
                        // color='primary'
                        className='coin-selector'
                        // className={classnames('coin-selector', { 'active': !isEmpty(activeRoute) })}
                      >
                        <span style={{
                          maxWidth: '140px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'pre',
                          display: 'inline-flex',
                          alignItems: 'center'
                        }}>
                          {/* <img src={downCaret} /> */}
                          {
                            !!isNil(borrowDisabledCoinIndex) &&
                              <img
                                src={downCaret}
                                style={{
                                  transform: isCoinSelectorOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
                                  transition: 'transform 0.3s',
                                  height: '14px'
                                }}
                              />
                          }
                          <span
                            style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'pre',
                            margin: '2px 0px 2px 8px',
                            display: 'inline-flex',
                            alignItems: 'center'
                          }}>
                            <img style={{ height: '14px', margin: '0 4px 0 0' }} src={logos[selectedCoinIndex]} />
                            <span style={{ margin: '0 8px 0 0' }}>{coins[selectedCoinIndex].symbol}</span>
                            <span>
                              {leverageValue > 1 ? '-' : null}
                              {selectedCoinIndex === 0 ? getFormattedNumber(adjustedCoinBorrowingInterest) : getFormattedNumber(adjustedPcBorrowingInterest)}
                              %
                            </span>
                          </span>
                        </span>
                      </DropdownToggle>
                      <DropdownMenu right>
                        {
                          map(coins, (coin, index) => (
                            <DropdownItem
                              onClick={this.handleCoinSelect.bind(this, index)}
                              key={index}
                              style={{
                                padding: '0.3rem 1rem 0.3rem 1rem',
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '0.8rem',
                                textAlign: 'right',
                                // justifyContent: 'flex-end'
                              }}
                            >
                              <img style={{ height: '14px', width: '14px', margin: '0 4px 0 0', justifySelf: 'flex-start' }} src={logos[index]} />
                              <span style={{ margin: '0 8px 0 0', justifySelf: 'flex-start' }}>{coin.symbol}</span>
                              <span style={{ marginLeft: 'auto' }}>
                                {leverageValue > 1 ? '-' : null}
                                {index === 0 ? getFormattedNumber(adjustedCoinBorrowingInterest) : getFormattedNumber(adjustedPcBorrowingInterest)}
                                %
                              </span>
                            </DropdownItem>
                          ))
                        }
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
                <div className='yield-line'>
                  <div className='yield-line-name'>
                    Total APR:
                  </div>
                  <div className={classnames('yield-line-value', 'total')}>
                    {getFormattedNumber(totalAPR)}%
                  </div>
                </div>
                <div className='yield-line'>
                  <div className='yield-line-name'>
                    Daily APR:
                  </div>
                  <div className='yield-line-value'>
                    {getFormattedNumber(dailyAPR)}%
                  </div>
                </div>
              </div>
          }

          {/* Leverage */}
          {
            !isMobile &&
              <div className={
                classnames(
                  'leverage-farming-table__row-item__cell',
                  'leverage'
                )
              }>
                {/* {
                  isRefreshing ? <Loader /> :
                  (weeklyAPY ? `${weeklyAPY} %` : '-')
                } */}
                {/* {weeklyAPY ? (<span><span>{weeklyAPY}</span> %</span>) : '-'} */}
                {/* <Input
                  type='number'
                  placeholder='Enter deposit amount...'
                  value={leverageValue}
                  onChange={this.handleLeverageChange}
                  step={0.5}
                  // disabled={!wallet || disabled}
                /> */}
                <CustomNumberInput
                  placeholder='Enter deposit amount...'
                  value={leverageValue}
                  onChange={this.handleLeverageChange}
                  step={0.5}
                  // disabled={!wallet || disabled}
                />
              </div>
          }

          {/* Farm Button */}
          {
            !isMobile &&
              <div className={
                classnames(
                  'leverage-farming-table__row-item__cell',
                  'farm'
                )
              }>
                {/* {
                  isRefreshing ? <Loader /> :
                  (weeklyAPY ? `${weeklyAPY} %` : '-')
                } */}
                {/* {weeklyAPY ? (<span><span>{weeklyAPY}</span> %</span>) : '-'} */}
                <Button
                  onClick={this.props.toggleModal}
                  disabled={!wallet || lowSolBalance || whitelisted}
                  disabledTooltip={lowSolBalance && 'Minimum 0.05 SOL balance required'}
                >
                  Farm {leverageValue}x
                </Button>
              </div>
          }
          </div>
        {/* {
          !isMobile && liquidityMining &&
          <Tooltip
            target={`tulipIcon${this.props.rowIndex}`}
            isOpen={this.state.isLiquidityMiningInfoTooltipVisible}
            hideArrow
            placement='bottom'
            innerClassName='leverage-farming-table__tooltip'
          >
            Liquidity Mining
          </Tooltip>
        } */}

        {
          <CSSTransitionGroup
            transitionName='slide-in'
            transitionEnterTimeout={50}
            transitionLeaveTimeout={50}
          >
          {
            isExpanded && isMobile &&
              <div className={`leverage-farming-table__row-expanded ${isExpanded ? '' : 'is-hidden'}`}>
                <div className='leverage-farming-table__row-item__cell mobile-details-expanded'>
                  {/* APY */}
                  <div>
                    APY
                    {adjustedTotalAPY ? (
                      <span>
                        <span className='adjusted-apy'>{getFormattedNumber(adjustedTotalAPY)}</span> %&nbsp;
                        {
                          (leverageValue > 1) && (!isNaN(initialTotalAPY)) &&
                          initialTotalAPY ? (<span className='initial-apy'><span>{getFormattedNumber(initialTotalAPY)}</span> %</span>) : '-'
                        }
                      </span>
                    ) : '-'}
                  </div>

                  {/* Yield (APR) */}
                  <div className='yield-container'>
                    <div className='yield-line'>
                        <div className='yield-line-name'>
                          Yield Farming:
                        </div>
                        <div className='yield-line-value'>
                          {getFormattedNumber(dailyAPRInNumber * 365 * leverageValue)}%
                        </div>
                      </div>
                      <div className='yield-line'>
                        <div className='yield-line-name'>
                          Trading Fees:
                        </div>
                        <div className='yield-line-value'>
                          {getFormattedNumber(tradingFees * leverageValue)}%
                        </div>
                      </div>
                      <div className='yield-line'>
                        <div className='yield-line-name'>
                          Tulip Reward:
                        </div>
                        <div className='yield-line-value'>
                          {getFormattedNumber(tulipAPR * leverageValue)}%
                        </div>
                      </div>
                      <div className='yield-line'>
                        <div className='yield-line-name'>
                          Borrowing Interest:
                        </div>
                        <div className={classnames('yield-line-value', 'selector')}>
                          <Dropdown
                            isOpen={isCoinSelectorOpen}
                            toggle={this.toggleCoinSelectorOpen}
                            // direction={this.state.isEndpointDropdownOpen ? 'up' : 'down'}
                            disabled={!isNil(borrowDisabledCoinIndex)}
                          >
                            <DropdownToggle
                              // caret
                              // outline
                              // color='primary'
                              className='coin-selector'
                              // className={classnames('coin-selector', { 'active': !isEmpty(activeRoute) })}
                            >
                              <span style={{
                                maxWidth: '140px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'pre',
                                display: 'inline-flex',
                                alignItems: 'center'
                              }}>
                                {/* <img src={downCaret} /> */}
                                <img
                                  src={downCaret}
                                  style={{
                                    transform: isCoinSelectorOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s',
                                    height: '14px'
                                  }}
                                />
                                <span
                                  style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'pre',
                                  margin: '2px 0px 2px 8px',
                                  display: 'inline-flex',
                                  alignItems: 'center'
                                }}>
                                  <img style={{ height: '14px', margin: '0 4px 0 0' }} src={logos[selectedCoinIndex]} />
                                  <span style={{ margin: '0 8px 0 0' }}>{coins[selectedCoinIndex].symbol}</span>
                                  <span>
                                    {leverageValue > 1 ? '-' : null}
                                    {selectedCoinIndex === 0 ? getFormattedNumber(adjustedCoinBorrowingInterest) : getFormattedNumber(adjustedPcBorrowingInterest)}
                                    %
                                  </span>
                                </span>
                              </span>
                            </DropdownToggle>
                            <DropdownMenu right>
                              {
                                map(coins, (coin, index) => (
                                  <DropdownItem
                                    onClick={this.handleCoinSelect.bind(this, index)}
                                    key={index}
                                    style={{
                                      padding: '0.3rem 1rem 0.3rem 1rem',
                                      display: 'flex',
                                      alignItems: 'center',
                                      fontSize: '0.8rem',
                                      textAlign: 'right',
                                      // justifyContent: 'flex-end'
                                    }}
                                  >
                                    <img style={{ height: '14px', width: '14px', margin: '0 4px 0 0', justifySelf: 'flex-start' }} src={logos[index]} />
                                    <span style={{ margin: '0 8px 0 0', justifySelf: 'flex-start' }}>{coin.symbol}</span>
                                    <span style={{ marginLeft: 'auto' }}>
                                      {leverageValue > 1 ? '-' : null}
                                      {index === 0 ? getFormattedNumber(adjustedCoinBorrowingInterest) : getFormattedNumber(adjustedPcBorrowingInterest)}
                                      %
                                    </span>
                                  </DropdownItem>
                                ))
                              }
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className='yield-line'>
                        <div className='yield-line-name'>
                          Total APR:
                        </div>
                        <div className={classnames('yield-line-value', 'total')}>
                          {getFormattedNumber(totalAPR)}%
                        </div>
                      </div>
                      <div className='yield-line'>
                        <div className='yield-line-name'>
                          Daily APR:
                        </div>
                        <div className='yield-line-value'>
                          {getFormattedNumber(dailyAPR)}%
                        </div>
                      </div>
                    </div>


                  {/* Leverage */}
                  <div className='leverage-container'>
                    Leverage
                    <CustomNumberInput
                      placeholder='Enter deposit amount...'
                      value={leverageValue}
                      onChange={this.handleLeverageChange}
                      step={0.5}
                      // disabled={!wallet || disabled}
                    />
                  </div>

                  {/* Farm Button */}
                  <div className='farm-button-container'>
                    <Button
                      onClick={this.props.toggleModal}
                      disabled={!wallet || lowSolBalance || whitelisted}
                      disabledTooltip={lowSolBalance && 'Minimum 0.05 SOL balance required'}
                    >
                      Farm {leverageValue}x
                    </Button>
                  </div>
                </div>
              </div>
          }
          </CSSTransitionGroup>
        }
      </div>
    );
  }
}

const Header = observer(LeverageFarmingTableHeader);
const Row = observer(LeverageFarmingTableRow);

class LeverageFarmingTable extends React.Component {
  constructor () {
    super();

    this.state = {
      isModalOpen: false,
      sortedBy: null,
      sortOrder: '',
      activeLeverageVault: {}
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.setSuccessCallback = this.setSuccessCallback.bind(this);
    this.handleModalSuccess = this.handleModalSuccess.bind(this);
    this.getRowsInOrder = this.getRowsInOrder.bind(this);
    this.setSortBy = this.setSortBy.bind(this);

    this.modalSuccessCallback = null;
  }

  toggleModal (e, leverageVault = {}, value) {
    isNil(value) && (value = !this.state.isModalOpen);

    // if (!isEmpty(leverageVault)) {
    //   // Set default selected coin
    //   const coin = leverageVault.coins[0],
    //     pc = leverageVault.coins[1],
    //     { getReserve } = getStore('ReserveStore'),
    //     { utilization: coinUtilization = 0 } = getReserve(coin?.mintAddress) || {},
    //     { utilization: pcUtilization = 0 } = getReserve(pc?.mintAddress) || {},
    //     { setLeverageVault } = getStore('LeverageVaultStore');

    //   if (Number(coinUtilization) > Number(pcUtilization)) {
    //     setLeverageVault(leverageVault.mintAddress, { selectedCoinIndex: 1 });
    //   } else {
    //     setLeverageVault(leverageVault.mintAddress, { selectedCoinIndex: 0 });
    //   }
    // }

    this.setState({ isModalOpen: value, activeLeverageVault: leverageVault });
  }

  setSuccessCallback (cb) {
    this.modalSuccessCallback = cb;
  }

  handleModalSuccess () {
    this.toggleModal(false);

    isFunction(this.modalSuccessCallback) && this.modalSuccessCallback();

    // Clear success callback after calling it
    this.setSuccessCallback(null);
  }

  getRowsInOrder (data) {
    const { sortedBy, sortOrder } = this.state;

    // If both `sortedBy` and `sortOrder` are not present,
    // then we fallback to the default order
    if (!(sortedBy && sortOrder)) {
      return data;
    }

    return orderBy(data, [sortedBy], [sortOrder]);
  }

  setSortBy (headerName) {
    if (!headerName) {
      return;
    }

    const { sortedBy, sortOrder } = this.state;

    // If the same header is being clicked multiple times,
    // we change it from 'desc' to 'asc' to `null` and so on.
    if (sortedBy === headerName) {
      const currentOrderIndex = SORT_ORDER.indexOf(sortOrder),
        nextOrder = SORT_ORDER[(currentOrderIndex + 1) % 3],
        nextState = { sortOrder: nextOrder };

      // If the next order is `null`, then we also reset `sortedBy`
      !nextOrder && (nextState.sortedBy = null);

      return this.setState(nextState);
    }

    // This is a new header, so we start with 'desc' again
    return this.setState({
      sortedBy: headerName,
      sortOrder: SORT_ORDER[0]
    });
  }

  getEmptyText () {
    const { isRefreshing, isSilentRefresh } = getStore('UIStore');

    if (isRefreshing || isSilentRefresh) {
      return 'Please wait, we\'re refreshing the data. Try a cookie maybe? 🍪';
    }

    if (getStore('LeverageFilterStore').isFilterActive) {
      return 'You have no active farms for the selected filter(s).';
    }

    return 'You have no active farms.';
  }

  render () {
    const {
        data = [],
        onWithdraw,
        onDeposit,
        checkUnsafeWithdraw,
        onFixTokenAccount,
        onTulipHarvest
      } = this.props || {},
      { isRefreshing } = getStore('UIStore'),
      rowsInOrder = this.getRowsInOrder(data),
      {
        sortedBy,
        sortOrder,
        isModalOpen,
        activeLeverageVault
      } = this.state;

    return (
      <div className='leverage-farming-table'>
        <Header
          toast={this.props.toast}
          setSortBy={this.setSortBy}
          sortedBy={sortedBy}
          sortOrder={sortOrder}
        />
        {
          isEmpty(data) ? 
            (
              isRefreshing ?
                <Loader /> :
                <div className='leverage-farming-table__empty'>{this.getEmptyText()}</div>
            ) :
            rowsInOrder.map((row, index) => (
              <Row
                data={row}
                onWithdraw={onWithdraw.bind(this, row.assetSymbol)}
                onDeposit={onDeposit.bind(this, row.assetSymbol)}
                toast={this.props.toast}
                key={row.assetName}
                toggleModal={this.toggleModal.bind(this, null, row)}
                setSuccessCallback={this.setSuccessCallback}
                checkUnsafeWithdraw={checkUnsafeWithdraw.bind(this, row.assetSymbol)}
                rowIndex={index}
                onFixTokenAccount={onFixTokenAccount.bind(this, row.assetSymbol)}
                onTulipHarvest={onTulipHarvest.bind(this, row.assetSymbol)}
              />
            ))
        }
        <LeverageFarmingModal
          isOpen={isModalOpen}
          onToggle={this.toggleModal}
          activeLeverageVault={activeLeverageVault}
          toast={this.props.toast}
          history={this.props.history}
        />
      </div>
    );
  }
}

export default observer(LeverageFarmingTable);
