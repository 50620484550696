/* eslint-disable no-unused-vars */
import { observable, makeObservable, action, computed } from 'mobx';
import { isNil, forEach, assign, find, map, concat, slice, filter, orderBy } from 'lodash';
import {FARMS, LEVERAGE_FARMS} from '../utils/farms';
import { PriceFetcherService } from '../services/PriceFetcherService';
import * as anchor from '@project-serum/anchor';
import { commitment, getMultipleAccounts, getMultipleAccountsGrouped } from '../utils/web3';
import {ACCOUNT_LAYOUT, GLOBAL_FARM_DATA_LAYOUT, MINT_LAYOUT, VAULT_LAYOUT} from '../utils/layouts';
import {
  getFarmAmmId,
  getFarmAmmOpenOrders,
  getFarmSerumProgramId,
  getFarmPoolCoinTokenaccount,
  getFarmPoolId,
  getFarmPoolLpTokenAccount,
  getFarmPoolPcTokenaccount,
  getVaultAccount,
  getVaultStakeLayout,
  isVersionFourOrFive,
  getVaultAmmLayout,
  isSupportedLendingFarm,
  getSaberVaultProgramId,
  getSaberVaultAccount,
  getSaberVaultCoinTokenAccount,
  getSaberVaultPcTokenAccount,
  getOrcaVaultAccount,
  getOrcaFarmPoolId,
  getOrcaFarmPoolLpTokenAccount,
  getOrcaFarmPoolCoinTokenaccount,
  getOrcaFarmPoolPcTokenaccount,
  getOrcaFarmAmmId,
  getOrcaFarmAmmOpenOrders,
  getOrcaVaultProgramId, getOrcaVaultGlobalFarm,
} from '../utils/config';
import { getStore } from './get-store';
import { TokenAmount } from '../utils/safe-math';
import { _MARKET_STATE_LAYOUT_V2, Orderbook, OpenOrders } from '@project-serum/serum/lib/market.js';
import * as web3js from "@solana/web3.js";
import {SABER_FARMS} from "../utils/saber_farms";
import { LENDING_RESERVES } from '../utils/lendingReserves';
import { TOKENS } from '../utils/tokens';
import { ORCA_FARMS } from '../utils/orcaFarms';
import Decimal from 'decimal.js'
import {getOrcaPeriodRate} from "../utils/vault";
import { FARM_PLATFORMS } from '../constants/farmConstants';

const NUMBER_OF_PERIODS_IN_A_WEEK = 24 * 7,
  NUMBER_OF_PERIODS_IN_A_YEAR = 24 * 365;

const saberIdlJson = require('../idl/saber_idl.json');
const orcaIdlJson = require('../idl/orca_idl.json');

const getAPY = (periodicRate, numberOfPeriods) => {
  return (Math.pow((1 + (periodicRate/100)), numberOfPeriods) - 1);
}


const getPerBlockAmountTotalValue = (perBlockAmount, price) => {
  return (
    perBlockAmount  *
    2 *
    60 *
    60 *
    24 *
    365 *
    price
  );
}

const tulipUsdcMarketId = '8GufnKq7YnXKhnB3WNhgy5PzU9uvHbaaRrZWQK6ixPxW';
const sunnyUsdcMarketId = 'Aubv1QBFh4bwB2wbP1DaPW21YyQBLfgjg8L4PHTaPzRc';

export default class FarmStore {
  constructor () {
    this.farms = {};

    makeObservable(this, {
      farms: observable,
      setFarm: action.bound,
      setPrice: action.bound,
      pageTVL: computed,
      visibleFarms: computed,
      lendingFarms: computed
    });


    this.getFarm = this.getFarm.bind(this);

    this.setPrice();
  }

  getFarm (mintAddress) {
    return this.farms[mintAddress];
  }

  setFarm (mintAddress, farmDetails) {
    if (!mintAddress || isNil(farmDetails)) {
      return;
    }

    !this.farms[mintAddress] && (this.farms[mintAddress] = {});

    assign(this.farms[mintAddress], farmDetails);
  }

  async setPrice () {
    // return;
    getStore('UIStore').setIsRefreshing(true);

    const saberIdl = saberIdlJson;

    const walletToInitialize = {
      signTransaction: () => {},
      signAllTransactions: () => {},
      publicKey: new anchor.web3.Account().publicKey
    };
    const provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true });

    const saberVaultProgramId = new anchor.web3.PublicKey(getSaberVaultProgramId());
    const saberVaultProgram = new anchor.Program(saberIdl, saberVaultProgramId, provider);

    const orcaVaultProgramId = new anchor.web3.PublicKey(getOrcaVaultProgramId());
    const orcaVaultProgram = new anchor.Program(orcaIdlJson, orcaVaultProgramId, provider);

    // const pairs = await PriceFetcherService.fetchAll();
    const { getTokenPrice, getPair } = getStore('PriceStore');
    
    // Raydium
    const vaultAccounts = map(FARMS, (farm) => new anchor.web3.PublicKey(getVaultAccount(farm.symbol)));
    const mintAddresses = map(FARMS, (farm) => new anchor.web3.PublicKey(farm.mintAddress));
    const poolIds = map(FARMS, (farm) => new anchor.web3.PublicKey(getFarmPoolId(farm.symbol)));
    const poolLpTokenAccounts = map(FARMS, (farm) => new anchor.web3.PublicKey(getFarmPoolLpTokenAccount(farm.symbol)));
    const poolCoinTokenaccounts = map(FARMS, (farm) => new anchor.web3.PublicKey(getFarmPoolCoinTokenaccount(farm.symbol)));
    const poolPcTokenaccounts = map(FARMS, (farm) => new anchor.web3.PublicKey(getFarmPoolPcTokenaccount(farm.symbol)));
    const ammIdAccounts = map(FARMS, (farm) => new anchor.web3.PublicKey(getFarmAmmId(farm.symbol)));
    const ammOpenOrdersAccounts = map(FARMS, (farm) => new anchor.web3.PublicKey(getFarmAmmOpenOrders(farm.symbol)));
    
    // Saber
    const saberVaultAccounts =  map(SABER_FARMS, (farm) => new anchor.web3.PublicKey(getSaberVaultAccount(farm.symbol)));
    const saberVaultCoinTokenAccounts =  map(SABER_FARMS, (farm) => new anchor.web3.PublicKey(getSaberVaultCoinTokenAccount(farm.symbol)));
    const saberVaultPcTokenAccounts =  map(SABER_FARMS, (farm) => new anchor.web3.PublicKey(getSaberVaultPcTokenAccount(farm.symbol)));
    const saberMintAddresses = map(SABER_FARMS, (farm) => new anchor.web3.PublicKey(farm.mintAddress));
    const sunnyMintAddresses = map(SABER_FARMS, (farm) => new anchor.web3.PublicKey(farm.sunnyMintAddress));

    // Orca
    const orcaVaultAccounts = map(ORCA_FARMS, (farm) => new anchor.web3.PublicKey(getOrcaVaultAccount(farm.symbol)));
    const orcaMintAddresses = map(ORCA_FARMS, (farm) => new anchor.web3.PublicKey(farm.mintAddress));
    // const orcaPoolIds = map(ORCA_FARMS, (farm) => new anchor.web3.PublicKey(getOrcaFarmPoolId(farm.symbol)));
    // const orcaPoolLpTokenAccounts = map(ORCA_FARMS, (farm) => new anchor.web3.PublicKey(getOrcaFarmPoolLpTokenAccount(farm.symbol)));
    const orcaPoolCoinTokenaccounts = map(ORCA_FARMS, (farm) => new anchor.web3.PublicKey(getOrcaFarmPoolCoinTokenaccount(farm.symbol)));
    const orcaPoolPcTokenaccounts = map(ORCA_FARMS, (farm) => new anchor.web3.PublicKey(getOrcaFarmPoolPcTokenaccount(farm.symbol)));
    const orcaGlobalFarms = map(ORCA_FARMS, (farm) => new anchor.web3.PublicKey(getOrcaVaultGlobalFarm(farm.symbol)));
    // const orcaAmmIdAccounts = map(ORCA_FARMS, (farm) => new anchor.web3.PublicKey(getOrcaFarmAmmId(farm.symbol)));
    // const orcaAmmOpenOrdersAccounts = map(ORCA_FARMS, (farm) => new anchor.web3.PublicKey(getOrcaFarmAmmOpenOrders(farm.symbol)));
    const accountDetailsToFetch = [
        // Raydium
        vaultAccounts,
        mintAddresses,
        poolIds,
        poolLpTokenAccounts,
        poolCoinTokenaccounts,
        poolPcTokenaccounts,
        ammIdAccounts,
        ammOpenOrdersAccounts,

        // Saber
        saberVaultAccounts,
        saberVaultCoinTokenAccounts,
        saberVaultPcTokenAccounts,
        saberMintAddresses,
        sunnyMintAddresses,

        // Orca
        orcaVaultAccounts,
        orcaMintAddresses,
        // orcaPoolIds,
        // orcaPoolLpTokenAccounts,
        orcaPoolCoinTokenaccounts,
        orcaPoolPcTokenaccounts,
        orcaGlobalFarms
        // orcaAmmIdAccounts,
        // orcaAmmOpenOrdersAccounts,
      ],
      [
        // Raydium
        vaultAccountsInfo,
        tokenSupplyForAllFarms,
        poolInfo,
        poolLpTokenAccountsInfo,
        poolCoinTokenaccountsInfo,
        poolPcTokenaccountsInfo,
        ammIdAccountsInfo,
        ammOpenOrdersAccountsInfo,

        // Saber
        saberVaultAccountsInfo,
        saberVaultCoinTokenAccountsInfo,
        saberVaultPCTokenAccountsInfo,
        tokenSupplyForSaberFarms,
        tokenSupplyForSunnyFarms,

        // Orca
        orcaVaultAccountsInfo,
        orcaMintAddressesInfo,
        // orcaPoolIdsInfo,
        // orcaPoolLpTokenAccountsInfo,
        orcaPoolCoinTokenaccountsInfo,
        orcaPoolPcTokenaccountsInfo,
        orcaGlobalFarmsInfo,
        // orcaAmmIdAccountsInfo,
        // orcaAmmOpenOrdersAccountsInfo,

      ] = await getMultipleAccountsGrouped(window.$web3, accountDetailsToFetch, commitment),
      tulipPrice = getTokenPrice(TOKENS.TULIP.symbol);

    //#region Raydium Farms
    tokenSupplyForAllFarms.forEach((tokenSupply, index) => {
      const farm = FARMS[index];
      const decodedTokenData = MINT_LAYOUT.decode(tokenSupply.account.data);
      const uiAmount = decodedTokenData.supply / Math.pow(10, decodedTokenData.decimals);
      const vaultAccountInfo = vaultAccountsInfo[index];
      const decodedVaultAccountData = VAULT_LAYOUT.decode(vaultAccountInfo.account.data);
      const { totalVaultBalance, totalVlpShares } = decodedVaultAccountData;
      const farmDetails = getPair(farm.mintAddress) || {},
          currentPoolInfo = poolInfo[index],
          currentPoolLayout = getVaultStakeLayout(farm.symbol),
          isPoolVersionFourOrFive = isVersionFourOrFive(farm.symbol),
          decodedPoolInfo = currentPoolLayout.decode(currentPoolInfo.account.data),
          poolLpTokenAccountInfo = poolLpTokenAccountsInfo[index],
          decodedPoolLpTokenAccountInfo = ACCOUNT_LAYOUT.decode(poolLpTokenAccountInfo.account.data),
          decodedPoolCoinTokenaccountInfo = ACCOUNT_LAYOUT.decode(poolCoinTokenaccountsInfo[index].account.data),
          decodedPoolPcTokenaccountInfo = ACCOUNT_LAYOUT.decode(poolPcTokenaccountsInfo[index].account.data),
          currentAmmLayout = getVaultAmmLayout(farm.symbol),
          currentAmmInfo = ammIdAccountsInfo[index],
          decodedAmmInfo = currentAmmLayout.decode(currentAmmInfo.account.data),
          OPEN_ORDERS_LAYOUT = OpenOrders.getLayout(new web3js.PublicKey(getFarmSerumProgramId(farm.symbol))),
          decodedAmmOpenOrdersInfo = OPEN_ORDERS_LAYOUT.decode(ammOpenOrdersAccountsInfo[index].account.data),
          { baseTokenTotal, quoteTokenTotal } = decodedAmmOpenOrdersInfo,
          { needTakePnlCoin, needTakePnlPc } = decodedAmmInfo;

      let price,
        rewardPerBlockAmount,
        rewardBPerBlockAmount,
        rewardPerBlockAmountTotalValue,
        rewardBPerBlockAmountTotalValue,
        totalAPY,
        poolCoinAmount,
        poolPCAmount,
        coinInLp,
        pcInLp;

      if (farm.singleStake) {
        price = Number(getTokenPrice(farm.symbol));
      } else {
        poolCoinAmount = new TokenAmount(0, farm.coin.decimals);
        poolPCAmount = new TokenAmount(0, farm.pc.decimals);

        poolCoinAmount.wei = poolCoinAmount.wei.plus(decodedPoolCoinTokenaccountInfo.amount.toString())
        poolPCAmount.wei = poolPCAmount.wei.plus(decodedPoolPcTokenaccountInfo.amount.toString())

        poolCoinAmount.wei = poolCoinAmount.wei.plus(baseTokenTotal.toString())
        poolPCAmount.wei = poolPCAmount.wei.plus(quoteTokenTotal.toString())

        poolCoinAmount.wei = poolCoinAmount.wei.minus(needTakePnlCoin.toString())
        poolPCAmount.wei = poolPCAmount.wei.minus(needTakePnlPc.toString())

        coinInLp = Number(poolCoinAmount.fixed()) / uiAmount;
        pcInLp = Number(poolPCAmount.fixed()) / uiAmount;


        price = (coinInLp * Number(getTokenPrice(farm.coin.symbol))) + (pcInLp * Number(getTokenPrice(farm.pc.symbol)));
      }

      const priceBN = new anchor.BN(price);
      if (isPoolVersionFourOrFive) {
        const { perBlock, perBlockB } = decodedPoolInfo,
          { reward, rewardB } = farm;

        rewardPerBlockAmount = new TokenAmount(perBlock.toString(), reward.decimals);
        rewardBPerBlockAmount = new TokenAmount(perBlockB.toString(), rewardB.decimals);

        rewardPerBlockAmountTotalValue = getPerBlockAmountTotalValue(
            rewardPerBlockAmount.toEther().toNumber(),
            getTokenPrice(reward.symbol)
          );

        rewardBPerBlockAmountTotalValue = getPerBlockAmountTotalValue(
            rewardBPerBlockAmount.toEther().toNumber(),
            getTokenPrice(rewardB.symbol)
          );

        // const liquidityInTokens = new anchor.BN(decodedPoolLpTokenAccountInfo.amount.toString()).div(new anchor.BN(Math.pow(10, farm.decimals)));
        // console.log("$$$ farmstore liquidity", farm.symbol, price)
        let liquidityInUsd = 0;
        let apyA = 0;
        let apyB = 0;
        try {
          liquidityInUsd = new anchor.BN(new anchor.BN(decodedPoolLpTokenAccountInfo.amount.toString()) * price).div(new anchor.BN(Math.pow(10, farm.decimals)));
          apyA = (100 * rewardPerBlockAmountTotalValue) / liquidityInUsd;
          apyB = (100 * rewardBPerBlockAmountTotalValue) / liquidityInUsd;
        } catch (e) {
          liquidityInUsd = (new anchor.BN(decodedPoolLpTokenAccountInfo.amount.toString()).div(new anchor.BN(Math.pow(10, farm.decimals)))) * price;
          apyA = (100 * rewardPerBlockAmountTotalValue) / liquidityInUsd;
          apyB = (100 * rewardBPerBlockAmountTotalValue) / liquidityInUsd;
        }
        // console.log("$$$ apy data ", farm.symbol, apyA, apyB, liquidityInUsd);
        if (reward && rewardB) {
          totalAPY = (apyA + apyB);
        } else {
          totalAPY = apyA;
        }
      } else {
        const { rewardPerBlock } = decodedPoolInfo;

        rewardPerBlockAmount = new TokenAmount(rewardPerBlock.toString(), farm.decimals);

        rewardPerBlockAmountTotalValue = getPerBlockAmountTotalValue(
            rewardPerBlockAmount.toEther().toNumber(),
            getTokenPrice(farm.reward.symbol)
          );

        const liquidityInUsd = new anchor.BN(decodedPoolLpTokenAccountInfo.amount.toString()).mul(priceBN).div(new anchor.BN(Math.pow(10, farm.decimals)));
        // const liquidityInUsd = (decodedPoolLpTokenAccountInfo.amount.toNumber() / Math.pow(10, farm.decimals)) * price;

        totalAPY = 100 * rewardPerBlockAmountTotalValue / liquidityInUsd;
      }

      // const totalVaultBalanceInNumber =
      const tvl = new anchor.BN(new anchor.BN(totalVaultBalance?.toString()) * price).div(new anchor.BN( Math.pow(10, farm.decimals))) * 1;

      // We want `apyDetails` in all cases but `farmDetails` only when the Farm is NOT `singleStake`
      if (!((farmDetails || farm.singleStake) && !isNil(totalAPY))) {
        console.log("$$$ failed to set tvb and tvs", farm.symbol, farmDetails, farm.singleStake, totalAPY);
        return;
      }

      const periodicRate = totalAPY / 365;
      const { apy: tradingFees = 0 } = getPair(farm.mintAddress) || {};
      const dailyTradingFees = Number(tradingFees) / 365;

      const mintAddress = (
        farm.symbol === 'RAY-SRM-DUAL' ? `${farm.mintAddress}0` : farm.mintAddress
      );

      const tulipRewardEmissionRate = window.$slot < farm.rewardEndSlot ? farm.totalTulipEmission : 0;
      const dailyAPR = periodicRate + (farm.disabled ? 0 : dailyTradingFees);
      const weeklyAPY = (100 * getAPY(periodicRate/(24), NUMBER_OF_PERIODS_IN_A_WEEK)) + (farm.disabled ? 0 : (dailyTradingFees * 7));
      const yearlyAPY = (100 * getAPY(periodicRate/(24), NUMBER_OF_PERIODS_IN_A_YEAR)) + (farm.disabled ? 0 : Number(tradingFees));

      this.setFarm(mintAddress, {
        dailyAPR,
        price,
        // since we compound every 1hr, we need that rate
        weeklyAPY,
        yearlyAPY,
        yieldBreakdown: {
          dailyYield: periodicRate,
          weeklyYield: (100 * getAPY(periodicRate/(24), NUMBER_OF_PERIODS_IN_A_WEEK)),
          yearlyYield: (100 * getAPY(periodicRate/(24), NUMBER_OF_PERIODS_IN_A_YEAR)),
          dailyTradingFees
        },
        tulipAPR: (100 * 365 * tulipRewardEmissionRate * tulipPrice) / tvl,
        baseTokenTotal,
        quoteTokenTotal,
        needTakePnlCoin,
        needTakePnlPc,
        tvl,
        totalVaultBalance,
        totalVlpShares,
        coinInLp,
        pcInLp
      });
    });
    ///#endregion

    //#region Saber Farms
    let saberPrice = getTokenPrice("SABER");
    // let sunnyPrice = getTokenPrice("SUNNY");

    saberVaultAccountsInfo.forEach((saberVaultAccountsData, index) => {
      const saberfarm = SABER_FARMS[index];
      let coinPrice;
      let pcPrice;
      if (saberfarm.pc.symbol === "BTC" || saberfarm.pc.symbol === "SOL" || saberfarm.pc.symbol === "FTT" || saberfarm.pc.symbol === "SRM") {
        coinPrice = Number(getTokenPrice(saberfarm.pc.symbol));
        pcPrice = coinPrice
      } else {
        coinPrice = Number(1);
        pcPrice = coinPrice
      }

      const decodedVaultAccountData = saberVaultProgram.coder.accounts.decode('VaultAccount', Buffer.from(saberVaultAccountsData.account.data));

      const { totalVaultBalance, totalVlpShares } = decodedVaultAccountData;
      const totalVaultBalanceInNumber = new anchor.BN(totalVaultBalance?.toString());

      const decodedSaberCoinTokenaccountInfo = ACCOUNT_LAYOUT.decode(saberVaultCoinTokenAccountsInfo[index].account.data);
      const decodedSaberPCTokenaccountInfo = ACCOUNT_LAYOUT.decode(saberVaultPCTokenAccountsInfo[index].account.data);

      const decodedTokenData = MINT_LAYOUT.decode(tokenSupplyForSaberFarms[index].account.data);
      const uiAmount = decodedTokenData.supply / Math.pow(10, decodedTokenData.decimals);

      const decodedTokenDataSunny = MINT_LAYOUT.decode(tokenSupplyForSunnyFarms[index].account.data);
      const uiAmountSunny = decodedTokenDataSunny.supply / Math.pow(10, decodedTokenData.decimals);


      let poolCoinAmount = new TokenAmount(0, saberfarm.coin.decimals);
      let poolPCAmount = new TokenAmount(0, saberfarm.pc.decimals);

      poolCoinAmount.wei = poolCoinAmount.wei.plus(decodedSaberCoinTokenaccountInfo.amount.toString())
      poolPCAmount.wei = poolPCAmount.wei.plus(decodedSaberPCTokenaccountInfo.amount.toString())

      const price = ( (Number(poolCoinAmount.fixed()) * coinPrice)  + (Number(poolPCAmount.fixed()) * pcPrice) ) / uiAmount;
      const tvl = (totalVaultBalanceInNumber * price) / Math.pow(10, saberfarm.decimals);

      const totalLiquidity = (Number(poolCoinAmount.fixed() * coinPrice) + Number(poolPCAmount.fixed()) * pcPrice);
      const totalLiquiditySunny = ( uiAmountSunny * price);

      const periodicRateSaber = ( saberfarm.totalSBREmission * 0.84 * Number(saberPrice) * 100) / totalLiquidity;
      // const periodicRateSunny = ( saberfarm.totalSunnyEmission * Number(sunnyPrice) ) * 105 / totalLiquiditySunny;
      // const periodicRate = periodicRateSaber+periodicRateSunny;
      const periodicRate = periodicRateSaber;

      // console.log("$$coin ", poolCoinAmount.fixed(), poolPCAmount.fixed(), coinPrice);
      this.setFarm(saberfarm.mintAddress, {
        dailyAPR: periodicRate,
        price: price,
        // since we compound every 1hr, we need that rate
        weeklyAPY: 100 * getAPY(periodicRate/(24), NUMBER_OF_PERIODS_IN_A_WEEK),
        yearlyAPY: 100 * getAPY(periodicRate/(24), NUMBER_OF_PERIODS_IN_A_YEAR),
        tvl,
        totalVaultBalance,
        totalVlpShares,
        tulipAPR: (100 * 365 * saberfarm.totalTulipEmission * tulipPrice) / tvl
      });
    });
    //#endregion

    //#region Orca Farms
    ORCA_FARMS.forEach((farm, index) => {
      const orcaPrice = getTokenPrice(TOKENS.ORCA.symbol) || 0;

      const decodedGlobalFarm = GLOBAL_FARM_DATA_LAYOUT.decode(orcaGlobalFarmsInfo[index].account.data);
      // console.log("$$$orca", decodedGlobalFarm);
      // const periodicRate = (decodedGlobalFarm.orcaEmissionPerDayPerThousandUsd * orcaPrice) / 10; // (÷ 1000 USD * 100%)



      const decodedTokenData = MINT_LAYOUT.decode(orcaMintAddressesInfo[index].account.data);
      const uiAmount = decodedTokenData.supply / Math.pow(10, decodedTokenData.decimals);
      const decodedVaultAccountData = orcaVaultProgram.coder.accounts.decode('Vault', Buffer.from(orcaVaultAccountsInfo[index].account.data));
      const { totalVaultBalance, totalVlpShares } = decodedVaultAccountData;
      const totalVaultBalanceInNumber = new anchor.BN(totalVaultBalance?.toString()).div(new anchor.BN(Math.pow(10, farm.decimals)));

      const decodedPoolCoinTokenaccountInfo = ACCOUNT_LAYOUT.decode(orcaPoolCoinTokenaccountsInfo[index].account.data);
      const decodedPoolPcTokenaccountInfo = ACCOUNT_LAYOUT.decode(orcaPoolPcTokenaccountsInfo[index].account.data);

      let price,
        poolCoinAmount,
        poolPCAmount,
        coinInLp,
        pcInLp,
        totalLiquidity,
        coinToPcRatio;

      if (farm.singleStake) {
        price = Number(getTokenPrice(farm.symbol));
      } else {
        poolCoinAmount = new TokenAmount(decodedPoolCoinTokenaccountInfo.amount.toString(), farm.coin.decimals);
        poolPCAmount = new TokenAmount(decodedPoolPcTokenaccountInfo.amount.toString(), farm.pc.decimals);

        coinToPcRatio = Number(poolCoinAmount.fixed()) / Number(poolPCAmount.fixed());

        coinInLp = Number(poolCoinAmount.fixed()) / uiAmount;
        pcInLp = Number(poolPCAmount.fixed()) / uiAmount;

        if (farm.symbol === 'ORCA-USDC') {
          getStore('PriceStore').setTokenPrice(
            TOKENS.ORCA.symbol,
            poolPCAmount.wei.div( poolCoinAmount.wei).toNumber()
          );
        }

        price = (coinInLp * Number(getTokenPrice(farm.coin.symbol))) + (pcInLp * Number(getTokenPrice(farm.pc.symbol)));
        totalLiquidity  = price * uiAmount;
      }

      const periodicRate = getOrcaPeriodRate(
          decodedGlobalFarm,
          totalLiquidity,
          farm.reward.decimals,
          orcaPrice
      );


      const { apy: tradingFees = 0 } = getPair(farm.mintAddress) || {};
      const dailyTradingFees = Number(tradingFees) / 365;

      // console.log("$$$ orca farm", farm.symbol, periodicRate, tradingFees, decodedGlobalFarm, totalLiquidity, farm.reward.decimals, orcaPrice);

      const dailyAPR = periodicRate + (farm.disabled ? 0 : dailyTradingFees);
      const weeklyAPY = (100 * getAPY(periodicRate/(24), NUMBER_OF_PERIODS_IN_A_WEEK)) + (farm.disabled ? 0 : (dailyTradingFees * 7));
      const yearlyAPY = (100 * getAPY(periodicRate/(24), NUMBER_OF_PERIODS_IN_A_YEAR)) + (farm.disabled ? 0 : Number(tradingFees));




      const tvl = totalVaultBalanceInNumber * price;

      // @to-do: if we have Tulip rewards for Orca in the future, fix this
      const tulipAPR = 0;

      this.setFarm(farm.mintAddress, {
        dailyAPR,
        price,
        // since we compound every 1hr, we need that rate
        weeklyAPY,
        yearlyAPY,
        yieldBreakdown: {
          dailyYield: periodicRate,
          weeklyYield: (100 * getAPY(periodicRate/(24), NUMBER_OF_PERIODS_IN_A_WEEK)),
          yearlyYield: (100 * getAPY(periodicRate/(24), NUMBER_OF_PERIODS_IN_A_YEAR)),
          dailyTradingFees
        },
        tulipAPR,
        tvl,
        totalVaultBalance,
        totalVlpShares,
        coinInLp,
        pcInLp,
        coinToPcRatio
      });
    });
    //#endregion

    getStore('UIStore').resetRefreshState();
  }
  
  get pageTVL () {
    let totalSum = 0;
    
    forEach(this.farms, (farm) => {
      if (!farm.tvl) {
        return;
      }

      totalSum += farm.tvl;
    });

    forEach(LENDING_RESERVES, (reserve) => {
      const {
        borrowedAmount: totalBorrow = 0,
        totalSupply,
      } = getStore('ReserveStore').getReserve(reserve.mintAddress) || {};

      const price = Number(getStore('PriceStore').getTokenPrice(reserve.name)) || 0;

      const totalSupplyInUsd = totalSupply * price;
      const totalBorrowInUsd = totalBorrow * price;

      totalSum += (totalSupplyInUsd - totalBorrowInUsd);
    });

    return totalSum;
  }

  applyFilters (farms) {
    const { activeFarms } = getStore('FilterStore');

    // return farms.filter((farm) => activeFarms[farm.symbol]);

    return orderBy(farms, [{ isNew: true }, { disabled: true }, { platform: FARM_PLATFORMS.ORCA }], ['desc', 'asc', 'desc'])
      .filter((farm) => activeFarms[farm.symbol]);
  }

  get visibleFarms () {
    const { wallet, tokenAccounts, isTokenAccountInvalid } = getStore('WalletStore'),
      showStaked = getStore('UserPreferenceStore').get('showStaked');

    const ALLFARMS = concat(SABER_FARMS, FARMS, ORCA_FARMS);

    // If wallet is not connected or if `showStaked` is turned off, show all farms.
    if (!wallet || !showStaked) {
      return this.applyFilters(ALLFARMS);
    }

    const filteredFarms = filter(ALLFARMS, (farm) => {
      const mintAddress = (
        farm.symbol === 'RAY-SRM-DUAL' ? `${farm.mintAddress}0` : farm.mintAddress
      );

      // Show farms with invalid token accounts
      if (isTokenAccountInvalid(mintAddress, farm?.platform)) {
        return true;
      }

      const tokenAccount = tokenAccounts[mintAddress];

      if (!tokenAccount) {
        return false;
      }

      const deposited = Number(tokenAccount.deposited);

      if (!deposited) {
        return false;
      }

      return true;
    });

    return this.applyFilters(filteredFarms);
  }


  applyLeverageFarmingFilters (farms) {
    const { activeFarms } = getStore('LeverageFilterStore');

    return orderBy(farms, { platform: FARM_PLATFORMS.ORCA }, ['desc'])
      .filter((farm) => activeFarms[farm.symbol]);
  }

  get lendingFarms () {
    const { wallet, tokenAccounts, isTokenAccountInvalid } = getStore('WalletStore'),
      showStaked = getStore('UserPreferenceStore').get('showStaked');

    // If wallet is not connected or if `showStaked` is turned off, show all farms.
    // if (!wallet || !showStaked) {
    //   return FARMS;
    // }

    // const ALL_FARMS = concat(FARMS, ORCA_FARMS);

    const supportedLendingFarms = filter(LEVERAGE_FARMS, (farm) => {
      // Show farms with invalid token accounts
      // if (isTokenAccountInvalid(farm.mintAddress, farm.platform)) {
      //   return true;
      // }

      // const tokenAccount = tokenAccounts[farm.mintAddress];

      // if (!tokenAccount) {
      //   return false;
      // }

      // const deposited = Number(tokenAccounts[farm.mintAddress].deposited);

      // if (!deposited) {
      //   return false;
      // }

      return isSupportedLendingFarm(farm.symbol);
    });

    return this.applyLeverageFarmingFilters(supportedLendingFarms);
  }
}
