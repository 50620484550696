import {
  EventEmitter,
  pollUntilReady,
  WalletAccountError,
  WalletAdapter,
  WalletAdapterEvents,
  WalletNotConnectedError,
  WalletNotFoundError,
  WalletPublicKeyError,
  // WalletSignatureError,
  WalletNotInstalledError,
} from "@solana/wallet-adapter-base";
import { PublicKey, Transaction } from "@solana/web3.js";
import bs58 from "bs58";
import { delay } from "../utils/web3";

export class Coin98WalletAdapter extends EventEmitter {
  constructor(config = {}) {
    super();
    this._connecting = false;
    this._wallet = null;
    this._publicKey = null;
    this.signTransaction = this.signTransaction.bind(this);
    this.signAllTransactions = this.signAllTransactions.bind(this);
    if (!this.ready)
      pollUntilReady(this, config.pollInterval || 1000, config.pollCount || 3);
  }

  get publicKey() {
    return this._publicKey;
  }

  get ready() {
    return !!window.coin98;
  }

  get connecting() {
    return this._connecting;
  }

  get connected() {
    return !!this._wallet?.isConnected();
  }

  get autoApprove() {
    return false;
  }

  async connect() {
    try {
      if (this.connected || this.connecting) return;
      this._connecting = true;

      const wallet = window.coin98?.sol;
      if (!wallet) throw new WalletNotFoundError();
      if (!wallet.isCoin98) throw new WalletNotInstalledError();

      let account;
      try {
        const [address] = await wallet.connect();
        account = address;
      } catch (error) {
        throw new WalletAccountError(error?.message, error);
      }

      let publicKey;
      try {
        publicKey = new PublicKey(account);
      } catch (error) {
        throw new WalletPublicKeyError(error?.message, error);
      }

      this._wallet = wallet;
      this._publicKey = publicKey;

      this.emit("connect");
    } catch (error) {
      this.emit("error", error);
      throw error;
    } finally {
      this._connecting = false;
    }
  }

  async disconnect() {
    if (this._wallet) {
      this._wallet.disconnect();
      this._wallet = null;
      this._publicKey = null;
      this.emit("disconnect");
    }
  }

  async signTransaction(transaction) {
    try {
      const wallet = this._wallet;
      if (!wallet) throw new WalletNotConnectedError();

      try {
        const signedTransaction = await wallet.request({
          method: "sol_sign",
          params: [transaction],
        });
        const sig = bs58.decode(signedTransaction.signature);
        const publicKey = new PublicKey(signedTransaction.publicKey);
        transaction.addSignature(publicKey, sig);

        console.log({
          signedTransaction,
          sig,
          bs58
        });
        return transaction;
      } catch (error) {
        throw new Error(error?.message, error);
      }
    } catch (error) {
      this.emit("error", error);
      throw error;
    }
  }

  // async signAllTransactions(transactions) {
  //   try {
  //     const wallet = this._wallet;
  //     if (!wallet) throw new WalletNotConnectedError();

  //     try {
  //       // return await Promise.all(
  //       //   transactions.map((transaction) => this.signTransaction(transaction))
  //       // );

  //       const signedTransactions = await wallet.request({
  //         method: "sol_sign",
  //         params: transactions,
  //       });

  //       console.log('signedTransactions', signedTransactions);
  //       // const sig = bs58.decode(signedTransaction.signature);
  //       // const publicKey = new PublicKey(signedTransaction.publicKey);
  //       // transaction.addSignature(publicKey, sig);
  //       return signedTransactions;


  //     } catch (error) {
  //       throw new Error(error?.message, error);
  //     }
  //   } catch (error) {
  //     this.emit("error", error);
  //     throw error;
  //   }
  // }

  async signAllTransactions(transactions) {
    const result = [];

    for (let i = 0; i < transactions.length; i++) {
      const transaction = transactions[i];

      if (transaction) {
        const signed = await this.signTransaction(transaction)
        result.push(signed);
      }

      if (i !== transactions.length) {
        await delay(500);
      }
    }

    return result
  }
}
