/* eslint-disable default-case */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import onClickOutside from "react-onclickoutside";

// Components
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Loader } from '../base-components/Loader';
import MenuIcon from '../base-components/MenuIcon';
import Badge from '../base-components/Badge';

// Store
import { getStore } from '../stores/get-store';

// Wallet Adapters
// import { WalletAdapter } from '@solana/wallet-adapter-base'
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom'
import { SolongWalletAdapter } from '@solana/wallet-adapter-solong'
import { MathWalletWalletAdapter } from '@solana/wallet-adapter-mathwallet'
import { SolletWalletAdapter } from '@solana/wallet-adapter-sollet'
import { LedgerWalletAdapter, getDerivationPath } from '@solana/wallet-adapter-ledger'
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare'
// import { Coin98WalletAdapter } from '@solana/wallet-adapter-coin98'
import { Coin98WalletAdapter } from '../wallet-adapters/coin98';
import { SlopeWalletAdapter } from '@solana/wallet-adapter-slope'
import { SafePalWalletAdapter } from '@solana/wallet-adapter-safepal'
import { BloctoWalletAdapter } from '@solana/wallet-adapter-blocto'
// import { BitpieWalletAdapter } from '@solana/wallet-adapter-bitpie'

// Lodash
import { find, isEmpty, map } from 'lodash';

// Icons
import walletIcon from '../icons/wallet.svg';
import globeIcon from '../icons/globe-icon.svg';
import SolFarmLogo from '../icons/SolFarmLogo.png';
import downCaret from '../icons/down-caret.svg';
import copyIcon from '../icons/copy.svg';
import linkIcon from '../icons/link.svg';
import checkIcon from '../icons/check-icon-stroke.svg';

// Utils
import { getFormattedNumber } from '../utils/safe-math';
import { endpoints, getUserFriendlyNameForEndpoint } from '../utils/web3';
import { copyToClipboard } from '../utils/clipboard';

// Services
import { setConnection } from '../services/ConnectionService';

// Constants
import { NAV_BAR_ROUTES, APP_ROUTE_TYPES, APP_ROUTES } from '../constants/routes';
import { TOAST_TYPES } from '../constants/toastConstants';
import { WALLETS, WALLET_LOGO_MAP } from '../constants/walletConstants';

function navigateTo (history, route) {
  return history.push(route);
}

class AppHeader extends React.Component {
  constructor () {
    super();

    this.state = {
      isWalletDropdownOpen: false,
      isEndpointDropdownOpen: false,
      isNavigationDropdownOpen: false,
      isMenuOpen: false
    };

    this.handleWalletConnect = this.handleWalletConnect.bind(this);
    this.handleWalletDisconnect = this.handleWalletDisconnect.bind(this);
    this.toggleWalletDropdown = this.toggleWalletDropdown.bind(this);
    this.toggleEndpointDropdown = this.toggleEndpointDropdown.bind(this);
    this.handleCopyAddress = this.handleCopyAddress.bind(this);
    this.toggleNavigationDropdown = this.toggleNavigationDropdown.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.wallet = null;

    // Initialize the responsive store
    getStore('ResponsiveStore');
  }

  handleClickOutside () {
    // Close the dropdown menu when clicking outside
    this.setState({ isMenuOpen: false });
  }

  toggleMenu () {
    this.setState((prevState) => ({
      isMenuOpen: !prevState.isMenuOpen
    }));
  }

  toggleWalletDropdown () {
    this.setState((prevState) => ({
      isWalletDropdownOpen: !prevState.isWalletDropdownOpen
    }));
  }

  toggleEndpointDropdown () {
    this.setState((prevState) => ({
      isEndpointDropdownOpen: !prevState.isEndpointDropdownOpen
    }));
  }

  toggleNavigationDropdown () {
    this.setState((prevState) => ({
      isNavigationDropdownOpen: !prevState.isNavigationDropdownOpen
    }));
  }

  handleCopyAddress () {
    const { wallet } = getStore('WalletStore');

    // wallet not initialized
    if (!wallet) {
      return;
    }

    copyToClipboard(wallet.publicKey.toBase58());

    this.props.toast('Wallet Address copied to clipboard');
  }

  handleWalletDisconnect () {
    const { wallet } = getStore('WalletStore');

    // wallet not initialized
    if (!wallet) {
      return;
    }

    wallet.disconnect();
  }

  handleWalletConnect (walletName) {
    // No selection specified
    if (!walletName) {
      return;
    }

    const { endpoint } = getStore('ConnectionStore');

    switch (walletName) {
      case 'Phantom': {
        if (window.solana === undefined || !window.solana.isPhantom) {
          this.props.toast('Connect wallet failed. Please install and initialize Phantom wallet extension first.', TOAST_TYPES.ERROR);

          return;
        }

        this.wallet = new PhantomWalletAdapter();
        break;
      }

      case 'Sollet Web': {
        this.wallet = new SolletWalletAdapter({ provider: WALLETS[walletName] });
        break;
      }

      case 'Ledger': {
        this.wallet = new LedgerWalletAdapter({ derivationPath: getDerivationPath() });
        break;
      }

      case 'Solflare Extension': {
        if (window.solflare === undefined) {
          this.props.toast('Connect wallet failed. Please install and initialize Solflare wallet extension first', TOAST_TYPES.ERROR);
          return;
        }

        this.wallet = new SolflareWalletAdapter();
        break;
      }

      case 'Coin98': {
        if (window.coin98 === undefined) {
          this.props.toast('Connect wallet failed. Please install and initialize Coin98 wallet extension first.', TOAST_TYPES.ERROR);

          return;
        }

        this.wallet = new Coin98WalletAdapter();
        break;
      }

      case 'Sollet Extension': {
        if (window.sollet === undefined) {
          this.props.toast('Connect wallet failed. Please install and initialize Sollet wallet extension first', TOAST_TYPES.ERROR);
          return;
        }

        this.wallet = new SolletWalletAdapter({ provider: window.sollet });
        break;
      }

      case 'MathWallet': {
        if (window.solana === undefined || !window.solana.isMathWallet) {
          this.props.toast('Connect wallet failed. Please install and initialize Math wallet extension first', TOAST_TYPES.ERROR);
          
          return;
        }

        this.wallet = new MathWalletWalletAdapter();
        break;
      }

      case 'Solong': {
        if (window.solong === undefined) {
          this.props.toast('Connect wallet failed. Please install and initialize Solong wallet extension first.', TOAST_TYPES.ERROR);
          return;
        }
        this.wallet = new SolongWalletAdapter();
        break;
      }

      case 'Slope': {
        if (window.Slope === undefined) {
          this.props.toast('Connect wallet failed. Please install and initialize Slope wallet extension first', TOAST_TYPES.ERROR);
          return;
        }

        this.wallet = new SlopeWalletAdapter();
        break;
      }

      case 'SafePal': {
        if (window.solana === undefined || !window.solana.isSafePalWallet) {
          this.props.toast('Connect wallet failed. Please install and initialize SafePal wallet extension first.', TOAST_TYPES.ERROR);
          
          return;
        }

        this.wallet = new SafePalWalletAdapter();
        break;
      }

      case 'Blocto': {
        if (window.solana === undefined || !window.solana.isBlocto) {
          this.props.toast('Connect wallet failed. Please install and initialize Blocto wallet extension first.', TOAST_TYPES.ERROR);
          
          return;
        }

        this.wallet = new BloctoWalletAdapter();
        break;
      }

      case 'Solflare Web': {
        this.wallet = new SolletWalletAdapter({ provider: WALLETS[walletName] });
        break;
      }
    }

    const { setWallet, clearWallet } = getStore('WalletStore');

    this.wallet.on('connect', () => {
      if (this.wallet.publicKey) {
        setWallet(this.wallet);
      }
    });

    this.wallet.on('disconnect', () => {
      clearWallet();
    });

    try {
      this.wallet.connect();
    } catch (error) {
      console.error('Wallet connect error:', error);
    }
  }

  getMenu (isOpen, activePath) {
    return (
      <div className={classnames('app-header__menu', { 'is-active': isOpen })}>
        <ul>
          {
            NAV_BAR_ROUTES.map((navBarRoute) => {
              switch (navBarRoute.type) {
                case APP_ROUTE_TYPES.LINK: {
                  const { route } = navBarRoute;

                  return (
                    <li>
                      <Link
                        key={route.name}
                        to={route.route}
                        className={
                          classnames(
                            'app-header__left__routes__item',
                            {
                              'active': activePath === route.route
                            }
                          )
                        }
                      >
                        {route.name}
                      </Link>
                    </li>
                  );
                }

                case APP_ROUTE_TYPES.DROPDOWN: {
                  const activeRoute = find(navBarRoute.routes, (route) => route.route === activePath);

                  return map(navBarRoute.routes, (route, index) => (
                      <li>
                        <Link
                          key={route.name}
                          to={route.route}
                          // onClick={navigateTo.bind(this, history, route.route)}
                          className={
                            classnames(
                              'app-header__left__routes__item',
                              {
                                'active': activePath === route.route
                              }
                            )
                          }
                        >
                          {route.name}
                        </Link>
                      </li> 
                    ));
              }

                default: {
                  return null;
                }
              }
            })
          }
          {/* <li>Leverage Farming</li>
          <li>Lending</li>
          <li>Your Positions</li>
          <li>Vaults</li> */}
        </ul>
      </div>
    );
  }

  render () {
    const { walletAddress, totalDeposited, totalReservesDeposited } = getStore('WalletStore'),
      { pageTVL } = getStore('FarmStore'),
      { endpoint: activeEndpoint } = getStore('ConnectionStore'),
      { isRefreshing } = getStore('UIStore'),
      { path, history } = this.props,
      { isEndpointDropdownOpen, isWalletDropdownOpen, isNavigationDropdownOpen, isMenuOpen } = this.state,
      { activeLayout, isDesktop } = getStore('ResponsiveStore');

    return (
      <div className={classnames('app-header', activeLayout)}>
        <div className='app-header__left'>
          <div
            className='app-header__left__logo'
            onClick={navigateTo.bind(this, history, APP_ROUTES.HOME.route)}
          >
            <img src={SolFarmLogo} className='app-header__left__logo-img' />
            <span className='app-header__left__logo-label'>SolFarm</span>
            <Badge
              type='secondary'
              tooltipText='All code on SolFarm is experimental. Please exercise discretion when using the dApp.'
            >
              BETA
            </Badge>
          </div>
          {
            isDesktop &&
            <div className='separator' />
          }
          {
            isDesktop && 
            <div className='app-header__left__routes'>
              {
                NAV_BAR_ROUTES.map((navBarRoute) => {
                  switch (navBarRoute.type) {
                    case APP_ROUTE_TYPES.LINK: {
                      const { route } = navBarRoute;

                      return (
                        <Link
                          key={route.name}
                          to={route.route}
                          className={
                            classnames(
                              'app-header__left__routes__item',
                              {
                                'active': path === route.route
                              }
                            )
                          }
                        >
                          {route.name}
                        </Link>
                      );
                    }

                    case APP_ROUTE_TYPES.DROPDOWN: {
                      const activeRoute = find(navBarRoute.routes, (route) => route.route === path);

                      return (
                        <Dropdown
                          isOpen={isNavigationDropdownOpen}
                          toggle={this.toggleNavigationDropdown}
                          // direction={this.state.isEndpointDropdownOpen ? 'up' : 'down'}
                        >
                          <DropdownToggle
                            // caret
                            // outline
                            // color='primary'
                            className={classnames('route-selector', { 'active': !isEmpty(activeRoute) })}
                          >
                            <span style={{
                              maxWidth: '180px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'pre',
                              display: 'inline-flex',
                            }}>
                              {/* <img src={globeIcon} /> */}
                              <span
                                style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'pre',
                                margin: '2px 8px 2px 8px'
                              }}>
                                { !isEmpty(activeRoute) ? activeRoute.name : navBarRoute.routes[0].name }
                              </span>
                              <img
                                src={downCaret}
                                style={{
                                  transform: isNavigationDropdownOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
                                  transition: 'transform 0.3s'
                                }}
                              />
                            </span>
                          </DropdownToggle>
                          <DropdownMenu right>
                            {
                              map(navBarRoute.routes, (route, index) => (
                                <DropdownItem
                                  onClick={navigateTo.bind(this, history, route.route)}
                                  key={index}
                                  style={{ padding: '0.5rem 3rem 0.5rem 2rem' }}
                                >
                                  <Link
                                    key={route.name}
                                    to={route.route}
                                    className={
                                      classnames(
                                        'app-header__left__routes__item',
                                        {
                                          'active': path === route.route
                                        }
                                      )
                                    }
                                  >
                                    {route.name}
                                  </Link>
                                </DropdownItem>
                              ))
                            }
                          </DropdownMenu>
                        </Dropdown>
                      )
                    }
                  }
                })
              }
            </div>
          }
        </div>
        { isDesktop && <div className='app-header__meta'>
          <span className='app-header__meta-item'>
            <span className='app-header__meta-item__label'>TVL: </span>
            <span className='app-header__meta-item__value'>
              {
                isRefreshing ? <Loader /> : 
                (<span>$<span>{getFormattedNumber(pageTVL)}</span></span>)
              }
            </span>
          </span>
          {
            [APP_ROUTES.VAULTS.route, APP_ROUTES.LENDING.route].includes(path) &&
            <span className='app-header__meta-item'>
              <span className='app-header__meta-item__label'>Deposited: </span>
              <span className='app-header__meta-item__value'>
                {/* {
                  (isRefreshing && walletAddress) ? <span><Loader /></span> :
                  (walletAddress ? (<span>$<span>{getFormattedNumber(totalDeposited)}</span></span>) : '-')
                } */}
                {walletAddress ? `$${getFormattedNumber(path === APP_ROUTES.VAULTS.route ? totalDeposited : totalReservesDeposited)}` : '-'}
              </span>
            </span>
          }
        </div>
        }
        <div>
          <Dropdown
            isOpen={this.state.isEndpointDropdownOpen}
            toggle={this.toggleEndpointDropdown}
            // direction={this.state.isEndpointDropdownOpen ? 'up' : 'down'}
          >
            <DropdownToggle
              // caret
              // outline
              // color='primary'
              className='rpc-selector'
            >
              <span style={{
                maxWidth: '160px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'pre',
                display: 'inline-flex',
                alignItems: 'center',
              }}>
                <img
                  src={globeIcon}
                  style={{
                    height: 16,
                    width: 16,
                    marginRight: 0
                  }}
                />
                {
                  isDesktop &&
                    <span
                      style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'pre',
                      margin: '2px 8px 2px 8px'
                    }}>
                      { activeEndpoint && getUserFriendlyNameForEndpoint(activeEndpoint) }
                    </span>
                }
                {
                  isDesktop &&
                  <img
                    src={downCaret}
                    style={{
                      transform: isEndpointDropdownOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s'
                    }}
                  />
                }
              </span>
            </DropdownToggle>
            <DropdownMenu right>
              {
                map(endpoints, (endpoint, index) => (
                  <DropdownItem
                    onClick={setConnection.bind(this, endpoint.url)}
                    key={index}
                    style={{ padding: '0.5rem 2rem 0.5rem 1rem' }}
                  >
                    <img
                      src={checkIcon}
                      style={{
                        height: 16,
                        width: 16,
                        visibility: (endpoint.url === activeEndpoint) ? 'visible' : 'hidden'
                      }}
                    />
                    {endpoint.userFriendlyName}
                  </DropdownItem>
                ))
              }
            </DropdownMenu>
          </Dropdown>
        </div>
        <div>
          <Dropdown
            isOpen={this.state.isWalletDropdownOpen}
            toggle={this.toggleWalletDropdown}
            // direction={this.state.isWalletDropdownOpen ? 'up' : 'down'}
          >
            <DropdownToggle
              // caret
              // outline
              // color='primary'
            >
              <span title={walletAddress} style={{
                maxWidth: '190px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'pre',
                display: 'inline-flex',
              }}>
                <img src={walletIcon} />
                {
                  isDesktop &&
                    <span
                      style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'pre',
                      margin: '2px 8px 2px 8px'
                    }}>
                      {(walletAddress || 'Connect wallet')}
                    </span>
                }
                {
                  isDesktop &&
                  <img
                    src={downCaret}
                    style={{
                      transform: isWalletDropdownOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s'
                    }}
                  />
                }
              </span>
            </DropdownToggle>
            <DropdownMenu right>
              {
                walletAddress ?
                (
                  <>
                    <DropdownItem style={{ padding: '0.5rem 3rem 0.5rem 2rem' }} onClick={this.handleCopyAddress}>
                      <img style={{ height: '16px', margin: '0 8px 0 0' }} src={copyIcon} />
                      Copy Address
                    </DropdownItem>
                    <DropdownItem style={{ padding: '0.5rem 3rem 0.5rem 2rem' }} onClick={this.handleWalletDisconnect}>
                      <img style={{ height: '16px', margin: '0 8px 0 0' }} src={linkIcon} />
                      Disconnect
                    </DropdownItem>
                  </>
                ) :
                (
                  <>
                    {
                      map(WALLETS, (val, walletName) => (
                        <DropdownItem
                          onClick={this.handleWalletConnect.bind(this, walletName)}
                          key={walletName}
                        >
                          <img src={WALLET_LOGO_MAP[walletName]} />
                          {walletName}
                        </DropdownItem>
                      ))
                    }
                  </>
                )
              }
            </DropdownMenu>
          </Dropdown>
        </div>
        {
          !isDesktop &&  this.getMenu(isMenuOpen, path)
        }
        {
          !isDesktop && <MenuIcon isOpen={isMenuOpen} onClick={this.toggleMenu} />
        }
      </div>
    )
  }
}

export default onClickOutside(observer(AppHeader));
