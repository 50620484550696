export const APP_ROUTE_TYPES = {
  DROPDOWN: 'dropdown',
  LINK: 'link'
}

export const APP_ROUTES = {
  LEVERAGE: {
    name: 'Leverage Farming',
    route: '/leverage'
  },
  YOUR_POSITIONS: {
    name: 'Your Positions',
    route: '/your-positions'
  },
  ALL_POSITIONS: {
    name: 'All Positions',
    route: '/all-positions'
  },
  LENDING: {
    name: 'Lending',
    route: '/lend'
  },
  VAULTS: {
    name: 'Vaults',
    route: '/vaults'
  },
  ABOUT: {
    name: 'About Us',
    route: '/about'
  },
  HOME: {
    name: 'Home',
    route: '/'
  }
};

export const NAV_BAR_ROUTES = [
  {
    type: APP_ROUTE_TYPES.DROPDOWN,
    routes: [
      APP_ROUTES.LEVERAGE,
      APP_ROUTES.YOUR_POSITIONS,
      // APP_ROUTES.ALL_POSITIONS
    ]
  },
  {
    type: APP_ROUTE_TYPES.LINK,
    route: APP_ROUTES.LENDING
  },
  {
    type: APP_ROUTE_TYPES.LINK,
    route: APP_ROUTES.VAULTS
  }
];
