import { Connection } from '@solana/web3.js';
import { getStore } from '../stores/get-store';
import { commitment, getRandomEndpoint } from '../utils/web3';

const createWeb3Instance = (endpoint) => {
  const web3 = new Connection(endpoint, { commitment, wsEndpoint: endpoint });

  // const signatureStatus = await web3.getSignatureStatus('3HxQYtRLexDAu2mxn71CA9G6FT5Do4gvxE7xej4xAya6QbHP1zfVQvaYK3WGi2pv5XoWEsSL86oNaESs6w4sB4cW', {searchTransactionHistory: true});

  // console.log('signatureStatus', signatureStatus);

  return web3;
}

export const bootConnection = () => {
  const endpoint = getRandomEndpoint();

  setConnection(endpoint);
}

export const setConnection = (endpoint) => {
  const { wallet } = getStore('WalletStore');

  wallet && wallet.disconnect();

  const web3 = createWeb3Instance(endpoint);

  getStore('ConnectionStore').setEndpoint(endpoint);
  window.$web3 = web3;

  console.log(`using ${endpoint}`);
}
