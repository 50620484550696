import React from 'react';
import classnames from 'classnames';
import { isNil } from 'lodash';
import { Tooltip } from 'reactstrap';

export default class Badge extends React.Component {
  constructor () {
    super();

    this.state = {
      isTooltipVisible: false
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.getBadgeRef = this.getBadgeRef.bind(this);
  }

  toggleTooltip (value) {
    const isTooltipVisible = isNil(value) ? !this.state.isTooltipVisible : value;

    this.setState({ isTooltipVisible });
  }

  getBadgeRef () {
    return this.badgeRef;
  }

  getBadgeClasses () {
    const { type, className } = this.props;

    return classnames(
      'base-badge',
      {
        [`badge-${type}`]: Boolean(type)
      },
      className
    );
  }

  render () {
    const {
      tooltipText,
      placement = 'bottom',
      children,
      style = {}
    } = this.props;

    return (
      <>
        <div
          style={style}
          className={this.getBadgeClasses()}
          onMouseOver={this.toggleTooltip.bind(this, true)}
          onMouseLeave={this.toggleTooltip.bind(this, false)}
          ref={(ref) => this.badgeRef = ref}
        >
          {children}
        </div>
        {
          tooltipText &&
          <Tooltip
            target={this.getBadgeRef}
            isOpen={this.state.isTooltipVisible}
            hideArrow
            placement={placement}
            innerClassName='base-tooltip'
          >
            {tooltipText}
          </Tooltip>
        }
      </>
    );
  }
}

Badge.defaultProps = {
  type: 'default'
};
