import React, { Component } from 'react';
import downCaret from '../icons/down-caret.svg';
import { map, filter } from 'lodash';
import classnames from 'classnames';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap';

export default class SelectDropdown extends Component {
  constructor () {
    super();

    this.state = {
      isOpen: false,
      query: ''
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleSearch (event) {
    const value = event?.target?.value;

    this.setState({ query: value });
  }

  handleToggle (event) {
    if (!event) {
      return;
    }

    if (this.props.onToggle) {
      return this.props.onToggle();
    }

    const { target } = event;

    try {
      // If the click is being registered from inside the
      // multi-select dropdown then bail-out
      if (!this.props.dismissOnSelect && target.closest('.dropdown-menu')) {
        return;
      }

      this.setState((prevState) => ({
        isOpen: !prevState.isOpen
      }));
    }
    catch (e) {
      this.setState((prevState) => ({
        isOpen: !prevState.isOpen
      }));
    }
  }

  getFilteredItems (items) {
    const { query } = this.state;

    if (!query) {
      return items;
    }

    return filter(items, (item) => {
      const sanitizedLabel = item?.label?.split('-')?.join('')?.toLowerCase();
      const sanitizedQuery = query?.split('-')?.join('')?.toLowerCase();

      return sanitizedLabel?.includes(sanitizedQuery);
    })
  }

  render () {
    const {
      isOpen,
      title,
      placeholder,
      items,
      itemRender,
      onClick,
      isSelected,
      className
    } = this.props;

    const {
      isOpen: isOpenFromState,
      query
    } = this.state;

    return (
      <div className={classnames('select-dropdown', className)}>
        <Dropdown
          isOpen={isOpen || isOpenFromState}
          toggle={this.handleToggle}
          // direction={this.state.isEndpointDropdownOpen ? 'up' : 'down'}
        >
          <DropdownToggle
            // caret
            // outline
            // color='primary'
            className='select-dropdown__btn'
            // className={classnames('route-selector', { 'active': !isEmpty(activeRoute) })}
          >
            <span style={{
              maxWidth: '180px',
              maxHeight: '380px',
              overflowX: 'hidden',
              overflowY: 'auto',
              textOverflow: 'ellipsis',
              whiteSpace: 'pre',
              display: 'inline-flex',
            }}>
              <span
                style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'pre',
                margin: '2px 8px 2px 8px'
              }}>
                {title}
              </span>
              <img
                src={downCaret}
                alt='down-caret'
                style={{
                  transform: (isOpen || isOpenFromState) ? 'rotate(-180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s'
                }}
              />
            </span>
          </DropdownToggle>
          <DropdownMenu
            style={{
              maxWidth: '180px',
              maxHeight: '380px',
              overflowX: 'hidden',
              overflowY: 'auto'
            }}
            right={this.props.right}
          >
            {
              !this.props.disableSearch &&
                <Input
                  className='select-dropdown__search'
                  placeholder={placeholder}
                  value={query}
                  onChange={this.handleSearch}
                  type='search'
                />
            }
            {
              map(this.getFilteredItems(items), (item, index) => {
                return (
                  <DropdownItem
                    onClick={onClick.bind(this, item.value)}
                    key={index}
                    className={classnames({'is-active': isSelected(item?.value)})}
                  >
                    {itemRender(item)}
                  </DropdownItem>
                );
              })
            }
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

SelectDropdown.defaultProps = {
  isSelected: () => { return false; },
  placeholder: 'Search',
  right: false,
  dismissOnSelect: false,
  disableSearch: false
}
