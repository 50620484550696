import { Observer } from 'mobx-react';
import classnames from 'classnames';
import React from 'react';
import { getStore } from '../../stores/get-store';

export const WarningBanner = ({ message }) => {
  return (
    <Observer>
      {
        () => {
          return (
            <div className={classnames('banner', { 'banner-shift': !!getStore('InfobarStore').activeInfo })}>
              {/* WARNING: All code on SolFarm is experimental. Please exercise discretion when using the dApp. */}
              <div className='banner-alt'>
                {message}
                {/* <span
                  className='banner-alt__link'
                  onClick={() => window.open('https://solfarm.gitbook.io/solfarm/vaults/strategy', '_blank')}
                >
                  Read more
                </span> */}
              </div>
            </div>
          )
        }
      }
    </Observer>
  );
};

WarningBanner.defaultProps = {
  message: 'Please note, you must stay staked for at least 2 hours to receive rewards.'
}