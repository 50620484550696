import { observable, makeObservable, action } from 'mobx';
import { isNumber, isNil } from 'lodash';

const DEFAULT_AUTO_REFRESH_TIME = 60;
const INIT_REFRESH_TIMEOUT = 6 * 1000;

export default class UIStore {
  constructor () {
    this.isDarkTheme = true;
    this.timeToRefresh = DEFAULT_AUTO_REFRESH_TIME;
    this.isRefreshing = false;
    this.isSilentRefresh = false;
    this.initRefresh = true;

    makeObservable(this, {
      isDarkTheme: observable,
      timeToRefresh: observable,
      isRefreshing: observable,
      isSilentRefresh: observable,
      toggleTheme: action.bound,
      setIsRefreshing: action.bound,
      setTimeToRefresh: action.bound,
      resetRefreshState: action.bound
    });
  }

  // Method to toggle the active theme
  toggleTheme () {
    this.isDarkTheme = !this.isDarkTheme;
  }

  setInitialRefreshTimeout () {
    this.initTimeout = setTimeout(() => {
      this.initRefresh = false;
    }, INIT_REFRESH_TIMEOUT);
  }

  setIsRefreshing (value) {
    if (value && !this.initTimeout) {
      this.setInitialRefreshTimeout();
    }

    // Only allow the loading and refresh state when the app is initially loading
    if (this.initRefresh) {
      !isNil(value) && (this.isRefreshing = value);
    }
    else {
      this.isRefreshing = false;
    }

    !isNil(value) && (this.isSilentRefresh = value);

    // Reset the timer
    this.setTimeToRefresh(DEFAULT_AUTO_REFRESH_TIME);
  }

  setTimeToRefresh (value) {
    isNumber(value) && (this.timeToRefresh = value);
  }

  resetRefreshState () {
    this.timeToRefresh = DEFAULT_AUTO_REFRESH_TIME;
    this.isRefreshing = false;
    this.isSilentRefresh = false;
  }
}