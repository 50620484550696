import React from 'react';
import classnames from 'classnames';
import { map } from 'lodash';

function Radio (props) {
  return (
    <div className='radio'>
      <div className={classnames({ 'radio--selected': props.isSelected })} />
    </div>
  );
}

function Card (props) {
  const {
    className,
    title,
    content,
    isSelected,
    onClick,
    value
  } = props;

  return (
    <div
      className={
        classnames(
          'radio-card',
          {
            'radio-card--selected': isSelected
          },
          className
        )
      }
      onClick={onClick}
    >
      <Radio isSelected={isSelected} />
      <div className='radio-card__title'>{title}</div>
      <div className='radio-card__content'>
        {content}
      </div>
    </div>
  );
}


export default function RadioCard (props) {
  const {
    name,
    selected,
    onClick,
    items
  } = props;

  return (
    <div className='radio-card-group'>
      {
        map(items, (card) => {
          return (
            <Card
              className={card.className}
              isSelected={card.value === selected}
              title={card.title}
              content={card.content}
              onClick={onClick.bind(this, card.value)}
            />
          );
        })
      }
    </div>
  );
}