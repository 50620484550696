import {
    Account,
    // AccountInfo,
    // Commitment,
    // Connection,
    PublicKey,
    SystemProgram,
    Transaction,
    // TransactionSignature
  } from '@solana/web3.js'

import { ACCOUNT_LAYOUT } from './layouts';
import { TOKEN_PROGRAM_ID } from './ids';
//   // eslint-disable-next-line
//   import assert from 'assert'
import { initializeAccount } from '@project-serum/serum/lib/token-instructions';
import { struct } from 'superstruct';
import { find, get, isEmpty, noop, slice } from 'lodash';
import { _OPEN_ORDERS_LAYOUT_V2 } from "@project-serum/serum/lib/market";
import * as anchor from '@project-serum/anchor';
import * as serumAssoToken from '@project-serum/associated-token';

  // export const endpoint = 'https://api.mainnet-beta.solana.com'
  // export const endpoint = 'https://solana-api.projectserum.com';
  // export const endpoint = 'https://solfarm.rpcpool.com';

export const commitment = 'confirmed';
  // export const commitment = 'finalized'

//   export async function createAmmAuthority(programId: PublicKey) {
//     const [ammAuthority, nonce] = await PublicKey.findProgramAddress(
//       [new Uint8Array(Buffer.from('amm authority'.replace('\u00A0', ' '), 'utf-8'))],
//       programId
//     )

//     return { ammAuthority, nonce }
//   }

export async function createTokenAccountIfNotExist(
  connection,
  account,
  owner,
  mintAddress,
  lamports,
  transaction,
  signer
) {
  let publicKey;

  if (account) {
    publicKey = new PublicKey(account);
  } else {
    publicKey = await createProgramAccountIfNotExist(
      connection,
      account,
      owner,
      TOKEN_PROGRAM_ID,
      lamports,
      ACCOUNT_LAYOUT,
      transaction,
      signer
    );

    transaction.add(
      initializeAccount({
        account: publicKey,
        mint: new PublicKey(mintAddress),
        owner
      })
    );
  }

  return publicKey;
}

export async function createProgramAccountIfNotExist(
  connection,
  account,
  owner,
  programId,
  lamports,
  layout,
  transaction,
  signer
) {
  let publicKey;

  if (account) {
    publicKey = new PublicKey(account);
  } else {
    const newAccount = new Account();
    publicKey = newAccount.publicKey;

    transaction.add(
      SystemProgram.createAccount({
        fromPubkey: owner,
        newAccountPubkey: publicKey,
        lamports: lamports ?? (await connection.getMinimumBalanceForRentExemption(layout.span)),
        space: layout.span,
        programId
      })
    );

    signer.push(newAccount);
  }

  return publicKey;
}

export async function getFilteredProgramAccounts(connection, programId, filters) {
  const resp = await connection._rpcRequest('getProgramAccounts', [
    programId.toBase58(),
    {
      commitment: connection.commitment,
      filters,
      encoding: 'base64'
    }
  ]);

  if (resp.error) {
    return console.error(resp.error.message);
  }

  return resp.result.map(({ pubkey, account: { data, executable, owner, lamports } }) => ({
    publicKey: new PublicKey(pubkey),
    accountInfo: {
      data: Buffer.from(data[0], 'base64'),
      executable,
      owner: new PublicKey(owner),
      lamports
    }
  }));
}

//   // getMultipleAccounts
export async function getMultipleAccounts(connection, publicKeys, commitment) {
  const keys = [];
  let tempKeys = [];

  publicKeys.forEach((k) => {
    if (tempKeys.length >= 100) {
      keys.push(tempKeys)
      tempKeys = []
    }
    tempKeys.push(k.toBase58())
  });

  if (tempKeys.length > 0) {
    keys.push(tempKeys)
  }

  const accounts = [];

  for (const key of keys) {
    const args = [key, { commitment }];

    const unsafeRes = await connection._rpcRequest('getMultipleAccounts', args);
    const res = GetMultipleAccountsAndContextRpcResult(unsafeRes)

    // Update slot globally
    window.$slot = get(res, 'result.context.slot');

    if (res.error) {
      console.error(
        'failed to get info about accounts ' + publicKeys.map((k) => k.toBase58()).join(', ') + ': ' + res.error.message
      );

      return;
    }

    // assert(typeof res.result !== 'undefined')

    for (const account of res.result.value) {
      let value = null;

      if (account === null) {
        accounts.push(null);
        continue;
      }

      if (res.result.value) {
        const { executable, owner, lamports, data } = account || {};

        // assert(data[1] === 'base64')

        value = {
          executable,
          owner: new PublicKey(owner),
          lamports,
          data: Buffer.from(data[0], 'base64')
        };
      }

      if (value === null) {
        console.error('Invalid response');
        return;
      }

      accounts.push(value)
    }
  }

  return accounts.map((account, idx) => {
    if (account === null) {
      return null;
    }

    return {
      publicKey: publicKeys[idx],
      account
    };
  });
}

/**
 * Get multiple accounts for grouped public keys (in arrays).
 *
 * @param {Object} connection - web3 connection
 * @param {Array[]} publicKeyGroupedArray - Array of array of public keys
 * @param {String} commitment
 *
 * @returns {Array[]}
 */
export async function getMultipleAccountsGrouped(connection, publicKeyGroupedArray, commitment) {
  let dataToFetch = [],
    responseToReturn = [];

  publicKeyGroupedArray.forEach((publicKeyArray) => {
    dataToFetch = dataToFetch.concat(publicKeyArray);
  });

  const dataFetched = await getMultipleAccounts(connection, dataToFetch, commitment);

  let lastIndex = 0;

  publicKeyGroupedArray.forEach((publicKeyArray) => {
    responseToReturn.push(
      slice(dataFetched, lastIndex, lastIndex + publicKeyArray.length)
    );

    lastIndex += publicKeyArray.length;
  });

  return responseToReturn;
}

function jsonRpcResult(resultDescription) {
  const jsonRpcVersion = struct.literal('2.0');

  return struct.union([
    struct({
      jsonrpc: jsonRpcVersion,
      id: 'string',
      error: 'any'
    }),
    struct({
      jsonrpc: jsonRpcVersion,
      id: 'string',
      error: 'null?',
      result: resultDescription
    })
  ]);
}

function jsonRpcResultAndContext(resultDescription) {
  return jsonRpcResult({
    context: struct({
      slot: 'number'
    }),
    value: resultDescription
  });
}

export const AccountInfoResult = struct({
  executable: 'boolean',
  owner: 'string',
  lamports: 'number',
  data: 'any',
  rentEpoch: 'number?'
});

const GetMultipleAccountsAndContextRpcResult = jsonRpcResultAndContext(
  struct.array([struct.union(['null', AccountInfoResult])])
);

//   // transaction
export async function signTransaction(connection, wallet, transaction, signers = []) {
  transaction.recentBlockhash = (await connection.getRecentBlockhash(commitment)).blockhash;
  transaction.setSigners(wallet.publicKey, ...signers.map((s) => s.publicKey));

  if (signers.length > 0) {
    transaction.partialSign(...signers);
  }

  return await wallet.signTransaction(transaction);
}

export async function sendTransaction(connection, wallet, transaction, signers) {
  const signedTransaction = await signTransaction(connection, wallet, transaction, signers)
  return await sendSignedTransaction(connection, signedTransaction);
}

export async function sendSignedTransaction(connection, signedTransaction) {
  const rawTransaction = signedTransaction.serialize();

  const txid = await connection.sendRawTransaction(rawTransaction, {
    skipPreflight: true,
    preflightCommitment: commitment
  });

  return txid;
}


// Bulk transactions
export async function signAllTransactions(connection, wallet, transactions, signers = [], extraSigners = []) {
  // let blockHashses = [];
  const recentBlockhash = (await connection.getRecentBlockhash('confirmed')).blockhash;

  const finalTransactions = []
  transactions.forEach((transaction, index) => {
    if (transaction.instructions.length > 0 ) {
      transaction.recentBlockhash = recentBlockhash;
      transaction.setSigners(wallet.publicKey, ...signers.map((s) => s.publicKey));

      if (extraSigners[index].length > 0) {
        const extraSigner = extraSigners[index]; // todo(therealssj): fix this
        transaction.setSigners(wallet.publicKey, ...extraSigner.map((s) => s.publicKey));
        transaction.partialSign(...extraSigner);
      }

      if (signers.length > 0) {
        transaction.partialSign(...signers);
      }

      finalTransactions.push(transaction)
    }
  });

  return await wallet.signAllTransactions(finalTransactions);
}

export async function sendAllTransactions(connection, wallet, transactions, signers, extraSigners) {
  const signedTransactions = await signAllTransactions(connection, wallet, transactions, signers, extraSigners);
  return await sendAllSignedTransactions(connection, signedTransactions);
}

export async function sendAllSignedTransactions(connection, signedTransactions) {
  const transactions = [];

  for (let signedTransaction of signedTransactions) {
    const rawTransaction = signedTransaction.serialize();
    const txId = await connection.sendRawTransaction(rawTransaction, {
      skipPreflight: true,
      preflightCommitment: 'confirmed'
    });

    console.log('Sending transaction ID:', txId);

    transactions.push(txId);
    await delay(2000);

    const signatureStatus = await window.$web3.getSignatureStatus(txId, {searchTransactionHistory: true});

    if (signatureStatus.value?.err) {
      throw new Error('Transaction not completed successfully. Please retry.');
    }
  }

  return transactions;

  return signTransactionsSynchronously(connection, signedTransactions, transactions, Promise.resolve);

  // const signTransactionsSynchronously = async (signedTransaction) => {
  //   if (shouldAbort) {
  //     return;
  //   }

  //   const rawTransaction = signedTransaction.serialize();
  //   const txId = await connection.sendRawTransaction(rawTransaction, {
  //     skipPreflight: true,
  //     preflightCommitment: commitment
  //   });

  //   transactions.push(txId);

  //   const listenerId = window.$web3.onSignature(
  //     txId,
  //     function (signatureResult, context) {
  //       if (shouldAbort) {
  //         return;
  //       }

  //       const { slot } = context;

  //       if (!signatureResult.err) {
  //         // success
  //         const nextSignedTransaction = transactionIterator.next().value;
  //         signedTransaction = nextSignedTransaction;

  //         if (nextSignedTransaction) {
  //           signTransactionsSynchronously(nextSignedTransaction);
  //         }
  //       } else {
  //         // fail
  //         shouldAbort = true;

  //         return;
  //       }
  //     },
  //     'single'
  //   );

  //   if (!signedTransaction) {
  //     return transactions;
  //   }
  // };


  // const rawTransaction = signedTransaction.serialize();
  // const txid = await connection.sendRawTransaction(rawTransaction, {
  //   skipPreflight: true,
  //   preflightCommitment: commitment
  // });
  // transactions.push(txid);

  // // for (let signedTransaction of signedTransactions) {
  //   const rawTransaction = signedTransaction.serialize();

  //   const txid = await connection.sendRawTransaction(rawTransaction, {
  //     skipPreflight: true,
  //     preflightCommitment: commitment
  //   });

  //   // const listenerId = window.$web3.onSignature(
  //   //   txId,
  //   //   function (signatureResult, context) {
  //   //     const { slot } = context;

  //   //     if (!signatureResult.err) {
  //   //       // success

  //   //       // console.log({ signatureResult, context });
  //   //       // this.props.toast('Transaction has been confirmed');
  //   //     } else {
  //   //       // fail

  //   //       // this.props.toast('Transaction failed', 'error');
  //   //     }
  //   //   },
  //   //   'single'
  //   // );

  //   transactions.push(txid);
  // // }

  // return transactions;
}

export async function signTransactionsSynchronously (connection, signedTransactions, transactions, resolve) {
  if (isEmpty(signedTransactions)) {
    return resolve(transactions);
  }

  return new Promise(async (resolve, reject) => {
    const signedTransaction = signedTransactions.shift();
    const rawTransaction = signedTransaction.serialize();
    const txId = await connection.sendRawTransaction(rawTransaction, {
      skipPreflight: true,
      preflightCommitment: commitment
    });

    // transactions.push(txId);

    console.log('sending txId', txId);

    const listenerId = window.$web3.onSignature(
      txId,
      async (signatureResult, context) => {
        const { slot } = context;

        if (!signatureResult.err) {
          // success

          console.log('sent txId', txId);
          transactions.push(txId);
          signTransactionsSynchronously(connection, signedTransactions, transactions, resolve);
        } else {
          // fail
          return reject(signatureResult.err);
        }
      },
      'confirmed'
    );
  });
};

export function delay (ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


//   export function mergeTransactions(transactions: (Transaction | undefined)[]) {
//     const transaction = new Transaction()
//     transactions
//       .filter((t): t is Transaction => t !== undefined)
//       .forEach((t) => {
//         transaction.add(t)
//       })
//     return transaction
//   }

export async function createOpenOrdersAccount(
  openOrderAccount,
  provider,
  owner,
  dexProgramId,
  signer
) {
  // let account = new Account();
  let openOrdersAddress = openOrderAccount.publicKey;
  const transaction = new Transaction();
  transaction.add(
    SystemProgram.createAccount({
      fromPubkey: provider.wallet.publicKey,
      newAccountPubkey: openOrdersAddress,
      lamports: await provider.connection.getMinimumBalanceForRentExemption(
        _OPEN_ORDERS_LAYOUT_V2.span
      ),
      space: _OPEN_ORDERS_LAYOUT_V2.span,
      programId: dexProgramId,
    })
  );
  let ownerAcct = await localAccount();
  let tx = await provider.send(transaction, [openOrderAccount, ownerAcct])
  console.log("create open orders account tx: ", tx);
  return openOrdersAddress;
}

// returns an account from the local id.json file from solana-keygen
export async function localAccount () {
  const payer = new Account(
    Buffer.from(
      JSON.parse(
        require("fs").readFileSync(
          require("os").homedir() + "/.config/solana/id.json",
          {
            encoding: "utf-8",
          }
        )
      )
    )
  );
  return payer;
}

export function getAccount () {
  return new anchor.web3.Account(
    Buffer.from(
      JSON.parse(
        require("fs").readFileSync(
          require("os").homedir() + "/.config/solana/id.json",
          {
            encoding: "utf-8",
          }
        )
      )
    )
  );
}

export async function createAssociatedTokenAccount(
  provider, // payer
  owner,
  mint
) {
  return await serumAssoToken.getAssociatedTokenAddress(owner, mint);
  //
  // if (!acct) {
  //   let txs = new anchor.web3.Transaction();
  //   txs.add(
  //     await serumAssoToken.createAssociatedTokenAccount(
  //       provider.wallet.publicKey,
  //       owner,
  //       mint
  //     )
  //   );
  //   await provider.send(txs);
  // }
  // // let acct = await serumAssoToken.getAssociatedTokenAddress(owner, mint);
  // return acct;
}

export const getUserFriendlyNameForEndpoint = (url) => {
  const endpoint = find(endpoints, (endpoint) => endpoint.url === url);

  return endpoint?.userFriendlyName;
}

export const endpoints = [
  // {
  //   url: 'https://rpc3.solfarm.io',
  //   weight: 40,
  //   userFriendlyName: 'SolFarm RPC'
  // },
  {
    url: 'https://solana_trial.genesysgo.net',
    weight: 50,
    userFriendlyName: 'SolFarm RPC'
  },
  // {
  //   url: 'https://api.mainnet-beta.solana.com',
  //   weight: 30,
  //   userFriendlyName: 'Solana RPC'
  // },
  {
    url: 'https://solana-api.projectserum.com',
    weight: 50,
    userFriendlyName: 'Serum RPC'
  }
  // {
  //   url: 'https://api.devnet.solana.com',
  //   weight: 100,
  //   userFriendlyName: 'Devnet'
  // }
  // {
  //   url: 'https://api.mainnet-beta.solana.com',
  //   weight: 0,
  //   userFriendlyName: 'Solana Mainnet'
  // }
];

export function getRandomEndpoint() {
  let pointer = 0;
  const random = Math.random() * 100;
  let api = endpoints[0].url;

  for (const endpoint of endpoints) {
    if (random > pointer + endpoint.weight) {
      pointer += pointer + endpoint.weight;
    } else if (random >= pointer && random < pointer + endpoint.weight) {
      api = endpoint.url;
      break;
    } else {
      console.log(`${random} using ${endpoint.url}`);
      api = endpoint.url;
      break;
    }
  }

  return api;
}
