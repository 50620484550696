import React from 'react';
import { observer } from 'mobx-react';
import { getStore } from '../../stores/get-store';
import RepairIssuesModal from './RepairIssuesModal';
import { useHistory } from 'react-router-dom';

export const ModalContainer = (props) => {

  // Initialize the store
  const modalStore = getStore('ModalStore');
  const { activeModal = {} } = modalStore;
  const history = useHistory();

  return (
    <RepairIssuesModal
      activeModalId={activeModal.id}
      {...(activeModal.props || {})}
      history={history}
      toast={props.toast}
    />
  );
}

export default observer(ModalContainer);
