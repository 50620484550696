import { find, concat } from 'lodash';
import { NATIVE_SOL, TOKENS } from "./tokens";
import { SABER_FARMS } from "./saber_farms";
import { ORCA_FARMS } from "./orcaFarms";

// Logos
import copeLogo from '../coins/cope.png';
import ethLogo from '../coins/eth.png';
import rayLogo from '../coins/ray.png';
import solLogo from '../coins/sol.png';
import srmLogo from '../coins/srm.png';
import usdcLogo from '../coins/usdc.png';
import usdtLogo from '../coins/usdt.png';
import stepLogo from '../coins/step.png';
import mediaLogo from '../coins/media.png';
import fidaLogo from '../coins/fida.png';
import oxyLogo from '../coins/oxy.png';
import mapsLogo from '../coins/maps.png';
import kinLogo from '../coins/kin.png';
import merLogo from '../coins/mer.png';
import ropeLogo from '../coins/rope.png';
import alephLogo from '../coins/aleph.png';
import tulipLogo from '../coins/tulip.png';
import snyLogo from '../coins/sny.png';
import bopLogo from '../coins/bop.png';
import slrsLogo from '../coins/slrs.png';
import samoLogo from '../coins/samo.png';
import paiLogo from '../coins/pai.png';
import likeLogo from '../coins/like.png';
import mngoLogo from '../coins/mngo.png';
import atlasLogo from '../coins/atlas.png';
import polisLogo from '../coins/polis.png';
import grapeLogo from '../coins/grape.png';
import larixLogo from '../coins/larix.jpeg';
import orcaLogo from '../coins/orca.png';

// Empty Logo to fill up space
import emptyLogo from '../coins/empty.png';
import { FARM_PLATFORMS } from '../constants/farmConstants';
import {isFarmNew} from "./farmUtils";

export const FARMS = [
  // {
  //   symbol: 'ORCA-USDC',
  //   name: 'ORCA-USDC LP',
  //   coin: { ...TOKENS.ORCA },
  //   pc: { ...TOKENS.USDC },
  //   reward: { ...TOKENS.ORCA },
  //   rewardB: { ...TOKENS.USDC },
  //
  //   mintAddress: 'Gc7W5U66iuHQcC1cQyeX9hxkPF2QUVJPTf1NWbW8fNrt',
  //   decimals: TOKENS.ORCA.decimals,
  //   logos: [orcaLogo, usdcLogo],
  //   dualYield: false,
  //   liquidityMining: false,
  //   totalTulipEmission: 0,
  //   saber: false,
  //   platform: FARM_PLATFORMS.RAYDIUM,
  //   coins: [TOKENS.ORCA, TOKENS.USDC],
  //   marginIndex: 17,
  //   slippageWarning: false,
  //   maxPositionLimitInUsd: 50000,
  //   link: 'https://raydium.io/liquidity/?ammId=96hPvuJ3SRT82m7BAc7G1AUVPVcoj8DABAa5gT7wjgzX',
  //   rewardEndSlot: 0,
  // },
  {
    symbol: 'LARIX-RAY',
    name: 'LARIX-RAY LP',
    coin: { ...TOKENS.LARIX },
    pc: { ...TOKENS.RAY },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.LARIX },

    mintAddress: 'ZRDfSLgWGeaYSmhdPvFNKQQhDcYdZQaue2N8YDmHX4q',
    decimals: TOKENS.LARIX.decimals,
    logos: [larixLogo, rayLogo],
    dualYield: true,
    liquidityMining: false,
    totalTulipEmission: 0,
    saber: false,
    coins: [TOKENS.LARIX, TOKENS.RAY],
    marginIndex: 1111,
    slippageWarning: false,
    maxPositionLimitInUsd: 0,
    link: 'https://raydium.io/liquidity/?ammId=EBqQdu9rGe6j3WGJQSyTvDjUMWcRd6uLcxSS4TbFT31t',
    rewardEndSlot: 93847628,
    platform: FARM_PLATFORMS.RAYDIUM,
    isNew: isFarmNew('16/10/2021')
  },
  {
    symbol: 'LARIX-USDC',
    name: 'LARIX-USDC LP',
    coin: { ...TOKENS.LARIX },
    pc: { ...TOKENS.USDC },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.LARIX },

    mintAddress: '7yieit4YsNsZ9CAK8H5ZEMvvk35kPEHHeXwp6naoWU9V',
    decimals: TOKENS.LARIX.decimals,
    logos: [larixLogo, usdcLogo],
    dualYield: false,
    liquidityMining: false,
    totalTulipEmission: 0,
    saber: false,
    coins: [TOKENS.LARIX, TOKENS.USDC],
    marginIndex: 1111,
    slippageWarning: false,
    maxPositionLimitInUsd: 100000,
    link: 'https://raydium.io/liquidity/?ammId=A21ui9aYTSs3CbkscaY6irEMQx3Z59dLrRuZQTt2hJwQ',
    rewardEndSlot: 93847628,
    platform: FARM_PLATFORMS.RAYDIUM,
  },
  {
    symbol: 'GRAPE-USDC',
    name: 'GRAPE-USDC LP',
    coin: { ...TOKENS.GRAPE },
    pc: { ...TOKENS.USDC },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.GRAPE },

    mintAddress: 'A8ZYmnZ1vwxUa4wpJVUaJgegsuTEz5TKy5CiJXffvmpt',
    decimals: TOKENS.GRAPE.decimals,
    logos: [grapeLogo, usdcLogo],
    dualYield: false,
    liquidityMining: false,
    totalTulipEmission: 0,
    saber: false,
    coins: [TOKENS.GRAPE, TOKENS.USDC],
    marginIndex: 1111,
    slippageWarning: false,
    maxPositionLimitInUsd: 100000,
    link: 'https://raydium.io/liquidity/?ammId=vVXfY15WdPsCmLvbiP4hWWECPFeAvPTuPNq3Q4BXfhy',
    rewardEndSlot: 93847628,
    platform: FARM_PLATFORMS.RAYDIUM,
  },
  {
    symbol: 'ATLAS-USDC',
    name: 'ATLAS-USDC LP',
    coin: { ...TOKENS.ATLAS },
    pc: { ...TOKENS.USDC },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.ATLAS },

    mintAddress: '9shGU9f1EsxAbiR567MYZ78WUiS6ZNCYbHe53WUULQ7n',
    decimals: TOKENS.ATLAS.decimals,
    logos: [atlasLogo, usdcLogo],
    dualYield: false,
    liquidityMining: false,
    totalTulipEmission: 0,
    saber: false,
    coins: [TOKENS.ATLAS, TOKENS.USDC],
    marginIndex: 19,
    slippageWarning: false,
    maxPositionLimitInUsd: 100000,
    link: 'https://raydium.io/liquidity/?ammId=2bnZ1edbvK3CK3LTNZ5jH9anvXYCmzPR4W2HQ6Ngsv5K',
    rewardEndSlot: 93847628,
    platform: FARM_PLATFORMS.RAYDIUM,
  },
  {
    symbol: 'POLIS-USDC',
    name: 'POLIS-USDC LP',
    coin: { ...TOKENS.POLIS },
    pc: { ...TOKENS.USDC },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.POLIS },

    mintAddress: '8MbKSBpyXs8fVneKgt71jfHrn5SWtX8n4wMLpiVfF9So',
    decimals: TOKENS.POLIS.decimals,
    logos: [polisLogo, usdcLogo],
    dualYield: false,
    liquidityMining: false,
    totalTulipEmission: 0,
    saber: false,
    coins: [TOKENS.POLIS, TOKENS.USDC],
    marginIndex: 21,
    slippageWarning: false,
    maxPositionLimitInUsd: 100000,
    link: 'https://raydium.io/liquidity/?ammId=9xyCzsHi1wUWva7t5Z8eAvZDRmUCVhRrbaFfm3VbU4Mf',
    rewardEndSlot: 93847628,
    platform: FARM_PLATFORMS.RAYDIUM,
  },
  {
    symbol: 'ATLAS-RAY',
    name: 'ATLAS-RAY LP',
    coin: { ...TOKENS.ATLAS },
    pc: { ...TOKENS.RAY },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.ATLAS },

    mintAddress: '418MFhkaYQtbn529wmjLLqL6uKxDz7j4eZBaV1cobkyd',
    decimals: TOKENS.ATLAS.decimals,
    logos: [atlasLogo, rayLogo],
    dualYield: true,
    liquidityMining: false,
    totalTulipEmission: 0,
    saber: false,
    coins: [TOKENS.ATLAS, TOKENS.RAY],
    marginIndex: 20,
    slippageWarning: false,
    maxPositionLimitInUsd: 100000,
    link: 'https://raydium.io/liquidity/?ammId=F73euqPynBwrgcZn3fNSEneSnYasDQohPM5aZazW9hp2',
    rewardEndSlot: 93847628,
    platform: FARM_PLATFORMS.RAYDIUM,
  },
  {
    symbol: 'POLIS-RAY',
    name: 'POLIS-RAY LP',
    coin: { ...TOKENS.POLIS },
    pc: { ...TOKENS.RAY },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.POLIS },

    mintAddress: '9ysGKUH6WqzjQEUT4dxqYCUaFNVK9QFEa24pGzjFq8xg',
    decimals: TOKENS.POLIS.decimals,
    logos: [polisLogo, rayLogo],
    dualYield: true,
    liquidityMining: false,
    totalTulipEmission: 0,
    saber: false,
    coins: [TOKENS.POLIS, TOKENS.RAY],
    marginIndex: 22,
    slippageWarning: false,
    maxPositionLimitInUsd: 100000,
    link: 'https://raydium.io/liquidity/?ammId=5tho4By9RsqTF1rbm9Akiepik3kZBT7ffUzGg8bL1mD',
    rewardEndSlot: 93847628,
    platform: FARM_PLATFORMS.RAYDIUM,
  },
  {
    symbol: 'TULIP-USDC',
    name: 'TULIP-USDC LP',
    coin: { ...TOKENS.TULIP },
    pc: { ...TOKENS.USDC },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.TULIP },

    mintAddress: '2doeZGLJyACtaG9DCUyqMLtswesfje1hjNA11hMdj6YU',
    decimals: TOKENS.TULIP.decimals,
    logos: [tulipLogo, usdcLogo],
    dualYield: false,
    liquidityMining: true,
    totalTulipEmission: 450000 / 810,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.TULIP, TOKENS.USDC],
    marginIndex: 11,
    slippageWarning: true,
    maxPositionLimitInUsd: 100000,
    link: 'https://raydium.io/liquidity/?ammId=96hPvuJ3SRT82m7BAc7G1AUVPVcoj8DABAa5gT7wjgzX',
    rewardEndSlot: 93847628,
  },
  {
    symbol: 'RAY',
    name: 'Raydium',
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.RAY },

    mintAddress: '4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R',
    decimals: 6,
    referrer: '33XpMmMQRf6tSPpmYyzpwU4uXpZHkFwCZsusD9dMYkjy',
    logos: [rayLogo, emptyLogo],
    singleStake: true,
    liquidityMining: true,
    totalTulipEmission: 450000 / 810,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.RAY, TOKENS.RAY],
    maxPositionLimitInUsd: 100000,
    link: 'https://raydium.io/staking/',
    rewardEndSlot: 94284986,
  },
  {
    symbol: 'RAY-USDT',
    name: 'RAY-USDT LP',
    coin: { ...TOKENS.RAY },
    pc: { ...TOKENS.USDT },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.RAY },

    mintAddress: 'C3sT1R3nsw4AVdepvLTLKr5Gvszr7jufyBWUCvy4TUvT',
    decimals: TOKENS.RAY.decimals,
    logos: [rayLogo, usdtLogo],
    liquidityMining: true,
    totalTulipEmission: 750000 / 630,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.RAY, TOKENS.USDT],
    marginIndex: 2,
    maxPositionLimitInUsd: 300000,
    link: 'https://raydium.io/liquidity/?ammId=DVa7Qmb5ct9RCpaU7UTpSaf3GVMYz17vNVU67XpdCRut',
    rewardEndSlot: 94284901,
  },
  {
    symbol: 'RAY-USDC',
    name: 'RAY-USDC LP',
    coin: { ...TOKENS.RAY },
    pc: { ...TOKENS.USDC },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.RAY },

    mintAddress: 'FbC6K13MzHvN42bXrtGaWsvZY9fxrackRSZcBGfjPc7m',
    decimals: TOKENS.RAY.decimals,
    logos: [rayLogo, usdcLogo],
    liquidityMining: true,
    totalTulipEmission: 750000 / 630,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.RAY, TOKENS.USDC],
    marginIndex: 0,
    whitelisted: false,
    disabled: false,
    maxPositionLimitInUsd: 300000,
    link: 'https://raydium.io/liquidity/?ammId=6UmmUiYoBjSrhakAobJw8BvkmJtDVxaeBtbt7rxWo1mg',
    migrated: true,
    rewardEndSlot: 94285165,
  },
  {
    symbol: 'RAY-SRM',
    name: 'RAY-SRM LP',
    coin: { ...TOKENS.RAY },
    pc: { ...TOKENS.SRM },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.RAY },

    mintAddress: '7P5Thr9Egi2rvMmEuQkLn8x8e8Qro7u2U7yLD2tU2Hbe',
    decimals: TOKENS.RAY.decimals,
    logos: [rayLogo, srmLogo],
    liquidityMining: true,
    totalTulipEmission: 750000 / 630,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.RAY, TOKENS.SRM],
    marginIndex: 3,
    whitelisted: false,
    disabled: false,
    maxPositionLimitInUsd: 300000,
    link: 'https://raydium.io/liquidity/?ammId=GaqgfieVmnmY4ZsZHHA6L5RSVzCGL3sKx4UgHBaYNy8m',
    migrated: true,
    rewardEndSlot: 94284925,
  },
  {
    symbol: 'RAY-SOL',
    name: 'RAY-SOL LP',
    coin: { ...TOKENS.RAY },
    pc: { ...NATIVE_SOL },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.RAY },

    mintAddress: '89ZKE4aoyfLBe2RuV6jM3JGNhaV18Nxh8eNtjRcndBip',
    decimals: TOKENS.RAY.decimals,
    logos: [rayLogo, solLogo],
    liquidityMining: true,
    totalTulipEmission: 750000 / 630,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.RAY, NATIVE_SOL],
    marginIndex: 1,
    whitelisted: false,
    disabled: false,
    maxPositionLimitInUsd: 300000,
    link: 'https://raydium.io/liquidity/?ammId=AVs9TA4nWDzfPJE9gGVNJMVhcQy3V9PGazuz33BfG2RA',
    migrated: true,
    rewardEndSlot: 94284942,
  },
  {
    symbol: 'RAY-ETH',
    name: 'RAY-ETH LP',
    coin: { ...TOKENS.RAY },
    pc: { ...TOKENS.ETH },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.RAY },

    mintAddress: 'mjQH33MqZv5aKAbKHi8dG3g3qXeRQqq1GFcXceZkNSr',
    decimals: TOKENS.RAY.decimals,
    logos: [rayLogo, ethLogo],
    liquidityMining: true,
    totalTulipEmission: 750000 / 630,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.RAY, TOKENS.ETH],
    whitelisted: false,
    disabled: false,
    marginIndex: 7,
    maxPositionLimitInUsd: 300000,
    link: 'https://raydium.io/liquidity/?ammId=8iQFhWyceGREsWnLM8NkG9GC8DvZunGZyMzuyUScgkMK',
    migrated: true,
    rewardEndSlot: 94284954,
  },
  {
    symbol: 'MEDIA-USDC',
    name: 'MEDIA-USDC LP',
    coin: { ...TOKENS.MEDIA },
    pc: { ...TOKENS.USDC },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.MEDIA },

    mintAddress: 'A5zanvgtioZGiJMdEyaKN4XQmJsp1p7uVxaq2696REvQ',
    decimals: TOKENS.MEDIA.decimals,
    logos: [mediaLogo, usdcLogo],
    liquidityMining: true,
    totalTulipEmission: 750000 / 630,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.MEDIA, TOKENS.USDC],
    marginIndex: 5,
    maxPositionLimitInUsd: 100000,
    link: 'https://raydium.io/liquidity/?ammId=94CQopiGxxUXf2avyMZhAFaBdNatd62ttYGoTVQBRGdi',
    rewardEndSlot: 94284801,
  },
  {
    symbol: 'COPE-USDC',
    name: 'COPE-USDC LP',
    coin: { ...TOKENS.COPE },
    pc: { ...TOKENS.USDC },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.COPE },

    mintAddress: 'Cz1kUvHw98imKkrqqu95GQB9h1frY8RikxPojMwWKGXf',
    decimals: TOKENS.COPE.decimals,
    logos: [copeLogo, usdcLogo],
    liquidityMining: true,
    totalTulipEmission: 450000 / 810,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.COPE, TOKENS.USDC],
    marginIndex: 6,
    maxPositionLimitInUsd: 100000,
    link: 'https://raydium.io/liquidity/?ammId=DiWxV1SPXPNJRCt5Ao1mJRAxjw97hJVyj8qGzZwFbAFb',
    slippageWarning: true,
    rewardEndSlot: 94284813,
  },
  {
    symbol: 'MER-USDC',
    name: 'MER-USDC LP',
    coin: { ...TOKENS.MER },
    pc: { ...TOKENS.USDC },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.MER },

    mintAddress: '3H9NxvaZoxMZZDZcbBDdWMKbrfNj7PCF5sbRwDr7SdDW',
    decimals: TOKENS.MER.decimals,
    logos: [merLogo, usdcLogo],
    liquidityMining: true,
    totalTulipEmission: 450000 / 810,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.MER, TOKENS.USDC],
    marginIndex: 4,
    maxPositionLimitInUsd: 0,
    link: 'https://raydium.io/liquidity/?ammId=BkfGDk676QFtTiGxn7TtEpHayJZRr6LgNk9uTV2MH4bR',
    rewardEndSlot: 94284972,
  },
  {
    symbol: 'ROPE-USDC',
    name: 'ROPE-USDC LP',
    coin: { ...TOKENS.ROPE },
    pc: { ...TOKENS.USDC },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.ROPE },

    mintAddress: 'Cq4HyW5xia37tKejPF2XfZeXQoPYW6KfbPvxvw5eRoUE',
    decimals: TOKENS.ROPE.decimals,
    logos: [ropeLogo, usdcLogo],
    liquidityMining: true,
    totalTulipEmission: 450000 / 810,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.ROPE, TOKENS.USDC],
    marginIndex: 9,
    maxPositionLimitInUsd: 0,
    link: 'https://raydium.io/liquidity/?ammId=BuS4ScFcZjEBixF1ceCTiXs4rqt4WDfXLoth7VcM2Eoj',
    rewardEndSlot: 94284962,
  },
  {
    symbol: 'ALEPH-USDC',
    name: 'ALEPH-USDC LP',
    coin: { ...TOKENS.ALEPH },
    pc: { ...TOKENS.USDC },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.ALEPH },

    mintAddress: 'iUDasAP2nXm5wvTukAHEKSdSXn8vQkRtaiShs9ceGB7',
    decimals: TOKENS.ALEPH.decimals,
    logos: [alephLogo, usdcLogo],
    dualYield: false,
    liquidityMining: true,
    totalTulipEmission: 450000 / 810,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.ALEPH, TOKENS.USDC],
    marginIndex: 10,
    maxPositionLimitInUsd: 100000,
    link: 'https://raydium.io/liquidity/?ammId=GDHXjn9wF2zxW35DBkCegWQdoTfFBC9LXt7D5ovJxQ5B',
    slippageWarning: true,
    rewardEndSlot: 94134929,
  },
  {
    symbol: 'SNY-USDC',
    name: 'SNY-USDC LP',
    coin: { ...TOKENS.SNY },
    pc: { ...TOKENS.USDC },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.SNY },

    mintAddress: 'G8qcfeFqxwbCqpxv5LpLWxUCd1PyMB5nWb5e5YyxLMKg',
    decimals: TOKENS.SNY.decimals,
    logos: [snyLogo, usdcLogo],
    dualYield: false,
    liquidityMining: true,
    totalTulipEmission: 450000 / 810,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.SNY, TOKENS.USDC],
    marginIndex: 12,
    maxPositionLimitInUsd: 100000,
    link: 'https://raydium.io/liquidity/?ammId=5TgJXpv6H3KJhHCuP7KoDLSCmi8sM8nABizP7CmYAKm1',
    slippageWarning: true,
    rewardEndSlot: 93252854,
    disabled: true,
  },
  {
    symbol: 'SLRS-USDC',
    name: 'SLRS-USDC LP',
    coin: { ...TOKENS.SLRS },
    pc: { ...TOKENS.USDC },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.SLRS },

    mintAddress: '2Xxbm1hdv5wPeen5ponDSMT3VqhGMTQ7mH9stNXm9shU',
    decimals: TOKENS.SLRS.decimals,
    logos: [slrsLogo, usdcLogo],
    dualYield: false,
    liquidityMining: true,
    totalTulipEmission: 0, //450000 / 810
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.SLRS, TOKENS.USDC],
    marginIndex: 14,
    slippageWarning: true,
    maxPositionLimitInUsd: 100000,
    link: 'https://raydium.io/liquidity/?ammId=7XXKU8oGDbeGrkPyK5yHKzdsrMJtB7J2TMugjbrXEhB5',
    rewardEndSlot: 93040503,
  },
  {
    symbol: 'LIKE-USDC',
    name: 'LIKE-USDC LP',
    coin: { ...TOKENS.LIKE },
    pc: { ...TOKENS.USDC },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.LIKE },

    mintAddress: 'cjZmbt8sJgaoyWYUttomAu5LJYU44ZrcKTbzTSEPDVw',
    decimals: TOKENS.LIKE.decimals,
    logos: [likeLogo, usdcLogo],
    dualYield: false,
    liquidityMining: false,
    totalTulipEmission: 0,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.LIKE, TOKENS.USDC],
    marginIndex: 16,
    maxPositionLimitInUsd: 100000,
    link: 'https://raydium.io/liquidity/?ammId=GmaDNMWsTYWjaXVBjJTHNmCWAKU6cn5hhtWWYEZt4odoq',
    rewardEndSlot: 93040503,
  },
  // {
  //   symbol: 'TULIP-RAY',
  //   name: 'TULIP-RAY LP',
  //   coin: { ...TOKENS.TULIP },
  //   pc: { ...TOKENS.RAY },
  //   reward: { ...TOKENS.RAY },
  //   rewardB: { ...TOKENS.TULIP },
  //
  //   mintAddress: '3AZTviji5qduMG2s4FfWGR3SSQmNUCyx8ao6UKCPg3oJ',
  //   decimals: TOKENS.TULIP.decimals,
  //   logos: [tulipLogo, rayLogo],
  //   dualYield: true,
  //   liquidityMining: false,
  //   totalTulipEmission: 0,
  //   disabled: false,
  //   saber: false,
  //   platform: FARM_PLATFORMS.RAYDIUM,
  //   coins: [TOKENS.TULIP, TOKENS.RAY],
  //   link: 'https://raydium.io/liquidity/?ammId=Dm1Q15216uRARmQTbo6VfnyEGVzRvLTm4TfCWWX4MF3F'
  // },
  // {
  //   symbol: 'COPE-RAY',
  //   name: 'COPE-RAY LP',
  //   coin: { ...TOKENS.COPE },
  //   pc: { ...TOKENS.RAY },
  //   reward: { ...TOKENS.RAY },
  //   rewardB: { ...TOKENS.COPE },
  //
  //   mintAddress: 'A7GCVHA8NSsbdFscHdoNU41tL1TRKNmCH4K94CgcLK9F',
  //   decimals: TOKENS.COPE.decimals,
  //   logos: [copeLogo, rayLogo],
  //   dualYield: true,
  //   liquidityMining: false,
  //   totalTulipEmission: 0,
  //   disabled: false,
  //   saber: false,
  //   platform: FARM_PLATFORMS.RAYDIUM,
  //   coins: [TOKENS.COPE, TOKENS.RAY],
  //   link: 'https://raydium.io/liquidity/?ammId=8hvVAhShYLPThcxrxwMNAWmgRCSjtxygj11EGHp2WHz8'
  // },
  // {
  //   symbol: 'LIKE-RAY',
  //   name: 'LIKE-RAY LP',
  //   coin: { ...TOKENS.LIKE },
  //   pc: { ...TOKENS.RAY },
  //   reward: { ...TOKENS.RAY },
  //   rewardB: { ...TOKENS.LIKE },
  //
  //   mintAddress: '7xqDycbFSCpUpzkYapFeyPJWPwEpV7zdWbYf2MVHTNjv',
  //   decimals: TOKENS.LIKE.decimals,
  //   logos: [likeLogo, rayLogo],
  //   dualYield: true,
  //   liquidityMining: false,
  //   totalTulipEmission: 0,
  //   disabled: false,
  //   saber: false,
  //   platform: FARM_PLATFORMS.RAYDIUM,
  //   coins: [TOKENS.LIKE, TOKENS.RAY],
  //   link: 'https://raydium.io/liquidity/?ammId=DGSnfcE1kw4uDC6jgrsZ3s5CMfsWKN7JNjDNasHdvKfq'
  // },
  // {
  //   symbol: 'SNY-RAY',
  //   name: 'SNY-RAY LP',
  //   coin: { ...TOKENS.SNY },
  //   pc: { ...TOKENS.RAY },
  //   reward: { ...TOKENS.RAY },
  //   rewardB: { ...TOKENS.SNY },
  //
  //   mintAddress: '2k4quTuuLUxrSEhFH99qcoZzvgvVEc3b5sz3xz3qstfS',
  //   decimals: TOKENS.SNY.decimals,
  //   logos: [snyLogo, rayLogo],
  //   dualYield: true,
  //   liquidityMining: false,
  //   totalTulipEmission: 0,
  //   disabled: false,
  //   saber: false,
  //   platform: FARM_PLATFORMS.RAYDIUM,
  //   coins: [TOKENS.SNY, TOKENS.RAY],
  //   link: 'https://raydium.io/liquidity/?ammId=Am9FpX73ctZ3HzohcRdyCCv84iT7nugevqLjY5yTSUQP'
  // },
  // {
  //   symbol: 'MEDIA-RAY',
  //   name: 'MEDIA-RAY LP',
  //   coin: { ...TOKENS.MEDIA },
  //   pc: { ...TOKENS.RAY },
  //   reward: { ...TOKENS.RAY },
  //   rewardB: { ...TOKENS.MEDIA },
  //
  //   mintAddress: '9Aseg5A1JD1yCiFFdDaNNxCiJ7XzrpZFmcEmLjXFdPaH',
  //   decimals: TOKENS.MEDIA.decimals,
  //   logos: [mediaLogo, rayLogo],
  //   dualYield: true,
  //   liquidityMining: false,
  //   totalTulipEmission: 0,
  //   disabled: false,
  //   saber: false,
  //   platform: FARM_PLATFORMS.RAYDIUM,
  //   coins: [TOKENS.MEDIA, TOKENS.RAY],
  //   link: 'https://raydium.io/liquidity/?ammId=5ZPBHzMr19iQjBaDgFDYGAx2bxaQ3TzWmSS7zAGrHtQJ'
  // },
  // {
  //   symbol: 'MER-RAY',
  //   name: 'MER-RAY LP',
  //   coin: { ...TOKENS.MER },
  //   pc: { ...TOKENS.RAY },
  //   reward: { ...TOKENS.RAY },
  //   rewardB: { ...TOKENS.MER },
  //
  //   mintAddress: '214hxy3AbKoaEKgqcg2aC1cP5R67cGGAyDEg5GDwC7Ub',
  //   decimals: TOKENS.MER.decimals,
  //   logos: [merLogo, rayLogo],
  //   dualYield: true,
  //   liquidityMining: false,
  //   totalTulipEmission: 0,
  //   disabled: false,
  //   saber: false,
  //   platform: FARM_PLATFORMS.RAYDIUM,
  //   coins: [TOKENS.MER, TOKENS.RAY],
  //   link: 'https://raydium.io/liquidity/?ammId=BKLCqnuk4qc5iHWuJuewMxuvsNZXuTBSUyRT5ftnRb6H'
  // },
  // {
  //   symbol: 'SLRS-RAY',
  //   name: 'SLRS-RAY LP',
  //   coin: { ...TOKENS.SLRS },
  //   pc: { ...TOKENS.RAY },
  //   reward: { ...TOKENS.RAY },
  //   rewardB: { ...TOKENS.SLRS },
  //
  //   mintAddress: '2pk78vsKT3jfJAcN2zbpMUnrR57SZrxHqaZYyFgp92mM',
  //   decimals: TOKENS.SLRS.decimals,
  //   logos: [slrsLogo, rayLogo],
  //   dualYield: true,
  //   liquidityMining: false,
  //   totalTulipEmission: 0,
  //   disabled: false,
  //   saber: false,
  //   platform: FARM_PLATFORMS.RAYDIUM,
  //   coins: [TOKENS.SLRS, TOKENS.RAY],
  //   link: 'https://raydium.io/liquidity/?ammId=J3CoGcJqHquUdSgS7qAwdGbp3so4EpLX8eVDdGuauvi'
  // },
  {
    symbol: 'BOP-RAY',
    name: 'BOP-RAY LP',
    coin: { ...TOKENS.BOP },
    pc: { ...TOKENS.RAY },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.BOP },

    mintAddress: '9nQPYJvysyfnXhQ6nkK5V7sZG26hmDgusfdNQijRk5LD',
    decimals: TOKENS.BOP.decimals,
    logos: [bopLogo, rayLogo],
    dualYield: false,
    liquidityMining: false,
    totalTulipEmission: 0,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.BOP, TOKENS.RAY],
    link: 'https://raydium.io/liquidity/?ammId=SJmR8rJgzzCi4sPjGnrNsqY4akQb3jn5nsxZBhyEifC',
    rewardEndSlot: 93040503,
  },
  {
    symbol: 'SAMO-RAY',
    name: 'SAMO-RAY LP',
    coin: { ...TOKENS.SAMO },
    pc: { ...TOKENS.RAY },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.SAMO },

    mintAddress: 'HwzkXyX8B45LsaHXwY8su92NoRBS5GQC32HzjQRDqPnr',
    decimals: TOKENS.SAMO.decimals,
    logos: [samoLogo, rayLogo],
    dualYield: false,
    liquidityMining: false,
    totalTulipEmission: 0,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.SAMO, TOKENS.RAY],
    link: 'https://raydium.io/liquidity/?ammId=EyDgEU9BdG7m6ZK4bYERxbN4NCJ129WzPtv23dBkfsLg',
    rewardEndSlot: 93040503,
  },
  {
    symbol: 'MNGO-USDC',
    name: 'MNGO-USDC LP',
    coin: { ...TOKENS.MNGO },
    pc: { ...TOKENS.USDC },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.MNGO },

    mintAddress: 'DkiqCQ792n743xjWQVCbBUaVtkdiuvQeYndM53ReWnCC',
    decimals: TOKENS.MNGO.decimals,
    logos: [mngoLogo, usdcLogo],
    dualYield: false,
    liquidityMining: false,
    totalTulipEmission: 0,
    saber: false,
    disabled: true,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.MNGO, TOKENS.USDC],
    marginIndex: 200,
    maxPositionLimitInUsd: 0,
    link: 'https://raydium.io/liquidity/?ammId=34tFULRrRwh4bMcBLPtJaNqqe5pVgGZACi5sR8Xz95KC',
    rewardEndSlot: 93040503,
  },
  {
    symbol: 'RAY-SRM-DUAL',
    name: 'RAY-SRM LP',
    coin: { ...TOKENS.RAY },
    pc: { ...TOKENS.SRM },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.SRM },

    mintAddress: '7P5Thr9Egi2rvMmEuQkLn8x8e8Qro7u2U7yLD2tU2Hbe',
    decimals: TOKENS.RAY.decimals,
    logos: [rayLogo, srmLogo],
    liquidityMining: false,
    totalTulipEmission: 0,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.RAY, TOKENS.SRM],
    marginIndex: 666,
    whitelisted: false,
    disabled: true,
    maxPositionLimitInUsd: 0,
    link: 'https://raydium.io/liquidity/?ammId=GaqgfieVmnmY4ZsZHHA6L5RSVzCGL3sKx4UgHBaYNy8m',
    migrated: false,
    dualYield: true,
    rewardEndSlot: 0,
  },
  {
    symbol: 'STEP-USDC',
    name: 'STEP-USDC LP',
    coin: { ...TOKENS.STEP },
    pc: { ...TOKENS.USDC },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.STEP },

    mintAddress: '3k8BDobgihmk72jVmXYLE168bxxQUhqqyESW4dQVktqC',
    decimals: TOKENS.STEP.decimals,
    logos: [stepLogo, usdcLogo],
    liquidityMining: true,
    totalTulipEmission: 750000 / 630,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.STEP, TOKENS.USDC],
    marginIndex: 8,
    disabled: true,
    maxPositionLimitInUsd: 0,
    link: 'https://raydium.io/liquidity/?ammId=4Sx1NLrQiK4b9FdLKe2DhQ9FHvRzJhzKN3LoD6BrEPnf',
    rewardEndSlot: 94285155,
  },
  {
    symbol: 'KIN-RAY',
    name: 'KIN-RAY LP',
    coin: { ...TOKENS.KIN },
    pc: { ...TOKENS.RAY },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.KIN },

    mintAddress: 'CHT8sft3h3gpLYbCcZ9o27mT5s3Z6VifBVbUiDvprHPW',
    decimals: 6,
    logos: [kinLogo, rayLogo],
    dualYield: true,
    liquidityMining: true,
    totalTulipEmission: 0,
    disabled: true,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.KIN, TOKENS.RAY],
    link: 'https://raydium.io/liquidity/?ammId=6kmMMacvoCKBkBrqssLEdFuEZu2wqtLdNQxh9VjtzfwT',
    rewardEndSlot: 93040503,
  },
  {
    symbol: 'FIDA-RAY',
    name: 'FIDA-RAY LP',
    coin: { ...TOKENS.FIDA },
    pc: { ...TOKENS.RAY },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.FIDA },

    mintAddress: 'DsBuznXRTmzvEdb36Dx3aVLVo1XmH7r1PRZUFugLPTFv',
    decimals: TOKENS.FIDA.decimals,
    logos: [fidaLogo, rayLogo],
    dualYield: true,
    liquidityMining: true,
    totalTulipEmission: 0,
    disabled: true,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.FIDA, TOKENS.RAY],
    link: 'https://raydium.io/liquidity/?ammId=2dRNngAm729NzLbb1pzgHtfHvPqR4XHFmFyYK78EfEeX',
    rewardEndSlot: 93040503,
  },
  {
    symbol: 'OXY-RAY',
    name: 'OXY-RAY LP',
    coin: { ...TOKENS.OXY },
    pc: { ...TOKENS.RAY },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.OXY },

    mintAddress: 'FwaX9W7iThTZH5MFeasxdLpxTVxRcM7ZHieTCnYog8Yb',
    decimals: TOKENS.OXY.decimals,
    logos: [oxyLogo, rayLogo],
    dualYield: true,
    liquidityMining: true,
    totalTulipEmission: 0,
    disabled: true,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.OXY, TOKENS.RAY],
    link: 'https://raydium.io/liquidity/?ammId=B5ZguAWAGC3GXVtJZVfoMtzvEvDnDKBPCevsUKMy4DTZ',
    rewardEndSlot: 93040503,
  },
  {
    symbol: 'MAPS-RAY',
    name: 'MAPS-RAY LP',
    coin: { ...TOKENS.MAPS },
    pc: { ...TOKENS.RAY },
    reward: { ...TOKENS.RAY },
    rewardB: { ...TOKENS.MAPS },

    mintAddress: 'CcKK8srfVdTSsFGV3VLBb2YDbzF4T4NM2C3UEjC39RLP',
    decimals: TOKENS.MAPS.decimals,
    logos: [mapsLogo, rayLogo],
    dualYield: true,
    liquidityMining: true,
    totalTulipEmission: 0,
    disabled: true,
    saber: false,
    platform: FARM_PLATFORMS.RAYDIUM,
    coins: [TOKENS.MAPS, TOKENS.RAY],
    link: 'https://raydium.io/liquidity/?ammId=5VyLSjUvaRxsubirbvbfJMbrKZRx1b7JZzuCAfyqgimf',
    rewardEndSlot: 93040503,
  },
];

export const ALL_FARMS = concat(SABER_FARMS, FARMS, ORCA_FARMS);

export const ALL_VAULT_FARMS = concat(SABER_FARMS, FARMS, ORCA_FARMS);

export const LEVERAGE_FARMS = concat(FARMS, ORCA_FARMS);

export const getFarmBySymbol = (symbol) => find(ALL_FARMS, (farm) => farm.symbol === symbol);

export const getFarmByMintAddress = (mintAddress) => find(ALL_FARMS, (farm) => farm.mintAddress === mintAddress);

export const getFarmStoreKey = (farm) => `${farm.mintAddress}+${farm.symbol}`;


/*
  RAY-USDT - v4
  RAY-USDC - v3
  RAY-SRM - v3
  RAY-SOL - v3
  RAY-ETH - v3
  COPE-USDC - v4

  RAY-USDT  - C3sT1R3nsw4AVdepvLTLKr5Gvszr7jufyBWUCvy4TUvT
  RAY-USDC - BZFGfXMrjG2sS7QT2eiCDEevPFnkYYF7kzJpWfYxPbcx
  RAY-SRM - DSX5E21RE9FB9hM8Nh8xcXQfPK6SzRaJiywemHBSsfup
  RAY-SOL - F5PPQHGcznZ2FxD9JaxJMXaf7XkaFFJ6zzTBcW8osQjw
  RAY-ETH - 8Q6MKy5Yxb9vG1mWzppMtMb2nrhNuCRNUkJTeiE3fuwD
  COPE-USDC - 2Vyyeuyd15Gp8aH6uKE72c4hxc8TVSLibxDP9vzspQWG
*/
