import { observable, action, computed, values, makeObservable } from 'mobx';
import { assign, head, orderBy } from 'lodash';

class ModalItemStore {
  id = '';

  // The priority is important to decide which modal item will be currently shown in the UI
  // The higher the priority number is, the more precedence it takes compared to other
  // modal items in the list.
  priority = 0;

  props = {};

  constructor (modal) {
    this.id = modal.id;
    this.update(modal);
  }

  update (definition) {
    this.priority = definition.priority;
    this.props = definition;
  }
}

export default class ModalStore {
  constructor () {
    // Stores all the modals which are of type ModalItemStore
    this.modalList = new Map();

    makeObservable(this, {
      modalList: observable,
      activeModal: computed,
      _add: action.bound,
      _remove: action.bound
    });
  }

  /**
   * @description Finds and returns the item which has the max-priority to be shown to the user
   */
  get activeModal () {
    // Reverse the list so that the order of insertion is by the time it was inserted
    // and then check the maximum by priority in case two items have equal priority
    const modalList = orderBy(values(this.modalList).reverse(), 'priority', ['desc']);

    return head(modalList);
  }

  /**
   * @private
   * @description Adds a new item to the modal list
   *
   * @param {Object} modal
   */
  _add (id, modal) {
    this.modalList.set(id, modal);
  }

  /**
   * @private
   * @description Removes the item from the modal list
   *
   * @param {String} id
   */
  _remove (id) {
    this.modalList.delete(id);
  }

  isActive (id) {
    return this.activeModal?.id === id;
  }

  /**
   * @description Clears the modal list
   */
  clear () {
    // The dismiss function is called to make sure all the onDismiss functions
    // get called for this.
    this.modalList.forEach((modal) => this.dismiss(modal.id));
  }

  /**
   * @description Enqueue a new item to the modal list to be shown to the user
   *
   * @param {Object} modal
   */
  trigger (modal) {
    // This ID can be provided (in cases they want to remove that banner) or generated by us as 'guid'
    let sanitizedModal = assign(
      { priority: 0 },
      modal,
      { id: modal.id }
    );

    this._add(sanitizedModal.id, new ModalItemStore(sanitizedModal));
  }

  /**
   * @description Dismiss a specific/active item from the modal list
   *
   * @param {String} id - modal's ID
   */
  dismiss (id = null) {
    // If the ID is provided, then use that or else, use the active Modal's ID
    const modalToBeRemoved = id ? this.modalList.get(id) : this.activeModal;

    // If there's no active item, then bail out
    if (!modalToBeRemoved) {
      return;
    }

    this._remove(modalToBeRemoved.id);
  }
}
