import React from 'react';
import IconWithTooltip from '../../base-components/IconWithTooltip';
import classnames from 'classnames';
import warnIcon from '../../icons/warning.svg';

export default function HighUtilizationAlert({ className, minBalanceRequired }) {
  return (
    <div className={classnames('high-utilization-alert', className)}>
      <IconWithTooltip
        icon={warnIcon}
        tooltipText={'Please try when utilization has decreased.'}
        placement='bottom'
      />
      <span>Utilization is too high.</span>
    </div>
  )
}
