import React from 'react';
import { Observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import Badge from '../base-components/Badge';
import { APP_ROUTES } from '../constants/routes';
import { getStore } from '../stores/get-store';
import { getFormattedNumber } from '../utils/safe-math';
import { find } from 'lodash';

function _shouldShowDepositedBadge ({ walletAddress, path, totalDeposited, totalReservesDeposited }) {
  if (!walletAddress || !path) {
    return false;
  }

  if (path === APP_ROUTES.VAULTS.route && Boolean(totalDeposited)) {
    return true;
  }

  if (path === APP_ROUTES.LENDING.route && Boolean(totalReservesDeposited)) {
    return true;
  }

  return false;
}

function getPageTitle (activePath) {
  const route = find(APP_ROUTES, { route: activePath });
  return route?.name || '';
}

export const PageHeading = (props) => {
  const { pathname: path } = useLocation();

  return (
    <div className='page-heading'>
      <div className='page-heading__title'>
        {getPageTitle(path)}
      </div>
    <Observer>
      {
        () => {
          const { pageTVL } = getStore('FarmStore'),
            { walletAddress, totalDeposited, totalReservesDeposited } = getStore('WalletStore'),
            showDepositedBadge = _shouldShowDepositedBadge({
              walletAddress,
              path,
              totalDeposited,
              totalReservesDeposited
            });

          return (
            <>
              <Badge
                className='badge-secondary'
              >
                TVL: ${getFormattedNumber(pageTVL)}
              </Badge>
              {
                showDepositedBadge &&
                  <Badge
                    className='badge-secondary'
                  >
                    Deposited:&nbsp;$
                    {
                      getFormattedNumber(path === APP_ROUTES.VAULTS.route ? totalDeposited : totalReservesDeposited)
                    }
                  </Badge>
              }
            </>
          );
        }
      }
    </Observer>
    </div>
  );
}
