// Images
import bonfidaLogo from '../assets/wallets/bonfida.png';
import ledgerLogo from '../assets/wallets/ledger.png';
import mathwalletLogo from '../assets/wallets/mathwallet.png';
import phantomLogo from '../assets/wallets/phantom.png';
import solletExtensionLogo from '../assets/wallets/sollet-extension.png';
import solletLogo from '../assets/wallets/sollet.png';
import solongLogo from '../assets/wallets/solong.png';
import solflareLogo from '../assets/wallets/solflare.svg';
import bloctoLogo from '../assets/wallets/blocto.png';
import coin98Logo from '../assets/wallets/coin98.png';
import safepalLogo from '../assets/wallets/safepal.png';
import slopeLogo from '../assets/wallets/slope.png';

export const WALLETS = {
  Phantom: '',
  'Sollet Web': 'https://www.sollet.io',
  Ledger: '',
  'Solflare Extension': '',
  Coin98: 'https://www.coin98.com',
  // Bonfida: 'https://bonfida.com/wallet',
  'Sollet Extension': '',
  MathWallet: '',
  Solong: '',
  Slope: '',
  SafePal: '',
  'Solflare Web': 'https://solflare.com/access-wallet'
  // Blocto: 'https://blocto.portto.io',
};

export const WALLET_LOGO_MAP = {
  Ledger: ledgerLogo,
  'Sollet Extension': solletExtensionLogo,
  Solong: solongLogo,
  MathWallet: mathwalletLogo,
  Phantom: phantomLogo,
  'Sollet Web': solletLogo,
  // Bonfida: bonfidaLogo,
  'Solflare Extension': solflareLogo, 
  // Blocto: bloctoLogo,
  Coin98: coin98Logo,
  Slope: slopeLogo,
  SafePal: safepalLogo,
  'Solflare Web': solflareLogo,
};
