import React from 'react';
import { observer } from 'mobx-react';
import { isNil } from 'lodash';

// Store
import { getStore } from '../../stores/get-store';

// Components
import { WarningBanner } from '../EmptyStates/WarningBanner';
import LeverageFarmingTable from './LeverageFarmingTable';

// Services
import { TransactionService } from '../../services/TransactionService';

// Utils
import { getFormattedNumber } from '../../utils/safe-math';
import { PageHeading } from '../PageHeading';
import { getReserveByMintAddress } from '../../utils/lendingReserves';
import Infobar from '../../base-components/Infobar';
import { UTILIZATION_THRESHOLD } from '../../constants/leverageFarmingConstants';
import LeverageFarmingFilters from './LeverageFarmingFilters';

// Constants
const ZERO = 0;

class LeverageFarmingBody extends React.Component {
  constructor () {
    super();

    this.handleDeposit = this.handleDeposit.bind(this);
    this.handleWithdraw = this.handleWithdraw.bind(this);
    this.checkUnsafeWithdraw = this.checkUnsafeWithdraw.bind(this);
    this.handleTulipHarvest = this.handleTulipHarvest.bind(this);
    this.handleBulkTulipHarvest = this.handleBulkTulipHarvest.bind(this);
    this.handleFixTulipRewardTokenAccount = this.handleFixTulipRewardTokenAccount.bind(this);
  }

  async handleWithdraw (assetSymbol, value, withdrawMax = false) {
    const { wallet, setTokenAccounts } = getStore('WalletStore');

    // Bail-out if wallet is not connected
    if (!wallet) {
      return;
    }

    this.props.toast('Withdrawing...');

    // Refresh token balances
    setTimeout(() => {
      setTokenAccounts();
      getStore('UIStore').resetRefreshState();
    }, 9000);

    return TransactionService.handleWithdrawFromVault(assetSymbol, value, withdrawMax);
  }

  handleDeposit (assetSymbol, value) {
    const { wallet, setTokenAccounts } = getStore('WalletStore');

    // Bail-out if wallet is not connected
    if (!wallet) {
      return;
    }

    this.props.toast('Depositing...');

    // Refresh token balances
    setTimeout(() => {
      setTokenAccounts();
      getStore('UIStore').resetRefreshState();
    }, 9000);

    return TransactionService.handleDepositToVault(assetSymbol, value);
  }

  async checkUnsafeWithdraw (assetSymbol) {
    const { wallet } = getStore('WalletStore');

    // Bail-out if wallet is not connected
    if (!wallet) {
      return;
    }

    return TransactionService.checkUnsafeWithdraw(assetSymbol);
  }

  async handleFixTokenAccount (assetSymbol) {
    const { wallet } = getStore('WalletStore');

    // Bail-out if wallet is not connected
    if (!wallet) {
      return;
    }

    return TransactionService.handleFixTokenAccount(assetSymbol);
  }

  async handleFixTulipRewardTokenAccount () {
    const { wallet } = getStore('WalletStore');

    // Bail-out if wallet is not connected
    if (!wallet) {
      return;
    }

    // Refresh token balances
    setTimeout(() => {
      getStore('WalletStore').setTokenAccounts();
      getStore('UIStore').resetRefreshState();
    }, 9000);

    return TransactionService.handleFixTulipRewardTokenAccount();
  }

  handleTulipHarvest (assetSymbol) {
    return this.handleBulkTulipHarvest([assetSymbol]);
  }

  async handleBulkTulipHarvest (farms) {
    const { wallet, setTokenAccounts } = getStore('WalletStore');

    // Bail-out if wallet is not connected
    if (!wallet) {
      return;
    }

    this.props.toast('Harvesting...');

    // Refresh token balances
    setTimeout(() => {
      setTokenAccounts();
      getStore('UIStore').resetRefreshState();
    }, 9000);

    return TransactionService.handleBulkTulipHarvest(farms);
  }

  render () {
    const { wallet, tokenAccounts, isTokenAccountInvalid } = getStore('WalletStore'),
      { getFarm, lendingFarms } = getStore('FarmStore'),
      { getLeverageVault } = getStore('LeverageVaultStore'),
      tableData = lendingFarms.map((farm) => {
        let balance = '-',
          deposited = '-',
          rewardSinceLastDeposit = ZERO,
          tulipEarned = ZERO;

        if (wallet) {
          if (tokenAccounts[farm.mintAddress]) {
            balance = Number(tokenAccounts[farm.mintAddress].balance?.fixed());
            deposited = tokenAccounts[farm.mintAddress].deposited || ZERO.toFixed(farm.decimals);
            rewardSinceLastDeposit = tokenAccounts[farm.mintAddress]?.rewardSinceLastDeposit;
            tulipEarned = tokenAccounts[farm.mintAddress]?.tulipEarned;
          }
          else {
            balance = ZERO.toFixed(farm.decimals);
            deposited = ZERO.toFixed(farm.decimals);
          }
        }

        const {
          tvl = 0,
          // weeklyAPY = 0,
          // yearlyAPY = 0,
          // dailyAPR = 0,
          price = 0,
          isUserBalanceAccountValid = false,
          tulipAPR = 0,
          yieldBreakdown = {}
        } = getFarm(farm.mintAddress) || {};

        const {
          dailyYield: dailyAPR = 0,
          weeklyYield: weeklyAPY = 0,
          yearlyYield: yearlyAPY = 0
        } = yieldBreakdown;

        const {
          leverageValue = 2,
          selectedCoinIndex = 1
        } = getLeverageVault(farm.mintAddress) || {};

        const { getReserve } = getStore('ReserveStore');
        const { cumulativeBorrowRate: coinBorrowingInterest, utilization: coinUtilization = 0 } = getReserve(farm.coins[0].mintAddress) || {};
        const { cumulativeBorrowRate: pcBorrowingInterest, utilization: pcUtilization = 0 } = getReserve(farm.coins[1].mintAddress) || {};
        const { getPair } = getStore('PriceStore');
        const { apy: tradingFees = 0 } = getPair(farm.mintAddress) || {};

        // const { borrowDisabled: coinBorrowDisabled } = getReserveByMintAddress(farm.coins[0].mintAddress);
        // const { borrowDisabled: pcBorrowDisabled } = getReserveByMintAddress(farm.coins[1].mintAddress);

        let borrowDisabledCoinIndex;

        // if (coinBorrowDisabled) {
        //   borrowDisabledCoinIndex = 0;
        // }

        // if (pcBorrowDisabled) {
        //   borrowDisabledCoinIndex = 1;
        // }

        // If both `coin` and `pc` have utilization rates greater than 85%, then disable coin borrow,
        // otherwise whichever has utilization rate greater than 85% will be disabled
        // if ((coinUtilization > UTILIZATION_THRESHOLD) && (pcUtilization > UTILIZATION_THRESHOLD)) {
        //   borrowDisabledCoinIndex = 0;
        // } else if (coinUtilization > UTILIZATION_THRESHOLD) {
        //   borrowDisabledCoinIndex = 0;
        // } else {
        //   borrowDisabledCoinIndex = 1;
        // }

        // For now we're only disabling `coin` borrow in case of high utilization rate
        // If we require disabling `pc` in any case then uncomment the code above and comment out this one.
        if (coinUtilization >= UTILIZATION_THRESHOLD) {
          borrowDisabledCoinIndex = 0;
        }

        return {
          assetName: farm.name,
          assetSymbol: farm.symbol,
          mintAddress: farm.mintAddress,
          tvl: getFormattedNumber(tvl),
          weeklyAPY: getFormattedNumber(weeklyAPY),
          yearlyAPY: getFormattedNumber(yearlyAPY),
          dailyAPR: getFormattedNumber(dailyAPR),
          balance,
          deposited,
          logos: farm.logos,
          decimals: farm.decimals,
          price,
          dualYield: Boolean(farm.dualYield),
          rewardSinceLastDeposit,
          isUserBalanceAccountValid,
          liquidityMining: Boolean(farm.liquidityMining),
          tulipAPR,
          isTokenAccountMissing: isTokenAccountInvalid(farm.mintAddress, farm?.platform),
          tvlInNumber: tvl,
          depositedInUsd: deposited * price,
          balanceInUsd: balance * price,
          yearlyAPYInNumber: yearlyAPY,
          dailyAPRInNumber: dailyAPR,
          tulipEarned,
          disabled: Boolean(farm.disabled),
          coins: farm.coins,
          coinBorrowingInterest,
          pcBorrowingInterest,
          tradingFees,
          leverageValue,
          selectedCoinIndex,
          whitelisted: farm.whitelisted,
          borrowDisabledCoinIndex,
          slippageWarning: !isNil(farm.slippageWarning),
          maxPositionLimitInUsd: farm.maxPositionLimitInUsd
        };
      }),
      { isDesktop } = getStore('ResponsiveStore');

    return (
      <div className='app-body'>
        <Infobar />
        {/* <WarningBanner /> */}
        {/* <VaultsFilters
          onBulkTulipHarvest={this.handleBulkTulipHarvest}
          toast={this.props.toast}
          onFixTulipRewardTokenAccount={this.handleFixTulipRewardTokenAccount}
        /> */}
        {
          !isDesktop && <PageHeading />
        }
        {/* <PageHeading text='Leverage Vaults' /> */}
        <LeverageFarmingFilters />
        <LeverageFarmingTable
          toast={this.props.toast}
          data={tableData}
          onDeposit={this.handleDeposit}
          onWithdraw={this.handleWithdraw}
          checkUnsafeWithdraw={this.checkUnsafeWithdraw}
          onFixTokenAccount={this.handleFixTokenAccount}
          onTulipHarvest={this.handleTulipHarvest}
          history={this.props.history}
        />
      </div>
    )
  }
}

export default observer(LeverageFarmingBody);
