import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import downCaret from '../../icons/down-caret.svg';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CSSTransitionGroup } from 'react-transition-group';
import CustomNumberInput from '../../base-components/CustomNumberInput';

import refreshIcon from '../../icons/refresh.svg';
import infoIcon from '../../icons/info.svg';
import tulipIcon from '../../icons/SolFarmLogo.png';
import errorIcon from '../../icons/error.svg';
import upDownCaretIcon from '../../icons/up-down-caret.svg';
import upArrow from '../../icons/up-arrow.svg';
import downArrow from '../../icons/down-arrow.svg';

import { getStore } from '../../stores/get-store';
import { Loader } from '../../base-components/Loader';
import { Tooltip } from 'reactstrap';
import { isNil, debounce, isFunction, isEmpty, orderBy } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { getFormattedNumber } from '../../utils/safe-math';
import { NATIVE_SOL, TOKENS } from '../../utils/tokens';
import { MIN_SOL_BALANCE_AFTER_DEPOSIT } from '../../constants/lendConstants';
import LowSolWarningAlert from '../../components/LowSolWarningAlert/LowSolWarningAlert';
import { LENDING_MIN } from '../../constants/minSolBalance';
import HighUtilizationAlert from '../../components/HighUtilizationAlert/HighUtilizationAlert';
import SelectDropdown from '../../base-components/SelectDropdown';

const CustomSlider = withStyles({
  root: {
    // color: '#52af77',
    height: 4,
    // margin: '8px 4px'
    marginLeft: 4
  },
  thumb: {
    height: 16,
    width: 16,
    // backgroundColor: '#fff',
    background: 'linear-gradient(315deg, #C61FB5 -6.25%, #2CABC7 100%)',
    // border: '2px solid currentColor',
    marginTop: -6,
    marginLeft: -8,
    '&:focus, &$active': {
      boxShadow: 'inherit',
    },
    '&:hover, &$active': {
      boxShadow: '0 0 0 4px rgba(255, 255, 255, 0.2)',
      borderRadius: '50%'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50%)',
  },
  track: {
    height: 4,
    borderRadius: 4,
    backgroundColor: '#1C88B4'
  },
  rail: {
    height: 4,
    borderRadius: 4,
    backgroundColor: '#146C98'
  },
})(Slider);

const ZERO = 0;

const tableColumns = ['Wallet Balance', 'Deposited', 'APY', 'Total Supply', 'Total Borrow', 'Utilization'],
  sortableColumns = ['APY', 'Total Supply', 'Total Borrow', 'Wallet Balance', 'Deposited', 'Utilization'],
  mobileSortableColumns = [
      {
        label: 'APY',
        value: 'APY'
      },
      {
        label: 'Total Supply',
        value: 'Total Supply'
      },
      {
        label: 'Total Borrow',
        value: 'Total Borrow'
      },
      {
        label: 'Wallet Balance',
        value: 'Wallet Balance'
      },
      {
        label: 'Deposited',
        value: 'Deposited'
      },
      {
        label: 'Utilization',
        value: 'Utilization'
      }
  ],
  disabledColumnsWithoutConnection = ['Wallet Balance', 'Deposited'],
  SORT_ORDER = ['desc', 'asc', null],
  headerToPropertyMap = {
    'APY': 'apy',
    'Total Supply': 'totalSupplyInUsd',
    'Total Borrow': 'totalBorrowInUsd',
    'Wallet Balance': 'balanceInUsd',
    'Deposited': 'depositedInUsd',
    'Utilization': 'utilizationInNumber'
  },
  feeText = `Controller Fee: 0.01% 
  Platform fee: 0.1% 
  Deposit Fee: 0.0%
  Withdrawal Fee: 0.0%`,
  transactionToast = (tx) => (
    <div style={{ fontSize: '12px'}}>
      <div style={{ marginBottom: '8px', fontWeight: '600' }}>Transaction has been sent</div>
      <div>Confirmation is in progress. Check your transaction on <a className='link' href={`https://explorer.solana.com/tx/${tx}`} target='_blank'>here</a>.</div>
    </div>
  ),
  getRefreshTooltip = (timeToRefresh) => `Auto refresh in ${timeToRefresh} seconds, you can click to update manually\nAutomatically refreshes when the current pool had changed`,
  getRewardTooltipContent = (amountInTokens, amountInUsd) => {
    return (
      <div>
        <div style={{ marginBottom: '0.5rem' }}>
          Rewards since last deposit/withdraw
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ fontWeight: '600' }}>
            {amountInTokens}
          </span>
          <span style={{ fontSize: '0.8rem', marginLeft: '0.3rem' }}>
            (${amountInUsd})
          </span>
        </div>
      </div>
    )
  };

class LendTableHeader extends React.Component {
  constructor () {
    super();

    this.state = {
      isTooltipVisible: false,
      isInfoTooltipVisible: false
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.getTooltipText = this.getTooltipText.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.handleHeaderClick = this.handleHeaderClick.bind(this);
  }

  componentDidMount () {
    this.intervalId = setInterval(() => {
      const { wallet } = getStore('WalletStore'),
        { timeToRefresh, setTimeToRefresh } = getStore('UIStore');

      if (!wallet) {
        return;
      }

      if (timeToRefresh - 1) {
        return setTimeToRefresh(timeToRefresh - 1);
      }

      this.handleRefresh(null, true);
    }, 1000);
  }

  componentWillUnmount () {
    this.intervalId && clearInterval(this.intervalId);
  }

  async handleRefresh (e, silent = false) {

    // If current tab is not visible, then don't refresh
    if (window?.document?.hidden) {
      return;
    }

    const { wallet } = getStore('WalletStore'),
      { setIsRefreshing, resetRefreshState } = getStore('UIStore');

    if (!wallet) {
      return;
    }

    setIsRefreshing(true);

    await Promise.all([
      getStore('WalletStore').setTokenAccounts(),
      getStore('FarmStore').setPrice(),
      getStore('ReserveStore').init(),
      getStore('PriceStore').init()
    ]);

    !silent && this.props.toast('Values refreshed.');
    resetRefreshState();
  }

  getTooltipText () {
    const { wallet } = getStore('WalletStore'),
      { timeToRefresh } = getStore('UIStore');

    if (!wallet) {
      return 'Wallet not connected';
    }

    // return `Refreshing in ${this.state.timeToRefresh}s`;
    return getRefreshTooltip(timeToRefresh);
  }

  toggleTooltip (value) {
    const isTooltipVisible = isNil(value) ? !this.state.isTooltipVisible : value;

    this.setState({ isTooltipVisible });
  }

  toggleInfoTooltip (value) {
    const isInfoTooltipVisible = isNil(value) ? !this.state.isInfoTooltipVisible : value;

    this.setState({ isInfoTooltipVisible });
  }

  handleHeaderClick (column) {
    const { wallet } = getStore('WalletStore');

    if (!wallet && disabledColumnsWithoutConnection.includes(column)) {
      return;
    }

    if (!sortableColumns.includes(column)) {
      return;
    }

    this.props.setSortBy(headerToPropertyMap[column]);
  }

  getFilterDropdown ({ title, placeholder, items, selectedItems, onClick }) {
    return (
      <SelectDropdown
        className='lend-table__header-sort-by'
        disableSearch
        right
        title={title}
        placeholder={placeholder}
        isSelected={(value) => this.props.sortedBy === headerToPropertyMap[value]}
        items={items}
        itemRender={({ label, value }) => {
          return (
            <div>
              {<img src={
                this.props.sortedBy === headerToPropertyMap[value] ?
                (
                  this.props.sortOrder === 'asc' ? upArrow : downArrow
                ) :
                upDownCaretIcon
              } height='16px' width='16px' alt='farm-logo'/> }
              {label?.toUpperCase()}
            </div>
          );
        }}
        onClick={onClick}
      />
    )
  }

  render () {
    const { wallet } = getStore('WalletStore'),
      { isRefreshing, isSilentRefresh } = getStore('UIStore'),
      { sortedBy, sortOrder } = this.props,
      { isMobile } = getStore('ResponsiveStore');

    return (
      <div className='lend-table__header'>
        <div
          className='lend-table__header-asset'
          className={
            classnames(
              'lend-table__header-asset',
              {
                'sortable': sortableColumns.includes('Asset')
              }
            )
          }
          onClick={this.handleHeaderClick.bind(this, 'Asset')}
        >
          Reserve
          {/* {
            <div
              className='lend-table__header-cell__sort-btn'
            >
              <img src={
                sortedBy === headerToPropertyMap['Asset'] ?
                (
                  sortOrder === 'asc' ? upArrow : downArrow
                ) :
                upDownCaretIcon
              } />
            </div>
          } */}
        </div>
        {
          isMobile &&
            this.getFilterDropdown({
              title: 'Sort By',
              items: mobileSortableColumns,
              onClick: this.handleHeaderClick
            })
        }
        {
          !isMobile &&
          tableColumns.map((tableColumn) => (
            <div
              key={tableColumn}
              className={
                classnames(
                  'lend-table__header-cell',
                  {
                    'sortable': sortableColumns.includes(tableColumn),
                    'disabled': !wallet && disabledColumnsWithoutConnection.includes(tableColumn)
                  }
                )
              }
              onClick={this.handleHeaderClick.bind(this, tableColumn)}
            >
              {tableColumn}
              {
                sortableColumns.includes(tableColumn) &&
                <div
                  className='lend-table__header-cell__sort-btn'
                >
                  <img src={
                    sortedBy === headerToPropertyMap[tableColumn] ?
                    (
                      sortOrder === 'asc' ? upArrow : downArrow
                    ) :
                    upDownCaretIcon
                  } />
                </div>
              }
              {/* {
                tableColumn === 'Total Supply' &&
                <div
                  className='lend-table__header-cell__info-btn'
                  onMouseOver={this.toggleInfoTooltip.bind(this, true)}
                  onMouseLeave={this.toggleInfoTooltip.bind(this, false)}
                  id='infoIcon'
                >
                  <img src={infoIcon} />
                </div>
              } */}
            </div>
          ))
        }
        <div
          className={`lend-table__header-refresh ${!wallet ? 'disabled' : '' }`}
        >
          <div
            onClick={this.handleRefresh}
            className={`lend-table__header-refresh__btn ${isRefreshing || isSilentRefresh ? 'is-refreshing' : ''}`}
            id='refreshIcon'
            onMouseOver={this.toggleTooltip.bind(this, true)}
            onMouseLeave={this.toggleTooltip.bind(this, false)}
          >
            <img src={refreshIcon} />
          </div>
        </div>
        <Tooltip
          target='refreshIcon'
          isOpen={this.state.isTooltipVisible}
          hideArrow
          placement='bottom-end'
          innerClassName='lend-table__tooltip'
        >
          {this.getTooltipText()}
        </Tooltip>
        {/* <Tooltip
          target='infoIcon'
          isOpen={this.state.isInfoTooltipVisible}
          hideArrow
          placement='bottom-end'
          innerClassName='lend-table__tooltip'
        >
          Yearly APY is indicative and should not be used as a performance measure.
        </Tooltip> */}
      </div>
    );
  }
}

class LendTableRow extends React.Component {
  constructor () {
    super();

    this.state = {
      isExpanded: false,
      depositValue: '',
      withdrawValue: '',
      isDepositing: false,
      isWithdrawing: false,
      withdrawSliderValue: 0,
      depositSliderValue: 0,
      isDualYieldInfoTooltipVisible: false,
      isRewardsInfoTooltipVisible: false,
      isLiquidityMiningInfoTooltipVisible: false,
      isErrorTooltipVisible: false,
      isHarvesting: false
    };

    this.rowRef = React.createRef();

    this.toggleRow = this.toggleRow.bind(this);
    this.handleDeposit = this.handleDeposit.bind(this);
    this.handleDepositChange = this.handleDepositChange.bind(this);
    this.handleWithdraw = this.handleWithdraw.bind(this);
    this.handleWithdrawWithWarnings = this.handleWithdrawWithWarnings.bind(this);
    this.handleWithdrawChange = this.handleWithdrawChange.bind(this);
    this.handleDepositSliderChange = this.handleDepositSliderChange.bind(this);
    this.handleWithdrawSliderChange = this.handleWithdrawSliderChange.bind(this);
    this.handleFixTokenAccount = this.handleFixTokenAccount.bind(this);
    this.handleTulipHarvest = this.handleTulipHarvest.bind(this);

    this.debouncedWithdrawSliderChange = debounce(this.handleWithdrawSliderChange, 300);
    this.debouncedDepositSliderChange = debounce(this.handleDepositSliderChange, 100);

    this.toggleDualYieldInfoTooltip = this.toggleDualYieldInfoTooltip.bind(this);
    this.toggleLiquidityMiningInfoTooltip = this.toggleLiquidityMiningInfoTooltip.bind(this);
    this.toggleRewardsInfoTooltip = this.toggleRewardsInfoTooltip.bind(this);
    this.toggleErrorTooltip = this.toggleErrorTooltip.bind(this);
  }

  toggleDualYieldInfoTooltip (value) {
    const isDualYieldInfoTooltipVisible = isNil(value) ? !this.state.isDualYieldInfoTooltipVisible : value;

    this.setState({ isDualYieldInfoTooltipVisible });
  }

  toggleLiquidityMiningInfoTooltip (value) {
    const isLiquidityMiningInfoTooltipVisible = isNil(value) ? !this.state.isLiquidityMiningInfoTooltipVisible : value;

    this.setState({ isLiquidityMiningInfoTooltipVisible });
  }

  toggleRewardsInfoTooltip (value) {
    const isRewardsInfoTooltipVisible = isNil(value) ? !this.state.isRewardsInfoTooltipVisible : value;

    this.setState({ isRewardsInfoTooltipVisible });
  }

  toggleErrorTooltip (value) {
    const isErrorTooltipVisible = isNil(value) ? !this.state.isErrorTooltipVisible : value;

    this.setState({ isErrorTooltipVisible });
  }

  handleWithdrawSliderChange (e, value) {
    let { deposited, decimals } = this.props.data || {};

    deposited = Number(deposited);

    const withdrawValue = !value ? '' : Math.min(deposited, ((deposited * value)/100)).toFixed(decimals);

    this.setState({
      withdrawSliderValue: value,
      withdrawValue
    });
  }

  handleDepositSliderChange (e, value) {
    let { balance, decimals, mintAddress } = this.props.data || {};

    balance = Number(balance);

    // If token being deposited is SOL, then balance available to
    // withdraw is 0.05 SOL less than total amount
    if (mintAddress === NATIVE_SOL.mintAddress) {
      balance -= MIN_SOL_BALANCE_AFTER_DEPOSIT;
    }

    if (balance < 0) {
      balance = 0;
    }

    const depositValue = !value ? '' : Math.min(balance, ((balance * value)/100)).toFixed(decimals);

    this.setState({
      depositSliderValue: value,
      depositValue
    });
  }

  toggleRow () {
    this.setState((prevState) => {
      return {
        isExpanded: !prevState.isExpanded
      };
    }, () => {
      if (getStore('ResponsiveStore').isMobile) {
        this.state.isExpanded && this.rowRef?.current?.scrollIntoView(true);
      }
    });
  }

  async handleDeposit () {
    this.setState({ isDepositing: true });

    try {
      const tx = await this.props.onDeposit(this.state.depositValue);

      this.props.toast(transactionToast(tx));
  
      this.setState({ isDepositing: false });
      this.handleDepositChange({ target: { value: '' } });
    } catch (err) {

      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleDeposit~err:', err);

      this.setState({ isDepositing: false });
    }
  }

  async handleTulipHarvest () {
    this.setState({ isHarvesting: true });

    try {
      const tx = await this.props.onTulipHarvest();

      this.props.toast(transactionToast(tx));
  
      this.setState({ isHarvesting: false });
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleTulipHarvest~err:', err);

      this.setState({ isHarvesting: false });
    }
  }

  handleDepositChange (e) {
    let newValue = e?.target?.value,
      { balance, mintAddress } = this.props.data || {};

    balance = Number(balance);

    // If token being deposited is SOL, then balance available to
    // withdraw is 0.05 SOL less than total amount
    if (mintAddress === NATIVE_SOL.mintAddress) {
      balance -= MIN_SOL_BALANCE_AFTER_DEPOSIT;
    }

    if (balance < 0) {
      balance = 0;
    }

    // Sanitize new value
    if (newValue > balance) {
      newValue = balance;
    } else if (newValue < 0) {
      newValue = 0;
    }

    const valueForSlider = (newValue === '') ? 0 : newValue;

    this.setState({
      depositValue: newValue,
      depositSliderValue: !balance ? 0 : Math.round((valueForSlider * 100)/balance)
    });
  }

  async handleWithdrawWithWarnings () {
    const isWithdrawUnsafe = false;

    if (isWithdrawUnsafe) {
      this.props.toggleModal(true);

      return this.props.setSuccessCallback(this.handleWithdraw);
    }

    return this.handleWithdraw();
  }

  async handleWithdraw () {
    this.setState({ isWithdrawing: true });

    // this.props.toast(`Withdraw Successful: ${tx}`);

    // Making transaction

    // Transaction has been sent
    // Confirmation is in progress. Check your transaction on here.

    // Transaction has been confirmed
    // Stake 0.000200 RAY-SOL LP

    const withdrawMax = (this.state.withdrawSliderValue === 100);

    try {
      const tx = await this.props.onWithdraw(this.state.withdrawValue, withdrawMax);

      this.props.toast(transactionToast(tx));
  
      this.setState({ isWithdrawing: false });
      this.handleWithdrawChange({ target: { value: '' } });
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleWithdraw~err:', err);

      this.setState({ isWithdrawing: false });
    }
  }

  handleWithdrawChange (e) {
    let newValue = e?.target?.value,
      { deposited } = this.props.data || {};

    deposited = Number(deposited);

    // Sanitize new value
    if (newValue > deposited) {
      newValue = deposited;
    } else if (newValue < 0) {
      newValue = 0;
    }

    const valueForSlider = (newValue === '') ? 0 : newValue;

    this.setState({
      withdrawValue: newValue,
      withdrawSliderValue: !deposited ? 0 : Math.round((valueForSlider * 100)/deposited)
    });
  }

  async handleFixTokenAccount (e) {
    // Stop bubbling of click event, to prevent list-item from expanding/collapsing
    e && e.stopPropagation();

    try {
      const tx = await this.props.onFixTokenAccount();

      this.props.toast(transactionToast(tx));
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleFixTokenAccount~err:', err);
    }
  }

  getDepositBox ({
    depositValue,
    wallet,
    disabled,
    balance,
    depositSliderValue,
    isDepositDisabled,
    isDepositing,
    visible,
    isExpanded
  }) {
    return (
      <div className='lend-table__row-expanded__deposit'>
        <CustomNumberInput
          placeholder='Enter deposit amount...'
          value={depositValue}
          onChange={this.handleDepositChange}
          disabled={!wallet || disabled}
          withArrows={false}
          rightButtonLabel='MAX'
          onRightButtonClick={this.handleDepositChange.bind(this, { target: { value: Number(balance) }})}
        />
        <div className='slider-container'>
          <CustomSlider
            valueLabelDisplay='auto'
            aria-label='pretto slider'
            defaultValue={0}
            onChange={this.handleDepositSliderChange}
            disabled={!wallet || disabled}
            value={depositSliderValue}
          />
          <span className='slider-container__label'>
            {depositSliderValue}%
          </span>
        </div>
        <Button
          color='primary'
          onClick={this.handleDeposit}
          disabled={isDepositDisabled}
        >
          {
            isDepositing ?
              <Loader /> :
              'Deposit'
          }
        </Button>
        {
          visible && isExpanded &&
            <LowSolWarningAlert
              className='shift-up-16'
              minBalanceRequired={LENDING_MIN}
            />
        }
      </div>
    );
  }

  getWithdrawBox ({
    withdrawValue,
    wallet,
    deposited,
    withdrawSliderValue,
    isWithdrawDisabled,
    isWithdrawing,
    visible,
    isExpanded,
    isWithdrawAmountMoreThanAvailable
  }) {
    return (
      <div className='lend-table__row-expanded__withdraw'>
        <CustomNumberInput
          placeholder='Enter withdraw amount...'
          value={withdrawValue}
          onChange={this.handleWithdrawChange}
          disabled={!wallet}
          withArrows={false}
          rightButtonLabel='MAX'
          onRightButtonClick={this.handleWithdrawChange.bind(this, { target: { value: Number(deposited) }})}
        />
        <div className='slider-container'>
          <CustomSlider
            valueLabelDisplay='auto'
            aria-label='pretto slider'
            defaultValue={0}
            onChange={this.handleWithdrawSliderChange}
            disabled={!wallet}
            value={withdrawSliderValue}
          />
          <span className='slider-container__label'>
            {withdrawSliderValue}%
          </span>
        </div>
        <Button
          color='primary'
          onClick={this.handleWithdrawWithWarnings}
          disabled={isWithdrawDisabled || isWithdrawAmountMoreThanAvailable}
        >
          {
            isWithdrawAmountMoreThanAvailable ?
              (
                <HighUtilizationAlert />
              ) :
              (
                isWithdrawing ?
                  <Loader /> :
                  'Withdraw'
              )
          }
        </Button>
        {
          visible && isExpanded &&
            <LowSolWarningAlert
              className='shift-up-16'
              minBalanceRequired={LENDING_MIN}
            />
        }
      </div>
    );
  }


  render () {
    const {
      // assetName,
      // tvl,
      // deposited,
      // balance,
      // dailyAPR,
      // weeklyAPY,
      // yearlyAPY,
      // logos,
      // price,
      // dualYield,
      // decimals,
      // rewardSinceLastDeposit,
      // isUserBalanceAccountValid,
      // liquidityMining,
      // tulipAPR,
      // isTokenAccountMissing,
      // tulipEarned,
      disabled,
      name,
      apy,
      totalSupply,
      totalBorrow,
      utilization,
      balance,
      deposited,
      logo,
      price,
      decimals,
      totalSupplyInUsd = '',
      totalBorrowInUsd = '',
      balanceInUsd = '',
      depositedInUsd = '',
      whitelisted,
      visible
    } = this.props.data || {},
    {
      isExpanded,
      depositValue,
      withdrawValue,
      isDepositing,
      isWithdrawing,
      withdrawSliderValue,
      depositSliderValue,
      isHarvesting,
    } = this.state,
    { wallet } = getStore('WalletStore'),
    isDepositDisabled = (
      !wallet || !balance || isDepositing || !depositValue || !Number(depositValue) || Number(depositValue) > Number(balance) || disabled || whitelisted
    ),
    isWithdrawDisabled = (
      !wallet || !deposited || isWithdrawing || !withdrawValue || !Number(withdrawValue)
    ),
    // isHarvestDisabled = !wallet || !Number(tulipEarned?.toFixed(6)) || isHarvesting,
    // depositedInUsd = deposited === '-' ? '' : deposited * price,
    // rewardSinceLastDepositInUsd = rewardSinceLastDeposit * price,
    { isRefreshing } = getStore('UIStore'),
    { isMobile } = getStore('ResponsiveStore'),

    amountAvailableToWithdraw = totalSupply - totalBorrow,
    isWithdrawAmountMoreThanAvailable = (Number(withdrawValue) > amountAvailableToWithdraw),

    depositBox = this.getDepositBox({
      depositValue,
      wallet,
      disabled,
      balance,
      depositSliderValue,
      isDepositDisabled,
      isDepositing,
      visible,
      isExpanded
    }),
    withdrawBox = this.getWithdrawBox({
      withdrawValue,
      wallet,
      deposited,
      withdrawSliderValue,
      isWithdrawDisabled,
      isWithdrawing,
      visible,
      isExpanded,
      isWithdrawAmountMoreThanAvailable
    });

    return (
        visible && <div className={`lend-table__row ${isExpanded ? 'is-expanded': ''}`} ref={this.rowRef}>
        <div
          className='lend-table__row-item'
          onClick={this.toggleRow}
        >

          {/* Asset Details */}
          <div className='lend-table__row-item__asset'>
            <div className='lend-table__row-item__asset__logos'>
              {
                <img src={logo} className='lend-table__row-item__asset__logos-item' />
              }
            </div>
            <div className='lend-table__row-item__asset__text'>
              <div className='lend-table__row-item__asset__text-name'>
                {name}
                {/* {
                  dualYield &&
                  <div
                    className='lend-table__header-cell__info-btn'
                    onMouseOver={this.toggleDualYieldInfoTooltip.bind(this, true)}
                    onMouseLeave={this.toggleDualYieldInfoTooltip.bind(this, false)}
                    id={`infoIcon${this.props.rowIndex}`}
                  >
                    <img src={infoIcon} />
                  </div>
                }
                {
                  liquidityMining &&
                  <div
                    className='lend-table__header-cell__tulip-badge'
                    onMouseOver={this.toggleLiquidityMiningInfoTooltip.bind(this, true)}
                    onMouseLeave={this.toggleLiquidityMiningInfoTooltip.bind(this, false)}
                    id={`tulipIcon${this.props.rowIndex}`}
                  >
                    <img src={tulipIcon} />
                    <span>{getFormattedNumber(tulipAPR)}% APR</span>
                  </div>
                } */}
              </div>
              {/* <div className='lend-table__row-item__asset__text-tvl'>
                TVL: { isRefreshing ? <Loader /> : (<span>$<span>{tvl}</span></span>) }
              </div> */}
            </div>
          </div>

          {
            isMobile && !isExpanded &&
              <div className='lend-table__row-item__cell mobile-details'>
                {/* Balance */}
                <div>
                Balance&nbsp;
                {
                  (isRefreshing && wallet) ? <Loader /> :
                  (
                    <div>
                      <div className='lend-table__row-item__cell-token'>
                        {wallet ? getFormattedNumber(Number(balance)) : balance} &nbsp;
                        {/* {wallet ? '| $' : null}{getFormattedNumber(balanceInUsd)} */}
                      </div>
                    </div>
                  )
                }
                </div>

                {/* Deposited */}
                <div>
                Deposited&nbsp;
                {
                  (isRefreshing && wallet) ? <Loader /> :
                  (
                    <div>
                      <div className='lend-table__row-item__cell-token'>
                        {wallet ? getFormattedNumber(Number(deposited)) : deposited} &nbsp;
                        {/* {wallet ? '| $' : null}{getFormattedNumber(depositedInUsd)} */}
                      </div>
                    </div>
                  )
                }
                </div>

                {/* APY */}
                <div>
                  APY&nbsp;
                  {!isNil(apy) ? (<span><span>{getFormattedNumber(apy)}</span> %</span>) : '-'}
                </div>
              </div>
          }

          {/* Balance */}
          {
            !isMobile &&
              <div className={
                classnames(
                  'lend-table__row-item__cell',
                  // {
                  //   'has-error': isTokenAccountMissing
                  // }
                )
              }>
                {
                  (isRefreshing && wallet) ? <Loader /> :
                  (
                    <div>
                      <div className='lend-table__row-item__cell-token'>
                        {wallet ? getFormattedNumber(balance, decimals, decimals) : '-'}
                      </div>
                      {
                        wallet &&
                        <div className='lend-table__row-item__cell-usd'>
                          {wallet ? '$' : null}{getFormattedNumber(balanceInUsd)}
                          {/* {
                            isTokenAccountMissing &&
                            <div
                              className='lend-table__header-cell__info-btn'
                              onMouseOver={this.toggleErrorTooltip.bind(this, true)}
                              onMouseLeave={this.toggleErrorTooltip.bind(this, false)}
                              id={`errorIcon${this.props.rowIndex}`}
                              onClick={this.handleFixTokenAccount}
                            >
                              <img src={errorIcon} />
                            </div>
                          } */}
                          {/* {
                            isTokenAccountMissing &&
                            <Tooltip
                              target={`errorIcon${this.props.rowIndex}`}
                              isOpen={this.state.isErrorTooltipVisible}
                              hideArrow
                              placement='bottom'
                              innerClassName='lend-table__tooltip'
                            >
                              Token account missing. Click here to fix, to see your balance.
                            </Tooltip>
                          } */}
                        </div>
                      }
                    </div>
                  )
                }
              </div>
          }

          {/* Deposited */}
          {
            !isMobile &&
              <div className={
                classnames(
                  'lend-table__row-item__cell',
                  // {
                  //   'has-error': isTokenAccountMissing
                  // }
                )
              }>
                {
                  (isRefreshing && wallet) ? <Loader /> :
                  (
                    <div>
                      <div className='lend-table__row-item__cell-token'>
                        {wallet ? getFormattedNumber(deposited, decimals, decimals) : '-'}
                      </div>
                      {
                        wallet &&
                        <div className='lend-table__row-item__cell-usd'>
                          {wallet ? '$' : null}{getFormattedNumber(depositedInUsd)}
                          {/* {
                            isTokenAccountMissing &&
                            <div
                              className='lend-table__header-cell__info-btn'
                              onMouseOver={this.toggleErrorTooltip.bind(this, true)}
                              onMouseLeave={this.toggleErrorTooltip.bind(this, false)}
                              id={`errorIcon${this.props.rowIndex}`}
                              onClick={this.handleFixTokenAccount}
                            >
                              <img src={errorIcon} />
                            </div>
                          } */}
                          {/* {
                            isTokenAccountMissing &&
                            <Tooltip
                              target={`errorIcon${this.props.rowIndex}`}
                              isOpen={this.state.isErrorTooltipVisible}
                              hideArrow
                              placement='bottom'
                              innerClassName='lend-table__tooltip'
                            >
                              Token account missing. Click here to fix, to see your balance.
                            </Tooltip>
                          } */}
                        </div>
                      }
                    </div>
                  )
                }
              </div>
          }

          {/* APY */}
          {
            !isMobile &&
              <div className='lend-table__row-item__cell'>
                {/* {
                  isRefreshing ? <Loader /> :
                  (weeklyAPY ? `${weeklyAPY} %` : '-')
                } */}
                {!isNil(apy) ? (<span><span>{getFormattedNumber(apy)}</span> %</span>) : '-'}
              </div>
          }


          {/* Total Supply */}
          {
            !isMobile &&
              <div className='lend-table__row-item__cell'>
                {
                  (isRefreshing && wallet) ? <Loader /> :
                  (
                    <div>
                      <div className='lend-table__row-item__cell-token'>
                        {!isNil(totalSupply) ? `${getFormattedNumber(totalSupply)} ${name}` : totalSupply}
                      </div>
                      <div className='lend-table__row-item__cell-usd'>
                        {!isNil(totalSupplyInUsd) ? '$' : null}{getFormattedNumber(totalSupplyInUsd)}
                        {/* {
                          isUserBalanceAccountValid &&
                          <div
                            className='lend-table__header-cell__info-btn'
                            onMouseOver={this.toggleRewardsInfoTooltip.bind(this, true)}
                            onMouseLeave={this.toggleRewardsInfoTooltip.bind(this, false)}
                            id={`rewardsInfoIcon${this.props.rowIndex}`}
                          >
                            <img src={infoIcon} />
                          </div>
                        } */}
                        {/* {
                          isUserBalanceAccountValid &&
                          <Tooltip
                            target={`rewardsInfoIcon${this.props.rowIndex}`}
                            isOpen={this.state.isRewardsInfoTooltipVisible}
                            hideArrow
                            placement='bottom'
                            innerClassName='lend-table__tooltip'
                          >
                            {
                              getRewardTooltipContent(
                                getFormattedNumber(rewardSinceLastDeposit, decimals, decimals),
                                rewardSinceLastDepositInUsd.toFixed(2)
                              )
                            }
                          </Tooltip>
                        } */}
                      </div>
                    </div>
                  )
                }
              </div>
          }

          {/* Total Borrow */}
          {
            !isMobile &&
              <div className='lend-table__row-item__cell'>
                {
                  (isRefreshing && wallet) ? <Loader /> :
                  (
                    <div>
                      <div className='lend-table__row-item__cell-token'>
                        {!isNil(totalBorrow) ? `${getFormattedNumber(totalBorrow)} ${name}` : totalBorrow}
                      </div>
                      <div className='lend-table__row-item__cell-usd'>
                        {!isNil(totalBorrowInUsd) ? '$' : null}{getFormattedNumber(totalBorrowInUsd)}
                        {/* {
                          isUserBalanceAccountValid &&
                          <div
                            className='lend-table__header-cell__info-btn'
                            onMouseOver={this.toggleRewardsInfoTooltip.bind(this, true)}
                            onMouseLeave={this.toggleRewardsInfoTooltip.bind(this, false)}
                            id={`rewardsInfoIcon${this.props.rowIndex}`}
                          >
                            <img src={infoIcon} />
                          </div>
                        } */}
                        {/* {
                          isUserBalanceAccountValid &&
                          <Tooltip
                            target={`rewardsInfoIcon${this.props.rowIndex}`}
                            isOpen={this.state.isRewardsInfoTooltipVisible}
                            hideArrow
                            placement='bottom'
                            innerClassName='lend-table__tooltip'
                          >
                            {
                              getRewardTooltipContent(
                                getFormattedNumber(rewardSinceLastDeposit, decimals, decimals),
                                rewardSinceLastDepositInUsd.toFixed(2)
                              )
                            }
                          </Tooltip>
                        } */}
                      </div>
                    </div>
                  )
                }
              </div>
          }

          {/* Utilization */}
          {
            !isMobile &&
              <div className='lend-table__row-item__cell'>
                {/* {
                  isRefreshing ? <Loader /> :
                  (weeklyAPY ? `${weeklyAPY} %` : '-')
                } */}
                {!isNil(utilization) ? (<span><span>{utilization}</span> %</span>) : '-'}
              </div>
          }

          {/* Item Toggle */}
          <div className='lend-table__row-item__toggle'>
            <div className='lend-table__row-item__toggle__btn'>
              <img src={downCaret} />
            </div>
          </div>
        </div>
        {
          <CSSTransitionGroup
            transitionName='slide-in'
            transitionEnterTimeout={50}
            transitionLeaveTimeout={50}
          >
          {
            isExpanded &&
              <div className={`lend-table__row-expanded ${isExpanded ? '' : 'is-hidden'}`}>


                {/* Harvest and Fee Section */}
                <div className='lend-table__row-expanded__left-wrapper'>
                  {/* Harvest */}
                  {/* {
                    liquidityMining &&
                    <div className='lend-table__row-expanded__left-wrapper__harvest'>
                      <div className='lend-table__row-expanded__left-wrapper__harvest__text'>
                        <div className='lend-table__row-expanded__left-wrapper__harvest__text-label'>
                        TULIP Earned:
                        </div>
                        <div className='lend-table__row-expanded__left-wrapper__harvest__text-value'>
                          {tulipEarned ? tulipEarned.toFixed(TOKENS.TULIP.decimals) : ZERO.toFixed(TOKENS.TULIP.decimals)}
                        </div>
                      </div>

                      <Button
                        color='primary'
                        onClick={this.handleTulipHarvest}
                        disabled={isHarvestDisabled}
                      >
                        {
                          isHarvesting ?
                            <Loader /> :
                            'Harvest'
                        }
                      </Button>
                    </div>
                  } */}

                  {/* Fee Details
                  <div className='lend-table__row-expanded__left-wrapper__fees'>
                    <div className='lend-table__row-expanded__left-wrapper__fees-header'>
                      Fees
                    </div>
                    <div className='lend-table__row-expanded__left-wrapper__fees-text'>
                      {feeText}
                    </div>
                  </div> */ }
                </div>

                {/* Withdraw and Deposit Box */}
                {
                  isMobile ?
                    <>
                      {withdrawBox}
                      {depositBox}
                    </> :
                    <>
                      {depositBox}
                      {withdrawBox}
                    </>
                }

                {
                  isMobile &&
                    <div className='lend-table__row-item__cell mobile-details-expanded'>
                      {/* Balance */}
                      <div>
                      Balance
                      {
                        (isRefreshing && wallet) ? <Loader /> :
                        (
                          <div>
                            <div className='lend-table__row-item__cell-token'>
                              {wallet ? getFormattedNumber(Number(balance)) : balance}
                              {wallet ? ` | $${getFormattedNumber(balanceInUsd)}` : null}
                            </div>
                          </div>
                        )
                      }
                      </div>

                      {/* Deposited */}
                      <div>
                      Deposited
                      {
                        (isRefreshing && wallet) ? <Loader /> :
                        (
                          <div>
                            <div className='lend-table__row-item__cell-token'>
                              {wallet ? getFormattedNumber(Number(deposited)) : deposited}
                              {wallet ? ` | $${getFormattedNumber(depositedInUsd)}` : null}
                            </div>
                          </div>
                        )
                      }
                      </div>

                      {/* APY */}
                      <div>
                        APY
                        {!isNil(apy) ? (<span><span>{getFormattedNumber(apy)}</span> %</span>) : '-'}
                      </div>

                      {/* Total Supply */}
                      <div>
                      Total Supply
                      {
                        (isRefreshing && wallet) ? <Loader /> :
                        (
                          <div>
                            <div className='lend-table__row-item__cell-token'>
                              {!isNil(totalSupply) ? `${getFormattedNumber(totalSupply)} ${name}` : totalSupply}&nbsp;
                              {!isNil(totalSupplyInUsd) ? '| $' : null}{getFormattedNumber(totalSupplyInUsd)}
                            </div>
                          </div>
                        )
                      }
                      </div>

                      {/* Total Borrow */}
                      <div>
                      Total Borrow
                      {
                        (isRefreshing && wallet) ? <Loader /> :
                        (
                          <div>
                            <div className='lend-table__row-item__cell-token'>
                              {!isNil(totalBorrow) ? `${getFormattedNumber(totalBorrow)} ${name}` : totalBorrow}&nbsp;
                              {!isNil(totalBorrowInUsd) ? '| $' : null}{getFormattedNumber(totalBorrowInUsd)}
                            </div>
                          </div>
                        )
                      }
                      </div>

                      {/* Utilization */}
                      <div>
                        Utilization
                        {!isNil(utilization) ? (<span><span>{utilization}</span> %</span>) : '-'}
                      </div>
                    </div>
                }
              </div>
            }
          </CSSTransitionGroup>
        }
        {/* {
          dualYield &&
          <Tooltip
            target={`infoIcon${this.props.rowIndex}`}
            isOpen={this.state.isDualYieldInfoTooltipVisible}
            hideArrow
            placement='bottom'
            innerClassName='lend-table__tooltip'
          >
            Dual Yield
          </Tooltip>
        }
        {
          liquidityMining &&
          <Tooltip
            target={`tulipIcon${this.props.rowIndex}`}
            isOpen={this.state.isLiquidityMiningInfoTooltipVisible}
            hideArrow
            placement='bottom'
            innerClassName='lend-table__tooltip'
          >
            Liquidity Mining
          </Tooltip>
        } */}
      </div>
    );
  }
}

const Header = observer(LendTableHeader);
const Row = observer(LendTableRow);

class LendTable extends React.Component {
  constructor () {
    super();

    this.state = {
      isModalOpen: false,
      sortedBy: null,
      sortOrder: ''
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.setSuccessCallback = this.setSuccessCallback.bind(this);
    this.handleModalSuccess = this.handleModalSuccess.bind(this);
    this.getRowsInOrder = this.getRowsInOrder.bind(this);
    this.setSortBy = this.setSortBy.bind(this);

    this.modalSuccessCallback = null;
  }

  toggleModal (e, value) {
    isNil(value) && (value = !this.state.isModalOpen);

    this.setState({ isModalOpen: value});
  }

  setSuccessCallback (cb) {
    this.modalSuccessCallback = cb;
  }

  handleModalSuccess () {
    this.toggleModal(false);

    isFunction(this.modalSuccessCallback) && this.modalSuccessCallback();

    // Clear success callback after calling it
    this.setSuccessCallback(null);
  }

  getRowsInOrder (data) {
    const { sortedBy, sortOrder } = this.state;

    // If both `sortedBy` and `sortOrder` are not present,
    // then we fallback to the default order
    if (!(sortedBy && sortOrder)) {
      return data;
    }

    return orderBy(data, [sortedBy], [sortOrder]);
  }

  setSortBy (headerName) {
    if (!headerName) {
      return;
    }

    const { sortedBy, sortOrder } = this.state;

    // If the same header is being clicked multiple times,
    // we change it from 'desc' to 'asc' to `null` and so on.
    if (sortedBy === headerName) {
      const currentOrderIndex = SORT_ORDER.indexOf(sortOrder),
        nextOrder = SORT_ORDER[(currentOrderIndex + 1) % 3],
        nextState = { sortOrder: nextOrder };

      // If the next order is `null`, then we also reset `sortedBy`
      !nextOrder && (nextState.sortedBy = null);

      return this.setState(nextState);
    }

    // This is a new header, so we start with 'desc' again
    return this.setState({
      sortedBy: headerName,
      sortOrder: SORT_ORDER[0]
    });
  }

  render () {
    const {
      data = [],
      onWithdraw,
      onDeposit,
      checkUnsafeWithdraw,
      onFixTokenAccount,
      onTulipHarvest
    } = this.props || {},
      { isRefreshing } = getStore('UIStore'),
      rowsInOrder = this.getRowsInOrder(data),
      { sortedBy, sortOrder } = this.state;

    return (
      <div className='lend-table'>
        <Header
          toast={this.props.toast}
          setSortBy={this.setSortBy}
          sortedBy={sortedBy}
          sortOrder={sortOrder}
        />
        {
          isEmpty(data) ? 
            (
              isRefreshing ?
                <Loader /> :
                <div className='lend-table__empty'>You have no deposits.</div>
            ) :
            rowsInOrder.map((row, index) => (
              <Row
                data={row}
                onWithdraw={onWithdraw.bind(this, row.name)}
                onDeposit={onDeposit.bind(this, row.name)}
                toast={this.props.toast}
                key={row.name}
                toggleModal={this.toggleModal.bind(this, null)}
                setSuccessCallback={this.setSuccessCallback}
                checkUnsafeWithdraw={checkUnsafeWithdraw.bind(this, row.assetSymbol)}
                rowIndex={index}
                onFixTokenAccount={onFixTokenAccount.bind(this, row.assetSymbol)}
                onTulipHarvest={onTulipHarvest.bind(this, row.assetSymbol)}
              />
            ))
        }
        <Modal
          isOpen={this.state.isModalOpen}
          toggle={this.toggleModal}
          className='app-body__modal'
          centered
        >
          <ModalHeader toggle={this.toggleModal}>Are you sure?</ModalHeader>
          <ModalBody>
            Withdrawing within 2 hours from your last deposit
          </ModalBody>
          <ModalFooter>
            <Button className='modal__success-btn' color='danger' onClick={this.handleModalSuccess}>Withdraw anyway</Button>{' '}
            <Button className='modal__cancel-btn' color='primary' onClick={this.toggleModal}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default observer(LendTable);
