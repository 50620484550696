// MobX
import { observable, makeObservable, action, computed } from 'mobx';

// Lodash
import { map, isNil, assign, filter, last, concat, slice } from "lodash";

// Utils
import { LENDING_RESERVES } from "../utils/lendingReserves";
import { commitment, getMultipleAccounts } from '../utils/web3';
import {hundred, LENDING_RESERVE_LAYOUT, MINT_LAYOUT, WAD} from "../utils/layouts";

// Stores
import { getStore } from './get-store';

// web3
import * as anchor from '@project-serum/anchor';
import { PriceFetcherService } from '../services/PriceFetcherService';
import {TokenAmount} from "../utils/safe-math";

export default class ReserveStore {
  constructor () {
    this.reserves = {};
    this.tulipPrice = 0;

    makeObservable(this, {
      reserves: observable,
      setReserve: action.bound,
      init: action.bound,
      visibleReserves: computed
    });

    this.getReserve = this.getReserve.bind(this);
    this.calculateBorrowAPY = this.calculateBorrowAPY.bind(this);

    this.init();
  }

  async init () {
    getStore('UIStore').setIsRefreshing(true);

    const reserveAccounts = map(LENDING_RESERVES, (reserve) => new anchor.web3.PublicKey(reserve.account));
      const mintAddresses = map(LENDING_RESERVES, (reserve) => new anchor.web3.PublicKey(reserve.collateralTokenMint));
      const accountDetailsToFetch = concat(reserveAccounts, mintAddresses);
      const accountDetails = await getMultipleAccounts(window.$web3, accountDetailsToFetch, commitment);
      const reserveAccountDetails = slice(accountDetails, 0, LENDING_RESERVES.length);
      const tokenSupplyForAllReserves = slice(accountDetails, LENDING_RESERVES.length, LENDING_RESERVES.length * 2);

    reserveAccountDetails.forEach(async (reserveAccount, index) => {
      const reserve = LENDING_RESERVES[index];
      const decodedData = LENDING_RESERVE_LAYOUT.decode(reserveAccount.account.data);
      const { getTokenPrice } = getStore('PriceStore');
      const {
          availableAmount,
          platformAmountWads,
          borrowedAmount: borrowedAmountWads,
          cumulativeBorrowRate: cumulativeBorrowRateWads
        } = decodedData?.liquidity;
      const borrowedAmount = new TokenAmount(borrowedAmountWads.div(WAD), reserve.decimals);
      const platformAmount = new TokenAmount(platformAmountWads.div(WAD), reserve.decimals);
      const availableAmountWei = new TokenAmount(availableAmount, reserve.decimals);

      const decimals = new anchor.BN(Math.pow(10, reserve.decimals));
      const totalSupply = availableAmountWei.wei.plus(borrowedAmount.wei).minus(platformAmount.wei);
      const totalBorrow = borrowedAmount.wei.minus(platformAmount.wei);

      const utilization = totalBorrow.div(totalSupply);
      const utilizationRate = utilization.times(100);

      const decodedTokenData = MINT_LAYOUT.decode(tokenSupplyForAllReserves[index]?.account?.data);
      const uiAmount = decodedTokenData.supply / Math.pow(10, decodedTokenData.decimals);
      const borrowAPY = this.calculateBorrowAPY(utilizationRate.toFixed(2), reserve.name === 'RAY', (reserve.name === 'ORCA' || reserve.name === 'whETH'));
      // console.log("$$$ reserve data", reserve.name, borrowedAmount.fixed(), platformAmount.fixed(), availableAmountWei.fixed());
      this.setReserve(reserve.mintAddress, {
        availableAmount: (availableAmount.div(decimals)).toNumber(),
        price: getTokenPrice(reserve.name),
        borrowedAmount: (totalBorrow.div(decimals)).toNumber(),
        utilization: utilizationRate.toFixed(2),
        cumulativeBorrowRate: borrowAPY,
        lendAPY: borrowAPY * utilization.toNumber(),
        newCumulativeBorrowRate: cumulativeBorrowRateWads,
        totalSupply: (totalSupply.div(decimals)).toNumber(),
        uiAmount
      });
    });

    // getStore('UIStore').resetRefreshState();
  }

  calculateBorrowAPY = (currentUtilization, ray, higherMax) => {
    const optimalUtilization = 50; // todo: get this from lending_info.json
    const degenUtilization = 90; // todo: get this from lending_info.json
    const minBorrowRate = 0;
    const optimalBorrowRate = 15;
    const degenBorrowRate = (ray) ? 35 : 25;
    const maxBorrowRate = (higherMax) ? 150 : 100;

    let borrowAPY;
    if (currentUtilization <= optimalUtilization) {
      const normalizedFactor = currentUtilization / optimalUtilization;
      borrowAPY =
          normalizedFactor * (optimalBorrowRate - minBorrowRate) + minBorrowRate;
    } else if (currentUtilization > optimalUtilization && currentUtilization <= degenUtilization) {
      const normalizedFactor = ( currentUtilization - optimalUtilization ) / (degenUtilization - optimalUtilization)
      borrowAPY =
          normalizedFactor * (degenBorrowRate - optimalBorrowRate) +
          optimalBorrowRate;
    } else if (currentUtilization > degenUtilization) {
      const normalizedFactor = ( currentUtilization - degenUtilization ) / (100 - degenUtilization)
      borrowAPY = normalizedFactor * (maxBorrowRate - degenBorrowRate) + degenBorrowRate;
    }


    return borrowAPY;
  };

  getReserve (mintAddress) {
    return this.reserves[mintAddress];
  }

  setReserve (mintAddress, reserveDetails) {
    if (!mintAddress || isNil(reserveDetails)) {
      return;
    }

    !this.reserves[mintAddress] && (this.reserves[mintAddress] = {});

    assign(this.reserves[mintAddress], reserveDetails);
  }

  get visibleReserves () {
    const { wallet, tokenAccounts, isTokenAccountInvalid } = getStore('WalletStore'),
      showDeposited = getStore('UserPreferenceStore').get('showDeposited');

    // If wallet is not connected or if `showDeposited` is turned off, show all reserves.
    if (!wallet || !showDeposited) {
      return LENDING_RESERVES;
    }

    return filter(LENDING_RESERVES, (reserve) => {
      // Show farms with invalid token accounts
      // if (isTokenAccountInvalid(reserve.mintAddress, reserve?.platform)) {
      //   return true;
      // }

      const tokenAccount = tokenAccounts[reserve.collateralTokenMint];

      if (!tokenAccount) {
        return false;
      }

      const deposited = Number(tokenAccounts[reserve.collateralTokenMint].balance?.fixed());

      if (!deposited) {
        return false;
      }

      return true;
    });
  }
}
