import React from 'react';
import styles from '../../styles/static.css';
import solfarmLogo from '../../images/SolFarmLogo.png';
import momoPic from '../../images/team/momo.jpg';
import senxPic from '../../images/team/senx.png';
import ssjPic from '../../images/team/therealssj.png'
import postPic from '../../images/team/post.png';
import littlepoggersPic from '../../images/team/littlepoggers.png';
import barneyPic from '../../images/team/barney.png';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from '../../constants/routes';

function navigateTo (history, route) {
  return history.push(route);
}

export const AboutUs = (props) => {
  const history = useHistory();

  return (
    <div className='about-us-page-container'>
      <div className='about-us-content-container'>

        {/* Header */}
        <div className='header'>
          {/* <a href='../'> */}
            <div className='header-logo' onClick={navigateTo.bind(this, history, APP_ROUTES.HOME.route)}>
              <img src={solfarmLogo} className='solfarm-small-logo'/>
              SolFarm
            </div>
          {/* </a> */}
          <div className='header-links'>
            <div className='header-link' onClick={navigateTo.bind(this, history, APP_ROUTES.VAULTS.route)}>
              {/* <a href='../vaults'> */}
                Vaults
              {/* </a> */}
            </div>
            <div className='header-link'>
              <a href='https://solfarm.gitbook.io/solfarm/' target='_blank'>
                Docs
              </a>
            </div>
            <div className='header-link'>
              <a href='https://twitter.com/Solfarmio' target='_blank'>
                Twitter
              </a>
            </div>
            <div className='header-link'>
              <a href='https://discord.gg/vCaEUjnc28' target='_blank'>
                Discord
              </a>
            </div>
            <div className='header-link' onClick={navigateTo.bind(this, history, APP_ROUTES.ABOUT.route)}>
              <a>
                About Us
              </a>
            </div>
          </div>
        </div>
        {/* Header End */}

        {/* Brief Intro */}
        {/* <div className='section-on
          <h1>Some cool heading</h1>
          <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vel nunc at nunc congue ullamcorper. Vivamus ut justo a dolor tincidunt tristique. Praesent varius, dui quis fringilla hendrerit, est urna scelerisque turpis, sed aliquet mauris libero nec justo. Aenean velit dui, malesuada vel orci ac, ornare scelerisque nunc. Vivamus dignissim urna at turpis tincidunt, vitae mattis lectus finibus. Nunc mollis feugiat mauris non finibus. Quisque varius turpis vitae molestie laoreet.
          </div>
        </div> */}
        {/* Brief Intro End */}

        {/* Team */}
        <div className='section-two'>
          <h1>Team</h1>
          <div className='team-grid'>
            <div className='team-member'>
              <div className='team-member-content'>
                <div className='team-member-img'>
                  <img src={momoPic}/>
                </div>
                <div className='team-member-details'>
                  <div className='team-member-name'>momo</div>
                  <div className='team-member-position'>Co-Founder</div>
                  <div className='team-member-intro'>4 years of crypto experience managing blockchain projects, with a keen interest and eye for DeFi products.</div>
                </div>
              </div>
            </div>
            <div className='team-member'>
              <div className='team-member-content'>
                <div className='team-member-img'>
                  <img src={senxPic}/>
                </div>
                <div className='team-member-details'>
                  <div className='team-member-name'>senx</div>
                  <div className='team-member-position'>Co-Founder</div>
                  <div className='team-member-intro'>Senx is a frog with multiple years of operational experience in tier 1 crypto exchanges and is an experienced on-chain farmer.</div>
                </div>
              </div>
            </div>
            <div className='team-member'>
              <div className='team-member-content'>
                <div className='team-member-img'>
                  <img src={ssjPic}/>
                </div>
                <div className='team-member-details'>
                  <div className='team-member-name'>therealssj</div>
                  <div className='team-member-position'>Lead Developer</div>
                  <div className='team-member-intro'>Therealssj bought the top in 2017 and has been working in the cryptocurrency space even since.</div>
                </div>
              </div>
            </div>
            <div className='team-member'>
              <div className='team-member-content'>
                <div className='team-member-img'>
                  <img src={postPic}/>
                </div>
                <div className='team-member-details'>
                  <div className='team-member-name'>post</div>
                  <div className='team-member-position'>Senior Developer</div>
                  <div className='team-member-intro'>Post is a blockchain developer with a background in systems administration and has been involved in the blockchain ecosystem since 2011.</div>
                </div>
              </div>
            </div>
            <div className='team-member'>
              <div className='team-member-content'>
                <div className='team-member-img'>
                  <img src={littlepoggersPic}/>
                </div>
                <div className='team-member-details'>
                  <div className='team-member-name'>littlepoggers</div>
                  <div className='team-member-position'>System Admin & Junior Developer</div>
                  <div className='team-member-intro'>Crypto native DeFi enthusiast who has been passionate about mining and running nodes for novel blockchains since signing up on Slush Pool in 2012.</div>
                </div>
              </div>
            </div>
            <div className='team-member'>
              
              <div className='team-member-content'>
                <div className='team-member-img'>
                  <img src={barneyPic}/>
                </div>
                <div className='team-member-details'>
                  <div className='team-member-name'>barney</div>
                  <div className='team-member-position'>UI/UX & Frontend Developer</div>
                  <div className='team-member-intro'>Barney is a UI/UX and Frontend Developer, with a knack for memes. He has worked for 2 unicorn startups and enjoys building mature products.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Team End */}

      </div>
    </div>
  );
}
