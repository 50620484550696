import { observable, makeObservable, action } from 'mobx';
import { clone, forEach } from 'lodash';
import { USER_PREFERENCES, defaultPreferences } from '../constants/localStorageConstants';

export default class UserPreferenceStore {
  constructor () {
    this.preferences = new Map();

    makeObservable(this, {
      preferences: observable,
      _updateOne: action.bound,
      _updateMultiple: action.bound
    });

    // Get User Preferences from `localStorage`
    const userPreferences = localStorage.getItem(USER_PREFERENCES);

    // If `localStorage` doesn't have userPreferences, initialize it
    if (!userPreferences) {
      localStorage.setItem(USER_PREFERENCES, JSON.stringify(defaultPreferences));
      this._updateMultiple(defaultPreferences);
    } else {

      // If User Preferences already exist, add them to the store
      const userPreferencesToAdd = JSON.parse(userPreferences);

      forEach(userPreferencesToAdd, (value, key) => {
        this.preferences.set(key, value);
      });
    }
  }

  // Private method to update the store
  _updateOne (key, value) {
    this.preferences.set(key, value);
  }

  // Private method to update the store with multiple preferences
  _updateMultiple (userPreferences) {
    forEach(userPreferences, (value, key) => {
      this._updateOne(key, value);
    });
  }

  get (key) {
    return this.preferences.get(key);
  }

  set (key, value) {

    // Read the latest values from `localStorage` and create a temporary cache
    const userPreferences = clone(JSON.parse(localStorage.getItem(USER_PREFERENCES)));

    // Change the value in temporary cache
    userPreferences[key] = value;

    // Update the values in `localStorage`
    localStorage.setItem(USER_PREFERENCES, JSON.stringify(userPreferences));

    // Update the Store with latest preferences
    this._updateOne(key, value);
  }

  setMultiple (preferences) {

    // Read the latest values from `localStorage` and create a temporary cache
    const userPreferences = clone(JSON.parse(localStorage.getItem(USER_PREFERENCES)));

    // Change the values in temporary cache
    forEach(preferences, (value, key) => {
      userPreferences[key] = value;
    });

    // Update the values in `localStorage`
    localStorage.setItem(USER_PREFERENCES, JSON.stringify(userPreferences));

    // Update the Store with latest preferences
    this._updateMultiple(userPreferences);
  }
}