import React from 'react';
import classnames from 'classnames';
import { Button, Modal, ModalBody } from 'reactstrap';
import { getStore } from '../../stores/get-store';
import { REPAIR_ISSUES } from '../../constants/modalConstants';
import { SABER_FARMS } from '../../utils/saber_farms';
import { concat, findIndex, last, map } from 'lodash';
import { FARMS, getFarmBySymbol } from '../../utils/farms';
import { getLendingReserveByAccount } from '../../utils/config';
import { getReserveByName } from '../../utils/lendingReserves';
import { WAD } from '../../utils/layouts';
import { getFormattedNumber } from '../../utils/safe-math';
import { TransactionService } from '../../services/TransactionService';
import { APP_ROUTES } from '../../constants/routes';
import { Loader } from '../../base-components/Loader';
import LowSolWarningAlert from '../../components/LowSolWarningAlert/LowSolWarningAlert';
import { ADD_COLLATERAL_MIN, CLOSE_POSITION_MIN, LEVERAGE_FARMING_OPEN_POSITION_MIN } from '../../constants/minSolBalance';
import {ORCA_FARMS} from "../../utils/orcaFarms";

const transactionToast = (tx) => (
    <div style={{ fontSize: '12px'}}>
      <div style={{ marginBottom: '8px', fontWeight: '600' }}>Transaction has been sent</div>
      <div>Confirmation is in progress. Check your transaction on <a className='link' href={`https://explorer.solana.com/tx/${tx}`} target='_blank'>here</a>.</div>
    </div>
  ),
  goToYourPositionsPageToast = (cb) => (
    <div style={{ fontSize: '12px'}}>
      <div style={{ marginBottom: '8px', fontWeight: '600' }}>Your Position has been opened.</div>
      <div onClick={cb} style={{ color: '#5FBAD6' }}>Click here to check your positions.</div>
    </div>
  );

const SET_TOKEN_ACC_DELAY_ON_FAILURE = 2 * 1000;

export default class RepairIssuesModal extends React.Component {
  constructor () {
    super();

    this.state = { currentRepairId: null };

    this.getHeader = this.getHeader.bind(this);
    this.getBody = this.getBody.bind(this);
    this.getFooter = this.getFooter.bind(this);

    this.handleToggle = this.handleToggle.bind(this);
  }
  
  handleToggle () {
    this.setState({ currentRepairId: null });
    getStore('ModalStore').dismiss(REPAIR_ISSUES);
  }

  refreshTokens () {
    // Refresh the token accounts in case of failure
    getStore('UIStore').setIsRefreshing(true);

    setTimeout(async () => {
      await getStore('WalletStore').setTokenAccounts();
      getStore('UIStore').resetRefreshState();
    }, SET_TOKEN_ACC_DELAY_ON_FAILURE);
  }

  async handleFixOpenObligation (assetSymbol, repairId, obligationIdx) {
    // const { activeLeverageVault } = this.props,
      // { coinAValue, coinBValue } = this.state,
      // { getLeverageVault } = getStore('LeverageVaultStore'),
      // { selectedCoinIndex = 1, leverageValue = 3 } = getLeverageVault(activeLeverageVault.mintAddress) || {},
      // farm = getFarmBySymbol(activeLeverageVault?.assetSymbol),
      // reserveName = farm?.coins[selectedCoinIndex]?.symbol;

    this.setState({ currentRepairId: repairId });

    try {
      const transactions = await TransactionService.openMarginPosition(
        assetSymbol,
        '',
        0,
        0,
        0,
          obligationIdx
      );

      const tx = await last(transactions);
      this.props.toast(transactionToast(tx));

      const signatureStatus = await window.$web3.getSignatureStatus(tx, {searchTransactionHistory: true});

      if (signatureStatus.value?.err) {
        throw new Error('Failed to open position. Please retry.');
      }

      this.setState({ currentRepairId: null });

      getStore('FarmStore').setFarm(getFarmBySymbol(assetSymbol).mintAddress, {
        farmPendingObligationAccounts: []
      });

      this.props.toast(goToYourPositionsPageToast(() => this.props.history.push(APP_ROUTES.YOUR_POSITIONS.route)));

      // Refresh obligations
      setTimeout(getStore('WalletStore').setTokenAccounts, 9000);
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.refreshTokens();

      this.props.toast(errorToast, 'error');
      this.setState({ currentRepairId: null });
      err && console.error('handleFixOpenObligation~err:', err);
    }
  }

  async handleFixCloseObligation (assetSymbol, obligationIdx, repairId) {
    const farm = getFarmBySymbol(assetSymbol);
    const { farmPendingCloseObligationAccounts } = getStore('FarmStore').getFarm(farm.mintAddress) || {};
    let obligation = {};
    farmPendingCloseObligationAccounts.forEach( (pendingCloseObligation) => {
      if (pendingCloseObligation.obligationIdx === obligationIdx) {
        obligation = pendingCloseObligation;
      }
    });
    const borrows = obligation.borrows;
    const selectedOption = 0;

    this.setState({ currentRepairId: repairId });

    try {
      const transactions = await TransactionService.closeMarginPosition(
        assetSymbol,
        obligationIdx,
        borrows,
        selectedOption
      );

      const tx = await last(transactions);

      this.props.toast(transactionToast(tx));

      const signatureStatus = await window.$web3.getSignatureStatus(tx, {searchTransactionHistory: true});

      if (signatureStatus.value?.err) {
        throw new Error('Failed to close position. Please retry.');
      }

      this.setState({ currentRepairId: null });

      getStore('FarmStore').setFarm(farm.mintAddress, {
        farmPendingCloseObligationAccounts: []
      });

      this.props.toast('Position closed successfully.');

      // Refresh obligations
      setTimeout(getStore('WalletStore').setTokenAccounts, 9000);

      // this.props.history.push(APP_ROUTES.YOUR_POSITIONS.route);
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.refreshTokens();

      this.setState({ currentRepairId: null });
      this.props.toast(errorToast, 'error');
      err && console.error('handleClosePosition~err:', err);
    }
  }

  async handleFixAddCollateralObligation (assetSymbol, obligationIdx, repairId) {
    try {
      this.setState({ currentRepairId: repairId });

      const transactions = await TransactionService.addCollateralPosition(
        assetSymbol,
        obligationIdx,
        'RAY',
        '',
        ''
      );

      const tx = await last(transactions);
      this.props.toast(transactionToast(tx));

      const signatureStatus = await window.$web3.getSignatureStatus(tx, {searchTransactionHistory: true});

      // const signatureStatus = await window.$web3.getSignatureStatus('3HxQYtRLexDAu2mxn71CA9G6FT5Do4gvxE7xej4xAya6QbHP1zfVQvaYK3WGi2pv5XoWEsSL86oNaESs6w4sB4cW', {searchTransactionHistory: true});

      console.log('signatureStatus', signatureStatus);

      if (signatureStatus.value?.err) {
        throw new Error('Failed to add collateral. Please retry.');
      }

      this.setState({ currentRepairId: null });

      getStore('FarmStore').setFarm(getFarmBySymbol(assetSymbol).mintAddress, {
        farmPendingAddCollateralAccounts: []
      });

      this.props.toast('Collateral added successfully.');

      // Refresh obligations
      setTimeout(getStore('WalletStore').setTokenAccounts, 9000);
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.refreshTokens();

      this.props.toast(errorToast, 'error');
      this.setState({ currentRepairId: null });
      err && console.error('handleFixAddCollateralObligation~err:', err);
    }
  }

  getHeader () {
    return null;
  }

  getBody () {
    const ALLFARMS = concat(SABER_FARMS, ORCA_FARMS, FARMS).filter(farm => farm.symbol !== "RAY-SRM-DUAL");
    const pendingOpenPositions = [];
    const pendingClosePositions = [];
    const pendingAddCollateral = [];
    const { isRefreshing, isSilentRefresh } = getStore('UIStore');

    ALLFARMS.forEach((farm) => {
      const farmDetails = getStore('FarmStore').getFarm(farm?.mintAddress);
      const {
        userFarmInfo,
        farmPendingObligationAccounts,
        farmPendingCloseObligationAccounts,
        farmPendingAddCollateralAccounts,
        price: farmPrice
      } = farmDetails || {};
      const coin = farm.coins[0];
      const pc = farm.coins[1];

      // ---------------- Pending Open Obligations--------------------------------
      if (farmPendingObligationAccounts && farmPendingObligationAccounts.length > 0) {
        const {
          lpTokens,
          coinDeposits,
          pcDeposits,
          vaultShares,
          borrows,
          obligationIdx
        } = farmPendingObligationAccounts[0]; // For each because these could be multiple for add collateral / close 

        const { getTokenPrice } = getStore('PriceStore'),
          coinPrice = getTokenPrice(coin?.symbol),
          pcPrice = getTokenPrice(pc?.symbol);

        const { obligations } = userFarmInfo || {};

        // let obligationIdx = findIndex(obligations, (obligation) => {
        //   return obligation.positionState.hasOwnProperty("opening")
        //       || obligation.positionState.hasOwnProperty("borrowed")
        //       || obligation.positionState.hasOwnProperty("swapped")
        //       || obligation.positionState.hasOwnProperty("addedLiquidity")
        //       || obligation.positionState.hasOwnProperty("withdrawn")
        //       || obligation.positionState.hasOwnProperty("exitingAndLiquidated");
        // });
    
        let obligationPositionState = {"opening": {}};
        if (obligationIdx !== -1) {
          obligationPositionState = obligations[obligationIdx].positionState;
        }
    
        let obligationProgress = 0;
        if (obligationPositionState.hasOwnProperty("opening") || obligationPositionState.hasOwnProperty("withdrawn")
            || obligationPositionState.hasOwnProperty("exitingAndLiquidated") ) {
          obligationProgress = 1;
        } else if (obligationPositionState.hasOwnProperty("borrowed")) {
          obligationProgress = 2;
        } else if (obligationPositionState.hasOwnProperty("swapped")) {
          obligationProgress = 3;
        } else if (obligationPositionState.hasOwnProperty("addedLiquidity")) {
          obligationProgress = 4;
        }

        let debtValue = 0;
        let coinAmount,
          pcAmount,
          leverageValueToShow,
          selectedCoin = 0;

        borrows.forEach((borrow) => {
          const reserveInfo = getLendingReserveByAccount(borrow.borrowReserve.toBase58());
          const reservePrice = Number(getStore('PriceStore').getTokenPrice(reserveInfo.name));
          const reserve = getReserveByName(reserveInfo.name);
          const borrowedAmount = (borrow.borrowedAmountWads.div(WAD)).toNumber() / Math.pow(10, reserve.decimals);

          if (borrowedAmount) {
            if (reserve.name === farm.coins[0].symbol) {
              selectedCoin = 0;
            } else {
              selectedCoin = 1;
            }
          }

          debtValue += borrowedAmount * reservePrice;
        });

        const lpTokensDecimals = Math.pow(10, farm.decimals);
        const vaultShareTokens = 0;
        const lpTokensValue = (lpTokens).toNumber() / lpTokensDecimals;
        const coinDepositsValue = (coinDeposits).toNumber() / Math.pow(10, coin.decimals);
        const pcDepositsValue = (pcDeposits).toNumber() / Math.pow(10, pc.decimals);
        const positionValue = (coinDepositsValue*coinPrice) + (pcDepositsValue*pcPrice) + ((vaultShareTokens + lpTokensValue) *Number(farmPrice));
        const equityValue = Math.abs(positionValue - debtValue);

        leverageValueToShow = positionValue/equityValue;
        coinAmount = (obligations[obligationIdx].coinAmount.toNumber() / Math.pow(10, coin.decimals)).toString();
        pcAmount = (obligations[obligationIdx].pcAmount.toNumber() / Math.pow(10, pc.decimals)).toString();

        const repairId = `${farm.symbol}-${obligationIdx}`,
          { currentRepairId } = this.state;

        // pendingOpen
        // pendingAddCollateral
        // pendingClose

        pendingOpenPositions.push(
          <div
            className='harvest-list-item'
            style={{
              padding: '8px 12px 8px 12px',
              borderRadius: 10
            }}
          >
            <div
            // onClick={this.handleCheckboxToggle.bind(this, farm.symbol, !selectedFarms.has(farm.symbol))}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                flex: '1',
                width: '100%',
                marginBottom: 8
              }}
            >
              {/* <CustomCheckbox
                checked={selectedFarms.has(farm.symbol)}
                color='primary'
                // inputProps={{ 'aria-label': 'secondary checkbox' }}
                // onChange={this.handleCheckboxToggle.bind(this, farm.symbol)}
                disabled={!selectedFarms.has(farm.symbol) && (selectedFarms.size > 2)}
              /> */}
              {
                map(farm.logos, (logo) => (
                  <img
                    src={logo}
                    style={{
                      height: '24px'
                    }}
                  />
                ))
              }
              <div
                style={{
                  flex: '5.5',
                  marginLeft: '8px',
                  fontWeight: '600'
                }}
              >
                {farm.symbol}
              </div>
              <div
                style={{
                  flex: '2',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}
              >

                {/* <div
                  style={{
                    flex: '1'
                  }}
                >
                <img
                  src={tulipIcon}
                  style={{
                    height: '18px',
                    width: '12px',
                    // marginRight: '8px'
                  }}
                />
                </div> */}
                <div
                  style={{
                    flex: '5',
                    textAlign: 'right',
                    justifySelf: 'flex-end'
                  }}
                >
                  <Button
                    style={{ marginLeft: 'auto', padding: '0.5rem 1.5rem' }}
                    disabled={currentRepairId !== null || isRefreshing || isSilentRefresh}
                    onClick={this.handleFixOpenObligation.bind(this, farm.symbol, repairId, obligationIdx)}
                  >
                    {
                      currentRepairId === repairId
                        ? <Loader />
                        : 'Fix'
                    }
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', padding: 4 }}>
                <div style={{ margin: '0 16px 0 0', flex: 1, display: 'flex', alignItems: 'center' }}>
                  <img
                    src={farm.logos[0]}
                    style={{
                      height: '16px',
                      width: '16px',
                      marginRight: '4px'
                    }}
                  />
                  {coin.symbol}: {getFormattedNumber(coinAmount)}
                </div>
                <div style={{ textAlign: 'right' }}>
                  Leverage: {getFormattedNumber(leverageValueToShow, 1, 1)}x
                </div>
              </div>
              <div style={{ display: 'flex', padding: 4 }}>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                  <img
                    src={farm.logos[1]}
                    style={{
                      height: '16px',
                      width: '16px',
                      marginRight: '4px'
                    }}
                  />
                  {pc.symbol}: {getFormattedNumber(pcAmount)}
                </div>
                <div style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
                  Borrowed: 
                  <img
                    src={farm.logos[selectedCoin]}
                    style={{
                      height: '16px',
                      width: '16px',
                      marginRight: '4px',
                      marginLeft: '8px'
                    }}
                  />
                  {farm.coins[selectedCoin].symbol}
                </div>
              </div>
            </div>
          </div>
        );
      }

      // ---------------- Pending Close Obligations--------------------------------
      if (farmPendingCloseObligationAccounts && farmPendingCloseObligationAccounts.length > 0) {
        farmPendingCloseObligationAccounts.forEach((farmPendingCloseObligationAccount) => {
          const {
            lpTokens,
            coinDeposits,
            pcDeposits,
            borrows
          } = farmPendingCloseObligationAccount;
  
          const { getTokenPrice } = getStore('PriceStore'),
            coinPrice = getTokenPrice(coin?.symbol),
            pcPrice = getTokenPrice(pc?.symbol);
  
          const { obligations } = userFarmInfo || {};
  
          let obligationIdx = findIndex(obligations, (obligation) => {
            return obligation.positionState.hasOwnProperty("withdrawing") ||
              obligation.positionState.hasOwnProperty("removedLiquidity") ||
              obligation.positionState.hasOwnProperty("swappedForRepaying");
          });
  
          let debtValue = 0;
          let coinAmount,
            pcAmount,
            leverageValueToShow,
            selectedCoin = 0;
  
          borrows.forEach((borrow) => {
            const reserveInfo = getLendingReserveByAccount(borrow.borrowReserve.toBase58());
            const reservePrice = Number(getStore('PriceStore').getTokenPrice(reserveInfo.name));
            const reserve = getReserveByName(reserveInfo.name);
            const borrowedAmount = (borrow.borrowedAmountWads.div(WAD)).toNumber() / Math.pow(10, reserve.decimals);
  
            if (borrowedAmount) {
              if (reserve.name === farm.coins[0].symbol) {
                selectedCoin = 0;
              } else {
                selectedCoin = 1;
              }
            }
  
            debtValue += borrowedAmount * reservePrice;
          });
  
          const lpTokensDecimals = Math.pow(10, farm.decimals);
          const vaultShareTokens = 0;
          const lpTokensValue = (lpTokens).toNumber() / lpTokensDecimals;
          const coinDepositsValue = (coinDeposits).toNumber() / Math.pow(10, coin.decimals);
          const pcDepositsValue = (pcDeposits).toNumber() / Math.pow(10, pc.decimals);
          const positionValue = (coinDepositsValue*coinPrice) + (pcDepositsValue*pcPrice) + ((vaultShareTokens + lpTokensValue) *Number(farmPrice));
          const equityValue = Math.abs(positionValue - debtValue);
  
          leverageValueToShow = positionValue/equityValue;
          coinAmount = (obligations[obligationIdx].coinAmount.toNumber() / Math.pow(10, coin.decimals)).toString();
          pcAmount = (obligations[obligationIdx].pcAmount.toNumber() / Math.pow(10, pc.decimals)).toString();
  
          const repairId = `${farm.symbol}-${obligationIdx}`,
            { currentRepairId } = this.state;

          pendingClosePositions.push(
            <div
              className='harvest-list-item'
              style={{
                padding: '8px 12px 8px 12px',
                borderRadius: 10
              }}
            >
              <div
              // onClick={this.handleCheckboxToggle.bind(this, farm.symbol, !selectedFarms.has(farm.symbol))}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  flex: '1',
                  width: '100%',
                  marginBottom: 8
                }}
              >
                {/* <CustomCheckbox
                  checked={selectedFarms.has(farm.symbol)}
                  color='primary'
                  // inputProps={{ 'aria-label': 'secondary checkbox' }}
                  // onChange={this.handleCheckboxToggle.bind(this, farm.symbol)}
                  disabled={!selectedFarms.has(farm.symbol) && (selectedFarms.size > 2)}
                /> */}
                {
                  map(farm.logos, (logo) => (
                    <img
                      src={logo}
                      style={{
                        height: '24px'
                      }}
                    />
                  ))
                }
                <div
                  style={{
                    flex: '5.5',
                    marginLeft: '8px',
                    fontWeight: '600'
                  }}
                >
                  {farm.symbol}
                </div>
                <div
                  style={{
                    flex: '2',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                  }}
                >
  
                  {/* <div
                    style={{
                      flex: '1'
                    }}
                  >
                  <img
                    src={tulipIcon}
                    style={{
                      height: '18px',
                      width: '12px',
                      // marginRight: '8px'
                    }}
                  />
                  </div> */}
                  <div
                    style={{
                      flex: '5',
                      textAlign: 'right',
                      justifySelf: 'flex-end'
                    }}
                  >
                    <Button
                      style={{ marginLeft: 'auto', padding: '0.5rem 1.5rem' }}
                      disabled={currentRepairId !== null || isRefreshing || isSilentRefresh}
                      onClick={this.handleFixCloseObligation.bind(this, farm.symbol, obligationIdx, repairId)}
                    >
                      {
                        currentRepairId === repairId
                          ? <Loader />
                          : 'Fix'
                      }
                    </Button>
                  </div>
                </div>
              </div>
              <div>
                <div style={{ display: 'flex', padding: 4 }}>
                  <div style={{ margin: '0 16px 0 0', flex: 1, display: 'flex', alignItems: 'center' }}>
                    <img
                      src={farm.logos[0]}
                      style={{
                        height: '16px',
                        width: '16px',
                        marginRight: '4px'
                      }}
                    />
                    {coin.symbol}: {getFormattedNumber(coinAmount)}
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    Leverage: {getFormattedNumber(leverageValueToShow, 1, 1)}x
                  </div>
                </div>
                <div style={{ display: 'flex', padding: 4 }}>
                  <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                    <img
                      src={farm.logos[1]}
                      style={{
                        height: '16px',
                        width: '16px',
                        marginRight: '4px'
                      }}
                    />
                    {pc.symbol}: {getFormattedNumber(pcAmount)}
                  </div>
                  <div style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
                    Borrowed: 
                    <img
                      src={farm.logos[selectedCoin]}
                      style={{
                        height: '16px',
                        width: '16px',
                        marginRight: '4px',
                        marginLeft: '8px'
                      }}
                    />
                    {farm.coins[selectedCoin].symbol}
                  </div>
                </div>
              </div>
            </div>
          );
        });
      }

      // ---------------- Pending Add Collateral Obligations--------------------------------
      if (farmPendingAddCollateralAccounts && farmPendingAddCollateralAccounts.length > 0) {
        farmPendingAddCollateralAccounts.forEach((farmPendingAddCollateralAccount) => {
          const {
            lpTokens,
            coinDeposits,
            pcDeposits,
            borrows
          } = farmPendingAddCollateralAccount;
  
          const { getTokenPrice } = getStore('PriceStore'),
            coinPrice = getTokenPrice(coin?.symbol),
            pcPrice = getTokenPrice(pc?.symbol);
  
          const { obligations } = userFarmInfo || {};
  
          let obligationIdx = findIndex(obligations, (obligation) => {
            return obligation.positionState.hasOwnProperty("topUp") ||
              obligation.positionState.hasOwnProperty("topUpSwapped") ||
              obligation.positionState.hasOwnProperty("topUpAddedLiquidity");
          });
  
          let debtValue = 0;
          let coinAmount,
            pcAmount,
            leverageValueToShow,
            selectedCoin = 0;
  
          borrows.forEach((borrow) => {
            const reserveInfo = getLendingReserveByAccount(borrow.borrowReserve.toBase58());
            const reservePrice = Number(getStore('PriceStore').getTokenPrice(reserveInfo.name));
            const reserve = getReserveByName(reserveInfo.name);
            const borrowedAmount = (borrow.borrowedAmountWads.div(WAD)).toNumber() / Math.pow(10, reserve.decimals);
  
            if (borrowedAmount) {
              if (reserve.name === farm.coins[0].symbol) {
                selectedCoin = 0;
              } else {
                selectedCoin = 1;
              }
            }
  
            debtValue += borrowedAmount * reservePrice;
          });
  
          const lpTokensDecimals = Math.pow(10, farm.decimals);
          const vaultShareTokens = 0;
          const lpTokensValue = (lpTokens).toNumber() / lpTokensDecimals;
          const coinDepositsValue = (coinDeposits).toNumber() / Math.pow(10, coin.decimals);
          const pcDepositsValue = (pcDeposits).toNumber() / Math.pow(10, pc.decimals);
          const positionValue = (coinDepositsValue*coinPrice) + (pcDepositsValue*pcPrice) + ((vaultShareTokens + lpTokensValue) *Number(farmPrice));
          const equityValue = Math.abs(positionValue - debtValue);
  
          leverageValueToShow = positionValue/equityValue;
          coinAmount = (obligations[obligationIdx].coinAmount.toNumber() / Math.pow(10, coin.decimals)).toString();
          pcAmount = (obligations[obligationIdx].pcAmount.toNumber() / Math.pow(10, pc.decimals)).toString();
  
          const repairId = `${farm.symbol}-${obligationIdx}`,
            { currentRepairId } = this.state;

          pendingAddCollateral.push(
            <div
              className='harvest-list-item'
              style={{
                padding: '8px 12px 8px 12px',
                borderRadius: 10
              }}
            >
              <div
              // onClick={this.handleCheckboxToggle.bind(this, farm.symbol, !selectedFarms.has(farm.symbol))}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  flex: '1',
                  width: '100%',
                  marginBottom: 8
                }}
              >
                {/* <CustomCheckbox
                  checked={selectedFarms.has(farm.symbol)}
                  color='primary'
                  // inputProps={{ 'aria-label': 'secondary checkbox' }}
                  // onChange={this.handleCheckboxToggle.bind(this, farm.symbol)}
                  disabled={!selectedFarms.has(farm.symbol) && (selectedFarms.size > 2)}
                /> */}
                {
                  map(farm.logos, (logo) => (
                    <img
                      src={logo}
                      style={{
                        height: '24px'
                      }}
                    />
                  ))
                }
                <div
                  style={{
                    flex: '5.5',
                    marginLeft: '8px',
                    fontWeight: '600'
                  }}
                >
                  {farm.symbol}
                </div>
                <div
                  style={{
                    flex: '2',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                  }}
                >
  
                  {/* <div
                    style={{
                      flex: '1'
                    }}
                  >
                  <img
                    src={tulipIcon}
                    style={{
                      height: '18px',
                      width: '12px',
                      // marginRight: '8px'
                    }}
                  />
                  </div> */}
                  <div
                    style={{
                      flex: '5',
                      textAlign: 'right',
                      justifySelf: 'flex-end'
                    }}
                  >
                    <Button
                      style={{ marginLeft: 'auto', padding: '0.5rem 1.5rem' }}
                      disabled={currentRepairId !== null || isRefreshing || isSilentRefresh}
                      onClick={this.handleFixAddCollateralObligation.bind(this, farm.symbol, obligationIdx, repairId)}
                    >
                      {
                        currentRepairId === repairId
                          ? <Loader />
                          : 'Fix'
                      }
                    </Button>
                  </div>
                </div>
              </div>
              <div>
                <div style={{ display: 'flex', padding: 4 }}>
                  <div style={{ margin: '0 16px 0 0', flex: 1, display: 'flex', alignItems: 'center' }}>
                    <img
                      src={farm.logos[0]}
                      style={{
                        height: '16px',
                        width: '16px',
                        marginRight: '4px'
                      }}
                    />
                    {coin.symbol}: {getFormattedNumber(coinAmount)}
                  </div>
                  {/* <div style={{ textAlign: 'right' }}>
                    Leverage: {getFormattedNumber(leverageValueToShow, 1, 1)}x
                  </div> */}
                </div>
                <div style={{ display: 'flex', padding: 4 }}>
                  <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                    <img
                      src={farm.logos[1]}
                      style={{
                        height: '16px',
                        width: '16px',
                        marginRight: '4px'
                      }}
                    />
                    {pc.symbol}: {getFormattedNumber(pcAmount)}
                  </div>
                  <div style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
                    Borrowed: 
                    <img
                      src={farm.logos[selectedCoin]}
                      style={{
                        height: '16px',
                        width: '16px',
                        marginRight: '4px',
                        marginLeft: '8px'
                      }}
                    />
                    {farm.coins[selectedCoin].symbol}
                  </div>
                </div>
              </div>
            </div>
          );
        });
      }
    });

    // Based on the length of each item,
    // and then based on the maximum amount, we have to show the min-balance warning
    const minBalanceRequired = (() => {
      if (pendingOpenPositions.length) {
        return LEVERAGE_FARMING_OPEN_POSITION_MIN;
      }

      if (pendingClosePositions.length) {
        return CLOSE_POSITION_MIN;
      }

      if (pendingAddCollateral.length) {
        return ADD_COLLATERAL_MIN;
      }

      return 0;
    })();

    return (
      <ModalBody className='leverage-modal-meta'>
        <div className='leverage-modal__heading'>
          Repair Issues
        </div>
        {
          pendingOpenPositions.length > 0 &&
            <div>
              <div style={{ marginBottom: 8 }}>Pending Open Positions</div>
              {pendingOpenPositions}
            </div>
        }
        {
          pendingClosePositions.length > 0 &&
            <div>
              <div style={{ marginBottom: 8 }}>Pending Close Positions</div>
              {pendingClosePositions}
            </div>
        }
        {
          pendingAddCollateral.length > 0 &&
            <div>
              <div style={{ marginBottom: 8 }}>Pending Add Collateral Positions</div>
              {pendingAddCollateral}
            </div>
        }
        <LowSolWarningAlert
          className='shift-up-16'
          minBalanceRequired={minBalanceRequired}
        />
      </ModalBody>
    );
  }

  getFooter () {
    return null;
  }


  render () {
    const {
      activeModalId,
      data
    } = this.props;

    return (
      <Modal
        isOpen={activeModalId === REPAIR_ISSUES}
        toggle={this.handleToggle}
        centered
        className={
          classnames(
            'app-body__modal',
            'leverage-modal',
            'repair-issues-modal'
          )
        }
      >
        {/* <ModalBody style={{ background: '#004671' }}>
          Let's repair some issues!
        </ModalBody> */}

        {this.getHeader()}
        {this.getBody()}
        {this.getFooter()}
      </Modal>
    );
  }
}