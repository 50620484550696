import { observable, makeObservable, action } from 'mobx';
import { isNil } from 'lodash';

export default class ConnectionStore {
  constructor () {
    this.connection = null;
    this.endpoint = '';

    makeObservable(this, {
      connection: observable,
      endpoint: observable,
      setConnection: action.bound,
      clearConnection: action.bound,
      setEndpoint: action.bound
    });
  }

  setEndpoint (value) {
    this.endpoint = value;
  }

  setConnection (connection) {
    !isNil(connection) && (this.connection = connection);
  }

  clearConnection () {
    this.connection = null;
  }
}