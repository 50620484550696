import React from 'react';
import { observer } from 'mobx-react';
import { Input } from 'reactstrap';
import inputDownArrow from '../icons/input-down-arrow.svg';
import classnames from 'classnames';

class CustomNumberInput extends React.Component {
  constructor () {
    super();

    this.handleUpArrowClick = this.handleUpArrowClick.bind(this);
    this.handleDownArrowClick = this.handleDownArrowClick.bind(this);
  }

  handleUpArrowClick () {
    const { value, step = 1, onChange, disabled } = this.props;

    if (disabled) {
      return;
    }

    const newValue = Math.round((Number(value) + step) * 10) / 10;

    return onChange({ target: { value: newValue } });
  }

  handleDownArrowClick () {
    const { value, step = 1, onChange, disabled } = this.props;

    if (disabled) {
      return;
    }

    const newValue = Math.round((Number(value) - step) * 10) / 10;

    return onChange({ target: { value: newValue } });
  }

  render () {
    const {
      step,
      value,
      placeholder,
      onChange,
      disabled,
      withArrows = true,
      icon,
      rightButtonLabel,
      onRightButtonClick,
      className
    } = this.props;

    return (
      <div className='customNumberInput'>
        {
          icon &&
          <img className='customNumberInput-icon' src={icon} />
        }
        <Input
          step={step}
          value={value}
          type='number'
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          className={
            classnames(
              'customNumberInput-input',
              className
            )
          }
        />
        {
          withArrows &&
          (
            <div className='customNumberInput-arrows'>
              <div onClick={this.handleUpArrowClick} className='customNumberInput-arrows__up'>
                <img src={inputDownArrow} />
              </div>
              <div onClick={this.handleDownArrowClick} className='customNumberInput-arrows__down'>
                <img src={inputDownArrow} />
              </div>
            </div>
          )
        }
        {
          (rightButtonLabel && onRightButtonClick) &&
          (
            <>
              <div className='separator' />
              <div
                className='customNumberInput-rightBtn'
                onClick={onRightButtonClick}
              >
                {rightButtonLabel}
              </div>
            </>
          )
        }
      </div>
    );
  }
}

export default observer(CustomNumberInput);