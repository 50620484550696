/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
import classnames from 'classnames';
import React from 'react';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import RadioCard from '../../base-components/RadioCard';
import leftArrow from '../../icons/left-arrow.svg';
import rightArrow from '../../icons/right-arrow.svg';
import downCaret from '../../icons/down-caret.svg';
import { CSSTransitionGroup } from 'react-transition-group'
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { get, last, map } from 'lodash';
import rayLogo from '../../coins/ray.png'
import infoIcon from '../../icons/info.svg';
import { getFarmBySymbol } from '../../utils/farms';
import { getStore } from '../../stores/get-store';
import { TransactionService } from '../../services/TransactionService';
import IconWithTooltip from '../../base-components/IconWithTooltip';
import { getFormattedNumber, TokenAmount } from '../../utils/safe-math';
import { getLendingReserveByAccount } from '../../utils/config';
import { getReserveByName } from '../../utils/lendingReserves';
import { WAD } from '../../utils/layouts';
import { Loader } from '../../base-components/Loader';
import LowSolWarningAlert from '../../components/LowSolWarningAlert/LowSolWarningAlert';
import { CLOSE_POSITION_MIN } from '../../constants/minSolBalance';


// Constants
const CustomSlider = withStyles({
  root: {
    // color: '#52af77',
    height: 4,
    // margin: '8px 4px'
    marginLeft: 4
  },
  thumb: {
    height: 16,
    width: 16,
    // backgroundColor: '#fff',
    background: 'linear-gradient(315deg, #C61FB5 -6.25%, #2CABC7 100%)',
    // border: '2px solid currentColor',
    marginTop: -6,
    marginLeft: -8,
    '&:focus, &$active': {
      boxShadow: 'inherit',
    },
    '&:hover, &$active': {
      boxShadow: '0 0 0 4px rgba(255, 255, 255, 0.2)',
      borderRadius: '50%'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50%)',
  },
  track: {
    height: 4,
    borderRadius: 4,
    backgroundColor: '#1C88B4'
  },
  rail: {
    height: 4,
    borderRadius: 4,
    backgroundColor: '#146C98'
  },
})(Slider);

const META = 'meta',
  ACTIONS = 'actions',
  VIEWS = [META, ACTIONS],
  initialState = {
    activeView: VIEWS[0],
    coinAValue: 0,
    coinBValue: 0,
    selectedCoinIndex: 1,
    selectedCloseOption: 'minimize-trading'
  },
  MAX_LEVERAGE_VALUE = 2,
  MIN_LEVERAGE_VALUE = 1,
  LEVERAGE_STEP = 0.1,
  transactionToast = (tx) => (
    <div style={{ fontSize: '12px'}}>
      <div style={{ marginBottom: '8px', fontWeight: '600' }}>Transaction has been sent</div>
      <div>Confirmation is in progress. Check your transaction on <a className='link' href={`https://explorer.solana.com/tx/${tx}`} target='_blank'>here</a>.</div>
    </div>
  );

const RADIO_OPT_TO_INDEX_MAP = {
  'minimize-trading': 0,
  'convert': 1
};

// const TRADE_SLIP = 1.015;
const TRADE_SLIP = 1;

const SET_TOKEN_ACC_DELAY_ON_FAILURE = 2 * 1000;

export default class ClosePositionModal extends React.Component {
  constructor () {
    super();

    this.state = {
      ...initialState
    };

    this.handleViewChange = this.handleViewChange.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleLeverageChange = this.handleLeverageChange.bind(this);
    this.toggleLevSelectorDropdown = this.toggleLevSelectorDropdown.bind(this);
    this.handleCoinAChange = this.handleCoinAChange.bind(this);
    this.handleCoinBChange = this.handleCoinBChange.bind(this);
    this.handleCoinSelect = this.handleCoinSelect.bind(this);
    this.handleCreateUserFarmManager = this.handleCreateUserFarmManager.bind(this);
    this.handleDepositObligationCollateral = this.handleDepositObligationCollateral.bind(this);
    this.handleBorrowObligationLiquidity = this.handleBorrowObligationLiquidity.bind(this);
    this.handleSwapLpToken = this.handleSwapLpToken.bind(this);
    this.handleCreateLpToken = this.handleCreateLpToken.bind(this);
    this.handleDepositIntoVault = this.handleDepositIntoVault.bind(this);
    this.handleClosePosition = this.handleClosePosition.bind(this);
    this.handleRadioSelect = this.handleRadioSelect.bind(this);
  }

  handleViewChange (view) {
    this.setState({ activeView: view });
  }

  handleCoinSelect (selectedCoinIndex) {
    this.setState({ selectedCoinIndex });
  }

  handleToggle (e) {
    // Reset state
    this.setState(initialState);

    // @todo: Add logic to prevent toggle when modal is in dirty state
    this.props.onToggle();
  }

  handleCoinAChange (e) {
    const {
        activeLeverageVault
      } = this.props,
      farm = getFarmBySymbol(activeLeverageVault.assetSymbol),
      { wallet, tokenAccounts } = getStore('WalletStore'),
      coinABalance = wallet && tokenAccounts[get(farm, ['coins', 0, 'mintAddress'])]?.balance,
      value = e.target.value;

    let coinAValue;

    if (value > Number(coinABalance?.fixed())) {
      coinAValue = Number(coinABalance?.fixed());
    } else if (value < 0) {
      coinAValue = 0;
    } else {
      coinAValue = value;
    }

    this.setState({ coinAValue });
  }

  handleCoinBChange (e) {
    const {
        activeLeverageVault
      } = this.props,
      farm = getFarmBySymbol(activeLeverageVault.assetSymbol),
      { wallet, tokenAccounts } = getStore('WalletStore'),
      coinBBalance = wallet && tokenAccounts[get(farm, ['coins', 1, 'mintAddress'])]?.balance,
      value = e.target.value;

    let coinBValue;

    if (value > Number(coinBBalance?.fixed())) {
      coinBValue = Number(coinBBalance?.fixed());
    } else if (value < 0) {
      coinBValue = 0;
    } else {
      coinBValue = value;
    }

    this.setState({ coinBValue });
  }

  async handleClosePosition () {
    const { activeLeverageVault } = this.props,
      obligationIdx = get(activeLeverageVault, 'obligation.obligationIdx'),
      borrows = get(activeLeverageVault, 'obligation.borrows'),
      { selectedCloseOption } = this.state,
      selectedOption = RADIO_OPT_TO_INDEX_MAP[selectedCloseOption];

    this.setState({ isClosingPosition: true });
    try {
      const transactions = await TransactionService.closeMarginPosition(
        activeLeverageVault.assetSymbol,
        obligationIdx,
        borrows,
        selectedOption
      );

      const tx = await last(transactions);

      this.props.toast(transactionToast(tx));

      const signatureStatus = await window.$web3.getSignatureStatus(tx, {searchTransactionHistory: true});

      if (signatureStatus.value?.err) {
        throw new Error('Failed to close position. Please retry.');
      }

      this.setState({ isClosingPosition: false });
      this.props.toast('Position closed successfully.');

      // Close modal on success
      this.handleToggle();

      // Refresh obligations
      setTimeout(getStore('WalletStore').setTokenAccounts, 9000);

      // this.props.history.push(APP_ROUTES.YOUR_POSITIONS.route);
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      // Refresh the token accounts in case of failure
      getStore('UIStore').setIsRefreshing(true);

      setTimeout(async () => {
        await getStore('WalletStore').setTokenAccounts();
        getStore('UIStore').resetRefreshState();
      }, SET_TOKEN_ACC_DELAY_ON_FAILURE);

      this.setState({ isClosingPosition: false });
      this.props.toast(errorToast, 'error');
      err && console.error('handleClosePosition~err:', err);
    }
  }

  async handleCreateUserFarmManager () {
    const { activeLeverageVault } = this.props;

    try {
      const tx = await TransactionService.createUserFarmManager(activeLeverageVault.assetSymbol);

      this.props.toast(transactionToast(tx));
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleCreateUserFarmManager~err:', err);
    }
  }

  async handleDepositObligationCollateral () {
    const { activeLeverageVault } = this.props,
      { coinAValue, coinBValue } = this.state;

    try {
      const tx = await TransactionService.depositObligationCollateral(activeLeverageVault.assetSymbol, coinAValue, coinBValue);

      this.props.toast(transactionToast(tx));
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleDepositObligationCollateral~err:', err);
    }
  }
  
  async handleBorrowObligationLiquidity () {
    const { activeLeverageVault } = this.props,
      { coinAValue, coinBValue, selectedCoinIndex, leverageValue } = this.state,
      farm = getFarmBySymbol(activeLeverageVault?.assetSymbol),
      reserveName = farm?.coins[selectedCoinIndex]?.symbol;

    try {
      const tx = await TransactionService.borrowObligationLiquidity(
        activeLeverageVault.assetSymbol,
        reserveName,
        coinAValue,
        coinBValue,
        leverageValue
      );

      this.props.toast(transactionToast(tx));
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleBorrowObligationLiquidity~err:', err);
    }
  }

  async handleSwapLpToken () {
    const { activeLeverageVault } = this.props,
      { selectedCoinIndex } = this.state,
      farm = getFarmBySymbol(activeLeverageVault?.assetSymbol),
      reserveName = farm?.coins[selectedCoinIndex]?.symbol;

    try {
      const tx = await TransactionService.swapTokens(activeLeverageVault.assetSymbol, reserveName);

      this.props.toast(transactionToast(tx));
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleSwapLpToken~err:', err);
    }
  }

  async handleCreateLpToken () {
    const { activeLeverageVault } = this.props,
      { selectedCoinIndex } = this.state,
      farm = getFarmBySymbol(activeLeverageVault?.assetSymbol),
      reserveName = farm?.coins[selectedCoinIndex]?.symbol;

    try {
      const tx = await TransactionService.addLiquidity(activeLeverageVault.assetSymbol, reserveName);

      this.props.toast(transactionToast(tx));
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleCreateLpToken~err:', err);
    }
  }

  async handleDepositIntoVault () {
    const { activeLeverageVault } = this.props,
      { selectedCoinIndex } = this.state,
      farm = getFarmBySymbol(activeLeverageVault?.assetSymbol),
      reserveName = farm?.coins[selectedCoinIndex]?.symbol;

    try {
      const tx = await TransactionService.depositMarginLpTokens(activeLeverageVault.assetSymbol, reserveName);

      this.props.toast(transactionToast(tx));
    } catch (err) {
      const errorToast = err?.message || 'Something went wrong.';

      this.props.toast(errorToast, 'error');
      err && console.error('handleDepositIntoVault~err:', err);
    }
  }

  getHeader () {
    const { activeView } = this.state;

    switch (activeView) {
      case META: {
        return null;
      }

      case ACTIONS: {
        // return (
        //   <ModalHeader>
        //     Actions
        //   </ModalHeader>
        // );

        return null;
      }
    }
  }

  handleLeverageChange (e, value) {
    // @todo: Need to sync this with table state
    let leverageValue = value || e?.target?.value;

    // Sanitize leverage value
    if (Number(leverageValue) < MIN_LEVERAGE_VALUE) {
      leverageValue = MIN_LEVERAGE_VALUE;
    }
    else if (Number(leverageValue) > MAX_LEVERAGE_VALUE) {
      leverageValue = MAX_LEVERAGE_VALUE;
    }

    this.setState({
      leverageValue
    });
  }


  toggleLevSelectorDropdown () {
    this.setState((prevState) => ({
      isLevSelectorDropdownOpen: !prevState.isLevSelectorDropdownOpen
    }));
  }

  handleRadioSelect (selectedCloseOption) {
    this.setState({ selectedCloseOption });
  }

  getBody () {
    const {
      selectedCloseOption
    } = this.state,
      {
        activeLeverageVault,
        initialLeverageValue,
        isOpen
      } = this.props,
      farm = getFarmBySymbol(activeLeverageVault.assetSymbol),
      { wallet, tokenAccounts } = getStore('WalletStore'),
      coinABalance = wallet && tokenAccounts[get(farm, ['coins', 0, 'mintAddress'])]?.balance,
      coinBBalance = wallet && tokenAccounts[get(farm, ['coins', 1, 'mintAddress'])]?.balance;

    if (!isOpen) {
      return null;
    }

    const {
      mintAddress,
      obligation,
      decimals
    } = activeLeverageVault || {};

    const {
      depositsMarketValue,
      borrowedValue,
      lpTokens,
      coinDeposits,
      pcDeposits,
      vaultShares,
      borrows,
      tulipEarned,
      lastDepositedAmount
    } = obligation || {};

    const {
      totalVaultBalance,
      totalVlpShares,
      totalLpTokens,
      price: farmPrice,
      coinInLp,
      pcInLp
    } = getStore('FarmStore').getFarm(mintAddress) || {};

    const coin = farm?.coins[0];
    const pc = farm?.coins[1];

    const coinPrice = Number(getStore('PriceStore').getTokenPrice(coin?.symbol));
    const pcPrice = Number(getStore('PriceStore').getTokenPrice(pc?.symbol));

    let debtValue = 0;
    let borrowedReserve = {
      name: pc.symbol,
    };
    let borrowedAmountInLp = 0;
    let cumulativeBorrowRateWads;

    const reserveInfo = getLendingReserveByAccount(borrows[0]?.borrowReserve?.toBase58());
    const reserve = getReserveByName(reserveInfo?.name);
    const { getReserve } = getStore('ReserveStore');
    const { cumulativeBorrowRate, newCumulativeBorrowRate } = getReserve(reserve?.mintAddress) || {};

    borrows.forEach((borrow) => {
      const reserveInfo = getLendingReserveByAccount(borrow.borrowReserve.toBase58());
      const reservePrice = Number(getStore('PriceStore').getTokenPrice(reserveInfo.name));
      const reserve = getReserveByName(reserveInfo.name);
      // const borrowedAmount = (borrow.borrowedAmountWads.div(WAD)).toNumber() / Math.pow(10, reserve.decimals);
      const borrowedAmount = new TokenAmount(borrow.borrowedAmountWads.div(WAD), reserve.decimals);
      const oldBorrowRate = new TokenAmount(borrow.cumulativeBorrowRateWads);
      const newBorrowRate = new TokenAmount(newCumulativeBorrowRate);
      let borrowDebtValue = borrowedAmount.wei.times(newBorrowRate.wei).div(oldBorrowRate.wei).div(Math.pow(10, reserve.decimals));
      debtValue += borrowDebtValue.times(reservePrice).toNumber();

      // Store which reserve was borrowed and its amount
      if (borrowedAmount) {
        borrowedReserve = reserve;
        borrowedAmountInLp = borrowDebtValue.toNumber();
        cumulativeBorrowRateWads = borrow.cumulativeBorrowRateWads;
      }
    });

    const lpTokensDecimals = Math.pow(10, decimals);
    const vaultShareTokens = (((vaultShares).mul(totalVaultBalance)).div(totalVlpShares)).toNumber() / lpTokensDecimals;

    const lpTokensValue = (lpTokens).toNumber() / lpTokensDecimals;
    const coinDepositsValue = (coinDeposits).toNumber() / Math.pow(10, coin.decimals);
    const pcDepositsValue = (pcDeposits).toNumber() / Math.pow(10, pc.decimals);
    const positionValue = (coinDepositsValue*coinPrice) + (pcDepositsValue*pcPrice) + ((vaultShareTokens + lpTokensValue) *Number(farmPrice));


    const totalCoin = (vaultShareTokens * coinInLp) + coinDepositsValue;
    const totalPc = (vaultShareTokens * pcInLp) + pcDepositsValue;

    let amountToTrade,
      reserveToTrade,
      coinToBeReceived,
      pcToBeReceived;

    if (selectedCloseOption === 'convert') {
      if (borrowedReserve.name === coin.symbol) {
        amountToTrade = totalPc;
        reserveToTrade = pc.symbol;


        coinToBeReceived = (((totalPc * pcPrice) / coinPrice) + totalCoin ) - borrowedAmountInLp;
        pcToBeReceived = 0;
      } else {
        amountToTrade = totalCoin;
        reserveToTrade = coin.symbol;


        coinToBeReceived = 0;
        pcToBeReceived = (((totalCoin * coinPrice) / pcPrice) + totalPc) - borrowedAmountInLp;
      }
    }
    else if (borrowedReserve.name === coin.symbol) {
      const reserveDiff = (
        (totalCoin > borrowedAmountInLp) ?
          0 : (borrowedAmountInLp - totalCoin)
      );
      const reserveDiffWithSlip = reserveDiff * TRADE_SLIP;

      amountToTrade = (reserveDiffWithSlip * coinPrice) / pcPrice;
      reserveToTrade = pc.symbol;

      coinToBeReceived = (
        (totalCoin > borrowedAmountInLp) ?
          (totalCoin - borrowedAmountInLp) : (reserveDiffWithSlip - reserveDiff)
      );
      pcToBeReceived = totalPc - amountToTrade;
    } else {
      const reserveDiff = (
        (totalPc > borrowedAmountInLp) ?
          0 : (borrowedAmountInLp - totalPc)
      );
      const reserveDiffWithSlip = reserveDiff * TRADE_SLIP;

      amountToTrade = (reserveDiffWithSlip * pcPrice) / coinPrice;
      reserveToTrade = coin.symbol;

      coinToBeReceived = totalCoin - amountToTrade;
      pcToBeReceived = (
        (totalPc > borrowedAmountInLp) ?
          (totalPc - borrowedAmountInLp) : (reserveDiffWithSlip - reserveDiff)
      );
    }

    let minimizeText = `We will convert the minimum required amount of tokens into ${borrowedReserve.name} to pay back
            the debt and return the remaining assets to you.
            This can potentially save on slippage and trading fees.`;

    let convertText = `Your position value will all be converted to ${borrowedReserve.name} and returned to
            you after paying back the debt.`

    if (borrowedAmountInLp === 0) {
      minimizeText = `We will return the LP underlying assets to you.`;
      convertText = `Your position value will all be converted to ${borrowedReserve.name} and returned to you.`
    }
    const RADIO_OPTS = {
      name: 'position-opts',
      items: [
        {
          value: 'minimize-trading',
          title: 'Minimize trading',
          content: minimizeText,
        },
        {
          value: 'convert',
          title: `Convert to ${borrowedReserve.name}`,
          content: convertText,
        }
      ]
    }

    // Added log to get user's details
    // console.log({
    //   vaultShareTokens,
    //   coinDepositsValue,
    //   pcDepositsValue,
    //   'vaultShareTokens * coinInLp': vaultShareTokens * coinInLp,
    //   'vaultShareTokens * pcInLp': vaultShareTokens * pcInLp
    // });

    return (
      <ModalBody className='close-position-modal'>
        <div className='close-position-modal__heading'>
          Close Position
        </div>
        <div>
          <RadioCard
            {...RADIO_OPTS}
            onClick={this.handleRadioSelect}
            selected={selectedCloseOption}
          />
        </div>
        <div className='close-position-modal-meta__lev__yield'>
          <div className='yield-line'>
            <div className='yield-line-name'>
              Position value assets
            </div>
            <div className='yield-line-value'>
                {getFormattedNumber(totalCoin)}{' '}{coin.symbol}{' + '}{getFormattedNumber(totalPc)}{' '}{pc.symbol}
            </div>
          </div>
          <div className='yield-line'>
            <div className='yield-line-name'>
              Amount to trade
            </div>
            <div className='yield-line-value'>
              {getFormattedNumber(amountToTrade)}{' '}{reserveToTrade}
            </div>
          </div>
          {/* <div className='yield-line'>
            <div className='yield-line-name'>
              Price impact and trading fees
              <IconWithTooltip
                className='yield-line-name__tooltip'
                icon={infoIcon}
                tooltipText='TBD'
              />
            </div>
            <div className='yield-line-value'>
              {
                '15.67 USDC'
              }
            </div>
          </div> */}
          {/* <div className='yield-line'>
            <div className='yield-line-name'>
              Percent impact based on equity value
            </div>
            <div className='yield-line-value'>
              {
                '15.67 USDC'
              }
            </div>
          </div> */}
          <div className='yield-line'>
            <div className='yield-line-name'>
              Debt value
            </div>
            <div className='yield-line-value'>
              {getFormattedNumber(borrowedAmountInLp)}{' '}{borrowedReserve.name}
            </div>
          </div>
          <div className='yield-line'>
            <div className='yield-line-name yield-line-name--bold'>
              You will receive (approx)
            </div>
            <div className='yield-line-value'>
              {
                `${getFormattedNumber(coinToBeReceived)} ${coin.symbol} + ${getFormattedNumber(pcToBeReceived)} ${pc.symbol}`
              }
            </div>
          </div>
          {/* <div className='yield-line'>
            <div className='yield-line-name'>
              Minimum received
              <IconWithTooltip
                className='yield-line-name__tooltip'
                icon={infoIcon}
                tooltipText='TBD'
              />
            </div>
            <div className='yield-line-value'>
              {
                '15.67 USDC'
              }
            </div>
          </div> */}
          <LowSolWarningAlert
            className='shift-up-16'
            minBalanceRequired={CLOSE_POSITION_MIN}
          />
        </div>
      </ModalBody>
    );
  }

  getFooter () {
    const { activeView, isClosingPosition } = this.state;
    const { isRefreshing, isSilentRefresh } = getStore('UIStore');

    switch (activeView) {
      case META: {
        return (
          <ModalFooter>
            <Button color='secondary' onClick={this.handleToggle}>Cancel</Button>
            <Button
              color='primary'
              onClick={this.handleClosePosition}
              disabled={isRefreshing || isSilentRefresh}
            >
              {
                isClosingPosition ? <Loader /> : 'Close Position'
              }
            </Button>
          </ModalFooter>
        );
      }

      case ACTIONS: {
        return (
          <ModalFooter>
            <Button color='primary' onClick={this.handleToggle}>Finish</Button>
          </ModalFooter>
        )
      }
    }
  }

  render () {
    const {
      isOpen
    } = this.props;


    return (
      <Modal
        isOpen={isOpen}
        toggle={this.handleToggle}
        className={
          classnames(
            'app-body__modal',
            'close-position-modal'
          )
        }
        centered
      >
        {this.getHeader()}
        {this.getBody()}
        {this.getFooter()}
      </Modal>
    );
  }
}