import React from 'react';
import { observer } from 'mobx-react';
import * as anchor from '@project-serum/anchor';
import * as serum from '@project-serum/serum';
import {PublicKey, SystemProgram, SYSVAR_CLOCK_PUBKEY, Transaction, TransactionInstruction} from '@solana/web3.js';
import * as BufferLayout from "buffer-layout";
import * as Layout from '../../utils/layout-from-oyster';
import { getStore } from '../../stores/get-store';
import LendTable from './LendTable';
import { getFarmBySymbol } from '../../utils/farms';
import {Token} from "@solana/spl-token";
import {
  getFarmPoolAuthority,
  getFarmPoolId,
  getFarmProgramId,
  getVaultAccount,
  getVaultInfoAccount,
  getVaultLpTokenAccount,
  getVaultPdaAccount,
  getFarmPoolLpTokenAccount,
  getVaultProgramId,
  getFarmLpMintAddress,
  getVaultRewardAccountA,
  getVaultRewardAccountB,
  getFarmPoolRewardATokenAccount,
  getFarmPoolRewardBTokenAccount,
  getFarmFusion,
  getVaultTulipTokenAccount,
  getLendingMarketAccount,
  getPriceFeedsForReserve
} from '../../utils/config';

import { getMultipleAccounts, sendTransaction } from '../../utils/web3';
import * as serumAssoToken from '@project-serum/associated-token';
import { TOKENS } from '../../utils/tokens';
import { WarningBanner } from '../EmptyStates/WarningBanner';
import LendFilters from './LendFilters';
import { getReserveByName } from '../../utils/lendingReserves';
import { LENDING_PROGRAM_ID, TOKEN_PROGRAM_ID } from '../../utils/ids';
import BN from 'bn.js';
import Infobar from '../../base-components/Infobar';
import { UTILIZATION_THRESHOLD } from '../../constants/leverageFarmingConstants';
import { PageHeading } from '../PageHeading';

import {ACCOUNT_LAYOUT} from "../../utils/layouts";

const idlJson = require('../../idl/vault.json');

const ZERO = 0;
export const commitment = 'confirmed';

// const LendingInstruction = {
//   InitLendingMarket: 0,
//   InitReserve: 1,
//   InitObligation: 2,
//   DepositReserveLiquidity: 3,
//   WithdrawReserveLiquidity: 4,
//   BorrowLiquidity: 5,
//   RepayObligationLiquidity: 6,
//   LiquidateObligation: 7,
//   AccrueReserveInterest: 8,
// };

export const LendingInstruction = {
  InitLendingMarket: 0,
  SetLendingMarketOwner: 1,
  InitReserve: 2,
  RefreshReserve: 3,
  DepositReserveLiquidity: 4,
  RedeemReserveCollateral: 5,
  InitObligation: 6,
  RefreshObligation: 7,
  DepositObligationCollateral: 8,
  WithdrawObligationCollateral: 9,
  BorrowObligationLiquidity: 10,
  RepayObligationLiquidity: 11,
  LiquidateObligation: 12,
  FlashLoan: 13,
  UpdatePseudoDeposits: 14
};

const depositInstruction = ({
  liquidityAmount,
  from,
  to,
  reserveAccount,
  reserveSupply,
  collateralMint,
  lendingMarket,
  reserveAuthority,
  transferAuthority,
}) => {
  const dataLayout = BufferLayout.struct([
    BufferLayout.u8("instruction"),
    Layout.uint64("liquidityAmount"),
  ]);

  const data = Buffer.alloc(dataLayout.span);
  dataLayout.encode(
    {
      instruction: LendingInstruction.DepositReserveLiquidity,
      liquidityAmount: new BN(liquidityAmount),
    },
    data
  );

  const keys = [
    { pubkey: from, isSigner: false, isWritable: true },
    { pubkey: to, isSigner: false, isWritable: true },
    { pubkey: reserveAccount, isSigner: false, isWritable: true },
    { pubkey: reserveSupply, isSigner: false, isWritable: true },
    { pubkey: collateralMint, isSigner: false, isWritable: true },
    { pubkey: lendingMarket, isSigner: false, isWritable: false },
    { pubkey: reserveAuthority, isSigner: false, isWritable: false },
    { pubkey: transferAuthority, isSigner: true, isWritable: false },
    { pubkey: SYSVAR_CLOCK_PUBKEY, isSigner: false, isWritable: false },
    { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
  ];
  return new TransactionInstruction({
    keys,
    programId: LENDING_PROGRAM_ID,
    data,
  });
};

const withdrawInstruction = ({
  collateralAmount,
  from,
  to,
  reserveAccount,
  reserveSupply,
  collateralMint,
  lendingMarket,
  reserveAuthority,
  transferAuthority,
}) => {
  const dataLayout = BufferLayout.struct([
    BufferLayout.u8("instruction"),
    Layout.uint64("collateralAmount"),
  ]);

  const data = Buffer.alloc(dataLayout.span);
  dataLayout.encode(
    {
      instruction: LendingInstruction.RedeemReserveCollateral,
      collateralAmount: new BN(collateralAmount),
    },
    data
  );

  const keys = [
    { pubkey: from, isSigner: false, isWritable: true },
    { pubkey: to, isSigner: false, isWritable: true },
    { pubkey: reserveAccount, isSigner: false, isWritable: true },
    { pubkey: collateralMint, isSigner: false, isWritable: true },
    { pubkey: reserveSupply, isSigner: false, isWritable: true },
    { pubkey: lendingMarket, isSigner: false, isWritable: false },
    { pubkey: reserveAuthority, isSigner: false, isWritable: false },
    { pubkey: transferAuthority, isSigner: true, isWritable: false },
    { pubkey: SYSVAR_CLOCK_PUBKEY, isSigner: false, isWritable: false },
    { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
  ];
  return new TransactionInstruction({
    keys,
    programId: LENDING_PROGRAM_ID,
    data,
  });
};

const refreshReserve = ({
  reserveAccount,
  priceAccount
}) => {
  const dataLayout = BufferLayout.struct([BufferLayout.u8("instruction")]);

  const data = Buffer.alloc(dataLayout.span);
  dataLayout.encode(
    {
      instruction: LendingInstruction.RefreshReserve,
    },
    data
  );

  const keys = [
    { pubkey: reserveAccount, isSigner: false, isWritable: true },
    { pubkey: priceAccount, isSigner: false, isWritable: false },
    { pubkey: SYSVAR_CLOCK_PUBKEY, isSigner: false, isWritable: false },
  ];
  return new TransactionInstruction({
    keys,
    programId: LENDING_PROGRAM_ID,
    data,
  });
};

class LendBody extends React.Component {
  constructor () {
    super();

    this.handleDeposit = this.handleDeposit.bind(this);
    this.handleDeposit2 = this.handleDeposit2.bind(this);
    this.handleWithdraw = this.handleWithdraw.bind(this);
    this.handleWithdraw2 = this.handleWithdraw2.bind(this);
    this.checkUnsafeWithdraw = this.checkUnsafeWithdraw.bind(this);
    this.handleTulipHarvest = this.handleTulipHarvest.bind(this);
  }

  async handleWithdraw (assetSymbol, value, withdrawMax = false) {
    const { wallet, tokenAccounts, setTokenAccounts } = getStore('WalletStore'),
      // account for RAY-SOL
      authorityTokenAccount = tokenAccounts[getFarmLpMintAddress(assetSymbol)].tokenAccountAddress,
      walletToInitialize = {
        signTransaction: wallet.signTransaction,
        signAllTransactions: wallet.signAllTransactions,
        publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
      };

    const provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment});
    anchor.setProvider(provider);

    const idl = idlJson;

    // Address of the deployed program.
    const vaultProgramId = new anchor.web3.PublicKey(getVaultProgramId());
    // Generate the program client from IDL.
    const vaultProgram = new anchor.Program(idl, vaultProgramId);

    const { userBalanceAccount } = getStore('FarmStore').getFarm(getFarmLpMintAddress(assetSymbol)) || {};

    let withdrawAmount;

    if (withdrawMax) {
      let vaultBalanceAccount = await vaultProgram.account.vaultBalanceAccount(userBalanceAccount);

      withdrawAmount = vaultBalanceAccount.amount;
    } else {
      let vault = await vaultProgram.account.vault(new anchor.web3.PublicKey(getVaultAccount(assetSymbol))),
        { totalVaultBalance, totalVlpShares } = vault || {},
        { decimals } = getFarmBySymbol(assetSymbol) || {};

      const userInputValue = new anchor.BN(value * Math.pow(10, decimals));

      withdrawAmount = ((userInputValue.mul(totalVlpShares)).div(totalVaultBalance));
    }

    const [
      userBalanceMetadataAccount,
      userBalanceMetadataAccountNonce
    ] = await anchor.web3.PublicKey.findProgramAddress(
        [
          userBalanceAccount.toBuffer(),
          provider.wallet.publicKey.toBytes()
        ],
        vaultProgramId
    );

    const [
      tulipRewardMetadataAccount,
      tulipRewardMetadataNonce
    ] = await anchor.web3.PublicKey.findProgramAddress(
        [
          userBalanceMetadataAccount.toBytes(),
          provider.wallet.publicKey.toBytes()
        ],
        vaultProgramId,
    );

    this.props.toast('Withdrawing...');

    const withdrawAccounts = {
      vault: new anchor.web3.PublicKey(getVaultAccount(assetSymbol)),
      lpTokenAccount: new anchor.web3.PublicKey(
        getVaultLpTokenAccount(assetSymbol)
      ),
      authorityTokenAccount: new anchor.web3.PublicKey(
        authorityTokenAccount,
      ),
      authority: provider.wallet.publicKey,
      stakeProgramId: new anchor.web3.PublicKey(getFarmProgramId(assetSymbol)),
      vaultPdaAccount: new anchor.web3.PublicKey(
        getVaultPdaAccount(assetSymbol)
      ),
      poolId: new anchor.web3.PublicKey(getFarmPoolId(assetSymbol)),
      poolAuthority: new anchor.web3.PublicKey(getFarmPoolAuthority(assetSymbol)),
      userInfoAccount: new anchor.web3.PublicKey(
        getVaultInfoAccount(assetSymbol)
      ),
      userLpTokenAccount: new anchor.web3.PublicKey(
        getVaultLpTokenAccount(assetSymbol)
      ),
      poolLpTokenAccount: new anchor.web3.PublicKey(
        getFarmPoolLpTokenAccount(assetSymbol)
      ),
      userRewardATokenAccount: new anchor.web3.PublicKey(
        getVaultRewardAccountA(assetSymbol)
      ),
      poolRewardATokenAccount: new anchor.web3.PublicKey(
        getFarmPoolRewardATokenAccount(assetSymbol)
      ),
      userRewardBTokenAccount: new anchor.web3.PublicKey(
        getVaultRewardAccountA(assetSymbol)
      ),
      poolRewardBTokenAccount: new anchor.web3.PublicKey(
        getFarmPoolRewardBTokenAccount(assetSymbol)
      ),
      userBalanceAccount: userBalanceAccount,
      userBalanceMeta: userBalanceMetadataAccount,
      userTulipRewardMetadata: tulipRewardMetadataAccount,
      clock: SYSVAR_CLOCK_PUBKEY,
      tokenProgramId: serum.TokenInstructions.TOKEN_PROGRAM_ID,
      systemProgram: new anchor.web3.PublicKey(
        "11111111111111111111111111111111"
      ),
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
    };

    if (getFarmFusion(assetSymbol)) {
      withdrawAccounts.userRewardBTokenAccount = new anchor.web3.PublicKey(
        getVaultRewardAccountB(assetSymbol)
      );
    }

    const txn = await vaultProgram.transaction.withdrawVault(
        {
          amount: withdrawAmount,
          metaNonce: userBalanceMetadataAccountNonce,
          rewardNonce: tulipRewardMetadataNonce
        },
        {
          accounts: withdrawAccounts,
        }
    );

    const tx = await sendTransaction(window.$web3, wallet, txn, []);

    // Refresh token balances
    setTimeout(() => {
      setTokenAccounts();
      getStore('UIStore').resetRefreshState();
    }, 9000);

    return tx;
  }

  async handleDeposit (assetSymbol, value, claim = false, toastMessage = 'Depositing...') {
    const { wallet, tokenAccounts, setTokenAccounts } = getStore('WalletStore'),
      authorityTokenAccount = tokenAccounts[getFarmLpMintAddress(assetSymbol)]?.tokenAccountAddress,
      walletToInitialize = {
        signTransaction: wallet.signTransaction,
        signAllTransactions: wallet.signAllTransactions,
        publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
      },
      provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment }),
      tulipPubKey = new anchor.web3.PublicKey(TOKENS.TULIP.mintAddress);

    anchor.setProvider(provider);

    const idl = idlJson;

    // Address of the deployed program.
    const vaultProgramId = new anchor.web3.PublicKey(getVaultProgramId());
    // Generate the program client from IDL.
    const vaultProgram = new anchor.Program(idl, vaultProgramId);

    const [
      userBalanceAccount,
      userBalanceAccountNonce,
    ] = await anchor.web3.PublicKey.findProgramAddress(
      [
        new anchor.web3.PublicKey(getVaultInfoAccount(assetSymbol)).toBytes(),
        provider.wallet.publicKey.toBytes(),
      ],
      vaultProgramId
    );

    const [
      userBalanceMetadataAccount,
      userBalanceMetadataAccountNonce
    ] = await anchor.web3.PublicKey.findProgramAddress(
        [
          userBalanceAccount.toBuffer(),
          provider.wallet.publicKey.toBytes()
        ],
        vaultProgramId
    );

    const [
      tulipRewardMetadataAccount,
      tulipRewardMetadataNonce
    ] = await anchor.web3.PublicKey.findProgramAddress(
        [
          userBalanceMetadataAccount.toBytes(),
          provider.wallet.publicKey.toBytes()
        ],
        vaultProgramId,
    );

    const tulipRewardTokenAccount = await serumAssoToken.getAssociatedTokenAddress(
        wallet.publicKey,
        tulipPubKey
      ),
      tulipRewardTokenAccountInfo = await window.$web3.getAccountInfo(tulipRewardTokenAccount);

    this.props.toast(toastMessage);

    const depositAccounts = {
      vault: new anchor.web3.PublicKey(getVaultAccount(assetSymbol)),
      lpTokenAccount: new anchor.web3.PublicKey(
        getVaultLpTokenAccount(assetSymbol)
      ),
      authorityTokenAccount: new anchor.web3.PublicKey(
        authorityTokenAccount,
      ),
      authority: provider.wallet.publicKey,
      stakeProgramId: new anchor.web3.PublicKey(getFarmProgramId(assetSymbol)),
      vaultPdaAccount: new anchor.web3.PublicKey(
        getVaultPdaAccount(assetSymbol)
      ),
      poolId: new anchor.web3.PublicKey(getFarmPoolId(assetSymbol)),
      poolAuthority: new anchor.web3.PublicKey(getFarmPoolAuthority(assetSymbol)),
      userInfoAccount: new anchor.web3.PublicKey(
        getVaultInfoAccount(assetSymbol)
      ),
      userLpTokenAccount: new anchor.web3.PublicKey(
        getVaultLpTokenAccount(assetSymbol)
      ),
      poolLpTokenAccount: new anchor.web3.PublicKey(
        getFarmPoolLpTokenAccount(assetSymbol)
      ),
      userRewardATokenAccount: new anchor.web3.PublicKey(
        getVaultRewardAccountA(assetSymbol)
      ),
      poolRewardATokenAccount: new anchor.web3.PublicKey(
        getFarmPoolRewardATokenAccount(assetSymbol)
      ),
      userRewardBTokenAccount: new anchor.web3.PublicKey(
        getVaultRewardAccountA(assetSymbol)
      ),
      poolRewardBTokenAccount: new anchor.web3.PublicKey(
        getFarmPoolRewardBTokenAccount(assetSymbol)
      ),
      userBalanceAccount: userBalanceAccount,
      userBalanceMetadata: userBalanceMetadataAccount,
      userTulipRewardMetadata: tulipRewardMetadataAccount,
      vaultTulipTokenAccount: new anchor.web3.PublicKey(
        getVaultTulipTokenAccount(assetSymbol)
      ),
      userTulipTokenAccount: tulipRewardTokenAccount,
      clock: SYSVAR_CLOCK_PUBKEY,
      tokenProgramId: serum.TokenInstructions.TOKEN_PROGRAM_ID,
      systemProgram: new anchor.web3.PublicKey(
        "11111111111111111111111111111111"
      ),
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
    };

    if (getFarmFusion(assetSymbol)) {
      depositAccounts.userRewardBTokenAccount = new anchor.web3.PublicKey(
        getVaultRewardAccountB(assetSymbol)
      );
    }


    const { decimals } = getFarmBySymbol(assetSymbol) || {};
    const txn = new anchor.web3.Transaction();

    if (!tulipRewardTokenAccountInfo) {
      // add instruction to the deposit transaction
      // to also create $TULIP (rewards) token account
      txn.add(
        await serumAssoToken.createAssociatedTokenAccount(
          // who will pay for the account creation
          wallet.publicKey,

          // who is the account getting created for
          wallet.publicKey,

          // what mint address token is being created
          tulipPubKey
        )
      );
    }

    txn.add(
      vaultProgram.instruction.depositVault(
        {
          nonce: userBalanceAccountNonce,
          amount: new anchor.BN(Number(value) * Math.pow(10, Number(decimals))),
          metaNonce: userBalanceMetadataAccountNonce,
          rewardNonce: tulipRewardMetadataNonce,
          claim
        },
        {
          accounts: depositAccounts,
        }
      )
    );

    const tx = await sendTransaction(window.$web3, wallet, txn, []);

    // Refresh token balances
    setTimeout(() => {
      setTokenAccounts();
      getStore('UIStore').resetRefreshState();
    }, 9000);

    return tx;
  }

  async checkUnsafeWithdraw (assetSymbol) {
    const { wallet } = getStore('WalletStore'),
      walletToInitialize = {
        signTransaction: wallet.signTransaction,
        signAllTransactions: wallet.signAllTransactions,
        publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
      },
      provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment });

    anchor.setProvider(provider);

    const { userBalanceAccount } = getStore('FarmStore').getFarm(getFarmLpMintAddress(assetSymbol)) || {},
      vaultProgramId = new anchor.web3.PublicKey(getVaultProgramId()),
      vaultProgram = new anchor.Program(idlJson, vaultProgramId),
      [ userBalanceMetadataAccount ] = await anchor.web3.PublicKey.findProgramAddress(
        [
          userBalanceAccount.toBuffer(),
          provider.wallet.publicKey.toBytes()
        ],
        vaultProgramId
      ),
      vaultAccount = new anchor.web3.PublicKey(getVaultAccount(assetSymbol)),
      accountsToFetch = [userBalanceMetadataAccount, vaultAccount],
      [
        userBalanceMetadataAccountInfo,
        vaultAccountInfo
      ] = await getMultipleAccounts(window.$web3, accountsToFetch, commitment),
      decodedUserBalanceMetadataAccountInfo = vaultProgram.coder.accounts.decode(
        'VaultBalanceMetadata',
        userBalanceMetadataAccountInfo.account.data
      ),
      decodedVaultAccountInfo = vaultProgram.coder.accounts.decode('Vault', vaultAccountInfo.account.data),
      lastDepositTime = decodedUserBalanceMetadataAccountInfo.lastDepositTime.toNumber(),
      lastCompoundTime = decodedVaultAccountInfo.lastCompoundTime.toNumber(),
      timeDifference = (lastCompoundTime - lastDepositTime) / (60*60); // in hours

    return (timeDifference < 2);
  }

  async handleFixTokenAccount (assetSymbol) {
    const { wallet } = getStore('WalletStore'),
      walletToInitialize = {
        signTransaction: wallet.signTransaction,
        signAllTransactions: wallet.signAllTransactions,
        publicKey: new anchor.web3.PublicKey(wallet.publicKey.toBase58())
      },
      provider = new anchor.Provider(window.$web3, walletToInitialize, { skipPreflight: true, preflightCommitment: commitment });

    anchor.setProvider(provider);

    const mintAddress = getFarmLpMintAddress(assetSymbol),
      txn = new Transaction();

    txn.add(
      await serumAssoToken.createAssociatedTokenAccount(
        // who will pay for the account creation
        wallet.publicKey,

        // who is the account getting created for
        wallet.publicKey,

        // what mint address token is being created
        new anchor.web3.PublicKey(mintAddress)
      )
    );

    const tx = await sendTransaction(window.$web3, wallet, txn, []);

    // Refresh token balances
    setTimeout(() => {
      getStore('WalletStore').setTokenAccounts();
      getStore('UIStore').resetRefreshState();
    }, 9000);

    return tx;
  }

  handleTulipHarvest (assetSymbol) {
    return this.handleDeposit(assetSymbol, 0, true, 'Harvesting...');
  }

  async handleDeposit2 (reserveName, value) {
    const txn = new anchor.web3.Transaction();

    const { wallet, tokenAccounts, setTokenAccounts } = getStore('WalletStore'),
      reserve = getReserveByName(reserveName),
      {
        mintAddress,
        decimals,
        collateralTokenMint,
        account,
        liquiditySupplyTokenAccount
      } = reserve || {};

    const collateralMintAccount = await serumAssoToken.getAssociatedTokenAddress(
        wallet.publicKey,
        new PublicKey(collateralTokenMint)
      ),
      collateralMintAccountInfo = await window.$web3.getAccountInfo(collateralMintAccount);

      // console.log("$$$ lending deposit ", collateralTokenMint, collateralMintAccount, collateralMintAccountInfo);
      let fromAccount = new PublicKey(tokenAccounts[mintAddress]?.tokenAccountAddress);
      let signers = [];
      if (reserveName === "SOL") {
          const lamportsToCreateAccount = await window.$web3.getMinimumBalanceForRentExemption(
              ACCOUNT_LAYOUT.span,
              commitment
          );

          const newAccount = new anchor.web3.Account();
          signers.push(newAccount);

          fromAccount = newAccount.publicKey
          txn.add(
              SystemProgram.createAccount({
                  fromPubkey: wallet.publicKey,
                  newAccountPubkey: fromAccount,
                  lamports: ( value ) * Math.pow(10, decimals) + lamportsToCreateAccount,
                  space: ACCOUNT_LAYOUT.span,
                  programId: TOKEN_PROGRAM_ID,
              })
          );

          txn.add(
              Token.createInitAccountInstruction(
                  TOKEN_PROGRAM_ID,
                  new PublicKey(TOKENS.WSOL.mintAddress),
                  fromAccount,
                  wallet.publicKey
              )
          );
      }


      if (!collateralMintAccountInfo) {
      txn.add(
        await serumAssoToken.createAssociatedTokenAccount(
          // who will pay for the account creation
          wallet.publicKey,

          // who is the account getting created for
          wallet.publicKey,

          // what mint address token is being created
          new PublicKey(collateralTokenMint)
        )
      );
    }

    const [derivedLendingMarketAuthority, nonce] = await anchor.web3.PublicKey.findProgramAddress(
      [new anchor.web3.PublicKey(getLendingMarketAccount()).toBytes()],
      LENDING_PROGRAM_ID
    );

    txn.add(
      refreshReserve(
        {
          reserveAccount: new PublicKey(account),
          priceAccount: getPriceFeedsForReserve(reserveName)?.price_account
        }
      )
    );

    txn.add(
      depositInstruction(
        {
          liquidityAmount: value * Math.pow(10, decimals),
          from: fromAccount,
          to: collateralMintAccount,
          reserveAccount: new PublicKey(account),
          reserveSupply: new PublicKey(liquiditySupplyTokenAccount),
          collateralMint: new PublicKey(collateralTokenMint),
          lendingMarket: new PublicKey(getLendingMarketAccount()),
          reserveAuthority: derivedLendingMarketAuthority,
          transferAuthority: wallet.publicKey,
        }
      )
    );

      if (reserveName === "SOL") {
          txn.add(
              Token.createCloseAccountInstruction(
                  TOKEN_PROGRAM_ID,
                  fromAccount,
                  wallet.publicKey,
                  wallet.publicKey,
                  []
              )
          );
      }

      // Refresh token balances
    setTimeout(() => {
      setTokenAccounts();
      getStore('UIStore').resetRefreshState();
    }, 9000);

    return sendTransaction(window.$web3, wallet, txn, signers);
  }

  async handleWithdraw2 (reserveName, value = 1, withdrawMax) {
    const txn = new anchor.web3.Transaction();

    const { wallet, tokenAccounts, setTokenAccounts } = getStore('WalletStore'),
      reserve = getReserveByName(reserveName),
      {
        mintAddress,
        decimals,
        collateralTokenMint,
        account,
        liquiditySupplyTokenAccount
      } = reserve || {};

    const collateralMintAccount = await serumAssoToken.getAssociatedTokenAddress(
        wallet.publicKey,
        new PublicKey(collateralTokenMint)
      );

    const [derivedLendingMarketAuthority, nonce] = await anchor.web3.PublicKey.findProgramAddress(
      [new anchor.web3.PublicKey(getLendingMarketAccount()).toBytes()],
      LENDING_PROGRAM_ID
    );


      let toAccount = new PublicKey(tokenAccounts[mintAddress]?.tokenAccountAddress);
      let signers = [];
      if (reserveName === "SOL") {
          const lamportsToCreateAccount = await window.$web3.getMinimumBalanceForRentExemption(
              ACCOUNT_LAYOUT.span,
              commitment
          );

          const newAccount = new anchor.web3.Account();
          signers.push(newAccount);

          toAccount = newAccount.publicKey
          txn.add(
              SystemProgram.createAccount({
                  fromPubkey: wallet.publicKey,
                  newAccountPubkey: toAccount,
                  lamports: lamportsToCreateAccount,
                  space: ACCOUNT_LAYOUT.span,
                  programId: TOKEN_PROGRAM_ID,
              })
          );

          txn.add(
              Token.createInitAccountInstruction(
                  TOKEN_PROGRAM_ID,
                  new PublicKey(TOKENS.WSOL.mintAddress),
                  toAccount,
                  wallet.publicKey
              )
          );
      }

    txn.add(
      refreshReserve(
        {
          reserveAccount: new PublicKey(account),
          priceAccount: getPriceFeedsForReserve(reserveName)?.price_account
        }
      )
    );

    const { getReserve } = getStore('ReserveStore'),
      {
        totalSupply,
        uiAmount
      } = getReserve(reserve.mintAddress) || {};

    let collateralAmount;

    if (withdrawMax) {
      collateralAmount = Number(tokenAccounts[collateralTokenMint].balance.toWei());
    } else {
      const userInputValue = new anchor.BN(Number(value) * Math.pow(10, decimals));
      const totalSupplyBN = new anchor.BN(totalSupply);
      const uiAmountBN = new anchor.BN(uiAmount);

      collateralAmount = ((userInputValue.mul(uiAmountBN)).div(totalSupplyBN));
    }

    txn.add(
      withdrawInstruction(
        {
          collateralAmount,
          from: collateralMintAccount,
          to: toAccount,
          reserveAccount: new PublicKey(account),
          reserveSupply: new PublicKey(liquiditySupplyTokenAccount),
          collateralMint: new PublicKey(collateralTokenMint),
          lendingMarket: new PublicKey(getLendingMarketAccount()),
          reserveAuthority: derivedLendingMarketAuthority,
          transferAuthority: wallet.publicKey,
        }
      )
    );

    if (reserveName === "SOL") {
      txn.add(
          Token.createCloseAccountInstruction(
              TOKEN_PROGRAM_ID,
              toAccount,
              wallet.publicKey,
              wallet.publicKey,
              []
          )
      );
    }

      // Refresh token balances
    setTimeout(() => {
      setTokenAccounts();
      getStore('UIStore').resetRefreshState();
    }, 9000);

    // return sendTransaction(window.$web3, wallet, txn, []);

    return await sendTransaction(window.$web3, wallet, txn, signers);
  }

  render () {
    const { wallet, tokenAccounts, isTokenAccountInvalid } = getStore('WalletStore'),
      { getReserve, visibleReserves } = getStore('ReserveStore'),
      tableData = visibleReserves.map((reserve) => {
        const {
          availableAmount: available = 0,
          borrowedAmount: totalBorrow = 0,
          utilization = 0,
          lendAPY: apy = 0,
          totalSupply,
          uiAmount
        } = getReserve(reserve.mintAddress) || {};

        const price = Number(getStore('PriceStore').getTokenPrice(reserve.name));

        let balance = 0,
          // price = 20,
          // totalSupply = availableAmount,
          // totalBorrow = 400000,
          deposited = 0;
        //   rewardSinceLastDeposit = ZERO,
        //   tulipEarned = ZERO;

        if (wallet) {
          if (tokenAccounts[reserve.mintAddress]) {
            balance = Number(tokenAccounts[reserve.mintAddress].balance.fixed());
          }

          if (tokenAccounts[reserve.collateralTokenMint]) {
            deposited = (Number(tokenAccounts[reserve.collateralTokenMint].balance.fixed()) * (Number(totalSupply))) / uiAmount;
          }
        }


        // let totalSupply = available + Number(totalBorrow);
        return {
          // assetName: farm.name,
          // assetSymbol: farm.symbol,
          // tvl: getFormattedNumber(tvl),
          // weeklyAPY: getFormattedNumber(weeklyAPY),
          // yearlyAPY: getFormattedNumber(yearlyAPY),
          // dailyAPR: getFormattedNumber(dailyAPR),
          // balance,
          // deposited,
          // logos: farm.logos,
          // decimals: farm.decimals,
          // price,
          // dualYield: Boolean(farm.dualYield),
          // rewardSinceLastDeposit,
          // isUserBalanceAccountValid,
          // liquidityMining: Boolean(farm.liquidityMining),
          // tulipAPR,
          // isTokenAccountMissing: isTokenAccountInvalid(farm.mintAddress, farm.platform),
          // tvlInNumber: tvl,
          // depositedInUsd: deposited * price,
          // balanceInUsd: balance * price,
          // yearlyAPYInNumber: yearlyAPY,
          // tulipEarned,


          // New data for Lending Page
          name: reserve.name,
          apy,
          totalSupply,
          totalBorrow: Number(totalBorrow),
          utilization,
          balance,
          deposited,
          logo: reserve.logo,
          price,
          decimals: reserve.decimals,
          mintAddress: reserve.mintAddress,

          // For rendering USD data & sorting
          totalSupplyInUsd: totalSupply * price,
          totalBorrowInUsd: totalBorrow * price,
          balanceInUsd: balance * price,
          depositedInUsd: deposited * price,
          whitelisted: reserve.whitelisted,
          visible: reserve.visible,
          utilizationInNumber: Number(utilization)
        };
      }),
      { isDesktop } = getStore('ResponsiveStore');

    return (
      <div className='app-body'>
        <Infobar />
        {
          !isDesktop && <PageHeading />
        }
        <WarningBanner
          message={`If utilization for a pool is over ${UTILIZATION_THRESHOLD}%, borrowing will be disabled.`}
        />
        <LendFilters />
        <LendTable
          data={tableData}
          onDeposit={this.handleDeposit2}
          onWithdraw={this.handleWithdraw2}
          toast={this.props.toast}
          checkUnsafeWithdraw={this.checkUnsafeWithdraw}
          onFixTokenAccount={this.handleFixTokenAccount}
          onTulipHarvest={this.handleTulipHarvest}
        />
      </div>
    )
  }
}

export default observer(LendBody);
