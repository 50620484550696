import React from 'react';

// Components
import { Link } from 'react-router-dom';

// Logos
import discordLogo from '../images/discord.svg';
import twitterLogo from '../images/twitter.svg';
import telegramLogo from '../images/telegram2.svg';
import mediumLogo from '../images/medium.svg';
import { APP_ROUTES } from '../constants/routes';

export const AppFooter = () => {
  return (
    <div className='app-footer'>
      <div className='app-footer__left'>
        <Link
          to={APP_ROUTES.ABOUT.route}
          className='app-footer__left-link'
        >
          About
        </Link>
        <a
          className='app-footer__left-link'
          href='https://solfarm.gitbook.io/solfarm/'
          target='_blank'
        >
          Docs
        </a>
      </div>
      <div className='app-footer__right'>
        <div
          className='app-footer__right-icon'
          onClick={() => window.open('https://discord.gg/vCaEUjnc28', '_blank')}
        >
          <img src={discordLogo} />
        </div>
        <div
          className='app-footer__right-icon'
          onClick={() => window.open('https://t.me/solfarm', '_blank')}
        >
          <img src={telegramLogo} />
        </div>
        <div
          className='app-footer__right-icon'
          onClick={() => window.open('https://twitter.com/Solfarmio', '_blank')}
        >
          <img src={twitterLogo} />
        </div>
        <div
          className='app-footer__right-icon'
          onClick={() => window.open('https://solfarm.medium.com/', '_blank')}
        >
          <img src={mediumLogo} />
        </div>
      </div>
    </div>
  );
}
