import React from 'react';
import { observer } from 'mobx-react';

// Store
import { getStore } from '../../stores/get-store';

// Components
import VaultsFilters from './VaultsFilters';
import VaultsTable from './VaultsTable';
import { WarningBanner } from '../EmptyStates/WarningBanner';

// Services
import { TransactionService } from '../../services/TransactionService';

// Utils
import { getFormattedNumber } from '../../utils/safe-math';
import Infobar from '../../base-components/Infobar';
import { PageHeading } from '../PageHeading';
import {DEPOSIT_TYPES, FARM_PLATFORMS} from '../../constants/farmConstants';
import { get } from 'lodash';
import { getFarmBySymbol } from '../../utils/farms';

// Constants
const ZERO = 0;

class VaultsBody extends React.Component {
  constructor () {
    super();

    this.handleDeposit = this.handleDeposit.bind(this);
    this.handleWithdraw = this.handleWithdraw.bind(this);
    this.checkUnsafeWithdraw = this.checkUnsafeWithdraw.bind(this);
    this.handleTulipHarvest = this.handleTulipHarvest.bind(this);
    this.handleBulkTulipHarvest = this.handleBulkTulipHarvest.bind(this);
    this.handleFixTulipRewardTokenAccount = this.handleFixTulipRewardTokenAccount.bind(this);
  }

  async handleWithdraw (assetSymbol, value, withdrawMax = false) {
    const { wallet, setTokenAccounts } = getStore('WalletStore');

    // Bail-out if wallet is not connected
    if (!wallet) {
      return;
    }

    this.props.toast('Withdrawing...');

    // Refresh token balances
    setTimeout(() => {
      setTokenAccounts();
      getStore('UIStore').resetRefreshState();
    }, 9000);

    const farm = getFarmBySymbol(assetSymbol);

    if (farm.platform === FARM_PLATFORMS.ORCA) {
      return TransactionService.handleWithdrawFromOrcaVault(assetSymbol, value, withdrawMax);
    }

    return TransactionService.handleWithdrawFromVault(assetSymbol, value, withdrawMax);
  }

  async handleDeposit (assetSymbol, ...values) {
    const { wallet, setTokenAccounts } = getStore('WalletStore');

    // Bail-out if wallet is not connected
    if (!wallet) {
      return;
    }

    this.props.toast('Depositing...');

    // Refresh token balances
    setTimeout(() => {
      setTokenAccounts();
      getStore('UIStore').resetRefreshState();
    }, 9000);

    const farm = getFarmBySymbol(assetSymbol);

    if (farm.platform === FARM_PLATFORMS.ORCA) {
      return TransactionService.handleDepositToOrcaVault(assetSymbol, values[0], values[1], values[2]===DEPOSIT_TYPES.SINGLE);
    }

    return await TransactionService.handleDepositToVault(assetSymbol, values[0]);

    // const listenerId = window.$web3.onSignature(
    //   txId,
    //   function (signatureResult, context) {
    //     const { slot } = context;
    //
    //     if (!signatureResult.err) {
    //       // success
    //       // commit('setTxStatus', [txid, 's', slot])
    //
    //       console.log({ signatureResult, context });
    //
    //       this.props.toast('Transaction has been confirmed');
    //
    //       // notify.success({
    //       //   key: txid,
    //       //   message: 'Transaction has been confirmed',
    //       //   description
    //       // })
    //     } else {
    //       // fail
    //       // commit('setTxStatus', [txid, 'f', slot])
    //
    //       // notify.error({
    //       //   key: txid,
    //       //   message: 'Transaction failed',
    //       //   description
    //       // })
    //
    //       console.log({ signatureResult, context });
    //
    //       this.props.toast('Transaction failed', 'error');
    //     }
    //   },
    //   'single'
    // );

    // return txId;
  }

  async checkUnsafeWithdraw (assetSymbol) {
    const { wallet } = getStore('WalletStore');

    // Bail-out if wallet is not connected
    if (!wallet) {
      return;
    }

    return TransactionService.checkUnsafeWithdraw(assetSymbol);
  }

  async handleFixTokenAccount (assetSymbol) {
    const { wallet } = getStore('WalletStore');

    // Bail-out if wallet is not connected
    if (!wallet) {
      return;
    }

    return TransactionService.handleFixTokenAccount(assetSymbol);
  }

  async handleFixTulipRewardTokenAccount () {
    const { wallet } = getStore('WalletStore');

    // Bail-out if wallet is not connected
    if (!wallet) {
      return;
    }

    // Refresh token balances
    setTimeout(() => {
      getStore('WalletStore').setTokenAccounts();
      getStore('UIStore').resetRefreshState();
    }, 9000);

    return TransactionService.handleFixTulipRewardTokenAccount();
  }

  handleTulipHarvest (assetSymbol) {
    return this.handleBulkTulipHarvest([assetSymbol]);
  }

  async handleBulkTulipHarvest (farms) {
    const { wallet, setTokenAccounts } = getStore('WalletStore');

    // Bail-out if wallet is not connected
    if (!wallet) {
      return;
    }

    this.props.toast('Harvesting...');

    // Refresh token balances
    setTimeout(() => {
      setTokenAccounts();
      getStore('UIStore').resetRefreshState();
    }, 9000);

    return TransactionService.handleBulkTulipHarvest(farms);
  }

  render () {
    const { wallet, tokenAccounts, isTokenAccountInvalid } = getStore('WalletStore'),
      { getFarm, visibleFarms } = getStore('FarmStore'),
      tableData = visibleFarms.map((farm) => {
        let balance = '-',
          deposited = '-',
          rewardSinceLastDeposit = ZERO,
          tulipEarned = ZERO,
          coinABalance,
          coinBBalance;

        if (wallet) {
          if (tokenAccounts[farm.mintAddress]) {
            balance = Number(tokenAccounts[farm.mintAddress].balance?.fixed());
            tulipEarned = tokenAccounts[farm.mintAddress]?.tulipEarned;

            if (farm.symbol === 'RAY-SRM-DUAL') {
              const mintAddress = `${farm.mintAddress}0`;

              if (tokenAccounts[mintAddress]) {
                deposited = tokenAccounts[mintAddress].deposited || ZERO.toFixed(farm.decimals);
                rewardSinceLastDeposit = tokenAccounts[mintAddress]?.rewardSinceLastDeposit;
              } else {
                deposited = ZERO.toFixed(farm.decimals);
              }
            } else {
              deposited = tokenAccounts[farm.mintAddress].deposited || ZERO.toFixed(farm.decimals);
              rewardSinceLastDeposit = tokenAccounts[farm.mintAddress]?.rewardSinceLastDeposit;

              // console.log("$$$ farm info", farm.symbol, tokenAccounts[farm.mintAddress]?.rewardSinceLastDeposit, balance, deposited);
            }
          }
          else {
            balance = ZERO.toFixed(farm.decimals);
            deposited = ZERO.toFixed(farm.decimals);
          }

          if (farm.platform === FARM_PLATFORMS.ORCA) {
            const coinAMintAddress = get(farm, ['coins', 0, 'mintAddress']);
            const coinBMintAddress = get(farm, ['coins', 1, 'mintAddress']);
            coinABalance = tokenAccounts[coinAMintAddress]?.balance;
            coinBBalance = tokenAccounts[coinBMintAddress]?.balance;
          }
        }

        const mintAddress = (
          farm.symbol === 'RAY-SRM-DUAL' ? `${farm.mintAddress}0` : farm.mintAddress
        );

        if (rewardSinceLastDeposit < 0) {
          rewardSinceLastDeposit = 0;
        }



        const {
          tvl = 0,
          weeklyAPY = 0,
          yearlyAPY = 0,
          dailyAPR = 0,
          price = 0,
          isUserBalanceAccountValid = false,
          tulipAPR = 0,
          lastDepositTime = 0,
          yieldBreakdown = {}
        } = getFarm(mintAddress) || {};

        const { getTokenPrice } = getStore('PriceStore');

        return {
          assetName: farm.name,
          assetSymbol: farm.symbol,
          tvl: getFormattedNumber(tvl),
          weeklyAPY: getFormattedNumber(weeklyAPY),
          yearlyAPY: getFormattedNumber(yearlyAPY),
          dailyAPR: getFormattedNumber(dailyAPR),
          balance,
          deposited,
          logos: farm.logos,
          decimals: farm.decimals,
          price,
          dualYield: Boolean(farm.dualYield),
          rewardSinceLastDeposit,
          isUserBalanceAccountValid,
          liquidityMining: Boolean(farm.liquidityMining),
          tulipAPR,
          isTokenAccountMissing: isTokenAccountInvalid(farm.mintAddress, farm?.platform),
          tvlInNumber: tvl,
          depositedInUsd: deposited * price,
          balanceInUsd: farm.platform === FARM_PLATFORMS.ORCA ?
            (Number(coinABalance?.fixed() || 0) * getTokenPrice(farm.coins[0].symbol) + Number(coinBBalance?.fixed() || 0) * getTokenPrice(farm.coins[1].symbol)) :
            balance * price,
          yearlyAPYInNumber: yearlyAPY,
          tulipEarned,
          disabled: Boolean(farm.disabled),
          controllerFee: farm.saber ? farm.sunny ? (0.5) : (0.1) : (0.1),
          platformFee: farm.saber ? farm.sunny ? (2.5) : (1.4) : (1.4),
          saber: farm.saber,
          sunny: !!farm.sunny,
          link: farm.link,
          tooltipText: farm.tooltipText,
          lastDepositTime,
          yieldBreakdown,
          platform: farm.platform,
          coinABalance,
          coinBBalance,
          coins: farm.coins,
          isNew: farm.isNew
        };
      }),
      { isDesktop } = getStore('ResponsiveStore');

    return (
      <div className='app-body'>
        <Infobar />
        {
          !isDesktop && <PageHeading />
        }
        <VaultsFilters
          onBulkTulipHarvest={this.handleBulkTulipHarvest}
          toast={this.props.toast}
          onFixTulipRewardTokenAccount={this.handleFixTulipRewardTokenAccount}
        />
        <VaultsTable
          toast={this.props.toast}
          data={tableData}
          onDeposit={this.handleDeposit}
          onWithdraw={this.handleWithdraw}
          checkUnsafeWithdraw={this.checkUnsafeWithdraw}
          onFixTokenAccount={this.handleFixTokenAccount}
          onTulipHarvest={this.handleTulipHarvest}
        />
      </div>
    )
  }
}

export default observer(VaultsBody);
