// MobX
import { observable, makeObservable, action, computed } from 'mobx';

// Lodash
import { map, isNil, assign, filter } from 'lodash';

// Utils
import { commitment, getMultipleAccounts } from '../utils/web3';

// Stores
import { getStore } from './get-store';

// web3
import * as anchor from '@project-serum/anchor';
import { FARMS } from '../utils/farms';
import { LENDING_RESERVES } from '../utils/lendingReserves';

// Constants
export const MAX_LEVERAGE_VALUE = 3,
  MIN_LEVERAGE_VALUE = 1;

export default class LeverageVaultStore {
  constructor () {
    this.leverageVaults = {};

    makeObservable(this, {
      leverageVaults: observable,
      setLeverageVault: action.bound,
      init: action.bound,
      visibleLeverageVaults: computed
    });


    this.getLeverageVault = this.getLeverageVault.bind(this);
    this.setLeverageValue = this.setLeverageValue.bind(this);

    this.init();
  }

  async init () {
    // getStore('UIStore').setIsRefreshing(true);

    // do something

    // getStore('UIStore').resetRefreshState();

    LENDING_RESERVES.forEach((reserve) => {
      this.setLeverageVault(reserve.mintAddress, {
        selectedCoinIndex: 1,
        leverageValue: MAX_LEVERAGE_VALUE
      });
    });
  }

  getLeverageVault (mintAddress) {
    return this.leverageVaults[mintAddress];
  }

  setLeverageVault (mintAddress, leverageVaultDetails) {
    if (!mintAddress || isNil(leverageVaultDetails)) {
      return;
    }
    
    !this.leverageVaults[mintAddress] && (this.leverageVaults[mintAddress] = {});

    assign(this.leverageVaults[mintAddress], leverageVaultDetails);
  }

  setLeverageValue (mintAddress, value) {
    let leverageValue = value;

    // Sanitize leverage value
    if (Number(leverageValue) < MIN_LEVERAGE_VALUE) {
      leverageValue = MIN_LEVERAGE_VALUE;
    }
    else if (Number(leverageValue) > MAX_LEVERAGE_VALUE) {
      leverageValue = MAX_LEVERAGE_VALUE;
    }

    this.setLeverageVault(mintAddress, { leverageValue });
  }

  get visibleLeverageVaults () {
    const { wallet, tokenAccounts, isTokenAccountInvalid } = getStore('WalletStore'),
      showStaked = getStore('UserPreferenceStore').get('showStaked');

    // If wallet is not connected or if `showStaked` is turned off, show all farms.
    if (!wallet || !showStaked) {
      return FARMS;
    }

    return filter(FARMS, (farm) => {
      // Show farms with invalid token accounts
      if (isTokenAccountInvalid(farm.mintAddress, farm?.platform)) {
        return true;
      }

      const tokenAccount = tokenAccounts[farm.mintAddress];

      if (!tokenAccount) {
        return false;
      }

      const deposited = Number(tokenAccounts[farm.mintAddress].deposited?.fixed());

      if (!deposited) {
        return false;
      }

      return true;
    });
  }
}
