// Logos
import copeLogo from '../coins/cope.png';
import ethLogo from '../coins/eth.png';
import rayLogo from '../coins/ray.png';
import solLogo from '../coins/sol.png';
import srmLogo from '../coins/srm.png';
import usdcLogo from '../coins/usdc.png';
import usdtLogo from '../coins/usdt.png';
import stepLogo from '../coins/step.png';
import mediaLogo from '../coins/media.png';
import fidaLogo from '../coins/fida.png';
import oxyLogo from '../coins/oxy.png';
import mapsLogo from '../coins/maps.png';
import kinLogo from '../coins/kin.png';
import merLogo from '../coins/mer.png';
import ropeLogo from '../coins/rope.png';
import alephLogo from '../coins/aleph.png';
import tulipLogo from '../coins/tulip.png';
import snyLogo from '../coins/sny.png';
import bopLogo from '../coins/bop.png';
import slrsLogo from '../coins/slrs.png';
import samoLogo from '../coins/samo.png';
import paiLogo from '../coins/pai.png';
import likeLogo from '../coins/like.png';
import atlasLogo from '../coins/atlas.png';
import polisLogo from '../coins/polis.png';
import orcaLogo from '../coins/orca.png';

// Utils
import { NATIVE_SOL, TOKENS } from './tokens';

// Lodash
import { find } from 'lodash';

export const LENDING_RESERVES = [
  {
    name: 'USDC',
    account: 'FTkSmGsJ3ZqDSHdcnY7ejN1pWV3Ej7i88MYpZyyaqgGt',
    mintAddress: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    liquiditySupplyTokenAccount: '64QJd6MYXUjCBvCaZKaqxiKmaMkPUdNonE1KuY1YoGGb',
    liquidityFeeReceiver: 'GPf4tD3q71BzPU79YCadYB2NnLciXAVmYuxfgbKKzUdU',
    collateralTokenMint: 'Amig8TisuLpzun8XyGfC5HJHHGUQEscjLgoTWsCCKihg',
    collateralTokenSupply: 'GkhrquZah6wrGvM5GntLPWaG5TDdCk6n3QPvohHxWpak',
    destinationCollateralTokenAccount:
      '4s4fdm8fsnFdbEnujgASXwDFZ43afVnSzmk7MDA9Lqhn',
    quoteTokenMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',

    decimals: TOKENS.USDC.decimals,
    // decimals: 6,
    logo: usdcLogo,
    visible: true,
  },
  {
    name: 'USDT',
    account: "Csn3exasdhDzxYApmnci3d8Khb629VmgK4NQqdeyZBNt",
    mintAddress: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
    liquiditySupplyTokenAccount: '124J21csiR1FdDywteXa8LhAmeqBXZRvozhoE7zq9znc',
    liquidityFeeReceiver: '3VQV6yjMUXaTcR6KCvZSAgEKkvZUVEQnNdyEREPbjj31',
    collateralTokenMint: 'gLhY2arqFpmVGkpbBbTi3TeWbsWevA8dqrwbKacK3vJ',
    collateralTokenSupply: 'CSeQwrb7NHGDJkThJjMPVsQ6Rwb7wMgyFJPDS9gfjxAM',
    destinationCollateralTokenAccount: '7t3cWUB6XjwGhqreuK3uxv1BKP5Luczq4VqLSEB4Q9AJ',
    quoteTokenMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',

    decimals: TOKENS.USDT.decimals,
    logo: usdtLogo,
    visible: true,
  },
  {
    name: 'RAY',
    account: '9Bm8d2izGsf9eT6Wr79DTnXBkW2LHYVQa57QzeoTbsAF',
    mintAddress: '4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R',
    liquiditySupplyTokenAccount: '9SG6E3jBTTHLNgpV6ueUYypMYMkm4K5zyS9tk9Rsjm8Y',
    liquidityFeeReceiver: '4bRQL2hLqfinNJTsiQW6odhYtYjKXH7zsPc2tafadgoj',
    collateralTokenMint: '8Lg7TowFuMQoGiTsLE6qV9x3czRgDmVy8f8Vv8KS4uW',
    collateralTokenSupply: '2ESAmYeYCW7ee9tcMUdFeW912Sjn8wjeD8odmrvJkeiN',
    destinationCollateralTokenAccount:
      'DAKrpC5HmQrUzbcqKPKE5hK5xfdXdFdf8sxZqhTct4ug',
    quoteTokenMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',

    decimals: TOKENS.RAY.decimals,
    logo: rayLogo,
    visible: true,
    borrowDisabled: true,
    // whitelisted: false
  },
  {
    name: 'SOL',
    account: 'FzbfXR7sopQL29Ubu312tkqWMxSre4dYSrFyYAjUYiC4',
    mintAddress: '11111111111111111111111111111111',
    liquiditySupplyTokenAccount: 'CPs1jJ5XAjhcAJsmTToWksAiPEqoLwKMbb1Z83rzaaaU',
    liquidityFeeReceiver: '9GfaYar1r2HrGANq5qNiQnx88HJ7GULQqxXxt2B4U3Zy',
    collateralTokenMint: 'H4Q3hDbuMUw8Bu72Ph8oV2xMQ7BFNbekpfQZKS2xF7jW',
    collateralTokenSupply: '8sVTGPsSYkAXFS4tfW5k5z9FvcWhvBqYdMyiiWbbuT7g',
    destinationCollateralTokenAccount:
      '77QZQaxe6Gyn3zw2rnHYR2NmG5KaEGbmqPYmjuC4qguB',
    quoteTokenMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',

    decimals: NATIVE_SOL.decimals,
    // decimals: 6,
    logo: solLogo,
    visible: true,
  },
  {
    name: 'SRM',
    account: '9AiGVt7Qtap2ijvim4JSudDYgTrSWhwaZmKv8BWGFms9',
    mintAddress: 'SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt',
    liquiditySupplyTokenAccount: '93JKmnXMYHQ9KPkKRRJ5Mb7bqSv4newhDFvz9QE2suRG',
    liquidityFeeReceiver: 'EMwowECdn8hdGf89RuyGL8cbygwa14BN9tLbYDXgMckr',
    collateralTokenMint: '4QSK13NTKxTBExbMjHFsj3QfHBn4Hfp3DGLSba8GvFvh',
    collateralTokenSupply: '9KJNRpfHcdBZH5tTCBsxdUQXrJitpQpmU9T7GxQTQpYK',
    destinationCollateralTokenAccount: '4R4V2LatVjWUMpNj5XtfgG4rhPWMwk2ouVyN8gXXbYME',
    quoteTokenMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    decimals: TOKENS.SRM.decimals,
    logo: srmLogo,
    visible: true,
  },
  {
    name: "whETH",
    account: "7n9bDFUxehuw7yAHnK9eWKSfMx5u6NYWPVLKYnz93fzj",
    mintAddress: "7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs",
    liquiditySupplyTokenAccount: "EWhUcqPMNVetwrJLJhQSkpzE5sU8nAVtRmJJV2EpgD3g",
    liquidityFeeReceiver: "A4RG7kMBBhCUqu7Mge79m7X4ZYHMQ2CTtv8RzF2PLNMu",
    collateralTokenMint: "85SyKDLXZPC1sqdFqasywQojutGhDCobbPHM6zczD1Li",
    collateralTokenSupply: "Ft59j4mGN3JwD9rixqpepFJoAR2VjDyoESH6qApFQtw7",
    destinationCollateralTokenAccount: "5E2FmPnj2Yrn7ZbiGLYtesHQHuUiy4hUKacr7sywcsTv",
    quoteTokenMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    decimals: TOKENS.whETH.decimals,
    logo: ethLogo,
    visible: true,
  },

  {
    name: "ETH",
    account: "FxH3A2Bw9q3MDivXuWtr8zxiN3S7dGTEkK4Mm95NR2yB",
    mintAddress: "2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk",
    liquiditySupplyTokenAccount: "GfPzHMkxRWwM3i2a17ENLo5iRRFdb4iNQAGjTAEGkxJY",
    liquidityFeeReceiver: "4oVF2swaCseP9HxsmUrzxbEzxgHPYpyRMwGM8VUfaBiC",
    collateralTokenMint: "C1gwRSpKFu4Vjrg9MhNfRjg65SV4CNLsb3C6d7kWFEyV",
    collateralTokenSupply: "HnqohLynuMh1JZdKUgbS8v6K1sG2QpVEUQqahdtqKXP6",
    destinationCollateralTokenAccount: "3UWp5EuVFpeTpgRRGBudDfYJtiaQWcDYVRJ7scQL4hyo",
    quoteTokenMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    decimals: TOKENS.ETH.decimals,
    logo: ethLogo,
    visible: true,
  },

  {
    name: "ATLAS",
    account: "41Pgp5bSZtNgLiWuwi16Nhf6o75cKzbkKULUd53EFdcU",
    mintAddress: "ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx",
    liquiditySupplyTokenAccount: "4U6C1Ym7aBfMNPGMrZqJBVDknhGYmqNM7a2SpcMJreCP",
    liquidityFeeReceiver: "GUCRZ7sESTRV5WP9k4w1yVHjNCUrD72iSTx9veSjukzk",
    collateralTokenMint: "9eGNc4BZCAgpTSEjbu7ACCLjpnZh1WSdts3y4nMik4e7",
    collateralTokenSupply: "9uv5bScpye91V22KU5bgVebQGx1yg98MHWUNfDeYoynJ",
    destinationCollateralTokenAccount: "5LrfV4Bnj1uioSKAHTbL5hTh8JTKDLBKxGFxcSDeE2se",
    quoteTokenMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    decimals: TOKENS.ATLAS.decimals,
    logo: atlasLogo,
    visible: true,
    borrowDisabled: true
  },
  {
    name: "POLIS",
    account: "7hxTjiLvBuZcUQnztSRhtvthcsVdu7Na5WWXocwBWA8y",
    mintAddress: "poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk",
    liquiditySupplyTokenAccount: "5XHT644Lbgn7HCY93JSu8Vp8QjfmrEz8ZvD5QyB3EieE",
    liquidityFeeReceiver: "64bogB1G4Km8Toequs4bcwmrPb9evrzof2CugFpL8SNN",
    collateralTokenMint: "658FZo9B4HgKxsKsM7cUHN7jfNFgC7YftusWWYWc4piD",
    collateralTokenSupply: "3AH6WZBW5yYbS6shZAvQTB4iW5d4Shb1EBMMPThGzyQP",
    destinationCollateralTokenAccount: "3x5ZLLUwreSHmdPqegC3dt8jH6TXzmqSTxc9iFnt8Y9e",
    quoteTokenMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    decimals: TOKENS.POLIS.decimals,
    logo: polisLogo,
    visible: true,
    borrowDisabled: true
  },
  {
    name: "TULIP",
    account: "DdFHZu9n41MuH2dNJMgXpnnmuefxDMdUbCp4iizPfz1o",
    mintAddress: "TuLipcqtGVXP9XR62wM8WWCm6a9vhLs7T1uoWBk6FDs",
    liquiditySupplyTokenAccount: "CTY1eFRYza4nr7Hw4aFXoWwvcAmF99UtgSyTFWWUdJTf",
    liquidityFeeReceiver: "HyPhJfWDoXbsu6AaNmJzc7hwnMG59XcnQPXU1dDE1mJg",
    collateralTokenMint: "Am2kxXzFH84biqbswGWq2zieWqsX2ANnFDyiZr9Fh7zc",
    collateralTokenSupply: "7ffRNyEMYoHZ4eZNHhDPDQTVVXa5dtgoLCwWWkiprCdG",
    destinationCollateralTokenAccount: "CQMNF7T32Tg6oryUVSptFiKb4SA4j8f48GenUC8CPcei",
    quoteTokenMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    decimals: TOKENS.TULIP.decimals,
    logo: tulipLogo,
    visible: true,
    borrowDisabled: true
  },
  {

    name: "STEP",
    account: "HpYGGceBPSWhemfsUtdAXjDJpTiWa6MppMr8LaCfkwyX",
    mintAddress: "StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT",
    liquiditySupplyTokenAccount: "ArUwRwHqhg9nYxaJ4643coVggWmnAK9WhkpkR6CZiVSS",
    liquidityFeeReceiver: "8EQyWbvZjb5En3JRY6bmSYkcsWt6poUdBBC2gi3UL43a",
    collateralTokenMint: "36sf6j9N4zSv4BVP1nNPZfSxmuCDxv3Ay9Kq6qy37PXv", // (ssj): this is wrong
    collateralTokenSupply: "7QJw944wtodBV2bVfd2ajHxeAv99Zopo39QyvF3anHMb",
    destinationCollateralTokenAccount: "FKctxJ52noZmRN9uofXJi2gJy4Fk7A8d87bdaiCYi7Qu",
    quoteTokenMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    decimals: TOKENS.STEP.decimals,
    logo: stepLogo,
    visible: false,
  },
  {
    name: "ORCA",
    account: "6sJg8f3zcAjrd38QhSA3C34n8MzLq1XVTiQr4msozAuv",
    mintAddress: "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
    liquiditySupplyTokenAccount: "4LqykQes5scGz4bZLNaNm1bmgMutCCGqQZVni4VJjWhZ",
    liquidityFeeReceiver: "ADz1SJ28TH2TnLzA9FG7DqiW41CKeLfRvbvDckK1dvFv",
    collateralTokenMint: "HRYfSMc1gYEvxo9zsh14jYHKxAcfJfoVakKtRtJEomb8",
    collateralTokenSupply: "HY3edUqqoHFXDTYkFpsv6hHzH81Qiiq3DqCgRH37uP2c",
    destinationCollateralTokenAccount: "5hGH1JPeQzzAZv7DrZNiTyvMj8GEEVQDWR4bqAFzXieu",
    quoteTokenMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    decimals: TOKENS.ORCA.decimals,
    logo: orcaLogo,
    visible: true,
    borrowDisabled: true
  },
  {
    name: "SNY",
    account: "DiC9QF1MLQgzVNRDUdLaDgmAQ5JA8ksd8AaaYrJbEHnD",
    mintAddress: "4dmKkXNHdgYsXqBHCuMikNQWwVomZURhYvkkX5c4pQ7y",
    liquiditySupplyTokenAccount: "HpRAzc4ioizof1ndfJmYKJMvcLP9s9NhVWRXVnMiSV5A",
    liquidityFeeReceiver: "FEfvNVCPXDpBuyx6kdRixxMPJGnqNfAXbRA29KQfsYiU",
    collateralTokenMint: "2yQJdxJy4tGeeXK2u8Lwdy9oY6Ks5shVH9gYtRH9zdDw",
    collateralTokenSupply: "Doia9pBqHjtcRZCVaFoENfRYc6G5TaTmAJpeoeMPVbab",
    destinationCollateralTokenAccount: "9HNaGXfNef3iCeZUKCUDmuVrpoCCf3n4MbdqrbRuUxZ",
    quoteTokenMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    decimals: TOKENS.SNY.decimals,
    logo: snyLogo,
    visible: true,
    borrowDisabled: true
  },
  {
    name: "ROPE",
    account: "BAkQnFTVBHE9XGo7rEidRMEhrFyXXxKPchW2KXtkPKzG",
    mintAddress: "8PMHT4swUMtBzgHnh5U564N5sjPSiUz2cjEQzFnnP1Fo",
    liquiditySupplyTokenAccount: "6t6M4y22fKEY2RbtKKdrtxQMJKwDrmQLX24KjthbEwdB",
    liquidityFeeReceiver: "8FYkBrPaN9Lk7nRAWNRx9UVtzcPUg1Rm7YVFPvFpc9MZ",
    collateralTokenMint: "9oYxGZP4PWFLm9CFJb9YG6LiZZNz2StPW2yx5oK9k7df",
    collateralTokenSupply: "7k9tKTzMdCuxm9ocGEFUkDnZTw5K4acAzawyV1narjQ1",
    destinationCollateralTokenAccount: "HdxLdWVJHha1BZVw9gp8WnKgLR4q9tPgyMRim16H8U88",
    quoteTokenMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    decimals: TOKENS.ROPE.decimals,
    logo: ropeLogo,
    visible: false,
  },
  {
    name: "COPE",
    account: "DrYZA2Q6eBwFD7d2x8mmsLWNcQJGVEX6ntF9nMmNpPBe",
    mintAddress: "8HGyAAB1yoM1ttS7pXjHMa3dukTFGQggnFFH3hJZgzQh",
    liquiditySupplyTokenAccount: "7v1EmKhBa4deAtMia21CpZ1uUqgFg8oGiXt314x6qpq2",
    liquidityFeeReceiver: "6pFsWsCurqJ3Qzv6yUvXTcvR1p9Zb2TwSPiPAsVJ6pWd",
    collateralTokenMint: "8cm7UrBiDQ4C1ntQSCZfHSWKUizdW31ddTQGNY6Lym3B",
    collateralTokenSupply: "EfFKjzmFtoPPBoz1V78cgvQegoFFE3AKanBh2AT7p1kz",
    destinationCollateralTokenAccount: "LX7xER7JXiy8KrK1NnnRN44fN7LFJay6iUqH4cXBLKu",
    quoteTokenMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    decimals: TOKENS.COPE.decimals,
    logo: copeLogo,
    visible: true,
    borrowDisabled: true
  },
  {
    name: "ALEPH",
    account: "5nestDtwfXxCHbJ7BcWgucBmSp7ApxUtW9cDwDa3apED",
    mintAddress: "CsZ5LZkDS7h9TDKjrbL7VAwQZ9nsRu8vJLhRYfmGaN8K",
    liquiditySupplyTokenAccount: "4NaiudHa6ApXqgfLBDvZqQLrabLvMCKtw1vieHfVZpo4",
    liquidityFeeReceiver: "132bKdc6DwnxATSPR2BVZAWtL67327JiVoHzpbNJs7np",
    collateralTokenMint: "FJtaAZd6tXNCFGTq7ifRHt9AWoVdads6gWNc4SXCPw1k",
    collateralTokenSupply: "35jP1V7JQby5wLo3yutNa6dZ2nwWUoVUKiyc63Wr2dB4",
    destinationCollateralTokenAccount: "DKfXtqJFy5YCD4r4Uxyjampf7p6ahmS7FuAmkRzP6QbW",
    quoteTokenMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    decimals: TOKENS.ALEPH.decimals,
    logo: alephLogo,
    visible: true,
    borrowDisabled: true
  },
  {
    name: "MEDIA",
    account: "4YUqefxqtfa8PwFynQKJjDC74cJzPtR69uP1UqZKQLZQ",
    mintAddress: "ETAtLmCmsoiEEKfNrHKJ2kYy3MoABhU6NQvpSfij5tDs",
    liquiditySupplyTokenAccount: "5QWDi7dBdbAXPcVUbxzz93p6ptQAi3AbHQem2CFquX6k",
    liquidityFeeReceiver: "CCEi57RYB3APLho4pv4fqDXqxezkvVwkMnZhKPCWJHCK",
    collateralTokenMint: "6fGTc455JK3bsiSrvyLkEymQasPDXdrw2jJR16UkPnT5",
    collateralTokenSupply: "7WMg5Y5JANsucf5noBMDVJ4kAqu5Y27KGpDdsc6xqukT",
    destinationCollateralTokenAccount: "DAxvxAb2K6aFadQqn1rsJphirCS6AFM7mJrApPLSgELo",
    quoteTokenMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    decimals: TOKENS.MEDIA.decimals,
    logo: mediaLogo,
    visible: true,
    borrowDisabled: true
  },
  {
    name: "SLRS",
    account: "3YzfgFtos1cq1PGWABSYXj3txhwAnWjVZxacPafMPwZH",
    mintAddress: "SLRSSpSLUTP7okbCUBYStWCo1vUgyt775faPqz8HUMr",
    liquiditySupplyTokenAccount: "ARioqrJfVAP8AFT1xaiEFqiajY6Nhq2CS17mMi6A8Urz",
    liquidityFeeReceiver: "CTnTSgQqYbu8vMoKuoCjzu6SCyERNKx9pHqj7icqvTDq",
    collateralTokenMint: "GtFtWCcLYtWQT8NLRwEfUqc9sgVnq4SbuSnMCpwcutNk",
    collateralTokenSupply: "FYNSmQBv6WxJETxDzrpwPpda1sohzMX8xbEdkquMoysr",
    destinationCollateralTokenAccount: "GfWjrthTwB9DHwNQirS1XfGiFcRm9Q6KrjJdGyTATjCd",
    quoteTokenMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    decimals: TOKENS.SLRS.decimals,
    logo: slrsLogo,
    visible: true,
    borrowDisabled: true
  },
  {
    name: "LIKE",
    account: "BQk6St4EmdBUo6jx4XCM9bobwV7wwcc4L7QwZHgN3GwL",
    mintAddress: "3bRTivrVsitbmCTGtqwp7hxXPsybkjn4XLNtPsHqa3zR",
    liquiditySupplyTokenAccount: "8W8i2jbRYiw8BQ3aQUv7k7cvQztAidjR263y5aWyHRbE",
    liquidityFeeReceiver: "GYUAka5Ps7b1yTLBdU7Ct91662Q8sacm4GpWCjfYoRaD",
    collateralTokenMint: "DRu91PV94sb6kX6HwMGnGM8TuHrjycS4FmJNRWEgyw6n",
    collateralTokenSupply: "2NfkjMBcVTjMx4adV6LQsLjm3amB1HFXoo8wEjktRgTx",
    destinationCollateralTokenAccount: "3raVVLvK8V8UhxAWKdeFBPLBj8vBU3cyuJ6MpuY4Gdm1",
    quoteTokenMint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    decimals: TOKENS.LIKE.decimals,
    logo: likeLogo,
    visible: true,
    borrowDisabled: true
  },
];

export const getReserveByName = (name) => find(LENDING_RESERVES, (reserve) => reserve.name === name);
export const getReserveByMintAddress = (mintAddress) => find(LENDING_RESERVES, (reserve) => reserve.mintAddress === mintAddress);
export const getReserveByCollateralTokenMint = (collateralTokenMint) => find(LENDING_RESERVES, (reserve) => reserve.collateralTokenMint === collateralTokenMint);
