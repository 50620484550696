import React from 'react';
import AppHeader from '../AppHeader';
import { useHistory, useLocation } from 'react-router-dom';
import { WorkInProgress } from '../EmptyStates/WorkInProgress';
import { AppFooter } from '../AppFooter';
import YourPositionsBody from './YourPositionsBody';

export const YourPositionsPage = (props) => {
  const { notify } = props;
  const location = useLocation();
  const history = useHistory();

  return (
    <div className='app'>
      <AppHeader
        path={location.pathname}
        toast={notify}
        history={history}
      />
      {/* <WorkInProgress /> */}
      <YourPositionsBody
        toast={notify}
      />
      <AppFooter />
    </div>
  );
}