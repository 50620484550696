import { Observer } from 'mobx-react';
import React from 'react';
import { getStore } from '../../stores/get-store';
import { NATIVE_SOL } from '../../utils/tokens';
import IconWithTooltip from '../../base-components/IconWithTooltip';
import classnames from 'classnames';
import warnIcon from '../../icons/warning.svg';
import { GENERIC_MIN_BALANCE } from '../../constants/minSolBalance';

const getTooltipText = (minBalanceRequired) => `SOL is needed for Solana network fees. 
  A minimum balance of ${minBalanceRequired} SOL is recommended to avoid failed transactions.`;

export default function LowSolWarningAlert ({ className, minBalanceRequired }) {
  return (
    <Observer>
      {
        () => {
          const { isLowOnSolBalance } = getStore('WalletStore');

          if (!isLowOnSolBalance(minBalanceRequired)) {
            return null;
          }

          return (
            <div className={classnames('low-sol-warning', className)}>
              <IconWithTooltip
                icon={warnIcon}
                tooltipText={getTooltipText(minBalanceRequired)}
                placement='bottom'
              />
              <span>Your SOL balance is low.</span>
            </div>
          )
        }
      }
    </Observer>
  )
}

LowSolWarningAlert.defaultProps = {
  minBalanceRequired: GENERIC_MIN_BALANCE
};