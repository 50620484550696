import React from 'react';
import classNames from 'classnames';

export default function Logo (props) {
  const { logo, className = '', alt = 'logo', size = 'md' } = props,
    classes = [
      'logo',
      {
        [`logo-${size}`]: size
      },
      className
    ];

  if (!logo) {
    return null;
  }

  return (
    <img
      className={classNames(...classes)}
      src={logo}
      alt={alt}
    />
  );
}
