import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { getStore } from '../stores/get-store';
import { Button } from 'reactstrap';

class Infobar extends React.Component {
  constructor (props) {
    super(props);

    // Initialize the store
    this.infobarStore = getStore('InfobarStore');
  }

  getClassNames (type = 'info') {
    // Type can be: info, error, success and warn
    return classNames(
      {
        [`infobar--${type}`]: !!type
      },
      'infobar'
    );
  }

  render () {
    const { activeInfo = {} } = this.infobarStore;

    if (!activeInfo || !activeInfo.props) {
      return null;
    }

    return (
      <div
        className={this.getClassNames(activeInfo.props.type)}
      >
        <div className='infobar__message'>
          {activeInfo.props.message}
        </div>
        {
          activeInfo?.props?.primaryAction && 
            <Button
              outline
              onClick={activeInfo?.props?.primaryAction?.onClick}
            >
              {activeInfo.props.primaryAction.label}
            </Button>
        }
      </div>
    );
  }
}

export default observer(Infobar);
