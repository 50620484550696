import React from 'react';
import VaultsBody from './VaultsBody';
import AppHeader from '../AppHeader';
import { useHistory, useLocation } from 'react-router-dom';
import { AppFooter } from '../AppFooter';

export const VaultsPage = (props) => {
  const { notify } = props;
  const location = useLocation();
  const history = useHistory();

  return (
    <div className='app'>
      <AppHeader
        path={location.pathname}
        toast={notify}
        history={history}
      />
      <VaultsBody toast={notify} />
      <AppFooter />
    </div>
  );
}
